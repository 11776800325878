import React, {Component} from 'react';
import {Product} from "../models";
import InlineEditProduct from "./InlineEditProduct";

interface Props {
    product: Product

    reloadProductMappings()
}

interface State{
    editing: boolean
}

class EditableProductName extends Component<Props, State> {
    constructor(props) {
        super(props);

        this.state = {
            editing: false
        }
    }

    toggleEditing(e) {
        e.stopPropagation();
        this.setState({editing: !this.state.editing});
        return false;
    }

    render() {
        return (
            <div>
                {this.props.product.name}
                <br/>
                <button onClick={this.toggleEditing.bind(this)} className="btn btn-sm">Edit</button>

                {this.state.editing &&
                    <InlineEditProduct
                        mergeSrcProductId={this.props.product.id}
                        onSubmitted={this.props.reloadProductMappings}
                    />
                }
            </div>
        );
    }
}

export {EditableProductName}
