import React, {Component} from "react";
import {Picking} from "./models";
import {IGlobalDataContext, withGlobalData} from "./contexts/global-data";
import {Link} from "react-router-dom";
import {DisplayTime} from "./display_time";
import DisplayPickingState from "./display_picking_state";

interface Props extends IGlobalDataContext {
    pickings: Array<Picking>
}

interface State {
}

class _PickingsTable extends Component<Props, State> {
    constructor(props) {
        super(props);

        this.state = {
        };
    }

    render(): React.ReactElement<any, string | React.JSXElementConstructor<any>> | string | number | {} | React.ReactNodeArray | React.ReactPortal | boolean | null | undefined {
        let rows = this.props.pickings.map((picking) => {
            return (<tr key={picking.id}>
                <td><Link to={`/pickings/${picking.id}`}>{picking.id}</Link></td>
                <td><DisplayTime timestamp={picking.updateTime}/></td>
                <td><DisplayPickingState picking={picking}/></td>
                <td>{picking.vendingMachineCount}</td>
                <td>{picking.totalQuantity}</td>
                <td><DisplayTime timestamp={picking.scheduledVisitTime}/></td>
            </tr>)
        });

        return (
            <table className={"table table-condense"}>
                <thead>
                <tr>
                    <th>ID</th>
                    <th>Last Update Time</th>
                    <th>State</th>
                    <th>Vending Machine Count</th>
                    <th>Total Quantity</th>
                    <th>Scheduled Visit</th>
                </tr>
                </thead>
                <tbody>
                {rows}
                </tbody>
            </table>
        );
    }
}

export const PickingsTable = withGlobalData(_PickingsTable);
