import React, { Component} from 'react';
import {CSVLink} from "react-csv";
import moment from "moment";
import {getAPI} from "./api";
import {Transaction} from "./models";


interface Props {
    pathVariable : string
    vendingMachineUuid: string
}

interface State {
    data:Transaction[]
    ready:boolean
}

class DownloadTransactionsCsv extends Component<Props, State> {
    private csvLink: React.RefObject<any>;
    constructor(props: Props) {
        super(props);

        this.state = {
            data : [],
            ready: false,
        };
        this.csvLink = React.createRef();
    }

    getData(){
        getAPI(`/api/vending_machines/${this.props.vendingMachineUuid}/sales_csv_data?${this.props.pathVariable}`).then((response)=>{
            console.log("all csv data", response)
            this.setState({
                data:response.data.transactionInformations
            }, ()=>{
                this.setState({ready:true}, ()=>{this.csvLink.current.link.click();})
            })
        })
    }

    prepareCsvHeader(){
        return [
            {label: 'Date', key: 'date'},
            {label: 'Item Number', key: 'item_number'},
            {label: 'Product', key: 'product'},
            {label: 'Price', key: 'price'},
            {label: 'Surcharge', key: 'surcharge'},
            {label: 'Payment Method', key: 'payment_method'},
        ]
    }

    prepareCsvData(){
        return this.state.data.map((transaction)=>{
            let transactionExport:any = {}
            transactionExport['date'] = moment.unix(transaction.timestamp).toString()
            transactionExport['item_number'] = transaction.itemNumber
            transactionExport['product'] = transaction.product?.name
            transactionExport['price'] = transaction.itemPrice
            transactionExport['surcharge'] = transaction.cashlessSurcharge
            transactionExport['payment_method'] = transaction.paymentMethod
            return transactionExport;
        });
    }

    render() {
        return <div>
            <button className="btn btn-success btn-sm" onClick={this.getData.bind(this)}>Download As CSV</button>

            <CSVLink
                data={this.prepareCsvData()} headers={this.prepareCsvHeader()}
                filename={'transactions_'+moment().unix()+'_.csv'}
                className="hidden"
                ref={this.csvLink}
                target="_blank"
            />
        </div>
    }

}

export {DownloadTransactionsCsv}
