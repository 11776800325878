import React, {Component} from "react";
import {IGlobalDataContext, withGlobalData} from "./contexts/global-data";
import {
    TelemetryvendingMachinev1ScreenSaverMediaType,
    V1ScreenSavers
} from "./telemetry/vending_machine/v1/typescript-axios";
import {mediaManagementServiceApi} from "./api";
import Modal from "react-modal"
import {ScreenSaverEditPage} from "./screen_edit_page";
interface Props extends IGlobalDataContext {
    screenSavers: Array<V1ScreenSavers>
    onDelete: () => {}
    onUpdate: () => {}
}

interface State {
    playVideoUrl:string,
    openPlayVideoModel:boolean,
    openEditScreenSaverModal:boolean
    currEditScreenSaver:V1ScreenSavers|null
}

const videoPlayModalStyles = {
    content: {
        top: '50%',
        left: '50%',
        right: 'auto',
        bottom: 'auto',
        marginRight: '-50%',
        maxHeight:"60rem",
        transform: 'translate(-50%, -50%)',
    },
};

class _ScreenSaversTable extends Component<Props, State> {
    constructor(props) {
        super(props);

        this.state = {
            playVideoUrl:"",
            openPlayVideoModel:false,
            openEditScreenSaverModal:false,
            currEditScreenSaver:null
        };
    }


    async deleteScreen(e) {
        let userResponse = window.confirm("Are you sure you want to delete this screen saver?");

        if (userResponse) {
            let data = await new mediaManagementServiceApi().mediaManagementServiceDeleteScreenSaver({
                screenSaverUuid: e,
            })
            if (data.status !== 200) {
                alert("delete screen saver  fail ")
            } else {
                this.props.onDelete()
            }
        }
    }

    openEditScreenSaver(screenSaver) {
        this.setState({openEditScreenSaverModal: true, currEditScreenSaver: screenSaver})
    }

    closeEditScreenSaver(){
        this.setState({openEditScreenSaverModal:false,currEditScreenSaver:null})
    }

    secondsToTime(timestamp) {
        let date = new Date(parseInt(timestamp) * 1000);
        let year = date.getFullYear();
        let month = date.getMonth() + 1;
        let day = date.getDate();
        let hours = date.getHours();
        let minutes = date.getMinutes();
        let seconds = date.getSeconds();

        return year + '-' + this.addZero(month) + '-' + this.addZero(day) + ' ' + this.addZero(hours) + ':' + this.addZero(minutes) + ':' + this.addZero(seconds);
    }

    addZero(num) {
        if (num < 10) {
            return '0' + num;
        } else {
            return num;
        }
    }

    playVideo(e){
        this.setState({playVideoUrl:e.target.src,openPlayVideoModel:true})
    }
    closePlayVideoModal(){
        this.setState({playVideoUrl:"",openPlayVideoModel:false})
    }

    render(): React.ReactElement<any, string | React.JSXElementConstructor<any>> | string | number | {} | React.ReactNodeArray | React.ReactPortal | boolean | null | undefined {
        let rows = this.props.screenSavers.map((item) => {
            return (<tr key={item.uuid}>
                <td>{item.uuid}</td>
                <td>{item.mediaType}</td>
                <td>{item.duration}</td>
                <td>
                    {
                        item.mediaType === TelemetryvendingMachinev1ScreenSaverMediaType.Image ? <img src={item.screenUrl} alt={"screen saver"} width={"100px"}/> :
                            <video width={"100px"} height={"100px"} src={item.mediaLowQualityUrl} onClick={this.playVideo.bind(this)}>
                            </video>
                    }
                </td>
                <td><pre style={{maxWidth:"300px"}}>{item.cta}</pre></td>
                <td>{this.secondsToTime(item.createdAt)}</td>
                <td>{this.secondsToTime(item.updatedAt)}</td>
                <td>{item.mediaType === TelemetryvendingMachinev1ScreenSaverMediaType.Video && item.mediaConvertJobStatus }</td>
                <td>
                    <button onClick={() => this.openEditScreenSaver(item)} className={"btn btn-sm btn-primary"}>Edit</button>
                    <button onClick={() => this.deleteScreen(item.uuid)} className={"btn btn-sm btn-danger"}>Remove</button>
                </td>
            </tr>)
        });

        return (
            <div>
                <table className={"table table-condense"}>
                    <thead>
                    <tr>
                        <th>ID</th>
                        <th>Media Type</th>
                        <th>Duration (seconds)</th>
                        <th>Screen Saver</th>
                        <th>Description</th>
                        <th>Created At</th>
                        <th>Last Updated Time</th>
                        <th>Video Convert Status</th>
                        <th>Action</th>
                    </tr>
                    </thead>
                    <tbody>
                    {rows}
                    </tbody>
                </table>

                <Modal
                    isOpen={this.state.openPlayVideoModel}
                    style={videoPlayModalStyles}
                    ariaHideApp={false}
                    contentLabel="Video Modal"
                    shouldCloseOnOverlayClick={this.closePlayVideoModal}
                >
                    <div>
                        <video style={{maxHeight:"50rem"}} src={this.state.playVideoUrl} controls ></video>
                        <div><button className={"btn btn-sm btn-primary"} onClick={this.closePlayVideoModal.bind(this)}>close</button></div>
                    </div>
                </Modal>

                {this.state.currEditScreenSaver &&
                    <ScreenSaverEditPage screenSaver={this.state.currEditScreenSaver} openEditModal={this.state.openEditScreenSaverModal} onUpdate={this.props.onUpdate} closeEditScreenSaver={this.closeEditScreenSaver.bind(this)}/>}

            </div>
        );
    }
}

export const ScreenSaversTable = withGlobalData(_ScreenSaversTable);