import React, {Component} from "react";
import {VendingMachinesTable} from "./vending_machines_table";
import {IGlobalDataContext, withGlobalData} from "./contexts/global-data";
import {VendingMachinesWithLoadingState} from "./models";
import {Helmet} from "react-helmet";


interface Props extends IGlobalDataContext {

}

interface State {
}

class VendingMachineListRaw extends Component<Props, State> {
    constructor(props) {
        super(props);

        this.state = {};
    }


    render() {
        const machines: VendingMachinesWithLoadingState = {
            vendingMachines: this.props.allVendingMachines.allVendingMachines,
            loading: this.props.allVendingMachines.initialLoading,
            failedToLoad: this.props.allVendingMachines.failedToLoad,
            errorMessage: this.props.allVendingMachines.errorMessage
        }

        return (
            <section className="content">
                <Helmet titleTemplate="%s - Vending on Track">
                    <title>Vending Machines</title>
                </Helmet>
                <h1>Vending Machines</h1>

                {!machines.failedToLoad &&
                <div className="box">

                    <VendingMachinesTable
                        withFilter={true}
                        vending_machines={machines}
                        isLinkedToSettings={false}/>
                </div>
                }
                {machines.failedToLoad &&

                <div className={"box"}>
                    <div className="callout callout-warning">
                        <p><b>Warning: </b> {machines.errorMessage}</p>

                        <hr/>
                        <p>Vending on Track's team has been notified of this.</p>
                    </div>

                </div>
                }
            </section>
        );
    }
}

export const VendingMachineList = withGlobalData(VendingMachineListRaw);
