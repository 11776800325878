import {VendingMachine} from "./models";
import React, {useEffect, useState} from "react";
import { ManagementServiceApi} from "./api";
import BusyIndicator, {LoadingSpanner} from "./busy_indicator";
import {VendingMachineConfigurationPanel} from "./vending_machine_configuration_panel";
import {VendingMachineBlackOutPanel} from "./vending_machin_blackout_panel";
import {V1MachineDetailResponse} from "./telemetry/vending_machine/v1/typescript-axios";

export function VendingOnTrackInside(props: { vendingMachine: VendingMachine }) {
    const [delay, setDelay] = React.useState(0);
    const [updatingVendDelay, setUpdatingVendDelay] = React.useState(false);

    const [restartLoading, setRestartLoading] = React.useState(false);
    const [machineDetail, setMachineDetail] = useState<V1MachineDetailResponse | null>(null)

    useEffect(() =>{
        loadMachineConfiguration();
        // eslint-disable-next-line
    },[props.vendingMachine])


    const loadMachineConfiguration = () => {
        new ManagementServiceApi().machineManagementServiceMachineDetail(props.vendingMachine.uuid).then(
            res => {
                setMachineDetail(res.data)
            }).catch((e) => {
            alert("get machine detail error " + e)
        });
    }

    const resetVendDelay = async (vmid: string) => {
        setRestartLoading(true);
        if (window.confirm("Are you sure to reset waiting period?")) {
            try {
                let restartResponse = await new ManagementServiceApi().machineManagementServiceResetVendDelayPeriod({"vmid": vmid})
                if (restartResponse.status !== 200) {
                    alert("Failed to reset vend delay.")
                } else {
                    alert("Successfully reset vend delay")
                }
            } catch (e) {
                alert("Failed to reset vend delay.")
            } finally {
                setRestartLoading(false);
            }
        }
    }

    const updateVendDelay = async () => {
        setUpdatingVendDelay(true);

        try {
            let updateVendDelayResponse = await new ManagementServiceApi().machineManagementServiceUpdateVendDelay({
                "vmid": props.vendingMachine.uuid,
                'delay': delay.toString(10)
            })
            console.log("updateVendDelayResponse", updateVendDelayResponse)

            if (updateVendDelayResponse.status !== 200) {
                alert("Failed to update vend delay setting for this vending machine.")
            } else {
                alert("Successfully updated vend delay settings")
            }
        } catch (e) {
            alert("Failed to update vend delay setting for this vending machine.")
        } finally {
            setUpdatingVendDelay(false);
        }
    }

    const [restartingVendingMachine, setRestartingVendingMachine] = React.useState(false);
    const restartVendingMachine = async (vmid: string) => {
        setRestartingVendingMachine(true);

        if (window.confirm("Are you sure to restart your vending machine?")) {
            try {
                let restartResponse = await new ManagementServiceApi().machineManagementServiceRestartMachine({"vmid": vmid})
                if (restartResponse.status !== 200) {
                    alert("Failed to restart vending machine.")
                } else {
                    alert("Vending machine restarted successfully.")
                }
            } catch (e) {
                alert("Failed to restart vending machine.")
            } finally {
                setRestartingVendingMachine(false);
            }
        }

    }


    return <div className={"box"}>
        <div className={"box-header"}>
            <h1>
                VoTi
                [
                <span>{props.vendingMachine.name}</span>
                ]
            </h1>


            <div
                className={"hidden-xs box"}>
                <div className={"box-header"}>
                    <h4>VoTi</h4>
                </div>

                <div className={"box-body"}>
                    VoTi (Vending Machines with Vending on Track Inside) features some of the most advanced vending
                    machine innovations. It includes,
                    <ul>
                        <li>Real-time remote pricing update</li>
                        <li>Remote vending machine configuration and management</li>
                        <li>Operating Hours management features</li>
                        <li>Remote touchscreen advertisement management and report</li>
                    </ul>
                </div>
            </div>
        </div>
        <div className={"box-body"}>
            <section className="content">
                <section className="content-body">
                    <h3>Restart Vending Machine</h3>
                    <BusyIndicator busy={restartingVendingMachine} busyIndicator={<LoadingSpanner/>}>
                        <button onClick={() => restartVendingMachine(props.vendingMachine.uuid)}
                                className="btn btn-danger">Restart
                        </button>
                    </BusyIndicator>
                    <hr/>

                    <h3>Reset Waiting Period</h3>
                    <BusyIndicator busy={restartLoading} busyIndicator={<LoadingSpanner/>}>
                        <button onClick={() => resetVendDelay(props.vendingMachine.uuid)}
                                className="btn btn-danger">Reset Waiting Period
                        </button>
                    </BusyIndicator>
                    <hr/>

                    <h3>Update Vend Delay Period</h3>
                    <form className={"form"} onSubmit={async (e) => {
                        e.preventDefault();
                        await updateVendDelay()
                    }}>
                        <div className="form-group">
                            <label>Vend Delay (in second)</label>
                            <input className="form-control" value={delay} placeholder={"duration unit is second"}
                                   onChange={(event) => {
                                       let num = parseInt(event.target.value)
                                       if (Number.isNaN(num)) {
                                           setDelay(0);
                                       } else {
                                           if (num < 0) {
                                               num = 0;
                                           }
                                           setDelay(num);
                                       }
                                   }}/>
                        </div>
                        <BusyIndicator busy={updatingVendDelay} busyIndicator={<LoadingSpanner/>}>
                            <input type="submit" className="btn btn-success" disabled={updatingVendDelay}
                                   value="Update"/>
                        </BusyIndicator>
                    </form>
                </section>

                <section className="content-body" style={{marginTop: '20px'}}>
                    <VendingMachineConfigurationPanel vendingMachine={props.vendingMachine}/>
                </section>

                {machineDetail && <section className="content-body" style={{marginTop: '20px'}}>
                    <VendingMachineBlackOutPanel vendingMachine={props.vendingMachine} machineDetail={machineDetail}/>
                </section>}

            </section>
        </div>
    </div>
}
