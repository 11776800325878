import React, {Component} from "react";
import {productPicture, ProductRecommendationMap, ProductWantLevel} from "./models";

interface State {
    productPreference:ProductWantLevel
}

interface Props {
    productRecommendationMap:ProductRecommendationMap
    checkProduct(e, id, wantLevel:string)
}

class RecommendProductElement extends Component<Props, State> {
    constructor(props) {
        super(props);

        this.state = {
            productPreference:ProductWantLevel[this.props.productRecommendationMap.wantLevel]
        };
    }

    selection(e:any, selection:ProductWantLevel){
        this.setState({productPreference:selection})
        this.props.checkProduct(e, this.props.productRecommendationMap.id, selection);
    }




    render() {
        return <div className={"col-12 col-sm-6 col-md-4 col-lg-3"}>
            <div className="panel panel-default">
                <div className="panel-heading">
                    <span>{this.props.productRecommendationMap.product.name}</span>
                    <span style={{float:"right"}}>{this.props.productRecommendationMap.product.category}</span>
                </div>
                <div className="panel-body">
                    <div className={"text-center"} style={{marginBottom:"1em" }}>
                        {this.props.productRecommendationMap.product.imageUploadKey && <img alt={this.props.productRecommendationMap.product.name} src={productPicture(this.props.productRecommendationMap.product)} style={{height: "10em"}}/>}
                        {!this.props.productRecommendationMap.product.imageUploadKey && <div style={{height:"10em", paddingTop:"5em"}}>
                            <p>Photo Not Available</p>
                        </div>}
                    </div>
                    <div className="input-group">
                        <span className="input-group-addon" onClick={(e)=>{this.selection(e, ProductWantLevel.FAVORITE)}}>
                            <label htmlFor={"FAVORITE" + this.props.productRecommendationMap.id} >Favorite</label><br/>
                            <input type="radio"
                                   checked={this.state.productPreference === ProductWantLevel.FAVORITE}
                                   onChange={(e)=>{this.selection(e, ProductWantLevel.FAVORITE)}}
                                   name={"preference" + this.props.productRecommendationMap.id}
                                   id={"FAVORITE" + this.props.productRecommendationMap.id}
                                   value={ProductWantLevel.FAVORITE}/>
                        </span>
                        <span className="input-group-addon" onClick={(e)=>{this.selection(e, ProductWantLevel.STOCK)}}>
                            <label htmlFor={"STOCK" + this.props.productRecommendationMap.id} >Stock</label><br/>
                            <input type="radio"
                                   checked={this.state.productPreference === ProductWantLevel.STOCK}
                                   onChange={(e)=>{this.selection(e, ProductWantLevel.STOCK)}}
                                   name={"preference" + this.props.productRecommendationMap.id}
                                   id={"STOCK" + this.props.productRecommendationMap.id}
                                   value={ProductWantLevel.STOCK}/>
                        </span>
                        <span className="input-group-addon" onClick={(e)=>{this.selection(e, ProductWantLevel.NEGATIVE)}}>
                            <label htmlFor={"NEGATIVE" + this.props.productRecommendationMap.id}>Negative</label><br/>
                            <input type="radio"
                                   checked={this.state.productPreference === ProductWantLevel.NEGATIVE}
                                   onChange={(e)=>{this.selection(e, ProductWantLevel.NEGATIVE)}}
                                   name={"preference" + this.props.productRecommendationMap.id}
                                   id={"NEGATIVE" + this.props.productRecommendationMap.id}
                                   value={ProductWantLevel.NEGATIVE}/>
                        </span>
                    </div>
                </div>
            </div>
        </div>;
    }
}

export default RecommendProductElement;
