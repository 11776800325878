import React, {Component, Fragment} from 'react';

import {getAPI, postAPI} from "./api";

import {IGlobalDataContext, withGlobalData} from "./contexts/global-data";
import {CompanyClient, VendingMachine,} from "./models";
import {Typeahead} from "react-bootstrap-typeahead";
import {Helmet} from "react-helmet";

interface Props extends IGlobalDataContext {
    match: any
}

interface State {
    loading: boolean
    clients: Array<CompanyClient>
    emails: string
    name: string
    selectedVendingMachines: Array<VendingMachine>
    saving: boolean,
    selectedClient: CompanyClient | null
}

class CompanyClientsPage extends Component<Props, State> {
    constructor(props) {
        super(props);

        this.state = {
            loading: false,
            clients:[],
            emails:"",
            name: "",
            selectedVendingMachines: [],
            saving: false,
            selectedClient: null,
        };
    }

    loadCompanyClients() {
        getAPI(`/api/company_clients`).then((response) => {
            if (response.data.success) {
                this.setState({clients: response.data.companyClientInfos});
                this.state.clients.forEach(client => {
                    if (client.uuid === this.props.match.params.uuid) {
                        this.setState({selectedClient: client})
                    }
                })
            }
        })
    }

    componentDidMount(): void {
        this.loadCompanyClients();
    }


    handleName(e) {
        e.preventDefault();
        let newClient = Object.assign({}, this.state.selectedClient)
        newClient.name = e.target.value;
        this.setState({selectedClient: newClient});
    }

    handleEmails(e) {
        e.preventDefault();
        let newClient = Object.assign({}, this.state.selectedClient)
        newClient.adminEmails = e.target.value;
        this.setState({selectedClient: newClient});
    }

    submit(e) {
        e.preventDefault();
        this.setState({saving: true});
        postAPI(`/api/company_clients/${this.props.match.params.uuid}`, {
            emails: this.state.selectedClient?.adminEmails,
            name: this.state.selectedClient?.name,
            vmIds: this.state.selectedClient?.vmIds
        }).then((result) => {
            if (result.data.success) {
                this.loadCompanyClients();
                window.alert("Client setting has been updated!")
            }else{
                window.alert(result.data.message)
            }
            this.setState({saving: false});
        })
    }

    allocateVendingMachines(vendingMachines) {
        const vmIds = vendingMachines.map(vendingMachine => {
            return vendingMachine.uuid
        });
        let newClient = Object.assign({}, this.state.selectedClient)
        newClient.vmIds = vmIds;
        this.setState({selectedClient: newClient});
    }

    render() {
        let allocatedVendingMachine:Array<String> = [];
        allocatedVendingMachine = this.state.clients.flatMap(client => {
            return client.vmIds
        });

        const allocatableVendingMachine = this.props.allVendingMachines.allVendingMachines.filter(vendingMachine => {
            return !allocatedVendingMachine.includes(vendingMachine.uuid)
        })

        let allocatedVendingMachineToClient:Array<VendingMachine> = [];

        if (this.state.selectedClient?.vmIds) {
            allocatedVendingMachineToClient = this.props.allVendingMachines.allVendingMachines.filter(vendingMachine => {
                return this.state.selectedClient?.vmIds.includes(vendingMachine.uuid)
            })
        }

        return (
            <Fragment>
                <Helmet titleTemplate="%s - Vending on Track">
                    <title>Edit Company Client</title>
                </Helmet>
                <section className="content-header">
                    <h1>
                       Edit Company Client
                    </h1>
                </section>

                <section className="content">
                    <div className="box box-primary">
                        <div className="box-header with-border">
                            <h3 className="box-title">Edit Post Pay Client</h3>
                            <form onSubmit={this.submit.bind(this)}>
                                <div className="box-body">
                                    <div className="form-group">
                                        <label htmlFor="name">Client Company Name</label>
                                        <input onChange={this.handleName.bind(this)} value={this.state.selectedClient?.name} type="text"
                                               className="form-control" id="emails"
                                               placeholder="Enter User's Email(s)"/>
                                    </div>
                                    <div className="form-group">
                                        <label htmlFor="name">Client Admin Email</label>
                                        <input onChange={this.handleEmails.bind(this)} value={this.state.selectedClient?.adminEmails} type="text"
                                               className="form-control" id="emails"
                                               placeholder="Enter User's Email(s)"/>
                                    </div>
                                    <div className="form-group">
                                        <label htmlFor="name">Allocate Vending Machine</label>
                                        <Typeahead
                                            clearButton
                                            selected={allocatedVendingMachineToClient}
                                            multiple
                                            labelKey={"name"}
                                            onChange={this.allocateVendingMachines.bind(this)}
                                            options={allocatableVendingMachine}
                                            placeholder="Allocate Vending Machines"
                                        />
                                    </div>
                                </div>

                                <div className="box-footer">
                                    <button type="submit" disabled={this.state.saving} className="btn btn-primary">Update</button>
                                </div>
                            </form>
                        </div>
                    </div>
                </section>
            </Fragment>
        );
    }
}

export default withGlobalData(CompanyClientsPage);
