import React from 'react';
import {AllVendingMachines, Category, Me, Message, Product, VendingMachine, VendingMachineGroup} from "../models";

export interface FlashMessageContext {
    pushFlashMessage(message: Message): void;
}

export interface IGlobalDataContext extends FlashMessageContext {
    allProducts: Array<Product>;

    allCategories: Array<Category>;

    allVendingMachineGroups: Array<VendingMachineGroup>;

    reloadAllProducts();

    findProduct(id: string): Product | null;

    reloadCategories();

    reloadVendingMachineGroups();

    findVendingMachineGroups(uuid: string): VendingMachineGroup | null;

    findCategory(id: number): Category | null;

    allVendingMachines: AllVendingMachines;

    findVendingMachine(uuid: string): VendingMachine | null;

    reloadAllVendingMachines();

    notificationCount: number | null;

    reloadNotificationCount(): void;

    version: string;

    me: Me;

    reloadMyInfo();
}

const GlobalDataContext = React.createContext({
    pushFlashMessage: (message: string, cls: string) => {

    },
    findProduct: (id: string) => {

    },
    allProducts: [],

    allCategories: [],
    allVendingMachineGroups: [],
    notificationCount: null,
    reloadNotificationCount: () => {
    },
    reloadAllProducts: () => {
    },

    reloadCategories: () => {
    },

    reloadVendingMachineGroups: () => {

    },

    findVendingMachineGroups: () => {

    },

    findCategory: () => {

    },
    findVendingMachine: (s) => {
        return null
    },
    allVendingMachines: (): AllVendingMachines => {
        return {
            initialLoading: true,
            refreshing: false,
            errorMessage: null,
            failedToLoad: false,
            allVendingMachines: []
        }
    },
    reloadAllVendingMachines: () => {
    },
    version: '',
    me: null,
    reloadMyInfo: () => {
    }
});

function withGlobalData(C: any) {
    return function DataComponent(props: any) {
        return (
            <GlobalDataContext.Consumer>
                {({
                      allProducts,
                      version,
                      reloadAllProducts,
                      allVendingMachines,
                      reloadAllVendingMachines,
                      findVendingMachine,
                      notificationCount,
                      reloadNotificationCount,
                      pushFlashMessage,
                      findProduct,
                      me,
                      reloadMyInfo,
                      reloadCategories,
                      findCategory,
                      allCategories,
                      allVendingMachineGroups,
                      reloadVendingMachineGroups,
                      findVendingMachineGroups,
                  }) =>
                    <C {...props} allProducts={allProducts} reloadAllProducts={reloadAllProducts}
                       allVendingMachines={allVendingMachines} reloadAllVendingMachines={reloadAllVendingMachines}
                       notificationCount={notificationCount} reloadNotificationCount={reloadNotificationCount}
                       pushFlashMessage={pushFlashMessage}
                       findProduct={findProduct}
                       version={version}
                       me={me}
                       reloadMyInfo={reloadMyInfo}
                       findVendingMachine={findVendingMachine}
                       reloadCategories={reloadCategories}
                       findCategory={findCategory}
                       allCategories={allCategories}
                       allVendingMachineGroups={allVendingMachineGroups}
                       reloadVendingMachineGroups={reloadVendingMachineGroups} findVendingMachineGroups={findVendingMachineGroups}/>}
            </GlobalDataContext.Consumer>
        );
    }
}

export {GlobalDataContext, withGlobalData}
