import React, {Component, Fragment} from 'react';
import {getAPI, postAPI} from "./api";
import {Helmet} from "react-helmet";

interface Props {
}

interface State {
    originalAdditionalEmailsForAlerts: string
    newAdditionalEmailsForAlerts: string
    originalAdditionalMobilesForAlerts: string
    newAdditionalMobilesForAlerts: string
    originalAdditionalEmailsForDailySummary: string
    newAdditionalEmailsForDailySummary: string
    loading: boolean
}

class NotificationSettingsPage extends Component<Props, State> {
    constructor(props) {
        super(props);

        this.state = {
            originalAdditionalEmailsForAlerts: "",
            newAdditionalEmailsForAlerts: "",
            originalAdditionalMobilesForAlerts: "",
            newAdditionalMobilesForAlerts: "",
            originalAdditionalEmailsForDailySummary: "",
            newAdditionalEmailsForDailySummary: "",
            loading: false
        }
    }

    componentDidMount(): void {
        this.loadSettings();
    }

    loadSettings(): void {
        this.setState({'loading': true});
        getAPI("/api/alert_settings").then((response) => {
            if (response.data.success) {
                let newState: any = {
                    loading: false,
                };
                if (response.data.alertSettingInfo != null){
                    newState.originalAdditionalEmailsForAlerts = response.data.alertSettingInfo.additionalEmailsForAlerts.join(', ');
                    newState.newAdditionalEmailsForAlerts =  response.data.alertSettingInfo.additionalEmailsForAlerts.join(', ');
                    newState.originalAdditionalMobilesForAlerts = response.data.alertSettingInfo.additionalMobilesForAlerts.join(', ');
                    newState.newAdditionalMobilesForAlerts = response.data.alertSettingInfo.additionalMobilesForAlerts.join(', ');
                    newState.originalAdditionalEmailsForDailySummary = response.data.alertSettingInfo.additionalEmailsForDailySummary.join(', ');
                    newState.newAdditionalEmailsForDailySummary = response.data.alertSettingInfo.additionalEmailsForDailySummary.join(', ')
                }
                this.setState(newState)
            } else {
                window.alert(response.data.message)
            }
        })
    }

    handleSave(event) {
        event.preventDefault();
        event.stopPropagation();
        if (!this.needSave()) {
            return false;
        }
        console.log("Saving...");

        let newSettings = {
            additionalEmailsForAlerts : this.state.newAdditionalEmailsForAlerts.split(",").map((email) => email.trim()),
            additionalMobilesForAlerts : this.state.newAdditionalMobilesForAlerts.split(",").map((email) => email.trim()),
            additionalEmailsForDailySummary : this.state.newAdditionalEmailsForDailySummary.split(",").map((email) => email.trim())
        }
        console.log(newSettings);

        this.setState({loading: true});
        postAPI(`/api/alert_settings`, newSettings).then((response) => {
            console.log(response);
            this.setState({loading: false});

            if (response.data.success) {
                this.loadSettings();
            } else {
                window.alert(response.data.message);
            }
        })
    }

    needSave(): boolean {
        if (this.state.originalAdditionalMobilesForAlerts !== this.state.newAdditionalMobilesForAlerts) {
            return true;
        }

        if (this.state.originalAdditionalEmailsForDailySummary !== this.state.newAdditionalEmailsForDailySummary) {
            return true;
        }

        if (this.state.originalAdditionalEmailsForAlerts !== this.state.newAdditionalEmailsForAlerts) {
            return true;
        }

        return false;
    }

    handleAdditionalEmailsChanges(event): void {
        this.setState({newAdditionalEmailsForAlerts: event.target.value})
    }

    handleAdditionalMobilesChanges(event): void {
        this.setState({newAdditionalMobilesForAlerts: event.target.value})
    }

    handleAdditionalEmailsForDailySummary(event): void {
        this.setState({newAdditionalEmailsForDailySummary: event.target.value})
    }


    render() {
        return (
            <Fragment>
                <Helmet titleTemplate="%s - Vending on Track">
                    <title>Notification Settings</title>
                </Helmet>
                <section className="content-header">
                    <h1>
                        Notification Settings
                    </h1>

                </section>

                <section className="content">
                    <hr/>
                    <form autoComplete="off" onSubmit={this.handleSave.bind(this)} className="form">
                        <div className="row">
                            <div className="col-sm-6">

                                <div className="form-group">
                                    <label>Additional Emails for Alerts (please use comma to separate each email
                                        address)</label>
                                    <input className="form-control" type="text" value={this.state.newAdditionalEmailsForAlerts}
                                           onChange={this.handleAdditionalEmailsChanges.bind(this)}/>
                                </div>

                                <div className="form-group">
                                    <label>Additional Mobiles for Alerts (please use comma to separate each mobile
                                        number)</label>
                                    <input className="form-control" type="text" value={this.state.newAdditionalMobilesForAlerts}
                                           onChange={this.handleAdditionalMobilesChanges.bind(this)}/>
                                </div>
                                <div className="form-group">
                                    <label>Additional Emails for Daily Summary Emails (please use comma to separate each email
                                        address)</label>
                                    <input className="form-control" type="text"
                                           value={this.state.newAdditionalEmailsForDailySummary}
                                           onChange={this.handleAdditionalEmailsForDailySummary.bind(this)}/>
                                </div>
                                <input type="submit" value="Save" className="btn btn-success btn-lg"/>
                                <br/>
                            </div>
                        </div>
                    </form>
                </section>
            </Fragment>
        );
    }
}

export default NotificationSettingsPage
