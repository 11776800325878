import React, {Component, Fragment} from "react";
import {IGlobalDataContext, withGlobalData} from "./contexts/global-data";
import {IngredientMapping, Product} from "./models";

interface Props extends IGlobalDataContext {
    mapping: IngredientMapping;
    handleRemove(): void;
    updateIngredientMapping(IngredientMapping): Promise<any>;
}

interface State {
    editing: boolean
    mapping: IngredientMapping
    saving: boolean
}

class RawIngredientMapRow extends Component<Props, State> {
    constructor(props) {
        super(props);

        this.state = {
            editing: false,
            mapping: this.props.mapping,
            saving: false,
        }


    }

    onIngredientChange(ingredient: Array<Product>) {
        if (ingredient.length > 0) {
            let new_mapping = {...this.state.mapping};
            new_mapping["ingredientId"] = Number(ingredient[0].id);
            this.setState({mapping: new_mapping});
        }
    }

    startEditing() {
        if (this.state.editing) {
            this.stopEditing();
        } else {

            this.setState({
                editing: true
            });
        }
    }

    stopEditing() {
        this.setState({
            editing: false
        })
    }

    save() {
        if (window.confirm("Save?")) {
            this.setState({saving: true});
            let m = {...this.state.mapping};
            this.props.updateIngredientMapping(m).then((response) => {
                this.stopEditing();
            });
        }
    }

    render() {
        let options = ["capacity", "restockCapacity"].map((v, i)=>{
            if (this.state.editing) {
                return <td key={`${v}-${i}`}>
                    <input type="text" value={this.state.mapping[v]} onChange={(e)=>{
                        let new_mapping = {...this.state.mapping};
                        new_mapping[v] = e.target.value;
                        this.setState({mapping: new_mapping});
                    }}/> {this.state.mapping.productUnit}
                </td>
            } else {
                return <td key={`${v}-${i}`}>{this.state.mapping[v]}&nbsp;{this.state.mapping.productUnit}</td>
            }
        })

        let nameColumnNormalContent = (<Fragment>
            {this.props.mapping.ingredientName}
        </Fragment>);

        let nameColumn = (<td key={`name-column-${this.props.mapping.id}`}>
            {nameColumnNormalContent}
        </td>);

        options.unshift(nameColumn);

        return <tr key={this.props.mapping.id}>
            {options}
            <td>
                {this.state.editing ? <div>
                    <button className={"btn btn-default"} style={{'cursor': 'pointer'}} onClick={() => {
                        this.stopEditing()
                    }}>Cancel
                    </button>
                    |
                    <button className={"btn btn-success"} style={{'cursor': 'pointer'}} onClick={() => {
                        this.save()
                    }}>Save</button>
                </div> : null}
                {this.state.editing ? null :
                    <Fragment>
                        <button className={"btn btn-default"} title={"Click to edit"} style={{'cursor': 'pointer'}}
                                onClick={() => {
                                    this.startEditing()
                                }}>
                            Edit
                        </button>
                        &nbsp;|&nbsp;
                        <button onClick={() => {
                            this.props.handleRemove()
                        }}
                                className="btn btn-danger">Remove
                        </button>
                    </Fragment>
                }
            </td>
        </tr>
    }
}

const IngredientMapRow = withGlobalData(RawIngredientMapRow);

export default IngredientMapRow;