import React, {Component} from 'react';
import {Redirect} from "react-router-dom";
import {ProductMapping, VendingMachine, VendingMachinesWithLoadingState} from "./models";
import {DisplayTime} from "./display_time";
import DataTable from 'react-data-table-component';
import MediaQuery from 'react-responsive'
import {VendingMachineLink} from "./components/vending_machine_link";
import FillLevel from "./fill_level";
import {FormatMoney} from "./format_money";
import {FormatMoneyWithFlexibleSignSize} from "./format_money_without_style";


interface Props {
    vending_machines: VendingMachinesWithLoadingState
    isLinkedToSettings: boolean
    withFilter?: boolean
}

interface State {
    vendingMachineSelected?: VendingMachine
    filterText?: string
    onlineOnly: boolean

}

class VendingMachinesTable extends Component<Props, State> {
    constructor(props) {
        super(props);
        this.state = {onlineOnly: false}
    }

    updateFilter(event) {
        this.setState({filterText: event.target.value});
    }

    onSelect(row: VendingMachine) {
        this.setState({
            vendingMachineSelected: row
        });
    }

    toggleOnlineVisibility(event) {
        this.setState({onlineOnly: !this.state.onlineOnly})
    }

    render() {
        let vendingMachines = this.props.vending_machines.vendingMachines.map((vendingMachine) => {
            let missing = 0;
            if ((vendingMachine.standardStockCount != null) && (null != vendingMachine.currentStockCount)) {
                missing = vendingMachine.standardStockCount - vendingMachine.currentStockCount;
            }

            let lowestStockMissing = 0;
            if (vendingMachine.lowestStock != null) {
                const lowestStock = vendingMachine.lowestStock;
                if ((lowestStock.currentStock != null) && (lowestStock.restock != null)) {
                    const missing = lowestStock.restock - lowestStock.currentStock;
                    lowestStockMissing = missing;
                }
            }

            const salesSinceRefill = vendingMachine.salesSinceRefill
            const revenueSinceRefill = vendingMachine.revenueSinceRefill
            const salesAndRevenueSinceRefill = [salesSinceRefill, revenueSinceRefill]
            const collectableCash = vendingMachine.coinAmountInBox + vendingMachine.noteAmount

            return {
                ...vendingMachine,
                missing: missing,
                lowestStockMissing: lowestStockMissing,
                salesAndRevenue: salesAndRevenueSinceRefill,
                collectableCash: collectableCash
            }
        });

        if (this.state.vendingMachineSelected) {
            if (this.props.isLinkedToSettings) {
                return <Redirect to={`/vending_machines/${this.state.vendingMachineSelected.uuid}/setting`}
                                 push={true}/>;
            } else {
                return <Redirect to={`/vending_machines/${this.state.vendingMachineSelected.uuid}`} push={true}/>;
            }
        }

        if (this.state.filterText) {
            vendingMachines = vendingMachines.filter((vendingMachine) => {
                if ((vendingMachine.name.toLowerCase().search((this.state.filterText || "").toLowerCase()) >= 0) || ((vendingMachine.address || '').toLowerCase().search((this.state.filterText || '').toLowerCase()) >= 0)) {
                    return true;
                }

                return false;
            });
        }

        if (this.state.onlineOnly) {
            vendingMachines = vendingMachines.filter((vendingMachine) => {
                return vendingMachine.online;
            })
        }

        const mobileColumns = [
            {
                name: "Name",
                selector: "name",
                compact: true,
                sortable: true,
                ignoreRowClick: true,
                cell: (vendingMachine: VendingMachine) => {
                    if (this.props.isLinkedToSettings) {
                        return (
                            <div>
                                <VendingMachineLink vendingMachine={vendingMachine} setting={true}/>
                            </div>);
                    } else {
                        return (
                            <div>
                                <VendingMachineLink vendingMachine={vendingMachine} setting={false}/>
                            </div>);
                    }
                }
            },
            {
                name: "Today's Revenue",
                compact: true,
                sortable: true,
                selector: "todayRevenue",
                format: (vendingMachine: VendingMachine) => {
                    return <FormatMoneyWithFlexibleSignSize cents={vendingMachine.todayRevenue}/>;
                }
            },
            {
                name: "last transaction",
                sortable: true,
                compact: true,
                selector: "lastTransaction.timestamp",
                format: ((vendingMachine: VendingMachine) => {
                    return vendingMachine.lastTransaction ?
                        (<DisplayTime timestamp={vendingMachine.lastTransaction.timestamp}/>) : null
                })
            },
        ];

        const columns = [
            {
                name: "Name",
                selector: "name",
                sortable: true,
                ignoreRowClick: true,
                width: "200px",
                cell: (vendingMachine: VendingMachine) => {
                    if (this.props.isLinkedToSettings) {
                        return (
                            <div>
                                <VendingMachineLink vendingMachine={vendingMachine} setting={true}/>
                                {vendingMachine.creditCardReaderOnTestEnv && <div>
                                    <span style={{fontSize: "85%", color: "red"}}>
                                        card reader on test environment
                                    </span>
                                </div>}
                                <div>
                                    <span style={{fontSize: "85%"}}>{vendingMachine.address}</span>
                                </div>
                            </div>
                        );
                    } else {
                        return (
                            <div>
                                <VendingMachineLink vendingMachine={vendingMachine} setting={false}/>
                                {vendingMachine.creditCardReaderOnTestEnv && <div>
                                    <span style={{fontSize: "85%", color: "red"}}>
                                        card reader on test environment
                                    </span>
                                </div>}
                                <div>
                                    <span style={{fontSize: "85%"}}>{vendingMachine.address}</span>
                                </div>
                            </div>
                        );
                    }
                }
            },
            {
                name: "Today's Revenue",
                sortable: true,
                selector: "todayRevenue",
                format: (vendingMachine: VendingMachine) => {
                    return <FormatMoneyWithFlexibleSignSize cents={vendingMachine.todayRevenue}/>;
                }
            },
            {
                name: "Last Refill Time",
                sortable: true,
                selector: "lastRefill.createdTimestamp",
                format: ((vendingMachine: VendingMachine) => {
                    return vendingMachine.lastRefill ?
                        (<DisplayTime timestamp={vendingMachine.lastRefill.createdTimestamp}/>) : null
                })
            },
            {
                name: "Last Transaction",
                sortable: true,
                selector: "lastTransaction.timestamp",
                format: ((vendingMachine: VendingMachine) => {
                    if (vendingMachine.lastTransaction) {
                        return (<span><DisplayTime timestamp={vendingMachine.lastTransaction.timestamp}/></span>);
                    }

                    return null;
                })
            },
            {
                name: "Last Sales",
                sortable: true,
                selector: "lastSales.timestamp",
                format: ((vendingMachine: VendingMachine) => {
                    if (vendingMachine.lastSales) {
                        return (<span><DisplayTime timestamp={vendingMachine.lastSales.timestamp}/></span>);
                    }

                    return null;
                })
            },
            {
                name: "Lowest Stock",
                sortable: true,
                selector: "lowestStockMissing",
                format: ((vendingMachine) => {
                    if (vendingMachine.lowestStock == null) {
                        return '-';
                    }
                    const productMapping: ProductMapping = vendingMachine.lowestStock;
                    console.log(productMapping);
                    return `${productMapping.product_name} - ${productMapping.currentStock}/${productMapping.restock} `;
                })
            },
            {
                name: "Stock Level",
                sortable: true,
                selector: "missing",
                format: ((vendingMachine) => {
                    return `${vendingMachine.currentStockCount}/${vendingMachine.standardStockCount}`;
                })
            },
            {
                name: "Fill Level",
                sortable: true,
                selector: "fillLevel",
                format: ((vendingMachine) => {
                    return <FillLevel vendingMachine={vendingMachine}/>
                })
            },
            {
                name: "Source",
                maxWidth: "60px",
                minWidth: "60px",
                selector: "salesInfoSource"
            },
            {
                name: "Collectable Cash",
                selector: "collectableCash",
                sortable: true,
                format: ((vendingMachine) => {
                    return <FormatMoney cents={vendingMachine.collectableCash}/>
                })
            },
            {
                name: "Sales Since Last Refill",
                maxWidth: "200px",
                minWidth: "200px",
                selector: "salesAndRevenue[0]",
                sortable: true,
                format: ((vendingMachine) => {
                    return <p>
                        Sales: {vendingMachine.salesAndRevenue[0]}
                        &nbsp;&nbsp;
                        Revenue: <FormatMoney cents={vendingMachine.salesAndRevenue[1]}/>
                    </p>
                })
            }
        ];

        let filter: any = (<div className="row">
            <div className="col-xl-8 col-md-12" style={{paddingRight: '5px'}}>
                <input placeholder={"Type in here for filter"} className={"form-control"}
                       onChange={this.updateFilter.bind(this)} value={this.state.filterText}/>
            </div>
            <div className="col-xl-4 col-md-12">
                <label style={{fontSize: "15px", verticalAlign: "middle"}}>

                    <input checked={this.state.onlineOnly} onChange={this.toggleOnlineVisibility.bind(this)}
                           type="checkbox" name="onlineOnly"/>
                    &nbsp; Hide offline vending machines
                </label>
            </div>
        </div>);

        if (this.props.withFilter !== true) {
            filter = "Vending Machines"
        }

        if (this.props.vending_machines.loading) {
            return (<div className="callout callout-info">
                <h4>Loading vending machines...</h4>

                <p>We are listing all your vending machines. It won't be long.</p>
            </div>);
        }

        return (
            <div className="box box-success">
                <div className="box-body no-padding">
                    <MediaQuery minDeviceWidth={768}>
                        <DataTable
                            pagination={true}
                            paginationPerPage={100}
                            paginationRowsPerPageOptions={[20, 100, 200, 1000, 5000]}
                            responsive={true}
                            highlightOnHover={true}
                            pointerOnHover={false}
                            defaultSortField="name"
                            title={filter}
                            columns={columns}
                            data={vendingMachines}
                        />
                    </MediaQuery>
                    <MediaQuery maxDeviceWidth={768}>
                        <DataTable
                            pagination={true}
                            responsive={true}
                            highlightOnHover={true}
                            pointerOnHover={false}
                            defaultSortField="name"
                            title={filter}
                            columns={mobileColumns}
                            data={vendingMachines}
                            expandableRows={true}
                            expandableRowsComponent={<ExpanableRow data=""/>}
                        />
                    </MediaQuery>
                </div>
            </div>
        )
    }
}

interface IExpanableRowProps {
    data: any
}

const ExpanableRow: React.SFC<IExpanableRowProps> = ({data}) => {
    return (<div style={{padding: '10px'}}>
        Last refill: {data.lastRefill ? <DisplayTime timestamp={data.lastRefill.createdTimestamp}/> : ''}
        <br/>
        Lowest Stock: {data.lastRefill ? data.lowestStockMissing : ''}

    </div>)
};


export {VendingMachinesTable}
