import React, {Component} from 'react';

interface Props {
    data: {
        id: number,
        w: number,
        h: number,
        x: number,
        y: number,
        display_value: string}[]
}

class KeypadRow extends Component<Props> {

    render(){
        let output_row:JSX.Element[] = []


        this.props.data.forEach(button => {
            output_row.push(<div key = {button.id} className={"col-xs-"+(12/this.props.data.length)}><p className={"keypadButton"}>{button.display_value}</p></div>)
        })

        return(
            <div className={"row"}>
                {output_row}
            </div>
        )
    }
}

export default KeypadRow