import {VendingMachineGroup} from "../models";

export interface TreeItem {
    title: string
    subtitle: string
    children: TreeItem[]
    expanded: true,
    uuid: string,
    vendingMachineUuids: string[]
}

interface vendingMachineOption {
    value: string
    label: string
}

const findChildren = (groups: VendingMachineGroup[], parentUuid: string | null)=>{
    const nodes = groups.filter((group)=>{
        return group.parentUuid === parentUuid;
    }).sort((group1, group2)=>{
        return group1.name.localeCompare(group2.name)
    });
    return  nodes.map((group)=>{
        const children = findChildren(groups, group.uuid)
        return {title: group.name, subtitle: group.description, children:children, expanded: true, uuid: group.uuid, vendingMachineUuids: group.vendingMachineUuids}
    })
}

const formATree = (groups: VendingMachineGroup[]):TreeItem[]=>{
    return findChildren(groups, null)
}

export const handleTreeSelection = (treeNode: TreeItem, findVendingMachine: Function) => {
    let selectedVm:vendingMachineOption[] = [];
    selectedVm = treeNode.vendingMachineUuids.filter((vmUuid)=>{
        const vm = findVendingMachine(vmUuid)
        return vm !== null;
    }).map((vmUuid)=>{
        const vm = findVendingMachine(vmUuid)
        return {value: vm?.uuid!, label: vm?.name!}
    })
    if (treeNode.children.length > 0){
        const _selectedVm = treeNode.children.flatMap((treeNode)=>{
            return handleTreeSelection(treeNode, findVendingMachine);
        })
        selectedVm = selectedVm.concat(_selectedVm);
    }
    return selectedVm;
}

export default formATree;
