import React, {Component} from 'react';
import {Refill, refillSorting, VendingMachine, VirtualFinancialTransaction} from "./models";
import {DisplayTime} from "./display_time";
import ReactPaginate from "react-paginate";
import moment from "moment";
import VirtualTransTr from "./components/virtual_trans_tr";
import {DateSplitter} from "./date_splitter";


interface Props {
    vendingMachine: VendingMachine | null
    transactions: Array<VirtualFinancialTransaction>
    refills: Array<Refill>
    handlePagin:any
    totalpage:number
}

interface State {

}

export class VirtualFinancialTransactions extends Component<Props, State> {
    handlePageClick(selectedItem: { selected: number }): void {
        this.props.handlePagin(selectedItem.selected)
    }

    render() {
        let rows: Array<any> = [];
        let refills = [...this.props.refills].sort(refillSorting);

        let headingTime = this.props.transactions.length > 0 ? moment.unix(this.props.transactions[0].timestamp * 1000) : moment();

        while (refills.length > 0) {
            const headRefill = refills[0];

            console.log("Heading: ");
            console.log(headingTime.unix() / 1000);
            console.log('created timestamp');
            console.log(headRefill.createdTimestamp);

            if (headingTime.unix() / 1000 >= headRefill.createdTimestamp) {
                break;
            }

            refills.shift();
            rows.push(<tr key={headRefill.uuid} style={{backgroundColor: "#EFEFEF", textAlign: "center"}}>
                <td colSpan={5}>
                    <div>
                        Refill - <DisplayTime expanded={true} timestamp={headRefill.createdTimestamp}/>
                    </div>
                </td>
            </tr>);
        }

        this.props.transactions.forEach((transaction, idx) => {
            let ts = moment(transaction.timestamp * 1000);
            if (ts.format("DD MMM YYYY") !== headingTime.format("DD MMM YYYY")) {
                headingTime = ts;

                rows.push(<tr key={headingTime.format("DD MMM YYYY")}
                              style={{backgroundColor: "#EFEFEF", textAlign: "center"}}>
                  <td colSpan={5}>
                      <div>
                          <DateSplitter headingTime={headingTime} vendingMachine={this.props.vendingMachine}/>
                      </div>
                  </td>
              </tr>);
            }

            // 2-way merge!
            while (refills.length > 0) {
                const headRefill = refills[0];

                if (transaction.timestamp >= headRefill.createdTimestamp) {
                    break;
                }

                refills.shift();
                rows.push(<tr key={headRefill.uuid} style={{backgroundColor: "#EFEFEF", textAlign: "center"}}>
                    <td colSpan={5}>
                        <div>
                            Refill - <DisplayTime expanded={true} timestamp={headRefill.createdTimestamp}/>
                        </div>
                    </td>
                </tr>);
            }

            const row = (
                <VirtualTransTr
                    vftrans={transaction}
                    isExpandTime={true}
                    isShowMachine={false}
                    key={transaction.uuid}
                    isShrunk={false}
                />
            );

            rows.push(row);
        });

        return (
            <div className={"box statsBox"}>
                <h2 className={"box-header"}>Financial Transactions</h2>


                <div className={"box-body"}>

                    <ReactPaginate
                        pageRangeDisplayed={2}
                        marginPagesDisplayed={2}
                        pageCount={this.props.totalpage}
                        onPageChange={this.handlePageClick.bind(this)}
                        initialPage={0}
                        containerClassName={"pagination pagination-sm no-margin"}
                        activeClassName={'active'}
                    />

                    <table className={"table table-condensed"}>
                        <thead>
                        <tr>
                            <th>Time</th>
                            <th>Sales Type</th>
                            <th>Destination / Source</th>
                            <th>Amount</th>
                        </tr>
                        </thead>
                        <tbody>
                        {rows}
                        </tbody>
                    </table>
                </div>
            </div>
        );
    }
}
