import {Link} from "react-router-dom";
import React from "react";
import {
    CoilProductStats,
    IngredientMapping,
    ProductMapping,
    VendingMachine,
    ProductMappingWithProduct,
    IngredientMappingWithProduct,
} from "./models";
import DataTable from 'react-data-table-component';
import MediaQuery from 'react-responsive'
import {ExpanableRow} from "./expanable_row";
import {DownloadInventoriesCsv} from "./download_inventories_csv";

interface Props {
    vendingMachine: VendingMachine
    setAggregatedByProduct(boolean)
    aggregatedByProduct: boolean
    aggregatedColumns: Array<any>
    products: Array<CoilProductStats>
    columns: Array<any>
    coils: Array<ProductMappingWithProduct>
    mobileColumns: Array<any>
    manualBalance(coil: ProductMapping, newStock: number);
    ingredientMappingManualBalance(ingredientMapping: IngredientMapping, newStock: number);
    ingredientColumns:Array<any>
    ingredientMappings:Array<IngredientMappingWithProduct>
}


export function AdminCoilStatsTable(props: Props) {
    return <div className={"box statsBox"}>
        <div className="hidden-xs box-header">
            <div className="text">
                This section lists all the columns/coils for vending machines. To update settings or configuration,
                please go to <Link
                to={`/vending_machines/${props.vendingMachine.uuid}/setting/product_mappings`}>Settings</Link>.
            </div>


            <div className="alert alert-default">
                <b>Hint: </b>
                Click product "Current Stock" to adjust par level manually
            </div>
        </div>

        {props.coils.length === 0 ? null :
            <div className={"box-body content"}>
                <button onClick={() => {
                    props.setAggregatedByProduct(!props.aggregatedByProduct);
                }} className="btn btn-info">Toggle Product Aggregation
                </button>
                {props.aggregatedByProduct ?
                    <DataTable key={1}
                               title={"Product Inventory Stats"}
                               highlightOnHover={true}
                               defaultSortField={"Product"}
                               columns={props.aggregatedColumns}
                               data={props.products}
                    />
                    :
                    <div>
                        <MediaQuery minDeviceWidth={768}>
                            <div>
                            &nbsp; 
                            <DownloadInventoriesCsv vendingMachineUuid={props.vendingMachine.uuid}/>
                                <DataTable key={2}
                                           title={"Inventory"}
                                           highlightOnHover={false}
                                           pointerOnHover={false}
                                           defaultSortField="mdbRaw"
                                           columns={props.columns}
                                           data={props.coils}
                                />
                            </div>
                            {props.vendingMachine.vendingMachineType === 'COFFEE' && <div>
                                <DataTable key={3}
                                           title={"Ingredient"}
                                           highlightOnHover={false}
                                           pointerOnHover={false}
                                           defaultSortField="mdbRaw"
                                           columns={props.ingredientColumns}
                                           data={props.ingredientMappings}
                                />
                            </div>}
                        </MediaQuery>
                        <MediaQuery maxDeviceWidth={768}>
                            <DataTable
                                title={"Inventory"}
                                highlightOnHover={false}
                                pointerOnHover={false}
                                defaultSortField="name"
                                columns={props.mobileColumns}
                                data={props.coils}
                                expandableRows={true}
                                expandableRowsComponent={<ExpanableRow data="" manualBalance={props.manualBalance}/>}
                            />
                        </MediaQuery>
                    </div>
                }
            </div>
        }
    </div>
}
