import React, {Component} from 'react';

import {IAuthenticationContext, withAuthentication} from './authentication';
import {Link, Redirect} from 'react-router-dom';
import {postAPI} from './api';

interface Props extends IAuthenticationContext {
}

interface State {
    email: string
    message: string | null
    success: boolean
}

class _ForgotPassword extends Component<Props, State> {
    constructor(props) {
        super(props);

        this.state = {
            email: "",
            message: null,
            success: false,
        };

        this.handleEmailChange = this.handleEmailChange.bind(this);
        this.handleSubmit = this.handleSubmit.bind(this);
    }

    handleEmailChange(event) {
        this.setState({email: event.target.value});
    }


    handleSubmit(event) {
        event.preventDefault();

        postAPI("/api/users/forgot_password", {email: this.state.email})
            .then(result => {
                this.setState({message: result.data.message, success: result.data.success});
            })
    }

    render() {
        if (this.props.authentication.authenticated) {
            return <Redirect to="/"/>;
        }


        let message = this.state.message ? this.state.success ?
            (<div className="alert alert-success">{this.state.message}</div>)
            : (<div className="alert alert-danger">{this.state.message}</div>) : null;

        return (
            <div className="container">
                <div className="login-box">
                    <div className="login-logo">
                        Vending on Track
                    </div>
                    <div className="login-box-body">
                        <p className="login-box-msg">Enter your login email. We will email instructions on how to reset your password.</p>


                        {message}

                        <form onSubmit={this.handleSubmit} className="form">


                            <div className="form-group has-feedback">
                                <input type="email" value={this.state.email}
                                       onChange={this.handleEmailChange} className="form-control" placeholder="Email"/>
                                <span className="fas fa-envelope sign-in-and-up-form-control"></span>
                            </div>
                            <div className="row">
                                <div className="col-xs-2">
                                </div>
                                <div className="col-xs-8">
                                    <input type="submit" className="btn btn-primary btn-block btn-flat" value="Submit"/>
                                </div>
                            </div>
                        </form>


                        <br/>
                        <div style={{display: "flex", justifyContent: "center", alignItems: "center"}}>
                            <Link to="/login">Login here.</Link>
                        </div>
                    </div>
                </div>
            </div>
        )
    }
}

let ForgotPassword = withAuthentication(_ForgotPassword);
export {ForgotPassword};
