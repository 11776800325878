import React, {Component, Fragment} from 'react';
import {getAPI, postAPI} from "./api";
import {Login, LoginFilter} from "./models";
import {DisplayTime} from "./display_time";
import {Helmet} from "react-helmet";

interface Props {

}
interface State {
    logins: Array<Login>
    currentPage: number
    pages: number,
    loginFilter:LoginFilter
}

export class LoginsPage extends Component<Props, State> {
    constructor(props) {
        super(props);

        this.state = {
            logins: [],
            currentPage: 0,
            pages: 1,
            loginFilter:LoginFilter.ALL
        }
    }

    componentDidMount(): void {
        this.reloadData();
    }

    reloadData(): void {
        getAPI(`/api/security/logins?page=${this.state.currentPage}`).then((response) => {
            if (response.data.success) {
                this.setState({
                    logins: response.data.sessions.content,
                    pages: response.data.sessions.totalPages,
                });
            }
        })
    }

    handleLoginFilter(event){
        event.preventDefault();
        this.setState({loginFilter: LoginFilter[event.target.value]})
    }

    render() {
        let rawLogins;
        if (this.state.loginFilter === LoginFilter.WEB){
            rawLogins = this.state.logins.filter((login)=>{return !login.isApp})
        } else if(this.state.loginFilter === LoginFilter.APP){
            rawLogins = this.state.logins.filter((login)=>{return login.isApp})
        } else {
            rawLogins = this.state.logins
        }



        const logins = rawLogins.map((login: Login) => {
           return (<tr>
               <td><DisplayTime timestamp={login.createdTimestamp} /> - {login.location} - {login.browser} - {login.platform}</td>
               <td><DisplayTime timestamp={login.lastAccessTimestamp} /></td>
               <td>{login.deletedTimestamp ? <span>Logout: <DisplayTime timestamp={login.deletedTimestamp} /></span> : "ongoing" }</td>
                <td>
                    {login.deletedTimestamp == null &&
                        <form onSubmit={(e) => {
                            e.preventDefault();
                            this.handleRemove(login.id);
                            return false;
                        }}>
                            <input type="submit" className="btn btn-sm btn-danger" value={"Revoke"}/>
                        </form>
                    }
                </td>
           </tr>);
        });

        return (
            <Fragment>
                <Helmet titleTemplate="%s - Vending on Track">
                    <title>Security</title>
                </Helmet>
                <section className="content-header">
                    <h1>
                        Security
                    </h1>
                </section>

                <section className="content">
                    <div className="box box-success">
                        <div className="box-header">
                            <h2>Past Logins</h2>
                        </div>
                        <div className="container-fluid">
                            <div className="form-group">
                                <label>Login Records Filter</label>
                                <select className="form-control" onChange={this.handleLoginFilter.bind(this)}>
                                    <option value="ALL">ALL</option>
                                    <option value="APP">APP</option>
                                    <option value="WEB">WEB</option>
                                </select>
                            </div>
                        </div>
                        <div className="box-body with-border">
                            <table className="table table-bordered table-condensed">
                                <thead>
                                    <tr>
                                        <th>Login Time - Location - Browser and Platform</th>
                                        <th>Last Access Time</th>
                                        <th>State</th>
                                        <th>Operations</th>
                                    </tr>
                                </thead>

                                <tbody>
                                    { logins }
                                </tbody>
                            </table>
                        </div>
                    </div>
                </section>
            </Fragment>
                );
    }

    private handleRemove(id: number) {
        postAPI(`/api/security/revoke`, {id: id}).then((response) => {
            if (response.data.success) {
                this.reloadData();
            } else {
                window.alert(response.data.message);
            }
        })
    }
}