import React, {Component, Fragment} from 'react';

import {getAPI, postAPI} from "./api";

import {IGlobalDataContext, withGlobalData} from "./contexts/global-data";
import {CompanyUserNomination, CompanyUserRole, Message} from "./models";
import CompanyUserNominationsTable from "./company_user_nominations_table";
import Select from "react-select";
import {Helmet} from "react-helmet";

interface Props extends IGlobalDataContext {
}

interface State {
    loading: boolean
    message: string | null,
    selectedRole: string
    nominations: Array<CompanyUserNomination>
    emails: string
    saving: boolean,
}

class RawCompanyUsersPage extends Component<Props, State> {
    constructor(props) {
        super(props);

        this.state = {
            loading: false,
            message: null,
            selectedRole: '',
            nominations:[],
            emails:"",
            saving: false,
        };
    }

    loadCompanyDriverNominations() {
        getAPI(`/api/company_user_nominations`).then((response) => {
            if (response.data.success) {
                this.setState({nominations: response.data.companyUserNominationInfos});
            } else {
                this.setState({message: response.data.message})
            }
        })
    }

    componentDidMount(): void {
        this.loadCompanyDriverNominations();
    }


    handleName(e) {
        e.preventDefault();

        this.setState({emails: e.target.value});
    }

    submit(e) {
        e.preventDefault();

        this.setState({saving: true});

        const emails = this.state.emails;
        const role = this.state.selectedRole;

        postAPI("/api/company_user_nomination/nominate_user", {
            nominateUserEmails: emails,
            role: role
        }).then((result) => {
            if (result.data.success) {
                this.loadCompanyDriverNominations();
                this.props.pushFlashMessage(new Message("Successfully Nominated", "success"));
            }else{
                this.props.pushFlashMessage(new Message(result.data.message, "error"));
            }
            this.setState({saving: false});
        })
    }

    selectRole(event){
        this.setState({selectedRole:event.value})
    }

    render() {
        const roles = Object.keys(CompanyUserRole).map(key => {
            return {
                label: CompanyUserRole[key],
                value: CompanyUserRole[key]
            }
        });

        return (

            <Fragment>
                <Helmet titleTemplate="%s - Vending on Track">
                    <title>Company User Management</title>
                </Helmet>
                <section className="content-header">
                    <h1>
                        Company User Management
                    </h1>
                </section>

                <section className="content">

                    <div className="box box-primary">
                        <div className="box-header with-border">
                            <h3 className="box-title">Nominate Company User</h3>
                            <form onSubmit={this.submit.bind(this)}>
                                <div className="box-body">
                                    <div className="form-group">
                                        <label htmlFor="name">Email</label>
                                        <input onChange={this.handleName.bind(this)} value={this.state.emails} type="text"
                                               className="form-control" id="emails"
                                               placeholder="Enter User's Email(s)"/>
                                    </div>
                                    <label>Please choose a role for the nominated user</label> &nbsp;
                                    <br/>
                                    <Select
                                        onChange={this.selectRole.bind(this)}
                                        options={roles}
                                    />
                                    <br/>
                                </div>

                                <div className="box-footer">
                                    <button type="submit" disabled={this.state.saving} className="btn btn-primary">Nominate</button>
                                </div>
                            </form>
                        </div>
                    </div>
                    {this.state.message ?
                        <div className="callout callout-danger">
                            <h4>There was an error when we tried to load your nominations for this company.</h4>

                            <p>{this.state.message}</p>
                        </div>
                        :
                        (this.state.loading ?
                                <div className="callout callout-info">
                                    <h4>Loading nominations...</h4>

                                    <p>We are listing all your nominations. It won't be long.</p>
                                </div>
                                :
                                <div>
                                    <h3 className={""}>All Nominations</h3>

                                    <div className={"row"}>
                                        <div className={"col-xs-12"}>
                                            <div className="box with-border box-primary">
                                                <div className="box-body no-padding">
                                                    <CompanyUserNominationsTable nominations={this.state.nominations}
                                                                                 nominationsShouldReload={this.componentDidMount.bind(this)}
                                                                                 pushFlashMessage={this.props.pushFlashMessage}
                                                    />
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                        )
                    }
                </section>
            </Fragment>
        );
    }
}

export const CompanyUsersPage = withGlobalData(RawCompanyUsersPage);
