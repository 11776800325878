import React, {Component, Fragment} from 'react';
import {postAPI} from "./api";
import {ConfigTimes, SalesInfoSourceType, TelemetryDevice, VendingMachine} from "./models";
import {VendingMachineAddressPanel} from "./vending_machine_address_panel";
import {VendingMachineNameEditing} from "./vending_machine_name_editing";
import {DisplayTime} from "./display_time";
import {Link} from "react-router-dom";
import ReactTooltip from "react-tooltip";
import ReactDOMServer from 'react-dom/server'
import {VendingMachineTypeEditing} from "./vending_machine_type_editing";
import VendingMachineGroupSelector from "./vending_machine_group_selector";
import {TreeItem} from "./utils/tree";
import {IGlobalDataContext, withGlobalData} from "./contexts/global-data";
import BusyIndicator, {LoadingSpanner} from "./busy_indicator";

interface Props extends IGlobalDataContext{
    vending_machine: VendingMachine
    telemetry_devices: Array<TelemetryDevice>
    reloadAllProducts()
    detailUpdated()
    reloadAllVendingMachines()
}

interface State {
    deviceUUID: string
    message: string | null
    name_editing: boolean
    sales_info_source: SalesInfoSourceType,
    sales_info_source_message: string | null,
    type_editing:boolean,
    is_in_testing_mode: boolean | null
    isMovingToAGroup: boolean,
}

class _VendingMachineDetailPanel extends Component<Props, State> {
    constructor(props) {
        super(props);

        let vending_machine = props.vending_machine;
        let deviceUUID = vending_machine.installedTelemetryDeviceUuid;
        let telemetry_device = this.props.telemetry_devices.find((telemetryDevice)=>{return telemetryDevice.uuid === deviceUUID});


        this.state = {
            sales_info_source: vending_machine.salesInfoSource,
            sales_info_source_message: null,
            deviceUUID: deviceUUID,
            message: null,
            name_editing: false,
            type_editing:false,
            is_in_testing_mode: telemetry_device?.inTestingMode ?? null,
            isMovingToAGroup: false,
        }

    }

    clear_settings(e) {
        e.preventDefault();

        if (window.confirm("You may need to restart vending machine to get updated information. Confirm?")) {
            console.log('reset');

            postAPI(`/api/vending_machines/${this.props.vending_machine.uuid}/reset_config`, {}).then((response) => {
                if (response.data.success) {
                    this.props.detailUpdated();
                } else {
                    window.alert(response.data.message);
                }
            });
        }

        return false;
    }

    reset_coin_tube(e){
        e.preventDefault();

        if (window.confirm("Coin Tube Status will be cleared. Confirm?")){
            postAPI(`/api/vending_machines/${this.props.vending_machine.uuid}/tube_status/reset`, {}).then((response) => {
                if (response.data.success) {
                    window.alert("Coin Tube Status has been cleared");
                } else {
                    window.alert(response.data.message);
                }
            });
        }
    }

    toggleNameEditing(event) {
        event.preventDefault();

        this.setState({name_editing: !this.state.name_editing, type_editing: false});

        return false;
    }

    toggleTypeEditing(event) {
        event.preventDefault();

        this.setState({type_editing: !this.state.type_editing, name_editing: false});

        return false;
    }

    onChangeSource(event) {
        event.preventDefault();

        this.setState({sales_info_source: event.target.value});

        return false;
    }

    onEnvChange(event) {
        event.preventDefault();

        this.setState({is_in_testing_mode: event.target.value === "true"});

        return false;
    }

    handleSubmitIsInTestingMode(event) {
        event.preventDefault();

        const selectedTelemetryDevice = this.props.telemetry_devices.find((telemetryDevice)=>{return telemetryDevice.uuid === this.state.deviceUUID});
        if (selectedTelemetryDevice === undefined){
            window.alert("No Device Installed")
            return false;
        }

        postAPI(`/api/telemetry_devices/${selectedTelemetryDevice.uuid}/testing-mode`, {
            is_in_testing_mode: this.state.is_in_testing_mode
        }).then((response) => {
            if (response.data.success) {
                window.alert("Success")
                this.props.reloadAllVendingMachines();
            } else {
                window.alert(response.data.errorMessage)
            }
        });

        return true;
    }

    handleSubmitSalesInfoSource(event) {
        event.preventDefault();

        postAPI(`/api/vending_machines/${this.props.vending_machine.uuid}/sales_info_source`, {
            source: this.state.sales_info_source
        }).then((response) => {
            if (response.data.success) {

            } else {
                this.setState({sales_info_source_message: response.data.message})
            }
        });

        return false;
    }

    onFinished() {
        this.setState({name_editing: false});
        this.setState({type_editing:false});
        this.props.detailUpdated();
    }

    moveVendingMachine = (node: TreeItem):Promise<boolean> => {
        if (this.state.isMovingToAGroup){
            return Promise.reject(false);
        }
        if (window.confirm("Are you sure to move the vending machine into this group?")){
            this.setState({isMovingToAGroup: true});
            return postAPI(`/api/vending_machine_groups/${node.uuid}/vending_machine_moving`, {
                vendingMachineUuid:this.props.vending_machine.uuid
            }).then((result) => {
                if (result.data.success) {
                    this.props.detailUpdated();
                    this.props.reloadVendingMachineGroups();
                    return true;
                }else{
                    window.alert(result.data.message)
                    return false;
                }
            }).catch((e)=>{
                console.log(e)
                window.alert("Server error")
                return false;
            }).finally(()=>{
                this.setState({isMovingToAGroup: false});
            })
        }
        return Promise.reject(false);
    }

    render() {
        const selectedTelemetryDevice = this.props.telemetry_devices.find((telemetryDevice)=>{return telemetryDevice.uuid === this.state.deviceUUID});


        let timestamps: ConfigTimes = this.props.vending_machine.configTimes ? {...this.props.vending_machine.configTimes} : {
            communication_gateway_setup_response_timestamp: null,
            cashless_setup_timestamp: null,
            communication_gateway_setup_request_timestamp: null,
            cashless_setup_request_timestamp:null
        };
        console.log(timestamps);

        ["coin_changer_setup_timestamp", "note_reader_setup_timestamp", "communication_gateway_setup_response_timestamp", "cashless_setup_timestamp", "communication_gateway_setup_request_timestamp", "cashless_setup_request_timestamp"].forEach((column) => {
            if (timestamps && timestamps[column]) {
                timestamps[column] = Math.ceil(timestamps[column] / 1000);
            } else {
                timestamps[column] = null;
            }
        });

        let i = 0;

        let seconds = -1;

        let features = this.props.vending_machine.vmcfeatures.map((feature) => {
            i++;
            seconds = feature.age;
            return (
                <span key={i}><span className="label label-info">{feature.feature}</span>   &nbsp;</span>
            )
        });

        let cashlessSetupDetail;
        if (this.props.vending_machine.cashlessSetupDetail !== null){
            let displayInfo;
            if (this.props.vending_machine.cashlessSetupDetail.alphanumeric === null && this.props.vending_machine.cashlessSetupDetail.fullASCII === null){
                displayInfo = "Not Available";
            } else if (!this.props.vending_machine.cashlessSetupDetail.alphanumeric && this.props.vending_machine.cashlessSetupDetail.fullASCII){
                displayInfo = "Full ASCII"
            } else if (this.props.vending_machine.cashlessSetupDetail.alphanumeric && !this.props.vending_machine.cashlessSetupDetail.fullASCII){
                displayInfo = "Alphanumeric"
            }
            cashlessSetupDetail = <div className={"row"}>
                <div className={"col-xs-6"}>
                    <p>Feature Level</p>
                    <p>Number of Column</p>
                    <p>Number of Row</p>
                    <p>Display Information</p>
                </div>
                <div className={"col-xs-6"}>
                    <p>{this.props.vending_machine.cashlessSetupDetail.featureLevel}</p>
                    <p>{this.props.vending_machine.cashlessSetupDetail.column}</p>
                    <p>{this.props.vending_machine.cashlessSetupDetail.row}</p>
                    <p>{displayInfo}</p>
                </div>
            </div>
        }

        let expansionRequestDetail;
        if (this.props.vending_machine.expansionRequestDetail !== null) {
            expansionRequestDetail = <div className={"row"}>
                <div className={"col-xs-6"}>
                    <p>Manufacturer Code</p>
                    <p>Serial Number</p>
                    <p>Model Number</p>
                    <p>Software Version</p>
                </div>
                <div className={"col-xs-6"}>
                    <p>{this.props.vending_machine.expansionRequestDetail.manufacturerCode}</p>
                    <p>{this.props.vending_machine.expansionRequestDetail.serialNumber}</p>
                    <p>{this.props.vending_machine.expansionRequestDetail.modelNumber}</p>
                    <p>{this.props.vending_machine.expansionRequestDetail.softwareVersion}</p>
                </div>
            </div>
        }

        let coinChangerDetail;
        if (this.props.vending_machine.coinChangerDetail !== null){
            coinChangerDetail = <table className={"table table-bordered"}>
                <tr>
                    <th>Feature Level</th>
                    <td>{this.props.vending_machine.coinChangerDetail.featureLevel}</td>
                </tr>
                <tr>
                    <th>Country</th>
                    <td>{this.props.vending_machine.coinChangerDetail.country}</td>
                </tr>
                <tr>
                    <th>Decimal Place</th>
                    <td>{this.props.vending_machine.coinChangerDetail.decimalPlace}</td>
                </tr>
                <tr>
                    <th>Scaling Factor</th>
                    <td>{this.props.vending_machine.coinChangerDetail.scalingFactor}</td>
                </tr>
                <tr>
                    <th>Coin Type Routing Description</th>
                    <td>{this.props.vending_machine.coinChangerDetail.coinTypeRoutingDescription}</td>
                </tr>
                <tr>
                    <th>Coin Type Credit Description</th>
                    <td>{this.props.vending_machine.coinChangerDetail.coinTypeCreditDescription}</td>
                </tr>
            </table>
        } else {
            coinChangerDetail = <p>Not Available</p>
        }

        let noteReaderDetail;

        if (this.props.vending_machine.noteReaderDetail !== null){
            noteReaderDetail = <table className={"table table-bordered"}>
                <tr>
                    <th>Feature Level</th>
                    <td>{this.props.vending_machine.noteReaderDetail.featureLevel}</td>
                </tr>
                <tr>
                    <th>Country</th>
                    <td>{this.props.vending_machine.noteReaderDetail.country}</td>
                </tr>
                <tr>
                    <th>Decimal Place</th>
                    <td>{this.props.vending_machine.noteReaderDetail.decimalPlace}</td>
                </tr>
                <tr>
                    <th>Scaling Factor</th>
                    <td>{this.props.vending_machine.noteReaderDetail.scalingFactor}</td>
                </tr>
                <tr>
                    <th>Stack Capacity</th>
                    <td>{this.props.vending_machine.noteReaderDetail.stackCapacity}</td>
                </tr>
                <tr>
                    <th>Security Level</th>
                    <td>{this.props.vending_machine.noteReaderDetail.securityLevel}</td>
                </tr>
                <tr>
                    <th>Support Escrow</th>
                    <td>{this.props.vending_machine.noteReaderDetail.supportEscrow && "YES"}</td>
                </tr>
                <tr>
                    <th>Note Credits</th>
                    <td>{this.props.vending_machine.noteReaderDetail.creditDescription}</td>
                </tr>
            </table>
        } else {
            noteReaderDetail = <p>Not Available</p>
        }

        let cashlessDetail;

        if (this.props.vending_machine.cashlessDetail !== null){
            cashlessDetail = <table className={"table table-bordered"}>
                <tr>
                    <th>Feature Level</th>
                    <td>{this.props.vending_machine.cashlessDetail.featureLevel}</td>
                </tr>
                <tr>
                    <th>Country</th>
                    <td>{this.props.vending_machine.cashlessDetail.country}</td>
                </tr>
                <tr>
                    <th>Decimal Place</th>
                    <td>{this.props.vending_machine.cashlessDetail.decimalPlace}</td>
                </tr>
                <tr>
                    <th>Scaling Factor</th>
                    <td>{this.props.vending_machine.cashlessDetail.scalingFactor}</td>
                </tr>
                <tr>
                    <th>Max Response Time</th>
                    <td>{this.props.vending_machine.cashlessDetail.maxResponseTime}</td>
                </tr>
                <tr>
                    <th>Can issue refund</th>
                    <td>{this.props.vending_machine.cashlessDetail.noRefund !== null && (!this.props.vending_machine.cashlessDetail.noRefund).toString()}</td>
                </tr>
                <tr>
                    <th>Multi Vending</th>
                    <td>{this.props.vending_machine.cashlessDetail.multiVend !== null && this.props.vending_machine.cashlessDetail.multiVend.toString()}</td>
                </tr>
                <tr>
                    <th>Own Display</th>
                    <td>{this.props.vending_machine.cashlessDetail.ownDisplay !== null && this.props.vending_machine.cashlessDetail.ownDisplay.toString()}</td>
                </tr>
                <tr>
                    <th>Cash Sales Report</th>
                    <td>{this.props.vending_machine.cashlessDetail.cashSalesReport !== null && this.props.vending_machine.cashlessDetail.cashSalesReport.toString()}</td>
                </tr>
            </table>
        } else {
            cashlessDetail = <p>Not Available</p>
        }

        const group = this.props.findVendingMachineGroups(this.props.vending_machine.vendingMachineGroupUuid);


        return (
            <div>
                <section className="content-header">
                    <h2>Detail Panel for {this.props.vending_machine.name}</h2>
                    <div className="well">
                            <button onClick={this.toggleNameEditing.bind(this)}
                                     className="btn btn-success">Rename
                            </button>
                        &nbsp;|&nbsp;
                            <button onClick={this.toggleTypeEditing.bind(this)}
                                    className="btn btn-success">Vending Machine Type
                            </button>
                        {this.state.name_editing ?
                            <VendingMachineNameEditing onFinished={this.onFinished.bind(this)}
                                                       new_name={this.props.vending_machine.name}
                                                       vendingMachine={this.props.vending_machine}/> : null}
                        {this.state.type_editing ? <VendingMachineTypeEditing onFinished={this.onFinished.bind(this)}
                                                                              vendingMachine={this.props.vending_machine}
                                                                              reloadAllProducts={this.props.reloadAllProducts.bind(this)}/> : null}
                        <hr/>
                        <span>{group?.name}</span> &nbsp;
                        <BusyIndicator busy={this.state.isMovingToAGroup} busyIndicator={<LoadingSpanner/>}>
                            <VendingMachineGroupSelector vendingMachine={this.props.vending_machine}
                                                         skipGroupUuids={[this.props.vending_machine.vendingMachineGroupUuid]}
                                                         onSelectedGroup={this.moveVendingMachine.bind(this)}
                                                         button={<button className={"btn btn-default"}>Move Here</button>}
                                                         selectGroup={true}/>
                        </BusyIndicator>
                    </div>
                </section>

                <VendingMachineAddressPanel vendingMachine={this.props.vending_machine} updated={this.props.detailUpdated}/>

                <hr/>

                <div className={"row"}>
                    <div className={"col-md-6"}>
                        <div className={"row"}>
                            <div className={"col-sm-12"}>
                                <div className={"box box-success"}>
                                    <div className={"box-header"}>
                                        <h3>Vending Machine enabled features</h3>
                                    </div>

                                    <div className={"box-body"}>
                                        {seconds === -1 ? null : (
                                            <p>Last updated: <DisplayTime timestamp={(new Date().getTime() / 1000 - seconds)}/>
                                            </p>)}

                                        {features}
                                    </div>
                                </div>
                            </div>
                            <div className={"col-sm-12"}>
                                <div className={"box box-success"}>
                                    <div className={"box-header"}>
                                        <h3>Vending Machine cashless settings</h3>
                                    </div>

                                    <div className={"box-body"}>
                                        {timestamps.cashless_setup_request_timestamp &&(
                                            <p>Last updated: <DisplayTime  timestamp={timestamps.cashless_setup_request_timestamp}/>
                                            </p>)}
                                        {cashlessSetupDetail}
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div className={"col-md-6"}>
                        <div className={"box box-info"}>
                            <div className={"box-header"}>
                                <h2>Peripheral settings</h2>
                                <button onClick={this.clear_settings.bind(this)}
                                        className={"btn btn-danger btn-small"}>Reset settings
                                </button>
                                |
                                <button onClick={this.reset_coin_tube.bind(this)} className={"btn btn-danger btn-small"}>
                                    Reset Coin Tube
                                </button>
                            </div>
                            <div className={"box-body"}>
                                <div className={"row"}>
                                    <div className={"col-xs-6"}>
                                        <p data-tip={ReactDOMServer.renderToStaticMarkup(coinChangerDetail)} data-for='coinChangerDetail'>Coin Changer</p>
                                        <ReactTooltip html = {true} multiline = {true} id = "coinChangerDetail"/>
                                        <p data-tip={ReactDOMServer.renderToStaticMarkup(noteReaderDetail)} data-for='noteReaderDetail'>Note Reader</p>
                                        <ReactTooltip html = {true} multiline = {true} id = "noteReaderDetail"/>
                                        <p data-tip={ReactDOMServer.renderToStaticMarkup(cashlessDetail)} data-for='cashlessDetail'>Cashless</p>
                                        <ReactTooltip html = {true} multiline = {true} id = "cashlessDetail"/>
                                        <p>CommunicationGateway Request</p>
                                        <p>CommunicationGateway Response</p>
                                    </div>
                                    <div className={"col-xs-6"}>
                                        <p><DisplayTime  timestamp={timestamps.coin_changer_setup_timestamp}/></p>
                                        <p><DisplayTime timestamp={timestamps.note_reader_setup_timestamp}/></p>
                                        <p><DisplayTime timestamp={timestamps.cashless_setup_timestamp}/></p>
                                        <p><DisplayTime timestamp={timestamps.communication_gateway_setup_request_timestamp}/></p>
                                        <p><DisplayTime timestamp={timestamps.communication_gateway_setup_response_timestamp}/></p>
                                    </div>
                                </div>
                                {expansionRequestDetail}
                            </div>
                        </div>
                    </div>
                </div>

                <hr/>

                <div className="row">
                    <div className="col-md-6">
                        <div className="box box-info">
                            <div className="box-header">
                                <h3>Sales Information Source</h3>
                            </div>
                            <div className="box-body">

                                <form onSubmit={this.handleSubmitSalesInfoSource.bind(this)}>

                                    {this.state.sales_info_source_message}
                                    <div className="form-group">
                                        <label>Please choose vending machine sales info source: </label>

                                        <select onChange={this.onChangeSource.bind(this)} className="form-control"
                                                value={this.state.sales_info_source}>
                                            <option value={SalesInfoSourceType.DEX}>DEX - Delayed information,
                                                recommended for older
                                                vending machines that do not have proper MDB support
                                            </option>
                                            <option value={SalesInfoSourceType.MDB}>MDB - Realtime, recommended for
                                                modern vending
                                                machines
                                            </option>
                                        </select>

                                        <hr/>

                                        <input type="submit" value="Update" className="btn btn-success"/>
                                    </div>
                                </form>
                            </div>
                        </div>
                    </div>

                    <div className="col-md-6">
                        <div className="box box-warning">
                            <div className="box-header">
                                <h3>Tracking Device</h3>
                            </div>
                            <div className="box-body">
                                {
                                    selectedTelemetryDevice !== undefined && selectedTelemetryDevice.latpayAccountId !== null && <Fragment>
                                        <div className="form-group">
                                            <label>Latpay Account ID</label>
                                            <input className="form-control" disabled type="text" value={selectedTelemetryDevice ? `${selectedTelemetryDevice.latpayAccountId ?? "-"}` : "-"}/>
                                        </div>
                                        <form onSubmit={this.handleSubmitIsInTestingMode.bind(this)}>
                                            <div className="form-group">
                                                <label>Switch Between Testing and Production Mode </label>

                                                <select onChange={this.onEnvChange.bind(this)} className="form-control"
                                                        value={this.state.is_in_testing_mode?.toString() ?? "unavailable"}>
                                                    <option value={"true"}> Testing
                                                    </option>
                                                    <option value={"false"}>Production
                                                    </option>
                                                </select>
                                                <br/>
                                                <input type="submit" value="Update" className="btn btn-success"/>
                                            </div>
                                        </form>
                                    </Fragment>
                                }
                                <div className="form-group">
                                    <label>Currently installed telemetry device</label>
                                    <input className="form-control" disabled type="text" value={selectedTelemetryDevice ? `${selectedTelemetryDevice.imei} [${selectedTelemetryDevice.name}]` : "No Device Installed"}/>
                                </div>

                                <Link to={"/devices"}>All telemetry devices</Link>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

        );
    }
}

const VendingMachineDetailPanel = withGlobalData(_VendingMachineDetailPanel);
export {VendingMachineDetailPanel};
