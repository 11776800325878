import React, { Component } from "react";
import { IAuthenticationContext, withAuthentication } from "./authentication";
import { IGlobalDataContext, withGlobalData } from "./contexts/global-data";
import {CompanyUserRole} from "./models";
import {DriverSidebar} from "./driver_sidebar";
import {AdminSidebar} from "./admin_sidebar";
import {ReportSidebar} from "./report_sidebar";

interface Props extends IAuthenticationContext, IGlobalDataContext {
}

interface State {
    reportMenuOpen: boolean
    pickingMenuOpen: boolean
    productMenuOpen: boolean
    companyMenuOpen: boolean
}

class _Sidebar extends Component<Props, State> {
    constructor(props) {
        super(props);

        this.state = {
            reportMenuOpen: false,
            pickingMenuOpen: false,
            productMenuOpen: false,
            companyMenuOpen: false
        }
    }

    togglePickingMenu(e) {
        e.preventDefault();
        e.stopPropagation();
        console.log(e);

        this.setState({ pickingMenuOpen: !this.state.pickingMenuOpen });
        return false;
    }

    toggleReportMenu(e) {
        e.preventDefault();
        e.stopPropagation();

        this.setState({ reportMenuOpen: !this.state.reportMenuOpen });

        return false;
    }

    toggleProductMenu(e) {
        e.preventDefault();
        e.stopPropagation();

        this.setState({ productMenuOpen: !this.state.productMenuOpen });
        return false;
    }


    toggleCompanyMenu(e) {
        e.preventDefault();
        e.stopPropagation();

        this.setState({ companyMenuOpen: !this.state.companyMenuOpen });
        return false;
    }


    handleClick() {
        // collapse

        if (document.body.classList.contains("manual-controlled")) {
            document.body.classList.remove('sidebar-open');
            document.body.classList.add("sidebar-collapse");
        }
    }
    render() {
        if (this.props.me?.role === CompanyUserRole.DRIVER.valueOf()) {
            return <DriverSidebar handleClick={this.handleClick.bind(this)}
                                  authentication={this.props.authentication}
                                  pickingMenuOpen={this.state.pickingMenuOpen}
                                  togglePickingMenu={this.togglePickingMenu.bind(this)} />
        }
        if (this.props.me?.role === CompanyUserRole.REPORT.valueOf()) {
            return <ReportSidebar handleClick={this.handleClick.bind(this)}
                                  authentication={this.props.authentication}
                                  reportMenuOpen={this.state.reportMenuOpen}
                                  toggleReportMenu={this.toggleReportMenu.bind(this)}/>
        }
        return <AdminSidebar handleClick={this.handleClick.bind(this)}
                             authentication={this.props.authentication}
                             pickingMenuOpen={this.state.pickingMenuOpen}
                             togglePickingMenu={this.togglePickingMenu.bind(this)}
                             reportMenuOpen={this.state.reportMenuOpen}
                             toggleReportMenu={this.toggleReportMenu.bind(this)}
                             notificationCount={this.props.notificationCount}
                             productMenuOpen={this.state.productMenuOpen}
                             toggleProductMenu={this.toggleProductMenu.bind(this)}
                             companyMenuOpen={this.state.companyMenuOpen}
                             toggleCompanyMenu={this.toggleCompanyMenu.bind(this)}
                             />
    }
}

const Sidebar = withGlobalData(withAuthentication(_Sidebar));

export { Sidebar };
