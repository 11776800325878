import React, {useState} from 'react';
import {IGlobalDataContext, withGlobalData} from "./contexts/global-data";
import {Highlighter, Typeahead} from "react-bootstrap-typeahead";
import {VendingMachine} from "./models";
import {postAPI} from "./api";

interface Props extends IGlobalDataContext {
    vendingMachineUUID: string

    onUpdated()
}

function _importProductMapping(props: Props) {
    const vendingMachines = props.allVendingMachines;

    const [templateVendingMachine, setTemplateVendingMachine] = useState<VendingMachine | null>(null);
    const [busy, setBusy] = useState(false);

    function doImport(e) {
        e.preventDefault();
        e.stopPropagation();

        if (templateVendingMachine == null) {
            window.alert("Please choose template vending machine")
            return;
        }

        if (templateVendingMachine?.uuid === props.vendingMachineUUID) {
            window.alert("Can't import settings from the same vending machine.");
            return;
        }

        setBusy(true);

        postAPI(`/api/vending_machines/${props.vendingMachineUUID}/product_mappings/imports`, {
            source: templateVendingMachine.uuid
        }).then((resp) => {
            if (resp.data.success) {
                props.onUpdated();
            } else {
                window.alert(resp.data.message);
            }
        }).finally(() => {
            setBusy(false);
        })
    }

    function selectVendingMachines(vms) {
        if (vms.length > 0) {

            setTemplateVendingMachine(vms[0]);
        } else {
            setTemplateVendingMachine(null);
        }
    }


    const renderVendingMachine = (option: VendingMachine, props, index) => {
        return [
            <Highlighter key={`key-${index}`} search={props.text}>{option.name}</Highlighter>,
            <div key={`info-${index}`} style={{fontSize: 'smaller'}}>
                {option.address}
            </div>
        ];
    };

    return (
        <div>
            <br/>
            <form onSubmit={doImport} className={"form"}>
                <label>Select template vending machine</label>
                <Typeahead key={"typeahead"}
                           id={1} onChange={selectVendingMachines}
                           labelKey={"name"}
                           defaultSelected={undefined}
                           renderMenuItemChildren={renderVendingMachine}
                           options={vendingMachines.allVendingMachines}/>
                <br/>
                {busy ?
                    (<i className="fas fa-cog fa-spin"></i>)
                    :
                    (<input type={"submit"} value={"Save"} className={"btn btn-success"}/>)
                }
            </form>
        </div>
    );
}

const ImportProductMapping = withGlobalData(_importProductMapping);

export default ImportProductMapping;
