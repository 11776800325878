import React, {Component} from "react";
import {VendingMachine} from "./models";
import {getAPI, postAPI} from "./api";

interface Props {
    vendingMachine: VendingMachine
    onFinished()
    reloadAllProducts()
}

interface State{
    allType:Array<string>;
    selectedVendingMachineType: string;
}

export class VendingMachineTypeEditing extends Component<Props, State> {
    constructor(props) {
        super(props);

        this.state = {
            allType:[],
            selectedVendingMachineType: this.props.vendingMachine.vendingMachineType
        }
        this.getAllVendingMachineType()
    }

    getAllVendingMachineType() {
        getAPI(`/api/vending_machines/all_type`).then((response) => {
            this.setState({
                allType: response.data.types,
            })
        })
    }

    handleSelection(e){
        e.preventDefault();
        e.stopPropagation();
        this.setState({selectedVendingMachineType:e.target.value})
    }

    handleSave(e) {
        e.preventDefault();

        postAPI(`/api/vending_machines/${this.props.vendingMachine.uuid}/type`, {vendingMachineType: this.state.selectedVendingMachineType})
            .then((response) => {
                if (response.data.success) {
                    this.props.onFinished();
                    this.props.reloadAllProducts();
                } else {
                    window.alert(response.data.message);
                }
            });

        return false;
    }

    render() {

        const options = this.state.allType.map((vendingMachineType)=>{
            return <option key={vendingMachineType} value={vendingMachineType}>
                {vendingMachineType !== "FOURPRICE" && vendingMachineType}
                {vendingMachineType === "FOURPRICE" && "JONES PLUG"}
            </option>
        })

        return (<div>
            <label htmlFor="types">Change Type:</label>
            <select onChange={this.handleSelection.bind(this)} className={"form-control"} name="types" id="types"
                    value={this.state.selectedVendingMachineType}>
                {options}
            </select>
            <input onClick={this.handleSave.bind(this)} type={"submit"} className={"btn btn-success"}
                   value={"Update Type"}/>
        </div>);

    }
}