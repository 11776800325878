import React, {Component} from 'react';
import {IAuthenticationContext, withAuthentication} from './authentication';
import {Link, Redirect} from 'react-router-dom';
import {postAPI} from './api';
import VersionInfo from "./version_info";

import VotLogo from './assets/vot-logo.png';

interface Props extends IAuthenticationContext {
}

interface State {
    password: string
    email: string
    validating: boolean
    error_message: string | null
    isTfaRequired: boolean
}

class _Login extends Component<Props, State> {
    constructor(props) {
        super(props);

        this.state = {
            email: "",
            password: "",
            validating: false,
            error_message: null,
            isTfaRequired: false
        };

        this.handleEmailChange = this.handleEmailChange.bind(this);
        this.handlePasswordChange = this.handlePasswordChange.bind(this);
        this.handleSubmit = this.handleSubmit.bind(this);
    }

    handleEmailChange(event) {
        this.setState({email: event.target.value});
    }

    handlePasswordChange(event) {
        this.setState({password: event.target.value});
    }

    handleSubmit(event) {
        event.preventDefault();
        this.setState({validating: true});

        postAPI("/api/users/login", {email: this.state.email, password: this.state.password})
            .then(result => {
                this.setState({validating: false});
                return result;
            })
            .then((result) => {
                if (result.data.success) {
                    // success
                    this.setState({isTfaRequired: result.data.tfaRequired})
                    this.props.login(result.data.securityToken, result.data.email, result.data.isApproved, result.data.hasCompany, result.data.tfaRequired)
                } else {
                    this.setState({error_message: result.data.reason});
                }
            })
            .catch((result) => {
                this.setState({
                    validating: false,
                    error_message: "Failed to validate credentials"
                })
            })
    }

    render() {
        if (this.props.authentication.authenticated) {
            return <Redirect to="/"/>;
        }


        let error_message = this.state.error_message ?
            (<div className="alert alert-danger">{this.state.error_message}</div>)
            : null;

        // @ts-ignore
        // @ts-ignore
        let loginForm = (
            <div className="login-box">


                <div className={"row"}>
                    <div className={"col-sx-12"} style={{textAlign: "center"}}>
                        <a href={"https://www.vendingontrack.com"}>
                            <img src={VotLogo}
                                 width={"100%"}
                                 alt={"Vending on Track is the leading technology provider for the vending industry"}/>
                        </a>
                    </div>
                </div>

                <div className="login-box-body">
                    <p className="login-box-msg">Sign in to use Vending on Track</p>
                    {error_message}

                    <form onSubmit={this.handleSubmit}>

                        <div className="form-group has-feedback">
                            <input type="email" value={this.state.email}
                                   onChange={this.handleEmailChange} className="form-control" placeholder="Email"/>
                            <span className="fas fa-envelope sign-in-and-up-form-control">&nbsp;</span>
                        </div>
                        <div className="form-group has-feedback">
                            <input type="password" className="form-control" value={this.state.password}
                                   onChange={this.handlePasswordChange} placeholder="Password"/>
                            <span className="fas fa-lock sign-in-and-up-form-control">&nbsp;</span>
                        </div>
                        <div className="row">
                            <div className="col-xs-2">
                            </div>
                            <div className="col-xs-8">
                                <input type="submit" className="btn btn-primary btn-block btn-flat" value="Sign In"/>
                            </div>
                        </div>

                        <br/>
                        <div className="row">
                            <div className="col-xs-6">
                                <Link to="/register">Sign up here.</Link>
                            </div>
                            <div className="col-xs-6" style={{textAlign: "end"}}>
                                <Link to="/forgot_password">Forgot password.</Link>
                            </div>
                        </div>

                        <hr/>

                        <div className={"row"} style={{marginTop: "5em"}}>
                            <div className={"col-sx-12"} style={{textAlign: "center"}}>
                                <img width={"100%"} src="https://d0.awsstatic.com/logos/powered-by-aws.png"
                                     alt="Vending on Track's vending machine telemetry management backend is powered by Amazon AWS"/>
                            </div>
                        </div>

                        <div style={{color: "white"}}>
                            <VersionInfo/>
                        </div>
                    </form>
                </div>
            </div>
        );

        let busy = (
            <div className="busyIndicator">
                <div className="progress progress-sm active">
                    <div className="progress-bar progress-bar-success progress-bar-striped" role="progressbar"
                         style={{width: "100%"}}>
                    </div>
                </div>


                <div className="alert alert-success">
                    <b>
                        <i className="fa fa-spinner fa-spin">&nbsp;</i>
                        Login:
                    </b>
                    &nbsp;&nbsp;&nbsp;&nbsp;
                    We are verifying your username and password. It takes roughly <em>2</em> seconds. This password hash
                    algorithm is called "bcrypt", it's designed to be extremely slow, so that it's brute-force proof.
                </div>
            </div>
        );


        return (
            <div className="container">
                {(this.state.validating) ? busy : loginForm}
            </div>
        )
    }
}

const Login = withAuthentication(_Login);

export {Login};
