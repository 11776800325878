import {Link} from "react-router-dom";
import {VendingMachinesTable} from "./vending_machines_table";
import {RefillsTable} from "./refills_table";
import React, {Fragment} from "react";
import {PickingsTable} from "./pickings_table";
import {AllVendingMachines, Picking, VendingMachinesWithLoadingState} from "./models";

interface Props {
    allVendingMachines: AllVendingMachines
    recentRefills

    reloadRefill(),

    upcomingPickings: Array<Picking>
}

export function DriverDashBoardFragment(props: Props) {
    const machines: VendingMachinesWithLoadingState = {
        vendingMachines: props.allVendingMachines.allVendingMachines,
        loading: props.allVendingMachines.initialLoading,
        errorMessage: props.allVendingMachines.errorMessage,
        failedToLoad: props.allVendingMachines.failedToLoad
    }
    return <Fragment>
        <section className="content-header">
            <h1>
                Dashboard
            </h1>
        </section>

        <div className="visible-xs-block">
            <Link to="/vending_machines" className="btn btn-app">
                <i className="fa fa-align-left"> </i>
                Vending Machines
            </Link>

            <Link to="/buttons" className="btn btn-app">
                <i className="fa fa-check"> </i>
                Service
            </Link>

            <Link to="/" className="btn btn-app">
                <i className="fa fa-line-chart"> </i>
                Dashboard
            </Link>
        </div>

        <section className="content">
            <div className="row">
                <div className={"col-xs-12"}>
                    <h2>Vending Machines <small><Link to={"/vending_machine_list"}>All Vending
                        Machines</Link></small></h2>

                    <VendingMachinesTable isLinkedToSettings={false}
                                          vending_machines={machines}/>
                </div>
            </div>


            <h2 className={""}>Recent Refills
                <small>
                    <Link to={"/refills"}>More refills</Link>
                </small>
            </h2>
            <div className={"row"}>
                <div className={"col-xs-12"}>
                    <div className="box with-border box-success">
                        <div className="box-body no-padding">
                            <RefillsTable refills={props.recentRefills}
                                          refillsShouldReload={props.reloadRefill}/>
                        </div>
                    </div>
                </div>
            </div>

            <h2 className={""}>Upcoming Pickings
                <small>
                    <Link to={"/pickings/recent"}>All pickings</Link>
                </small>
            </h2>
            <div className={"row"}>
                <div className={"col-xs-12"}>
                    <div className="box with-border box-success">
                        <div className="box-body no-padding">
                            <PickingsTable pickings={props.upcomingPickings}/>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    </Fragment>
}
