import React, {Component} from 'react';
import {ProductMapping} from "./models";
import DaysAgo from "./days_ago";

interface Props {
    coil: ProductMapping
}

interface State {
    displayChart: boolean
}

class ShelftimeDisplay extends Component<Props, State> {
    constructor(props) {
        super(props);

        this.state = {
            displayChart: false
        }
    }

    render() {
        let chart = (<div/>);

        if (this.props.coil.coilShelfTimes) {
            let dangerLength = 0;
            let warningLength = 0;
            let successLength = 0;

            this.props.coil.coilShelfTimes.forEach(function (coilShelftime) {
                if (coilShelftime.alert) {
                    dangerLength = dangerLength + coilShelftime.count;
                    return;
                }

                if (coilShelftime.notice) {
                    warningLength = warningLength + coilShelftime.count;
                    return;
                }

                successLength = successLength + coilShelftime.count;
            });

            const total = (warningLength + dangerLength + successLength) <= 0 ? 0 : (warningLength + dangerLength + successLength);
            chart = (<div className="progress progress-xs">
                <div className="progress-bar progress-bar-danger" style={{width: 100.0 * dangerLength / total}}/>
                <div className="progress-bar progress-bar-warning" style={{width: 100.0 * warningLength / total}}/>
                <div className="progress-bar" style={{width: 100.0 * successLength / total}}/>
            </div>);
        }

        if (this.props.coil.coilShelfTimes && this.props.coil.coilShelfTimes.length > 0) {
            const oldest = this.props.coil.coilShelfTimes[0];
            let txtClass = "text-blue";
            if (oldest.notice) {
                txtClass = "text-yellow";
            }
            if (oldest.alert) {
                txtClass = "text-red";
            }
            return (
                <div>
                    {!this.props.coil.cycleCounting && (
                        <div>
                            <span className={txtClass}><DaysAgo timestamp={oldest.timestamp}/></span>
                            {chart}
                        </div>)}
                </div>);
        } else {
            return '-';
        }
    }
}

export default ShelftimeDisplay;