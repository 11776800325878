import React, {Component} from "react";

interface Props {
    hide_dialog(): void;
    vm_id:string;
    onSubmit(e: any, laundryMachineSelection, vendingMachineUuid): void;
}

interface State {
    name:string,
    machineCode:string,
    selection:number,
    price:number,
    discount:number,
    processingFee:number
    photoBlob?: string
}

class AddLaundryMachineSelection extends Component<Props, State> {
    constructor(props: Props) {
        super(props);

        this.state = {
            name:"",
            machineCode:"",
            selection:0,
            price:0,
            discount:0,
            processingFee:0,
            photoBlob: undefined
        }
    }

    handleMachineCodeChange(e){
        e.preventDefault();

        this.setState({
            machineCode: e.target.value
        });
    }

    handleMachineNameChange(e){
        e.preventDefault();

        this.setState({
            name: e.target.value
        });
    }

    handleSelectionChange(e){
        e.preventDefault();

        this.setState({
            selection: e.target.value
        });
    }

    handlePriceChange(e){
        e.preventDefault();

        this.setState({
            price: e.target.value
        });
    }

    handleDiscountChange(e){
        e.preventDefault();

        this.setState({
            discount: e.target.value
        });
    }

    handleProcessingFeeChange(e){
        e.preventDefault();

        this.setState({
            processingFee: e.target.value
        });
    }

    onSelectFile(e) {
        const fileReader = new FileReader();
        fileReader.onerror = (e) => {
            window.alert("Failed to read file");
        };
        fileReader.onload = (e) => {
            const pictureContent: string = fileReader.result as string;
            this.setState({photoBlob:window.btoa(pictureContent)}, ()=>{console.log(this.state.photoBlob)})
        };
        fileReader.readAsBinaryString(e.target.files[0]);
    }

    onSubmit(event): void {
        event.preventDefault();
        let laundryMachineSelection = {
            name:this.state.name,
            price:this.state.price,
            processingFee:this.state.processingFee,
            discount:this.state.discount,
            machineCode:this.state.machineCode,
            selection:this.state.selection,
            photoBlob:this.state.photoBlob,
        };
        this.props.onSubmit(event, laundryMachineSelection, this.props.vm_id);
    }

    render() {
        return <form>
            <div className="form-group">
                <label>Machine Name</label>
                <span
                    className="label-explain">&nbsp; The Machine Name will be showed to customer</span>

                <input onChange={this.handleMachineNameChange.bind(this)} className="form-control" type="text" value={this.state.name}/>
            </div>

            <div className="form-group">
                <label>Machine Code</label>
                <span
                    className="label-explain">&nbsp; Machine Code is the is 4 digits unique code.</span>

                <input onChange={this.handleMachineCodeChange.bind(this)} className="form-control" type="text" value={this.state.machineCode}/>
            </div>

            <div className="form-group">
                <label>Selection</label>

                <span className="label-explain">&nbsp; Selection Number</span>

                <input onChange={this.handleSelectionChange.bind(this)} className="form-control" type="number" value={this.state.selection}/>
            </div>

            <div className="form-group">
                <label>Price</label>

                <span className="label-explain">&nbsp; Price</span>

                <input onChange={this.handlePriceChange.bind(this)} className="form-control" type="number" value={this.state.price}/>
            </div>

            <div className="form-group">
                <label>Discount</label>

                <span className="label-explain">&nbsp; Discount</span>

                <input onChange={this.handleDiscountChange.bind(this)} className="form-control" type="number" value={this.state.discount}/>
            </div>

            <div className="form-group">
                <label>Processing Fee</label>

                <span className="label-explain">&nbsp; Processing Fee</span>

                <input onChange={this.handleProcessingFeeChange.bind(this)} className="form-control" type="number" value={this.state.processingFee}/>
            </div>

            <div className="form-group">
                <label>Vending Machine Photo</label>
                <span className="label-explain">&nbsp; Vending Machine Photo</span>
                <input type="file" onChange={this.onSelectFile.bind(this)} className="form-control" />
            </div>

            <div className="form-group">
                <button className="btn btn-success" onClick={this.onSubmit.bind(this)}>Save</button>
                <button onClick={this.props.hide_dialog.bind(this)} className="btn btn-default">Cancel</button>
            </div>
        </form>;
    }
}

export {AddLaundryMachineSelection};
