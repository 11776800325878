import {Transaction} from "./models";
import React from "react";
import logo from "./assets/vendCoinLogo.png";
import CreditCardBadge from "./credit_card_badge";
import {FormatMoney} from "./format_money";

interface Props {
    transaction:Transaction
    getSalesDetail,
    getVmcpOrderDetail,
}

export default function PriceDisplay(props: Props) {
    if (props.transaction.mobilePaymentTransaction){
        return <span style={{cursor: "pointer", textDecoration: "dotted underline"}} onClick={props.getSalesDetail}>
            <img src={logo}  alt="VendCoinLogo" width= '30'/>
            {<span className={props.transaction.refunded?"refundedTrans":""} style={{marginBottom:"5px"}}>{<FormatMoney cents={props.transaction.itemPrice}/>}</span>}
            &nbsp;
            {props.transaction.cashlessSurcharge ?
                <span>[{<FormatMoney cents={props.transaction.cashlessSurcharge}/>} Cashless Surcharge]</span> : null}
        </span>
    } else if (props.transaction.creditCardTransaction) {
        return <span style={{cursor: "pointer", textDecoration: "dotted underline"}} onClick={props.getSalesDetail}>
            <CreditCardBadge transaction={props.transaction}/>
            {<span className={props.transaction.refunded?"refundedTrans":""} style={{marginBottom:"5px"}}>{<FormatMoney cents={props.transaction.itemPrice}/>}</span>}
            &nbsp;
            {props.transaction.cashlessSurcharge ?
                <span>[{<FormatMoney cents={props.transaction.cashlessSurcharge}/>} Cashless Surcharge]</span> : null}
        </span>
    } else {
        if (props.transaction.vmcpOrderUuid){
            return (<span style={{cursor: "pointer", textDecoration: "dotted underline"}} onClick={props.getVmcpOrderDetail}>
                <div>KioskForce</div>&nbsp;
                <span className={props.transaction.refunded?"refundedTrans":""} style={{marginBottom:"5px"}}>{<FormatMoney cents={props.transaction.itemPrice}/>} </span>
        </span>)

        }else {
            return <span>
            {<span className={props.transaction.refunded?"refundedTrans":""} style={{marginBottom:"5px"}}>{<FormatMoney cents={props.transaction.itemPrice}/>}</span>}
                &nbsp;
                {props.transaction.cashlessSurcharge ?
                    <span>[{<FormatMoney cents={props.transaction.cashlessSurcharge}/>} Cashless Surcharge]</span> : null}
        </span>
        }
    }
}