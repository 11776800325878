import {ProductRecommendationForm, ProductWantLevel} from "./models";
import {IGlobalDataContext, withGlobalData} from "./contexts/global-data";
import React, {Component} from "react";
import {Helmet} from "react-helmet";
import {getAPI} from "./api";
import InlineProductPhoto from "./inline_product_photo";

interface State {
    productRecommendationForm: ProductRecommendationForm | null
    filter:string
}

interface Props extends IGlobalDataContext {
    match:any
}

class _ViewProductRecommendationPage extends Component<Props, State> {
    constructor(props) {
        super(props);

        this.state = {
            productRecommendationForm:null,
            filter:"ALL"
        };
    }


    componentDidMount() {
        this.reloadProductRecommendationForm();
    }

    reloadProductRecommendationForm(){
        getAPI(`/api/product_recommendation_form/${this.props.match.params.uuid}`).then((response)=>{
            if (response.data.success){
                this.setState({
                    productRecommendationForm:response.data.productRecommendationForm
                })
            }
        })
    }

    handleFilter(event){
        event.preventDefault();
        this.setState({filter: event.target.value})
    }

    render() {
        let rows;
        if(this.state.productRecommendationForm!= null){
            rows = this.state.productRecommendationForm.productRecommendationMaps.filter((productRecommendationMap)=>{
                if (this.state.filter === "FAVORITE"){
                    return productRecommendationMap.wantLevel === ProductWantLevel.FAVORITE
                } else if (this.state.filter === "STOCK") {
                    return productRecommendationMap.wantLevel === ProductWantLevel.STOCK
                } else if (this.state.filter === "NEGATIVE") {
                    return productRecommendationMap.wantLevel === ProductWantLevel.NEGATIVE
                } else {
                    return productRecommendationMap
                }
            }).map((productRecommendationMap)=>{
                return <tr key={productRecommendationMap.id}>
                    <td>
                        {productRecommendationMap.product.name}
                        <InlineProductPhoto product={productRecommendationMap.product}/>
                    </td>
                    {this.state.productRecommendationForm != null && this.state.productRecommendationForm.completed  &&
                    <td>
                        {productRecommendationMap.wantLevel === "NEGATIVE" && <p>Negative</p>}
                        {productRecommendationMap.wantLevel === "STOCK" && <p>Stock</p>}
                        {productRecommendationMap.wantLevel === "FAVORITE" && <p>Favorite</p>}
                    </td>}
                </tr>
            })
        }

        return <section className="content">
            <Helmet titleTemplate="%s - Vending on Track">
                <title>View Product Recommendation Form</title>
            </Helmet>
            <h1>View Product Recommendation Form For Site {this.state.productRecommendationForm !== null && this.state.productRecommendationForm.siteName}</h1>
            <div className={"well"}>
                <p>Please send the following URL to site owner</p>
                {
                    this.state.productRecommendationForm !== null &&
                <a href={this.state.productRecommendationForm.formUrl}>{this.state.productRecommendationForm.formUrl}</a>
                }
            </div>
            <div className="box box-success">
                <div className="box-body no-padding">
                    <div className="container-fluid">
                        <div className="form-group">
                            <label>Filter</label>
                            <select className="form-control" onChange={this.handleFilter.bind(this)}>
                                <option value="ALL">All</option>
                                <option value="FAVORITE">Favorite</option>
                                <option value="STOCK">Stock</option>
                                <option value="NEGATIVE">Negative</option>
                            </select>
                        </div>
                    </div>
                    <div className="container-fluid">
                        <table className = "table table-bordered table-striped" id="listBarcodes">
                            <thead>
                            <tr>
                                <th scope="col">Product</th>
                                {this.state.productRecommendationForm !== null && this.state.productRecommendationForm.completed &&
                                <th>Like Level</th>}
                            </tr>
                            </thead>
                            <tbody>
                            {rows}
                            </tbody>
                        </table>
                    </div>
                    {
                        this.state.productRecommendationForm?.completed && <div className="container-fluid">
                            <div className="form-group">
                                <label>Additional Product</label>
                                <textarea className={"form-control"} disabled value={this.state.productRecommendationForm?.additionalProduct}>
                            </textarea>
                            </div>
                            <div className="form-group">
                                <label>Comment</label>
                                <textarea className={"form-control"} disabled value={this.state.productRecommendationForm?.comment}>
                            </textarea>
                            </div>
                        </div>
                    }
                </div>
            </div>
        </section>;
    }
}

export const ViewProductRecommendationPage = withGlobalData(_ViewProductRecommendationPage);




