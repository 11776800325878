import React, {useEffect, useState} from 'react';
import {Helmet} from "react-helmet";
import {getAPI} from "./api";
import {Settlement} from "./models";
import {DisplayTime} from "./display_time";
import ReactPaginate from "react-paginate";
import {FormatMoney} from "./format_money";

function SettlementReport(props) {
    const qs = require('qs');
    const params = qs.parse(props.location.search.slice(1));
    const [settlements, setSettlements] = useState<Array<Settlement>>([]);
    const [currentPage, setCurrentPage] = useState<number>(params['page'] !== "" ? params['page'] !== undefined ? parseInt(params['page'])-1 : 0 : 0);
    const [pageCount, setPageCount] = useState<number>(0);

    const reload = () => {
        getAPI(`/api/settlement_report?page=${currentPage+1}`).then((response)=>{
            if (response.data.success){
                setSettlements(response.data.data.data)
                setPageCount(response.data.data.lastPage)
            } else {
                window.alert(response.data.message)
            }
        })
    }
    useEffect(reload, [currentPage])

    const rows = settlements.sort((a, b)=>{
        return b.createdAt - a.createdAt;
    }).map((settlement, index) => {
        const transactionRows = settlement.merchantWithdraw.map((merchantWithdraw,index)=>{
            return <tr key={index}>
                <td>
                    <DisplayTime timestamp={merchantWithdraw.vendCoinMerchantTransaction.createdAt} expanded={true}/>
                </td>
                <td>
                    <FormatMoney cents={merchantWithdraw.amount}/>
                </td>
                <td>
                    <FormatMoney cents={merchantWithdraw.vendCoinMerchantTransaction.surcharge}/>
                </td>
                <td>
                    {merchantWithdraw.vendCoinMerchantTransaction.txnRef}
                </td>
                <td>
                    {merchantWithdraw.vendCoinMerchantTransaction.type}
                </td>
            </tr>
        })
        return <tr key={index}>
            <td>
                <DisplayTime timestamp={settlement.createdAt} expanded={true}/>
            </td>
            <td>
                <FormatMoney cents={settlement.amount}/>
            </td>
            <td>
                <table className="table table-bordered">
                    <thead>
                    <tr>
                        <th>Date & Time</th>
                        <th>Amount</th>
                        <th>surcharge</th>
                        <th>txnRef</th>
                        <th>type</th>
                    </tr>
                    </thead>
                    <tbody>
                    {transactionRows}
                    </tbody>
                </table>
            </td>
        </tr>
    })

    const handlePageChange = (selectedItem: { selected: number })=>{
        setCurrentPage(selectedItem.selected)
        handleParamsChange("page", (selectedItem.selected + 1).toString())
    }

    const handleParamsChange= (currentParam: string, newValue:string)=>{
        const qs = require('qs');
        let params = qs.parse(props.location.search.slice(1));
        params[currentParam] = newValue;
        const keys = Object.keys(params);
        let paramsStr = "?";
        keys.forEach((key)=>{
            paramsStr = paramsStr + key + "=" + params[key] + "&"
        })
        props.history.push(paramsStr);
    }

    return (
        <div>
            <Helmet titleTemplate="%s - Vending on Track">
                <title>Settlement Report</title>
            </Helmet>
            <section className="content-header">
                <h1>
                    Settlement Report
                </h1>
            </section>
            <section className="content">
                    <div className="box box-success">
                        <div className={"box-header"}>
                            <h1>Report</h1>
                        </div>
                        <div className="box-body">
                            <div>
                                <table className="table table-bordered">
                                    <thead>
                                    <tr>
                                        <th>Date & Time</th>
                                        <th>Amount</th>
                                        <th>Transaction</th>
                                    </tr>
                                    </thead>
                                    <tbody>
                                    {rows}
                                    </tbody>
                                </table>
                                <hr/>
                            </div>
                            <ReactPaginate
                                pageRangeDisplayed={2}
                                marginPagesDisplayed={2}
                                pageCount={pageCount}
                                initialPage={currentPage}
                                onPageChange={handlePageChange}
                                containerClassName={"pagination mb-0"}
                                pageClassName={"page-item"}
                                pageLinkClassName={"page-link"}
                                previousClassName={"page-item page-link"}
                                nextClassName={"page-item page-link"}
                                activeClassName={"active"} forcePage={currentPage}
                            />
                        </div>
                    </div>
            </section>
        </div>
    );
}

export default SettlementReport;