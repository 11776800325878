import React, {Component} from 'react';
import {IAuthenticationContext, withAuthentication} from './authentication';
import {Redirect} from 'react-router-dom';
import {getAPI} from './api';
import {
    CompanyUserRole,
    DailyRevenue,
    DashboardStats,
    Me,
    Picking,
    Refill,
    Transaction,
    VendingMachinesWithLoadingState,
    VirtualFinancialTransaction
} from "./models";
import {IGlobalDataContext, withGlobalData} from "./contexts/global-data";
import {AdminDashBoardFragment} from "./admin_dashboard_fragment";
import {DriverDashBoardFragment} from "./driver_dashboard_fragment.ts";
import {Helmet} from "react-helmet";


interface Props extends IAuthenticationContext, IGlobalDataContext {
}

interface State {
    me: Me | null
    stats: DashboardStats | null
    recentRefills: Array<Refill>
    latest_sales: Array<Transaction>
    latest_virtual_financial_transactions: Array<VirtualFinancialTransaction>
    number_of_day: number
    loadingRevenue: boolean
    revenues: DailyRevenue[]
    pickings: Array<Picking>
}


class _Dashboard extends Component<Props, State> {
    private _isMounted: boolean;

    constructor(props) {
        super(props);

        this.state = {
            me: null,
            stats: null,
            latest_sales: [],
            latest_virtual_financial_transactions: [],
            recentRefills: [],
            number_of_day: 28,
            loadingRevenue: false,
            revenues: [],
            pickings: [],
        };

        this._isMounted = false;
    }

    componentWillUnmount() {
        this._isMounted = false;
    }

    componentDidMount() {

        this._isMounted = true;

        getAPI("/api/refills").then((response) => {
            let recentRefills = response.data.refills.content.slice(0, 3);

            this.setState({recentRefills: recentRefills});
        });

        getAPI("/api/users/me").then((response) => {
            console.log(response);
            if (this._isMounted) {
                console.log("Set me");
                this.setState({
                    me: response.data
                });
                if (response.data.role === CompanyUserRole.ADMIN.valueOf()) {
                    this.loadStats()
                    this.loadRevenue()
                    this.loadRecent();
                } else {
                    this.loadPickings();
                }
            }
        });

        this.props.reloadAllVendingMachines(); // for online stats
    }

    handleNumOfDayChange(numberOfDay): void {
        this.setState({loadingRevenue: true})
        this.setState({number_of_day: numberOfDay}, this.loadRevenue)
    }

    loadRevenue() {
        const days = this.state.number_of_day
        getAPI(`/api/dashboard/revenue/${days}`).then((response) => {
            if (days === this.state.number_of_day) {
                this.setState({
                    revenues: response.data.revenues
                }, () => {
                    this.setState({loadingRevenue: false})
                })
            }
        });
    }

    loadStats(): void {
        getAPI(`/api/dashboard/stats`).then((response) => {
            this.setState({
                stats: response.data
            })
        });
    }

    loadRecent(): void {
        getAPI(`/api/dashboard/recent`).then((response) => {
            this.setState({
                latest_virtual_financial_transactions: response.data.recentFinancialTransactions,
                latest_sales: response.data.recentSales,
            });
        })
    }

    loadPickings(): void {
        getAPI("/api/prekittings").then((response) => {
            this.setState({pickings: response.data.prekittings.content});
        })
    }

    render() {
        if (this.props.authentication.authenticated) {
            // doing nothing
        } else {
            // redirect to login
            return (<Redirect to="/login"/>);
        }

        // console.log(this.props.allVendingMachines);
        const online_vending_machines: VendingMachinesWithLoadingState = {
            vendingMachines: this.props.allVendingMachines.allVendingMachines.filter((vendingMachine) => {
                return vendingMachine.online;
            }),
            loading: this.props.allVendingMachines.initialLoading,
            errorMessage: null,
            failedToLoad: false
        };

        const upcoming_pickings = this.state.pickings.filter(picking => {
            return picking.scheduledVisitTime && picking.scheduledVisitTime > (new Date().getTime() / 1000);
        })

        if (this.state.me?.role === CompanyUserRole.DRIVER.valueOf()) {
            return <DriverDashBoardFragment allVendingMachines={this.props.allVendingMachines}
                                            recentRefills={this.state.recentRefills}
                                            reloadRefill={this.componentDidMount.bind(this)}
                                            upcomingPickings={upcoming_pickings}/>
        }
        return (<div>
            <Helmet titleTemplate="%s - Vending on Track">
                <title>Dashboard</title>
            </Helmet>
            <AdminDashBoardFragment stats={this.state.stats}
                                    allVendingMachines={this.props.allVendingMachines.allVendingMachines}
                                    number_of_day={this.state.number_of_day}
                                    handleNumOfDayChange={this.handleNumOfDayChange.bind(this)}
                                    loadRecent={this.loadRecent.bind(this)}
                                    loadingRevenue={this.state.loadingRevenue}
                                    revenues={this.state.revenues}
                                    online_vending_machines={online_vending_machines}
                                    latest_sales={this.state.latest_sales}
                                    latest_virtual_financial_transactions={this.state.latest_virtual_financial_transactions}
                                    recentRefills={this.state.recentRefills}
                                    currencySymbol={this.props.me.mostRecentCompany.currencySymbol}
                                    reloadRefill={this.componentDidMount.bind(this)}/>
        </div>)

    }
}

export const Dashboard = withGlobalData(withAuthentication(_Dashboard));
