import {IGlobalDataContext, withGlobalData} from "./contexts/global-data";
import {LaundryMachineSelection} from "./models";
import React, {Component} from "react";

interface Props extends IGlobalDataContext {
    selection: LaundryMachineSelection;
    handleRemove(e, machineCode): void;
    handleUpdate(e, machineCode, data):void;
}

interface State {
    editing: boolean
    selection: LaundryMachineSelection
    saving: boolean,
    name:string,
    price:number,
    discount:number,
    processingFee:number
    photoBlob?: string
}

class RawLaundryMachineSelectionRow extends Component<Props, State> {
    constructor(props) {
        super(props);

        this.state = {
            editing: false,
            selection: this.props.selection,
            saving: false,
            name:this.props.selection.name,
            price:this.props.selection.price,
            discount:this.props.selection.discount,
            processingFee:this.props.selection.processingFee
        }
    }

    handleEditing(e){
        e.preventDefault();
        e.stopPropagation();
        this.setState({editing:true})
    }

    handleMachineNameChange(e){
        e.preventDefault();

        this.setState({
            name: e.target.value
        });
    }

    handlePriceChange(e){
        e.preventDefault();

        this.setState({
            price: e.target.value
        });
    }

    handleDiscountChange(e){
        e.preventDefault();

        this.setState({
            discount: e.target.value
        });
    }

    handleProcessingFeeChange(e){
        e.preventDefault();

        this.setState({
            processingFee: e.target.value
        });
    }

    onSelectFile(e) {
        const fileReader = new FileReader();
        fileReader.onerror = (e) => {
            window.alert("Failed to read file");
        };
        fileReader.onload = (e) => {
            const pictureContent: string = fileReader.result as string;
            this.setState({photoBlob:window.btoa(pictureContent)}, ()=>{console.log(this.state.photoBlob)})
        };
        fileReader.readAsBinaryString(e.target.files[0]);
    }

    onSave(e){
        e.preventDefault();
        e.stopPropagation();
        this.props.handleUpdate(e, this.state.selection.machineCode, {
            name:this.state.name,
            price:this.state.price,
            discount:this.state.discount,
            processingFee:this.state.processingFee,
            photoBlob:this.state.photoBlob !== undefined ? this.state.photoBlob : null
        })
        this.setState({editing:false})
    }

    render() {
        return <tr key={this.state.selection.selection}>
            <td>
                {!this.state.editing && <p>{this.props.selection.name}</p>}
                {this.state.editing && <input onChange={this.handleMachineNameChange.bind(this)} className="form-control" type="text" value={this.state.name}/>}
            </td>
            <td>
                <p>{this.state.selection.machineCode}</p>
            </td>
            <td>
                <p>{this.state.selection.selection}</p>
            </td>
            <td>
                {!this.state.editing && <p>{this.props.selection.price}</p>}
                {this.state.editing && <input onChange={this.handlePriceChange.bind(this)} className="form-control" type="number" value={this.state.price}/>}
            </td>
            <td>
                {!this.state.editing && <p>{this.props.selection.discount}</p>}
                {this.state.editing && <input onChange={this.handleDiscountChange.bind(this)} className="form-control" type="number" value={this.state.discount}/>}
            </td>
            <td>
                {!this.state.editing && <p>{this.props.selection.processingFee}</p>}
                {this.state.editing && <input onChange={this.handleProcessingFeeChange.bind(this)} className="form-control" type="number" value={this.state.processingFee}/>}
            </td>
            <td>
                {!this.state.editing && <img height="100" src={this.props.selection.externalPhotoUrl} alt="Laundry Machine" />}
                {this.state.editing && <input type="file" onChange={this.onSelectFile.bind(this)} className="form-control" />}
            </td>
            <td>
                {!this.state.editing && <button className="btn btn-danger" onClick={(e)=>{this.props.handleRemove(e, this.state.selection.machineCode)}}>Delete</button>}
                &nbsp;
                {!this.state.editing && <button className="btn btn-warning" onClick={this.handleEditing.bind(this)}>Edit</button>}
                {this.state.editing && <button className={"btn btn-success"} onClick={this.onSave.bind(this)}>Save</button>}
            </td>
        </tr>;
    }
}

const LaundryMachineSelectionRow = withGlobalData(RawLaundryMachineSelectionRow);

export default LaundryMachineSelectionRow;