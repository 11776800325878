import React, {Fragment, useEffect, useState} from 'react';
import {IGlobalDataContext, withGlobalData} from "./contexts/global-data";
import {getAPI} from "./api";
import ReactPaginate from "react-paginate";
import {Stock, StockChange} from "./models";
import {DisplayTime} from "./display_time";
import {Link} from "react-router-dom";
import InlineProductPhoto from "./inline_product_photo";
import {Helmet} from "react-helmet"

interface Props extends IGlobalDataContext {
    match: any
}

function _stockPage(props: Props) {
    const {warehouseId, stockId} = props.match.params;

    const [page, setPage] = useState(0);
    const [stockChanges, setStockChanges] = useState<Array<StockChange>>([]);
    const [stock, setStock] = useState<Stock | null>(null);
    const [totalPages, setTotalPages] = useState(0);

    const product = props.findProduct(`${stock?.productId}`);

    const pullHistory = () => {
        getAPI(`/api/warehouses/${warehouseId}/${stockId}/transactions?page=${page}`).then((response) => {
            if (response.data.success) {
                setTotalPages(response.data.stockChanges.totalPages);
                setStockChanges(response.data.stockChanges.content);
                setStock(response.data.stock);
            } else {
                window.alert(response.data.message);
            }
        })
    };

    useEffect(pullHistory, [warehouseId, stockId, page]);

    const rows = stockChanges.map((value => {
        return (
            <tr key={value.id}>
                <td>
                    <DisplayTime expanded={true} timestamp={value.eventTimestamp}/>
                </td>
                <td>
                    {value.quantity}
                </td>
                <td>
                    {value.type}
                </td>
            </tr>
        );
    }));

    return (
        <Fragment>
            <Helmet titleTemplate="%s - Vending on Track">
                <title>Stock Detail</title>
            </Helmet>
            <section className="content-header">
                <h1>
                    <a href={"/products/"+stock?.productId}>Inventory & Warehouse - Stock Detail for <InlineProductPhoto product={product}/>{product?.name}</a>
                </h1>

                <Link to={`/warehouses/${warehouseId}`}>Back</Link>
            </section>


            <section className="content">
                <div className="box box-info">
                    <div className="box-header">
                        Transactions

                    </div>
                    <div className="box-body">
                        <ReactPaginate
                            pageRangeDisplayed={2}
                            marginPagesDisplayed={2}
                            pageCount={totalPages}
                            initialPage={page}
                            onPageChange={selectedItem => {
                                setPage(selectedItem.selected)
                            }}
                            containerClassName={"pagination pagination-sm no-margin"}
                            activeClassName={'active'}
                        />

                        <table className="table table-bordered table-condensed">
                            <tr>
                                <th>Time</th>
                                <th>Change</th>
                                <th>Type</th>
                            </tr>
                            <tbody>
                            {rows}
                            </tbody>
                        </table>
                    </div>
                </div>
            </section>
        </Fragment>
    );
}

export const StockPage = withGlobalData(_stockPage);
