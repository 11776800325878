import React, {Component} from 'react';

import {withAuthentication} from './authentication';
import {Link, Redirect} from 'react-router-dom';
import {postAPI} from './api';


class _Register extends Component {
    constructor(props) {
        super(props);

        this.state = {
            email: "",
            password: "",
            name:"",
            address:"",
            phone:"",
            in_progress: false,
            error_message: null
        };

        this.handleEmailChange = this.handleEmailChange.bind(this);
        this.handlePasswordChange = this.handlePasswordChange.bind(this);
        this.handleNameChange = this.handleNameChange.bind(this);
        this.handleAddressChange = this.handleAddressChange.bind(this);
        this.handlePhoneChange = this.handlePhoneChange.bind(this);
        this.handleSubmit = this.handleSubmit.bind(this);
    }

    handleEmailChange(event) {
        this.setState({email: event.target.value});
    }

    handlePasswordChange(event) {
        this.setState({password: event.target.value});
    }


    handleNameChange(event) {
        this.setState({name: event.target.value});
    }

    handleAddressChange(event) {
        this.setState({address: event.target.value})
    }

    handlePhoneChange(event) {
        this.setState({phone: event.target.value})
    }

    handleSubmit(event) {
        event.preventDefault();
        this.setState({in_progress: true});

        postAPI("/api/users/signup", {email: this.state.email, password: this.state.password, name: this.state.name, address:this.state.address, phone:this.state.phone})
            .then(result => {
                this.setState({in_progress: false});
                return result.data;
            })
            .then((result) => {
                if (result.success) {
                    // success
                    this.props.login(result.securityToken, result.email);
                } else {
                    // alert(result.reason);
                    this.setState({error_message: result.reason});
                }
            });
    }

    render() {
        if (this.props.authentication.authenticated) {
            return <Redirect to="/"/>;
        }

        let busy = (
            <div className="busyIndicator">
                <h1>
                    Please wait... <span className="fa fa-spinner fa-spin">&nbsp;</span>
                </h1>
            </div>
        );

        let error_message = this.state.error_message ?
            (<div className="alert alert-danger">{this.state.error_message}</div>)
            : null;

        let signup_form = (

            <div className="login-box">

                <div className="login-logo">
                    Vending on Track
                </div>
                <div className="login-box-body">
                    <p className="login-box-msg">Sign up for Vending on Track</p>


                    {error_message}

                    <form onSubmit={this.handleSubmit} className="form">


                        <div className="form-group has-feedback">
                            <input type="email" value={this.state.email}
                                   onChange={this.handleEmailChange} className="form-control" placeholder="Email"/>
                            <span className="fas fa-envelope sign-in-and-up-form-control"></span>
                        </div>
                        <div className="form-group has-feedback">
                            <input type="password" className="form-control" value={this.state.password}
                                   onChange={this.handlePasswordChange} placeholder="Password"/>
                            <span className="fas fa-lock sign-in-and-up-form-control"></span>
                        </div>
                        <div className="form-group has-feedback">
                            <input type="text" value={this.state.name}
                                   onChange={this.handleNameChange} className="form-control" placeholder="Company Name"/>
                            <span className="fas fa-user sign-in-and-up-form-control"></span>
                        </div>
                        <div className="form-group has-feedback">
                            <input type="text" value={this.state.address}
                                   onChange={this.handleAddressChange} className="form-control" placeholder="Address"/>
                            <span className="fas fa-home sign-in-and-up-form-control"></span>
                        </div>
                        <div className="form-group has-feedback">
                            <input type="text" value={this.state.phone}
                                   onChange={this.handlePhoneChange} className="form-control" placeholder="Phone"/>
                            <span className="fas fa-phone-alt sign-in-and-up-form-control"></span>
                        </div>

                        <div className="row">
                            <div className="col-xs-2">
                            </div>
                            <div className="col-xs-8">
                                <input type="submit" className="btn btn-primary btn-block btn-flat" value="Sign Up"/>
                            </div>
                        </div>
                    </form>


                    <br/>
                    <center>
                        <Link to="/login">Login here.</Link>
                    </center>
                </div>
            </div>
        );


        return (
            <div className="container">

                {(this.state.in_progress) ? busy : signup_form}
            </div>
        )
    }
}

let Register = withAuthentication(_Register);
export {Register};
