import React, {Fragment, useEffect, useState} from 'react';
import DatePicker from 'react-datepicker';
import "react-datepicker/dist/react-datepicker.css";
import {CSVLink} from 'react-csv';


import {
    Barcode,
    CompanyUserRole,
    Message,
    PickingList,
    PickingState,
    PrekittingDetail,
    Product,
    productSorting,
    VendingMachine
} from "./models";
import {Link} from "react-router-dom";
import {DisplayTime} from "./display_time";
import {getAPI, postAPI} from "./api";
import {IGlobalDataContext, withGlobalData} from "./contexts/global-data";
import InlineProductPhoto from "./inline_product_photo";
import PickingQuantity from "./picking_quantity";
import moment from "moment";

interface _Barcode{
    barcode:Barcode
}

interface Props extends IGlobalDataContext {
    detail: PrekittingDetail
    barcodes:_Barcode[]

    reload()
}


function _PrekittingDetailPage(props: Props) {
    let vendingMachines: Array<VendingMachine> = [];
    let products: Array<Product> = [];

    const [previewPickingLists, setPreviewPickings] = useState<Array<PickingList>>([]);


    function loadPreview() {
        getAPI(`/api/prekittings/${props.detail.id}/preview`).then((response) => {
            if (response.data.success) {
                setPreviewPickings(response.data.pickingLists);
            } else {
                window.alert(response.data.message);
            }
        })
    }

    useEffect(loadPreview, [props.detail.id]);

    const [scheduledTime, setScheduledTime] = useState<number | null>(null);
    const selectedScheduleTime = scheduledTime == null ? null : moment.unix(scheduledTime).toDate();

    useEffect(() => {
        setScheduledTime(props.detail.scheduledVisitTime)
    }, [props.detail]);

    function dateChanged(ts) {
        if (ts) {
            setScheduledTime(ts.getTime() / 1000);
        } else {
            setScheduledTime(null);
        }
    }

    function saveScheduleVisit(e) {
        e.stopPropagation();
        e.preventDefault();
        postAPI(`/api/prekittings/${props.detail.id}/schedules`, {scheduleTimestamp: scheduledTime}).then((response) => {
            if (response.data.success) {
                props.pushFlashMessage(new Message("Successfully updated schedule time", "success"));
            } else {
                window.alert(response.data.message);
            }
        })
    }

    previewPickingLists.forEach((pickingList) => {
        pickingList.pickings.forEach((picking) => {
            const product = props.findProduct(`${picking.product_id}`);
            if (product) {
                if (!products.includes(product)) {
                    products.push(product);
                }
            }
        })
    });
    const [displayPicture, setDisplayPicture] = useState<boolean>(true);
    const [displaySlot, setDisplaySlot] = useState(false);
    const [displayLagging, setDisplayLagging] = useState(false);

    function toggle_lagging(e) {
        e.preventDefault();
        e.stopPropagation();


        setDisplayLagging(!displayLagging);
    }

    function toggle_slot(e) {
        e.preventDefault();
        e.stopPropagation();

        setDisplaySlot(!displaySlot);
    }

    function toggle_pictures(e) {
        e.preventDefault();
        e.stopPropagation();

        setDisplayPicture(!displayPicture);
    }


    const pickingLists = props.detail.pickingLists;

    // find all products and vending machines
    pickingLists.forEach((pickingList) => {
        const vendingMachine = props.findVendingMachine(pickingList.vendingMachineUuid);
        if (vendingMachine){
            vendingMachines.push(vendingMachine);
        }

        pickingList.pickings.forEach((picking) => {
            const product = props.findProduct(`${picking.product_id}`);

            if (product) {
                if (!products.includes(product)) {
                    products.push(product);
                }
            }
        });
    });

    products = products.sort(productSorting);

    const columns = vendingMachines.map((vendingMachine, i) => {
        return (<th key={i}>{vendingMachine?.name}</th>);
    });

    const rows = products.map((product) => {
        const tds = vendingMachines.map((vendingMachine, i) => {
            const pickings = pickingLists[i].pickings.filter((picking) => {
                if (picking.product_id === parseInt(product.id)) {
                    return true;
                }

                return false;
            });
            return (
                <td key={i}>
                    <PickingQuantity
                        product={product}
                        displayLagging={displayLagging} displaySlot={displaySlot} prekittingId={props.detail.id}
                        pickings={pickings}
                        vendingMachine={vendingMachine}
                        preview={previewPickingLists}
                        reload = {props.reload}
                        barcodes = {props.barcodes}
                    />
                </td>);
        });

        return (
            <tr key={product.id}>
                <th>

                    {displayPicture && <InlineProductPhoto product={product}/>}
                    {product.name}
                </th>

                {tds}
            </tr>
        )
    });

    function handleRefresh() {
        postAPI(`/api/prekittings/${props.detail.id}/refreshes`, {}).then((response) => {
            if (response.data.success) {
                props.pushFlashMessage(new Message("Successfully updated picking list.", "success"));
                props.reload();
            } else {
                window.alert(response.data.message);
            }
        })
    }

    function handleAbandon() {
        if (window.confirm("Are you sure to abandon this picking list?") === true) {
            postAPI(`/api/prekittings/${props.detail.id}/abandons`, {}).then((response) => {
                if (response.data.success) {
                    props.pushFlashMessage(new Message("Successfully abandoned picking list.", "success"));
                    props.reload();
                } else {
                    window.alert(response.data.message);
                }
            })
        }
    }

    function handlePicked() {
        if (window.confirm("Are you sure to mark this picking as picked? It will deduct quantities from warehouse.") === true) {
            postAPI(`/api/prekittings/${props.detail.id}/picked`, {}).then((response) => {
                if (response.data.success) {
                    props.pushFlashMessage(new Message("Successfully picked list.", "success"));
                    props.reload();
                } else {
                    window.alert(response.data.message);

                }
            })
        }
    }

    /**
     * @deprecated
     */
    // function handleRefill() {
    //     postAPI(`/api/prekittings/${props.detail.id}/refilled`, {}).then((response) => {
    //         if (response.data.success) {
    //             props.pushFlashMessage(new Message("Successfully refilled vending machines.", "success"));
    //             props.reload();
    //         } else {
    //             window.alert(response.data.message);
    //         }
    //     })
    // }

    function prepareCsvHeader(){
        let csvHeader = [{ label: 'Product', key: 'product' }]
        vendingMachines.forEach((vendingMachine, index)=>{
            csvHeader.push({label: vendingMachine.name, key: vendingMachine.name})
        })
        return csvHeader
    }

    function prepareCsvData(){
        let csvData:{}[] = []
        products.forEach((product, productIndex)=>{
            csvData.push({product:product.name})
            vendingMachines.forEach((vendingMachine, vendingMachineIndex)=>{
                const pickings = pickingLists[vendingMachineIndex].pickings.filter((picking) => {
                    return picking.product_id === parseInt(product.id);
                });
                csvData[productIndex][vendingMachine.name] = pickings.reduce((v, picking) => {
                    return v + picking.quantity
                }, 0) + " " + product.productUnit
            })
        })
        return csvData
    }


    const state = props.detail.state;
    const canAbandon = (state === PickingState.SCHEDULED || state === PickingState.PICKED);
    // const canRefill = (state === PickingState.PICKED || state === PickingState.SCHEDULED);
    return (
        <Fragment>
            <section className="content-header">
                <h1>
                    Prekitting Detail
                </h1>

                <Link to={"/pickings/recent"}>Back to picking list</Link>
            </section>
            <section className="content">
                <div className="row">
                    <div className="col-xs-6">

                        <div className="box box-info">
                            <div className="box-header">
                                <h3>Basic Information</h3>
                            </div>
                            <div className="box-body with-border">
                                <table className="table table-condensed table-bordered">
                                    <tbody>
                                    <tr>
                                        <td>Created At:</td>
                                        <td><DisplayTime timestamp={props.detail.createTime} expanded={true}/></td>
                                    </tr>
                                    <tr>
                                        <td>Updated At:</td>
                                        <td><DisplayTime timestamp={props.detail.updateTime} expanded={true}/></td>
                                    </tr>

                                    <tr>
                                        <td>Picked At:</td>
                                        <td><DisplayTime timestamp={props.detail.pickingTime} expanded={true}/></td>
                                    </tr>

                                    <tr>
                                        <td>Refilled At:</td>
                                        <td><DisplayTime timestamp={props.detail.refillTime} expanded={true}/></td>
                                    </tr>
                                    <tr>
                                        <td>Abandoned At:</td>
                                        <td><DisplayTime timestamp={props.detail.abandonTime} expanded={true}/></td>
                                    </tr>
                                    <tr>
                                        <td>State:</td>
                                        <td>{props.detail.state}</td>
                                    </tr>
                                    <tr>
                                        <td>Vending Machine Count:</td>
                                        <td>{props.detail.vendingMachineCount}</td>
                                    </tr>
                                    <tr>
                                        <td>Item Count:</td>
                                        <td>{props.detail.totalQuantity}</td>
                                    </tr>
                                    </tbody>
                                </table>
                            </div>
                        </div>
                    </div>
                    {props.me.role === CompanyUserRole.ADMIN.valueOf() &&
                    <div className="col-xs-6">
                        <div className="box box-info">
                            <div className="box-header">
                                <h3>Operations</h3>
                            </div>
                            <div className="box-body">
                                <ul className="prekitting-actions">
                                    <li>
                                        <button disabled={props.detail.state !== PickingState.SCHEDULED}
                                                onClick={handleRefresh} className="btn btn-success">Refresh
                                        </button>
                                    </li>
                                    <li>
                                        <button disabled={props.detail.state !== PickingState.SCHEDULED}
                                                onClick={handlePicked} className="btn btn-success">Mark as Picked
                                        </button>
                                    </li>
                                    <li>
                                        <button title={"Cancel the picking - move all products back to warehouse"}
                                                disabled={!canAbandon} onClick={handleAbandon}
                                                className="btn btn-success">Abandon
                                        </button>
                                    </li>
                                    {/*<li>*/}
                                    {/*    <button disabled={!canRefill}*/}
                                    {/*            title={"Move the picked list to corresponding vending machines"}*/}
                                    {/*            onClick={handleRefill} className="btn btn-success">*/}
                                    {/*        Mark as Refilled*/}
                                    {/*    </button>*/}
                                    {/*</li>*/}
                                    <li>
                                        <CSVLink className="btn btn-success"
                                                 headers = {prepareCsvHeader()}
                                                 data={prepareCsvData()}
                                                 filename={"Picking List.csv"}>Download As CSV</CSVLink>
                                    </li>
                                </ul>
                                <hr/>
                                <form onSubmit={saveScheduleVisit}>
                                    <div className="form-group">
                                        <label>Schedule Visit: </label>
                                        <br/>
                                        <DatePicker
                                            className={"form-control"}
                                            showTimeSelect
                                            dateFormat="dd/MM/yyyy h:mm aa"
                                            selected={selectedScheduleTime}
                                            onChange={dateChanged}/>
                                    </div>
                                    <input type="submit" value="Save" className="btn btn-success"/>
                                </form>

                            </div>
                        </div>
                    </div>
                    }
                </div>
                <div className={"box box-success"}>
                    <div className={"box-body with-border"}>
                        <table className={"table"}>
                            <thead>
                            <tr>
                                <th colSpan={vendingMachines.length + 1} style={{textAlign: "center"}}>
                                    Vending Machines
                                    <br/>
                                    <button onClick={toggle_slot}>Toggle Slot Display</button>
                                    <button onClick={toggle_pictures}>Toggle Product Pictures</button>
                                    <button title="Compare current vending machine stock level with picking list"
                                            onClick={toggle_lagging}>Show/Hide quantity lagging
                                    </button>
                                </th>
                            </tr>
                            <tr>
                                <th>Product</th>
                                {columns}
                            </tr>
                            </thead>

                            <tbody>
                            {rows}
                            </tbody>
                        </table>
                    </div>
                </div>
            </section>
        </Fragment>
    );
}


const PrekittingDetailPage = withGlobalData(_PrekittingDetailPage);
export default PrekittingDetailPage;

