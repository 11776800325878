import React from "react";
import {VirtualFinancialTransaction} from "../models";
import {DisplayTime} from "../display_time";
import {IGlobalDataContext, withGlobalData} from "../contexts/global-data";
import {VendingMachineLink} from "./vending_machine_link";
import {FormatMoneyWithFlexibleSignSize} from "../format_money_without_style";

interface IProps extends IGlobalDataContext {
    vftrans: VirtualFinancialTransaction
    isExpandTime: boolean
    isShowMachine: boolean
    isShrunk: boolean
}

const VirtualTransTrRaw: React.SFC<IProps> = props => {
    let {isShrunk, vftrans, isExpandTime, isShowMachine, findVendingMachine} = props;
    const vendingMachine = findVendingMachine(vftrans.vending_machine_uuid);
    return !isShrunk ?
        (<tr>
            <td><DisplayTime timestamp={vftrans.timestamp} expanded={isExpandTime}/></td>
            <td>{vftrans.salesType}</td>
            <td>{vftrans.destination}</td>
            <td><FormatMoneyWithFlexibleSignSize cents={vftrans.amount}/></td>
            {isShowMachine && (
                <td>{vendingMachine ? <VendingMachineLink vendingMachine={vendingMachine}/> : vftrans.machineName}</td>
            )}
        </tr>)
        :
        (<tr>
            <td><DisplayTime timestamp={vftrans.timestamp} expanded={isExpandTime}/></td>
            <td>{vftrans.destination}</td>
            <td><FormatMoneyWithFlexibleSignSize cents={vftrans.amount}/></td>
            <td>{vendingMachine ? <VendingMachineLink vendingMachine={vendingMachine}/> : vftrans.machineName}</td>
        </tr>)
};

const VirtualTransTr = withGlobalData(VirtualTransTrRaw);
export default VirtualTransTr;
