import React, {Fragment, useEffect, useState} from 'react';
import {IGlobalDataContext, withGlobalData} from "./contexts/global-data";
import {GeoLocation, VendingMachine} from "./models";
import {getAPI} from "./api";
import RoutingMap from "./routing_map";
import RouteSelection from "./route_selection";
import {Helmet} from "react-helmet";

interface Props extends IGlobalDataContext {

}

function _new_scheduling_page(props: Props) {
    const [start, setStart] = useState<GeoLocation|null>(null);
    const [finish, setFinish] = useState<GeoLocation|null>(null);
    const [routing, setRouting] = useState<Array<VendingMachine>>([]);


    function loadStartFinishLocation() {
        getAPI(`/api/users/me`).then((response) => {
            setStart(response.data.defaultStart);
            setFinish(response.data.defaultFinish);
        })
    }

    useEffect(loadStartFinishLocation, []);


    if (!start || !finish) {
        return (<h1>Loading...</h1>)
    }

    function updateRouting(routing: Array<VendingMachine>) {
        setRouting([...routing])
    }

    return (
        <Fragment>
            <Helmet titleTemplate="%s - Vending on Track">
                <title>Schedule</title>
            </Helmet>
            <section className="content-header">
                <h1>
                    Schedule
                </h1>
            </section>

            <section className="content">
                <div className="row">
                    <div className="col-lg-3">
                        <RouteSelection start={start} finish={finish} onUpdateRouting={updateRouting}  />
                    </div>
                    <div className="col-lg-9">
                        <RoutingMap start={start} finish={finish} routing={routing} />

                    </div>
                </div>
            </section>
        </Fragment>
    );
}

const NewSchedulingPage = withGlobalData(_new_scheduling_page);

export default NewSchedulingPage;
