import React, {useState} from 'react';
import {GeoLocation, VendingMachine} from "./models";
import {Highlighter, Typeahead} from 'react-bootstrap-typeahead';
import {IGlobalDataContext, withGlobalData} from "./contexts/global-data";
import 'react-bootstrap-typeahead/css/Typeahead.css';
import {postAPI} from "./api";
import FillLevel from "./fill_level";


interface Props extends IGlobalDataContext{
    start: GeoLocation
    finish: GeoLocation
    onUpdateRouting(routing:Array<VendingMachine>)
}

function _RouteSelection(props: Props) {
    const {start, finish} = props;
    const {allVendingMachines} = props;

    const elegibleVendingMachines = allVendingMachines.allVendingMachines.filter((vm) => {
        return vm.address
    });

    const [route, setRoute] = useState<Array<VendingMachine>>([]);
    const [calculating, setCalculating] = useState(false);

    function handleSelectedVendingMachines(vendingMachines: Array<VendingMachine>) {
        // we should calculate the best routing
        setCalculating(true);

        const uuids = vendingMachines.map((v) => v.uuid);
        postAPI(`/api/routings/optimizations`, {uuids: uuids, start: start, finish: finish}).then((response) => {
            if (response.data.success) {
                setCalculating(false);
                const vendingMachines = response.data.optimizedRoute.map((uuid) => {
                    return props.findVendingMachine(uuid);
                });

                setRoute(vendingMachines);
                props.onUpdateRouting(vendingMachines);
            } else {
                alert(response.data.message)
            }

        });
    }

    const stops = route.map((vendingMachine, i) => {
        return (
            <div key={vendingMachine.uuid} className="stop">
                <label>{i + 1} - {vendingMachine.name}</label>
                <div className="address">
                    {vendingMachine.address}
                </div>
            </div>
        )
    });

    function renderOption(option, props, index) {
        return [
            <Highlighter key="name" search={props.text}>
                {option.name}
            </Highlighter>,
            <div key="detail" className="drop-down-vending-machine-detail">
                <div className="address">
                    Address: {option.address}
                </div>
                <div className="fill_level">
                    <FillLevel vendingMachine={option}/>
                </div>
            </div>,
        ];
    }

    return (
        <div>
            <h1>Routing</h1>

            <Typeahead
                id={1}
                onChange={handleSelectedVendingMachines}
                multiple={true}
                options={elegibleVendingMachines}
                labelKey="name"
                placeholder={"Select vending machine"}
                renderMenuItemChildren={renderOption}
            />

            {calculating && <i className="fas fa-spinner fa-pulse"></i>}
            <hr />

            <div className="route">
                <div className="stop">
                    <label>Start:</label> {start.address}
                </div>

                {stops}

                <div className="stop">
                    <label>End:</label> {finish.address}
                </div>
            </div>
        </div>
    );
}

const RouteSelection = withGlobalData(_RouteSelection);
export default RouteSelection;
