import React, {Component} from 'react';
import moment from 'moment';



interface Props {
    date: Date
}

class DateDisplay extends Component<Props>{

    render(){
        let color;
        const daysLeft = moment(this.props.date).diff(moment(new Date()), "day")
        if(daysLeft > 7){
            color = "text-info"
        }else if(daysLeft <= 7 && daysLeft > 0){
            color = "text-warning"
        }else if(daysLeft <= 0){
            color = "text-danger"
        }
        let a = moment(this.props.date).format('DD MMM YYYY')
        return(
            <span className={color}>{a}</span>
        )
    }
}

export default DateDisplay;