import React, {Component} from "react";
import {IGlobalDataContext, withGlobalData} from "./contexts/global-data";

interface Props extends IGlobalDataContext{
    cents?: number
    dollar?: number
    smallSign?: boolean
}

interface State {

}

class _FormatMoneyWithoutStyle extends Component<Props, State> {
    constructor(props) {
        super(props);

        this.state = {}
    }

    render() {
        let amount = (this.props.cents || 0) / Math.pow(10, this.props.me.mostRecentCompany.currencyDecimalPoints);
        if (!amount) {
            amount = this.props.dollar || 0;
            if (!amount) {
                amount = 0.0;
            }
        }

        const sign = this.props.smallSign ? <small>{this.props.me.mostRecentCompany.currencySymbol}</small>:this.props.me.mostRecentCompany.currencySymbol;
        return (
            <>
                {sign}{amount.toFixed(this.props.me.mostRecentCompany.currencyDecimalPoints)}
            </>
        );
    }
}

export const FormatMoneyWithFlexibleSignSize = withGlobalData(_FormatMoneyWithoutStyle);