import React, {Component} from "react";
import {ProductMapping, Transaction, VendingMachine} from "./models";
import {Link, withRouter} from "react-router-dom";
import ReactPaginate from "react-paginate";
import moment from 'moment';
import SalesTr from "./components/sales_tr";
import {DateSplitter} from "./date_splitter";
import {Default, Mobile} from "./components/responsive";
import {getAPI} from "./api";
import DatePicker from "react-datepicker";
import {IGlobalDataContext, withGlobalData} from "./contexts/global-data";
import {DownloadTransactionsCsv} from "./download_transactions_csv";


enum FilterType {
    Product="Product",
    Coil="Coil"
}

interface Props {
    vendingMachine: VendingMachine | null
}

interface ComponentProps extends Props, IGlobalDataContext {
    isMobile: boolean
    location:any
    match:any
    history:any
}

interface State {
    selectedCoils:Array<ProductMapping>
    coils: Array<ProductMapping>
    transactions_page:number
    transactions: Array<Transaction>
    transactions_pages:number
    disableCoilFilter: boolean
    disableProductFilter: boolean
    startDate:string
    endDate:string
}

class _RowComponent extends Component<ComponentProps, State> {
    qs = require('qs');
    params = this.qs.parse(this.props.location.search.slice(1));
    constructor(props) {
        super(props);
        this.state = {
            selectedCoils:[],
            coils:[],
            transactions_page:0,
            transactions:[],
            transactions_pages:0,
            disableCoilFilter:false,
            disableProductFilter:false,
            startDate:this.params['start_date'] !== "" ? this.params['start_date'] !== undefined ? this.params['start_date'] : "" : "",
            endDate:this.params['end_date'] !== "" ? this.params['end_date'] !== undefined ? this.params['end_date'] : "" : "",
        }
    }

    componentDidMount() {
        this.load_coils()
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        if (prevProps.location.search.slice(1) !== this.props.location.search.slice(1)) {
            if(!this.props.location.search.slice(1)){
                this.setState({selectedCoils:[]},this.getSelectedCoil)
            }else{
                this.getSelectedCoil();
            }
        }
    }


    load_coils() {
        getAPI(`/api/vending_machines/${this.props.match.params.uuid}/stock`).then((response) => {
            this.setState({
                coils: response.data.productMaps
            }, ()=>{this.getSelectedCoil()})
        });
    }


    getSelectedCoil(){
        const qs = require('qs');
        const params = qs.parse(this.props.location.search.slice(1));
        console.log("params")
        console.log(params)
        if (params.mdbs !== undefined){
            const mdbs = params.mdbs.split(",").map((item)=>{return Number(item)})
            const tempCoils = this.state.coils.filter(coil=> mdbs.indexOf(coil.mdbRaw) !== -1)
            const filterType = params.filterType;
            if (tempCoils.length >= 1){
                if (filterType === FilterType.Coil){
                    this.setState({disableProductFilter:true})
                } else if (filterType === FilterType.Product){
                    this.setState({disableCoilFilter:true})
                } else {
                    if(tempCoils.length===1){
                        this.setState({disableProductFilter:true})
                    } else {
                        this.setState({disableCoilFilter:true})
                    }
                }
                this.setState({selectedCoils:tempCoils}, ()=>{this.updateSales()})
            } else {
                this.updateSales()
            }
        } else {
            this.updateSales()
        }
    }

    updateSales() {
        let url = `/api/vending_machines/${this.props.match.params.uuid}/sales?page=${this.state.transactions_page}&${this.getPathVariable()}`;

        getAPI(url).then((response) => {
                this.setState({
                    transactions: response.data.transactionInformations.content,
                    transactions_pages: response.data.transactionInformations.totalPages
                });
        });
    }

    getPathVariable(){
        let pathVariable = ""
        if (this.state.selectedCoils.length > 0){
            pathVariable = pathVariable + `mdbItemNumbers=${this.state.selectedCoils.map((coil)=>{
                return coil.mdbRaw
            }).toString()}&`
        }

        if (this.state.startDate !== ""){
            pathVariable = pathVariable + `from=${this.state.startDate}&`
        }

        if (this.state.endDate !== ""){
            pathVariable = pathVariable + `&to=${this.state.endDate}&`
        }
        return pathVariable;
    }

    handleTransactionPageChange(selectedItem: { selected: number }): void {
        this.setState({transactions_page: selectedItem.selected}, () => {
            this.updateSales();
        });
    }

    handleCoilSelection = (e)=>{
        const mdbRaw = Number(e.target.value)
        if (mdbRaw === -1){
            this.setState({selectedCoils:[]}, ()=>{
                this.setState({disableProductFilter:false})
                this.props.history.push(`?`);
                this.updateSales()
                })
        } else {
            const coil = this.state.coils.filter(coil => coil.mdbRaw === mdbRaw)[0]
            this.setState({selectedCoils:[coil]}, ()=>{
                this.setState({disableProductFilter:true})
                this.props.history.push(`?mdbs=${mdbRaw}&filterType=${FilterType.Coil}`);
                this.updateSales()
            })
        }
    }

    handleCoilChange = (mdbRaw)=>{
        const coil = this.state.coils.filter(coil => coil.mdbRaw === mdbRaw)[0]
        if (coil === undefined){
            alert("Coil not found")
            return
        }
        this.setState({selectedCoils:[coil]}, ()=>{
            this.setState({disableProductFilter:true})
            this.updateSales()
        })
    }

    handleProductSelection = (e)=>{
        const mdbRaws = e.target.value
        const mdbRawsList = mdbRaws.split(",").map((item)=>{return Number(item)})
        if (mdbRaws === "-1"){
            this.setState({selectedCoils:[]}, ()=>{
                this.setState({disableCoilFilter:false})
                this.props.history.push(`?`);
                this.updateSales()
            })
        } else {
            const coils = this.state.coils.filter(coil => mdbRawsList.indexOf(coil.mdbRaw) !== -1)
            if (coils.length >= 1){
                this.setState({selectedCoils:coils}, ()=>{
                    this.setState({disableCoilFilter:true})
                    this.props.history.push(`?mdbs=${mdbRawsList.toString()}&filterType=${FilterType.Product}`);
                    this.updateSales()
                })
            } else {
                this.updateSales()
            }
        }

    }

    handleParamsChange(currentParam: string, newValue:string){
        const qs = require('qs');
        let params = qs.parse(this.props.location.search.slice(1));
        params[currentParam] = newValue;
        const keys = Object.keys(params);
        let paramsStr = "?";
        keys.forEach((key)=>{
            paramsStr = paramsStr + key + "=" + params[key] + "&"
        })
        this.props.history.push(paramsStr);
    }

    handleStartDateChange(date:Date | null){
        this.setState({startDate:date !== null ? moment(date).format('YYYY-MM-DD') : "", transactions_page:0}, ()=>{this.getSelectedCoil()})
        this.handleParamsChange("start_date", date !== null ? moment(date).format('YYYY-MM-DD') : "")
    }

    handleEndDateChange(date:Date | null){
        this.setState({endDate:date !== null ? moment(date).format('YYYY-MM-DD') : "", transactions_page:0}, ()=>{this.getSelectedCoil()})
        this.handleParamsChange("end_date", date !== null ? moment(date).format('YYYY-MM-DD') : "")
    }

    render() {
        let rows: Array<any> = [];

        let currentDate: string = '';
        this.state.transactions.forEach((t) => {
            const newDate = moment.unix(t.timestamp).format('DD MMM YYYY');
            if (newDate !== currentDate) {
                currentDate = newDate;

                rows.push((
                    <tr key={currentDate} style={{backgroundColor: "#EFEFEF", textAlign: "center"}}>
                        <td colSpan={7}>
                            {this.state.selectedCoils.length === 0 && <DateSplitter headingTime={moment.unix(t.timestamp)}
                                          vendingMachine={this.props.vendingMachine}/>}
                            {this.state.selectedCoils.length >= 1 && <b> {moment.unix(t.timestamp).format("DD MMM YYYY")} </b>}
                        </td>
                    </tr>
                ))
            }

            rows.push((
                <SalesTr isShrunk={this.props.isMobile} key={t.transactionUUID} trans={t}
                         isExpandTime={true}
                         reloadData={this.updateSales.bind(this)}
                         isShowMachine={false} handleCoilChange={this.handleCoilChange} isShowLast4={false}
            />
            ));
        });


        let uniqueProducts = {}
        this.state.coils.forEach((coil,index)=>{
            if ((Object.keys(uniqueProducts).indexOf(String(coil.product_id))) === -1){
                uniqueProducts[coil.product_id] = {name: coil.product_name, mdbs:[coil.mdbRaw]}
            } else {
                uniqueProducts[coil.product_id]["mdbs"].push(coil.mdbRaw)
            }
        })
        let uniqueProductsList = Object.keys(uniqueProducts).map((product_id)=>{
            return {
                product_id:product_id,
                name:uniqueProducts[product_id]["name"],
                mdbs:uniqueProducts[product_id]["mdbs"]}
        })

        let dateFormat = moment().locale(this.props.me.localeISO).localeData().longDateFormat("L").replace("DD", "dd").replace("YYYY", "yyyy");

        return (
            <div className={"box statsBox"}>
                <h2 className={"box-header"}>
                    Transactions
                </h2>

                <div className={"box-body"}>
                        <ReactPaginate
                            pageRangeDisplayed={2}
                            marginPagesDisplayed={2}
                            pageCount={this.state.transactions_pages}
                            initialPage={this.state.transactions_page}
                            onPageChange={this.handleTransactionPageChange.bind(this)}
                            containerClassName={"pagination pagination-sm no-margin"}
                            activeClassName={'active'}
                        />
                        <DownloadTransactionsCsv pathVariable={this.getPathVariable()} vendingMachineUuid={this.props.match.params.uuid}/>
                        <div className={"row"}>
                            <div className={"col-xs-6"}>
                                <label> Filter By Coil</label>
                                <select disabled={this.state.disableCoilFilter}
                                        value={this.state.selectedCoils.length > 0 && !this.state.disableCoilFilter ? [this.state.selectedCoils[0].mdbRaw].toString():"-1"}
                                        onChange={this.handleCoilSelection}
                                        className={"form-control"}>
                                    <option value={["-1"]}>All Coils</option>
                                    {this.state.coils.sort((a, b)=>{
                                        return Number(a.mdb_code)-Number(b.mdb_code)
                                    }).map((coil)=>{
                                        return <option key={coil.mdbRaw} value={[String(coil.mdbRaw)]}>
                                            {coil.mdb_code + " - " + coil.product_name}
                                        </option>
                                    })}
                                </select>
                            </div>
                            <div className={"col-xs-6"}>
                                <label> Filter By Product</label>
                                <select disabled={this.state.disableProductFilter}
                                        value={this.state.selectedCoils.length > 0 && !this.state.disableProductFilter ? this.state.selectedCoils.map((coil)=>{return coil.mdbRaw}).toString():"-1"}
                                        onChange={this.handleProductSelection}
                                        className={"form-control"}>
                                    <option value={["-1"]}>All Products</option>
                                    {uniqueProductsList.sort((a, b)=>{
                                        return a.name.localeCompare(b.name)
                                    }).map((item)=>{
                                        return <option key={item.product_id} value={item.mdbs}>{item.name}</option>
                                    })}
                                </select>
                            </div>
                        </div>

                        <div className={"row"}>
                            <div className={"col-xs-6"}>
                                <label>From: </label>
                                <DatePicker className={"form-control"}
                                            selected={this.state.startDate !== "" ? new Date(this.state.startDate) : null}
                                            onChange={this.handleStartDateChange.bind(this)}
                                            dateFormat={dateFormat}
                                            wrapperClassName={"datePicker"}
                                            maxDate={new Date()}
                                            isClearable
                                />
                            </div>
                            <div className={"col-xs-6"}>
                                <label>To: </label>
                                <DatePicker className={"form-control"}
                                            selected={this.state.endDate !== "" ? new Date(this.state.endDate) : null}
                                            onChange={this.handleEndDateChange.bind(this)}
                                            dateFormat={dateFormat}
                                            wrapperClassName={"datePicker"}
                                            maxDate={new Date()}
                                            isClearable
                                />
                            </div>
                        </div>

                        <table className={"table table-condense"}>
                            <thead>

                            {this.props.isMobile ?
                                <tr>
                                    <th>Name</th>
                                    <th>Price</th>
                                    <th>Time</th>
                                </tr>
                                :
                                <tr>
                                    <th>Time</th>

                                    <th>Selection <small><Link
                                        to={`/vending_machines/${this.props.match.params.uuid}/setting/product_mappings`}
                                        title={"Change \"selection\" format"}><i
                                        className="fa fa-exclamation-circle"> </i></Link>
                                    </small></th>
                                    <th>Price</th>
                                    <th>Payment Method</th>
                                </tr>
                            }
                            </thead>
                            <tbody>
                              {rows}
                            </tbody>
                        </table>
                </div>
            </div>
        )
    }
}

// @ts-ignore
const RowComponent = withGlobalData(withRouter(_RowComponent));

export const TransactionsComponent = (props: Props) => (
    <div>
        <Mobile>
            <RowComponent isMobile={true} {...props} />
        </Mobile>
        <Default>
            <RowComponent isMobile={false} {...props} />
        </Default>
    </div>
);

