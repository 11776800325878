import React, {Component} from 'react';
import {getAPI, ManagementServiceApi} from "./api";
import {VendingMachineDetailPanel} from './vending_machine_detail_panel';
import {VendingMachineProductMappingPanel} from './vending_machine_product_mapping_panel';

import {Link, Route, Switch, withRouter} from 'react-router-dom';
import {NavTab, RoutedTabs} from 'react-router-tabs';

import "react-router-tabs/styles/react-router-tabs.css";

import {DexReadingSettingInfoPanel} from './dex_reading_setting_info_panel';
import {
    CoilDisplay,
    DexProduct,
    DexSettingInfo,
    IngredientMapping,
    Keypad,
    LaundryMachineSelection,
    Product,
    ProductMapping,
    TelemetryDevice,
    VendingMachine
} from "./models";
import {VendingMachineArchivePanel} from "./vending_machine_archive_panel";
import VendingMachinePlanogram from "./vending_machine_planogram";
import {VendCoinSettings} from "./vend_coin_settings";
import {IGlobalDataContext, withGlobalData} from "./contexts/global-data";
import {VendingMachineIngredientMappingPanel} from "./vending_machine_ingredient_mapping_panel";
import {Helmet} from "react-helmet"
import {LaundryMachineSelectionPanel} from "./laundry_machine_selection_panel";
import VendingMachineCreditCardPanel from "./vending_machine_credit_card_panel";
import {TelemetryvendingMachinev1MotorCoupling} from "./telemetry/vending_machine/v1/typescript-axios";


interface Props extends IGlobalDataContext {
    match: any
}

interface State {
    dex: DexSettingInfo | null
    loading: boolean,
    vending_machine: VendingMachine | null,
    telemetry_devices: Array<TelemetryDevice>,
    product_mappings: Array<ProductMapping>,
    product_list: Array<Product>,
    message: string | null,
    sample_selection: Array<CoilDisplay>,
    keypad_options: Array<Keypad>
    ingredient_mappings: Array<IngredientMapping>
    laundry_machine_selection: Array<LaundryMachineSelection>
    motorCouplings: Array<TelemetryvendingMachinev1MotorCoupling>
}


class _VendingMachineSetting extends Component<Props, State> {
    constructor(props) {
        super(props);

        this.state = {
            message: null,
            dex: null,
            loading: true,
            vending_machine: null,
            telemetry_devices: [],
            product_mappings: [],
            product_list: [],
            sample_selection: [],
            keypad_options: [],
            ingredient_mappings: [],
            laundry_machine_selection: [],
            motorCouplings: [],
        };
    }

    loadVendingMachineDetail() {
        getAPI('/api/telemetry_devices').then((response) => {
            console.log('telemetry device update');
            if (response.data.success) {
                this.setState({telemetry_devices: response.data.devices});
            }
        });

        getAPI(`/api/vending_machines/${this.props.match.params.uuid}`).then((response) => {
            console.log('vending_machine detail update');
            this.setState({loading: false});
            if (response.data.success) {
                console.log(response);
                this.setState({vending_machine: response.data.vendingMachine});

                // not online , query proxy api
                if (!response.data.vendingMachine.online) {
                    this.getMachineOnlineStatus()
                }
            } else {
                console.log('failure??');
                window.alert("Failed to load vending machine. Please contact support.");
                this.setState({message: response.data.message})
            }
        });

        new ManagementServiceApi().machineManagementServiceListMotorCouplings(this.props.match.params.uuid).then((response) => {
            console.log(response.data);
            this.setState({motorCouplings: response.data.motorCouplings ?? []})
        });

        getAPI(`/api/vending_machines/${this.props.match.params.uuid}/dex`).then((response) => {
            this.setState({dex: response.data.dex_info});
        });

        getAPI(`/api/vending_machines/${this.props.match.params.uuid}/product_mappings`).then((response) => {
            console.log("Response is: ");
            console.log(response);

            if (response.data.success) {
                this.setState({product_mappings: response.data.mapping});
            }
        });

        getAPI(`/api/vending_machines/${this.props.match.params.uuid}/ingredient_mappings`).then((response) => {
            console.log("Response is: ");
            console.log(response);

            if (response.data.success) {
                this.setState({ingredient_mappings: response.data.ingredientMappingViewModels});
            }
        });

        getAPI(`/api/vending_machines/${this.props.match.params.uuid}/laundry_machine_selection`).then((response) => {
            console.log("Response is: ");
            console.log(response);
            if (response.data.success) {
                this.setState({laundry_machine_selection: response.data.laundryMachineData});
            }
        });

        getAPI(`/api/vending_machines/${this.props.match.params.uuid}/coil_display_format`).then((response) => {
            this.setState({sample_selection: response.data.coilDisplays})
        })

        getAPI(`/api/products`).then((response) => {
            if (response.data.success) {
                this.setState({
                    product_list: response.data.products
                })
            }
        })

        getAPI('/api/keypad_configurations').then((response) => {
            if (response.data.success) {
                this.setState({keypad_options: response.data.keypad_configurations})
            }
        })
    }

    getMachineOnlineStatus() {
        new ManagementServiceApi().machineManagementServiceListMachinesOnlineStatus({
            vmids: [this.props.match.params.uuid]
        }).then(res => {
            res.data.machinesOnlineStatus?.forEach((machineStatus) => {
                if (machineStatus.vmid === this.props.match.params.uuid) {
                    let vending_machine = this.state.vending_machine
                    if (vending_machine) {
                        vending_machine.online = machineStatus.online as boolean
                        this.setState({
                            vending_machine: vending_machine
                        })
                    }
                }
            })
        })
    }

    componentDidMount() {
        this.loadVendingMachineDetail();
    }

    render() {
        if (this.state.loading || this.state.vending_machine == null || this.state.telemetry_devices == null) {
            return (
                <section className="content">
                    <h1>Vending Machines</h1>

                    <div className="box">
                        <div className="box-header">
                            <h3 className="box-title">Loading...</h3>
                        </div>
                        <div className="box-body no-padding">
                            <h1>Loading vending machine details.</h1>
                        </div>
                    </div>
                </section>
            )
        }

        let coils: Array<DexProduct> = [];
        if (this.state.dex) {
            coils = this.state.dex.coils;
        }


        return (
            <section className="content">
                <Helmet titleTemplate="%s - Vending on Track">
                    <title>Vending Machine Setting</title>
                </Helmet>
                <h1>{this.state.vending_machine.name}
                    <small>[<Link
                        to={`/vending_machines/${this.state.vending_machine && this.state.vending_machine.uuid}`}>Information</Link>]
                    </small>
                </h1>

                <div className="help-block">
                    This page allows vending machine operators to update vending machine settings. To view current
                    vending machine state, please switch to "Information" page by following this link: <Link
                    to={`/vending_machines/${this.state.vending_machine && this.state.vending_machine.uuid}`}>Information</Link>
                </div>

                {this.state.vending_machine.archived ?
                    <div className="alert alert-danger"><b>Note: </b> This vending machine is archived.</div> : null}

                <p className={(this.state.vending_machine.online ? "text-success" : "text-warning") + " text"}>
                    <i className={"fa fa-circle"}>&nbsp;</i>
                    {this.state.vending_machine.online ? "Online" : "Offline"}
                </p>

                <div>
                    <RoutedTabs startPathWith={`/vending_machines/${this.props.match.params.uuid}/setting`}>
                        <NavTab exact to={`/`}>Details</NavTab>
                        <NavTab exact to={`/vendcoin`}>VendCoin</NavTab>
                        {this.state.vending_machine.vendingMachineType !== 'LAUNDRY' &&
                            <NavTab exact to={`/product_mappings`}>Product Mapping</NavTab>}
                        {this.state.vending_machine.vendingMachineType === 'COFFEE' &&
                            <NavTab exact to={`/ingredient_mappings`}>Ingredient Mapping</NavTab>}
                        {this.state.vending_machine.vendingMachineType === 'LAUNDRY' &&
                            <NavTab exact to={`/laundry_machine_selection`}>Selection</NavTab>}
                        <NavTab exact to={`/planogram`}>Planogram</NavTab>
                        <NavTab exact to={`/credit_card`}>Credit Card</NavTab>
                        <NavTab exact to={`/dex`}>Dex Info{' '}
                            {(this.state.vending_machine && (this.state.vending_machine.salesInfoSource === "MDB")) ?
                                <small className="text text-warning">MDB machine</small> : null}</NavTab>
                        <NavTab exact to={`/archive`}>Archive</NavTab>
                    </RoutedTabs>
                    <Switch>
                        <Route exact path={`/vending_machines/${this.props.match.params.uuid}/setting/planogram`}>
                            <VendingMachinePlanogram vendingMachine={this.state.vending_machine}/>
                        </Route>

                        <Route exact path={`/vending_machines/${this.props.match.params.uuid}/setting`}>
                            <VendingMachineDetailPanel
                                detailUpdated={this.loadVendingMachineDetail.bind(this)}
                                vending_machine={this.state.vending_machine}
                                telemetry_devices={this.state.telemetry_devices}
                                reloadAllProducts={this.props.reloadAllProducts.bind(this)}
                                reloadAllVendingMachines={this.props.reloadAllVendingMachines.bind(this)}
                            />
                        </Route>

                        <Route exact path={`/vending_machines/${this.props.match.params.uuid}/setting/vendcoin`}>
                            <VendCoinSettings
                                onUpdated={this.loadVendingMachineDetail.bind(this)}
                                vendingMachine={this.state.vending_machine}
                                keypadOptions={this.state.keypad_options}/>
                        </Route>

                        <Route exact path={`/vending_machines/${this.props.match.params.uuid}/setting/credit_card`}>
                            <VendingMachineCreditCardPanel
                                vendingMachine={this.state.vending_machine}/>
                        </Route>

                        <Route exact path={`/vending_machines/${this.props.match.params.uuid}/setting/archive`}>
                            <VendingMachineArchivePanel vendingMachine={this.state.vending_machine} updated={() => {
                                this.loadVendingMachineDetail();
                            }}/>
                        </Route>

                        <Route exact
                               path={`/vending_machines/${this.props.match.params.uuid}/setting/product_mappings`}>
                            <VendingMachineProductMappingPanel coils={coils}
                                                               format={this.state.vending_machine.format}
                                                               sample_selection={this.state.sample_selection}
                                                               handleRefresh={this.loadVendingMachineDetail.bind(this)}
                                                               vending_machine_uuid={this.props.match.params.uuid}
                                                               product_list={this.state.product_list.filter((product) => {
                                                                   return (product.productType === 'NORMAL' || product.productType === 'COMPOSITE')
                                                               })}
                                                               product_mappings={this.state.product_mappings}
                                                               motorCouplings={this.state.motorCouplings}
                                                               vending_machine={this.state.vending_machine}/>
                        </Route>

                        <Route exact
                               path={`/vending_machines/${this.props.match.params.uuid}/setting/ingredient_mappings`}>
                            <VendingMachineIngredientMappingPanel
                                handleRefresh={this.loadVendingMachineDetail.bind(this)}
                                vending_machine_uuid={this.props.match.params.uuid}
                                ingredient_list={this.state.product_list.filter((product) => {
                                    return product.productType === 'INGREDIENT'
                                })}
                                ingredient_mappings={this.state.ingredient_mappings}/>
                        </Route>

                        <Route exact
                               path={`/vending_machines/${this.props.match.params.uuid}/setting/laundry_machine_selection`}>
                            <LaundryMachineSelectionPanel
                                handleRefresh={this.loadVendingMachineDetail.bind(this)}
                                vending_machine_uuid={this.props.match.params.uuid}
                                laundry_machine_selections={this.state.laundry_machine_selection}/>
                        </Route>

                        <Route exact path={`/vending_machines/${this.props.match.params.uuid}/setting/dex`}>
                            <DexReadingSettingInfoPanel dex={this.state.dex}
                                                        emailDexFile={this.state.vending_machine.emailDexFile}
                                                        uuid={this.state.vending_machine.uuid}
                                                        handleRefresh={this.loadVendingMachineDetail.bind(this)}/>
                        </Route>
                    </Switch>
                </div>
            </section>
        );
    }
}

// @ts-ignore
const VendingMachineSetting = withRouter(withGlobalData(_VendingMachineSetting));

export {VendingMachineSetting};
