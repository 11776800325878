import React, {Component} from "react";
import {IGlobalDataContext, withGlobalData} from "../contexts/global-data";
import {FormatMoney} from "../format_money";
import moment from "moment";
import 'moment-timezone';
import {KioskForceOrder} from "../connect-ts/telemetry/report/v1/api_pb";

interface Props extends IGlobalDataContext {
    order: KioskForceOrder
}

interface State {
    showOrderLineItems: boolean
}


class _KioskOrderTr extends Component<Props, State> {
    constructor(props) {
        super(props);
        this.state = {
            showOrderLineItems:false
        }
    }

    toggleShowLineItems() {
        this.setState({
            showOrderLineItems: !this.state.showOrderLineItems,
        })
    }
    render(): React.ReactElement<any, string | React.JSXElementConstructor<any>> | string | number | {} | React.ReactNodeArray | React.ReactPortal | boolean | null | undefined {

        let lineItemsDetail;
        if (this.state.showOrderLineItems) {
            lineItemsDetail = this.props.order.lineItems.map((item, index)=>{
                return (<tr key={index}>
                    <td>{item.lineItem?.name}</td>
                    <td>{item.lineItem?.code}</td>
                    <td>{item.lineItem?.currency}</td>
                    <td>{item.lineItem?.price}</td>
                    <td>{item.lineItem?.quantity}</td>
                    <td>{item.dispensedSuccessfully ? "Yes" : "No"}</td>
                    <td>{item.lineItem?.status}</td>
                </tr>)
            })
        }

        return (<React.Fragment>
            <tr>
                <td>{this.props.order.vmid}</td>
                <td>{this.props.order.vendingMachineName}</td>
                <td>{this.props.order.referenceId}</td>
                <td>{this.props.order.orderState}</td>
                <td>{moment.unix(Number(this.props.order.orderTime?.seconds)).utc().format('YYYY-MM-DDTHH:mm:ss[Z]').toString()}</td>
                <td>
                    {
                        this.props.order.orderState === "ORDER_STATE_SUCCEEDED" || this.props.order.orderState === "ORDER_STATE_REFUNDED" ?
                            moment.unix(Number(this.props.order.dispenseTime?.seconds)).utc().format('YYYY-MM-DDTHH:mm:ss[Z]').toString()
                            :
                            ""
                    }
                </td>
                <td><FormatMoney cents={this.props.order.totalAmount}/></td>
                <td><FormatMoney cents={this.props.order.totalPaid}/></td>
                <td>{moment.unix(Number(this.props.order.completeTime?.seconds)).utc().format('YYYY-MM-DDTHH:mm:ss[Z]').toString()}</td>
                <td>{this.props.order.paymentGateway}</td>
                {/*<td>{this.props.order.gatewayReferenceId}</td>*/}
                {/*<td>{this.props.order.gatewayTransactionId}</td>*/}
                <td><span style={{cursor: "pointer"}} onClick={this.toggleShowLineItems.bind(this)}><i
                    className="fas fa-shopping-basket"></i>LineItems</span></td>
                <td>{moment.unix(Number(this.props.order.orderTime?.seconds)).tz(this.props.me.timezone).toString()}</td>
                <td>{moment.unix(Number(this.props.order.completeTime?.seconds)).tz(this.props.me.timezone).toString()}</td>
            </tr>

            {this.state.showOrderLineItems && <tr>
                <td colSpan={14}>
                    <table className={"table table-striped"}>
                        <thead>
                        <tr>
                            <th>Name</th>
                            <th>Code</th>
                            <th>Currency</th>
                            <th>ItemPrice</th>
                            <th>Quantity</th>
                            <th>DispensedSuccessfully</th>
                            <th>Status</th>
                        </tr>
                        </thead>
                        <tbody>
                            {lineItemsDetail}
                        </tbody>
                    </table>
                </td>
            </tr>
            }
        </React.Fragment>)
    }

}

const KioskOrderTr = withGlobalData(_KioskOrderTr);
export default KioskOrderTr;
