import React, {Component, Fragment} from 'react';
import {Button, ButtonGroup, DropdownButton, MenuItem, ButtonToolbar} from "react-bootstrap";
import Modal from 'react-modal';
import {postAPI} from "./api";


interface Props {
    isMobilePaymentTransaction:boolean
    isCreditCardTransaction:boolean
    salesMobile:string
    creditCardLastFourDigits:string
    salesVendingMachineAddress:string
    salesVendingState:string
    salesDate:string
    refunded:boolean
    gatewayAccount:string
    makeRefund():void
    invoiceIdentity:string
    invoiceUrl:string,
    transactionUuid:string
}

interface State {
    emailModalOpen: boolean,
    email:string,
    name:string,
}

class SalesDetail extends Component<Props, State> {
    constructor(props: Props) {
        super(props);

        this.state = {
            emailModalOpen: false,
            name:"",
            email:"",
        };
    }

    openEmailInvoiceModal(event) {
        event.preventDefault();
        this.setState({emailModalOpen: true});
    }

    closeEmailInvoiceModal(event) {
        event.preventDefault();
        this.setState({emailModalOpen: false, name:"", email:""});
    }

    handleEmailChange(event){
        event.preventDefault();
        this.setState({email:event.target.value})
    }

    handleNameChange(event){
        event.preventDefault();
        this.setState({name:event.target.value})
    }

    sendEmail(event){
        event.preventDefault();
        postAPI(`/api/transactions/${this.props.transactionUuid}/email_invoice`, {
            email:this.state.email,
            name:this.state.name !== "" ? this.state.name : null
        }).then((response)=>{
            if (response.data.success){
                this.closeEmailInvoiceModal(event);
            } else {
                window.alert(response.data.message)
            }
        })
    }

    render() {
        return <Fragment>
            <tr>
                <td colSpan={7}>
                    <table className={"table table-striped"}>
                        <tbody>
                        {this.props.isMobilePaymentTransaction &&
                        <tr>
                            <td>Customer Phone No:</td>
                            <td>{this.props.salesMobile}</td>
                        </tr>}
                        {this.props.isCreditCardTransaction &&
                        <tr>
                            <td>Credit Card Last 4 Digits:</td>
                            <td>{this.props.creditCardLastFourDigits}</td>
                        </tr>}
                        {this.props.isCreditCardTransaction &&
                        <tr>
                            <td>Gateway Account:</td>
                            <td>{this.props.gatewayAccount}</td>
                        </tr>}
                        <tr>
                            <td>Vending Machine Address:</td>
                            <td>{this.props.salesVendingMachineAddress}</td>
                        </tr>
                        <tr>
                            <td>Vending State</td>
                            <td>{this.props.salesVendingState}</td>
                        </tr>
                        <tr>
                            <td>Purchase Time:</td>
                            <td>{this.props.salesDate}</td>
                        </tr>
                        <tr>
                            <td>Refunded:</td>
                            <td>{this.props.refunded.toString()}</td>
                        </tr>
                        <tr>
                            <td colSpan={2}>
                                <ButtonToolbar>
                                    <ButtonGroup>
                                        <Button disabled={this.props.refunded} className={"btn btn-warning"}
                                                onClick={this.props.makeRefund.bind(this)}>Refund</Button>
                                    </ButtonGroup>

                                    <ButtonGroup>
                                        <DropdownButton bsStyle={"success"} title={"Invoice"}>
                                            <MenuItem eventKey="1" onClick={()=>{window.open(this.props.invoiceUrl)}}>Download Invoice</MenuItem>
                                            <MenuItem eventKey="2" onClick={this.openEmailInvoiceModal.bind(this)}>Email
                                                Invoice</MenuItem>
                                        </DropdownButton>
                                    </ButtonGroup>
                                </ButtonToolbar>
                            </td>
                        </tr>
                        </tbody>
                    </table>
                </td>
            </tr>
            <Modal
                style={{overlay: {zIndex: 1000}}}
                ariaHideApp={false}
                isOpen={this.state.emailModalOpen}
                contentLabel="Email Invoice"
            >
                <h3>Please Enter Customer's Detail</h3>

                <form onSubmit={this.sendEmail.bind(this)}>
                    <div className="row">
                        <div className="col-sm-6">
                            <div className="form-group">
                                <label>Email *</label>
                                <input onChange={this.handleEmailChange.bind(this)} type={"email"} required className="form-control" value={this.state.email}/>
                            </div>
                        </div>
                    </div>

                    <div className="row">
                        <div className="col-sm-6">
                            <div className="form-group">
                                <label>Name</label>
                                <input onChange={this.handleNameChange.bind(this)} type={"text"}
                                       className="form-control" value={this.state.name}/>
                            </div>
                        </div>
                    </div>

                    <div className="form-group">
                        <input type="submit" className="btn btn-success" value={"Send"}/>
                        <button className={"btn btn-danger"} onClick={this.closeEmailInvoiceModal.bind(this)}>Cancel</button>
                    </div>
                </form>
            </Modal>
        </Fragment>;
    }
}

export {SalesDetail}
