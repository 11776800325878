import React, {Component} from "react";
import {CompanyUserNomination, Message} from "./models";
import {DisplayTime} from "./display_time";
import {postAPI} from "./api";
import { Link } from "react-router-dom";

interface Props{
    nomination: CompanyUserNomination
    nominationsShouldReload()
    pushFlashMessage(Message)
}

interface State {
}

class CompanyUserNominationsTableRow extends Component<Props, State> {
    constructor(props) {
        super(props);

        this.state = {};
    }

    removeNomination(event, nomination: CompanyUserNomination) {
        event.stopPropagation();
        event.preventDefault();

        if (window.confirm("Are you sure to remove " + nomination.email)) {
            postAPI(`/api/company_user_nomination/`, {
                companyUserNominationId: nomination.id
            }).then((resp) => {
                if (resp.data.success) {
                    this.props.nominationsShouldReload();
                    this.props.pushFlashMessage(new Message("Removed", "success"));
                } else {
                    this.props.pushFlashMessage(new Message(resp.data.message, "error"));
                }
            })
        }
    }

    render() {
        let nomination = this.props.nomination;
        return (
            <tr>
                <td><DisplayTime timestamp={nomination.createdTimestamp}/></td>
                <td>{nomination.email}</td>
                <td>{nomination.role}</td>
                <td>{nomination.status}</td>
                <td>
                    {nomination.status !== "PENDING_REGISTRATION" && nomination.status !== "DELETED" &&
                        <Link to="/company_driver_vending_machine_allocation" className="btn btn-success">Edit</Link>
                    }
                    {nomination.status !== "DELETED" && <button onClick={(event) => {
                        return this.removeNomination(event, nomination)
                    }} className="btn btn-danger" style={{marginLeft: "5px"}}>Delete
                    </button>}
                </td>
            </tr>
        );
    }
}

export {CompanyUserNominationsTableRow}
