import React, {Component} from "react";
import {Helmet} from "react-helmet";
import {SearchOrderTable} from "./search_order_table";
import {V1VmcpOrder,V1VmcpOrderItem,V1PaymentEvent} from "./telemetry/vending_machine/v1/typescript-axios";
import {vmcpOrderServiceApi} from "./api";

interface Props {
}

interface State {
    order: V1VmcpOrder
    orderItems: Array<V1VmcpOrderItem>
    paymentEvents: Array<V1PaymentEvent>
    receiptId: string
}

export class SearchOrder extends Component<Props, State> {
    constructor(props) {
        super(props);

        const searchParams = new URLSearchParams(props.location.search);
        const receiptId = searchParams.get('receiptId') ?? '';

        this.state = {
            paymentEvents: [],
            order: {},
            orderItems: [],
            receiptId: receiptId,
        };

        if (receiptId !== '') {
            this.loadPaymentEvents(receiptId);
        }
    }


    loadPaymentEvents(receiptId) {
        console.log('have receiptId'+receiptId);
        new vmcpOrderServiceApi().vmcpOrderServiceSearchOrderByReceiptId(receiptId).then(res => {
            if (res.data.order) {
                this.setState({
                    order: res.data.order ?? {},
                    orderItems: res.data.orderItems ?? [],
                    paymentEvents: res.data.paymentEvents ?? [],
                })
            } else {
                alert("Order not found")
            }
        }).catch(err => {
            alert(err);
        });
    }

    handleReceiptIdChange(event: any) {
        this.setState({receiptId: event.target.value.trim()})
    }

    handleSearch(event: any) {
        event.preventDefault()
        console.log(this.state.receiptId)
        if (this.state.receiptId === '') {
            alert('Please enter a receiptId')
            return
        }
        this.loadPaymentEvents(this.state.receiptId)
    }

    render() {
        return (
            <div>
                <Helmet titleTemplate="%s - Vending on Track">
                    <title>Payment Events</title>
                </Helmet>
                <section className="content">
                    <div className={"box statsBox"}>
                        <div className={"box-header"}>
                            <h1>
                                Search Order
                            </h1>
                        </div>
                        <div className={"box-body"}>
                            <div>
                                <form className="form-inline">
                                    <div className="fo202rm-group">
                                        <label htmlFor="floatingInput" className={"textSecondary"}
                                               style={{marginRight: "5px"}}>ReceiptId: </label>
                                        <input type="text" className="form-control" value={this.state.receiptId}
                                               placeholder={"enter ReceiptId"}
                                               onChange={this.handleReceiptIdChange.bind(this)}/>
                                         <button className="btn btn-success" onClick={e => this.handleSearch(e)}>Search
                                        </button>
                                    </div>
                                </form>
                            </div>

                            <br/>
                            <SearchOrderTable paymentEvents={this.state.paymentEvents} orderItems={this.state.orderItems} order={this.state.order}/>
                        </div>
                    </div>
                </section>
            </div>
        );
    }

}