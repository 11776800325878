import React, {useEffect, useState} from 'react';
import {Message, VendCoinInfo, VendingMachine} from "./models";
import {postAPI} from "./api";
import { IGlobalDataContext, withGlobalData } from "./contexts/global-data";
import KeypadDisplay from "./keypad_display";
import {Keypad} from "./models"

interface Props extends IGlobalDataContext {
  vendingMachine: VendingMachine
  keypadOptions : Keypad[]

  onUpdated()
}

interface VendCoinInfoWithPhoto extends VendCoinInfo {
  photoBlob?: string
}

function _vendCoinSettings(props: Props) {
  const { vendingMachine, keypadOptions } = props;

  const [vendCoinInfo, setVendCoinInfo] = useState<VendCoinInfoWithPhoto>(vendingMachine.vendCoinInfo);

  const [selectedKeypad, setSelectedKeypad] = useState<Keypad|null>(null)

  const [keypadEnable, setKeypadEnable] = useState(false)

  useEffect(loading, [vendingMachine])

  function loading(){
    if (vendingMachine.vendCoinInfo.keypad_configuration === null){
      setKeypadEnable(false)
    } else {
      if (vendingMachine.vendCoinInfo.keypad_configuration.virtual_keypad_enabled){
        setKeypadEnable(true)
        const keypad:Keypad = {
          id : vendingMachine.vendCoinInfo.keypad_configuration.id,
          total_row_number:vendingMachine.vendCoinInfo.keypad_configuration.total_row_number,
          total_column_number:vendingMachine.vendCoinInfo.keypad_configuration.total_column_number,
          total_action_needed:vendingMachine.vendCoinInfo.keypad_configuration.total_action_needed,
          name:vendingMachine.vendCoinInfo.keypad_configuration.name,
          keypad_items: vendingMachine.vendCoinInfo.keypad_configuration.keypad_items
        }
        setSelectedKeypad(keypad)
      } else {
        setKeypadEnable(false)
      }
    }
  }




  function onSelectFile(e) {
    const fileReader = new FileReader();
    fileReader.onerror = (e) => {
      window.alert("Failed to read file");
    };
    fileReader.onload = (e) => {
      const pictureContent: string = fileReader.result as string;
      setVendCoinInfo({ ...vendCoinInfo, photoBlob: window.btoa(pictureContent) })
    };
    fileReader.readAsBinaryString(e.target.files[0]);
  }

  function saveVendCoinInfo(e) {
    e.preventDefault();
    e.stopPropagation();
    console.log('saving...');
    console.log(vendCoinInfo);

    let request;
    if (keypadEnable){
      request = {
        externalPhotoUrl:vendCoinInfo.externalAddress,
        externalContactName:vendCoinInfo.externalContactName,
        externalAddress:vendCoinInfo.externalAddress,
        externalContactNumber:vendCoinInfo.externalContactNumber,
        photoBlob:vendCoinInfo.photoBlob,
        uuid:vendCoinInfo.uuid,
        operatorId:vendCoinInfo.operatorId,
        publicOnVendCoin:vendCoinInfo.publicOnVendCoin,
        allowSwipeBeforeSelection:vendCoinInfo.allowSwipeBeforeSelection,
        chargeConsumerSurcharge:vendCoinInfo.chargeConsumerSurcharge,
        virtualKeypadEnabled:keypadEnable,
        keypadConfigurationId:selectedKeypad?.id}
    } else {
      request = {
        externalPhotoUrl:vendCoinInfo.externalAddress,
        externalContactName:vendCoinInfo.externalContactName,
        externalAddress:vendCoinInfo.externalAddress,
        externalContactNumber:vendCoinInfo.externalContactNumber,
        photoBlob:vendCoinInfo.photoBlob,
        uuid:vendCoinInfo.uuid,
        operatorId:vendCoinInfo.operatorId,
        publicOnVendCoin:vendCoinInfo.publicOnVendCoin,
        allowSwipeBeforeSelection:vendCoinInfo.allowSwipeBeforeSelection,
        chargeConsumerSurcharge:vendCoinInfo.chargeConsumerSurcharge,
        virtualKeypadEnabled:keypadEnable}
    }

    postAPI(`/api/vending_machines/${vendingMachine.uuid}/vend_coin_settings`,
        request).then((response) => {
      props.onUpdated()
      if (response.data.success) {
        props.pushFlashMessage(new Message("Saved", "success"));
      }
    })
  }

  function changeAddress(e) {
    setVendCoinInfo({ ...vendCoinInfo, externalAddress: e.target.value });
    e.preventDefault();
    e.stopPropagation();
  }

  function changeContactName(e) {
    setVendCoinInfo({ ...vendCoinInfo, externalContactName: e.target.value });
    e.preventDefault();
    e.stopPropagation();
  }

  function changeContactNumber(e) {
    setVendCoinInfo({ ...vendCoinInfo, externalContactNumber: e.target.value });
    e.preventDefault();
    e.stopPropagation();
  }

  function changePublicOnVendCoin(e) {
    console.log(e.target.value)
    if (e.target.value === "public") {
      setVendCoinInfo({ ...vendCoinInfo, publicOnVendCoin: true });
    } else {
      setVendCoinInfo({ ...vendCoinInfo, publicOnVendCoin: false });
      console.log(vendCoinInfo)
    }
    e.preventDefault();
    e.stopPropagation();
  }

  function changeKeypadEnable(e){
    console.log(e.target.value);
    if (e.target.value === "enable") {
      setKeypadEnable(true)
      setSelectedKeypad(keypadOptions[0])
    } else {
      setKeypadEnable(false)
    }
    e.preventDefault();
    e.stopPropagation();
  }

  function changeKeypad(e){
    keypadOptions.forEach(keypad =>{
      if(keypad.name === e.target.value){
        setSelectedKeypad(keypad)
      }
    })
  }

  function changeSwipeBeforeSelection(e){
    console.log(e.target.value);
    if (e.target.value === "allow"){
      setVendCoinInfo({...vendCoinInfo, allowSwipeBeforeSelection: true})
    } else {
      setVendCoinInfo({...vendCoinInfo, allowSwipeBeforeSelection: false})
    }
    e.preventDefault();
    e.stopPropagation();
  }

  function changeChargeConsumerSurcharge(e){
    console.log(e.target.value);
    if (e.target.value === "true"){
      setVendCoinInfo({...vendCoinInfo, chargeConsumerSurcharge: true})
    } else {
      setVendCoinInfo({...vendCoinInfo, chargeConsumerSurcharge: false})
    }
    e.preventDefault();
    e.stopPropagation();
  }

  return (<div>
        <h1>VendCoin Settings for [{vendingMachine.name}]</h1>

        <div className="callout callout-info">
          <h4>VendCoin Tip</h4>

          <p>The following information is to be displayed to VendCoin customers, it's used for their customer
            help.</p>
        </div>

        <div className={"row"}>
          <div className={"col-xs-6"}>
            <form onSubmit={saveVendCoinInfo}>
              <div className="form-group">
                <label>Vending Machine Location</label>
                <input type="text" value={vendCoinInfo.externalAddress} className="form-control"
                       onChange={changeAddress} />
              </div>

              <div className="form-group">
                <label>Contact Name</label>
                <input type="text" value={vendCoinInfo.externalContactName} className="form-control"
                       onChange={changeContactName} />
              </div>

              <div className="form-group">
                <label>Contact Number</label>
                <input type="text" value={vendCoinInfo.externalContactNumber} className="form-control"
                       onChange={changeContactNumber} />
              </div>

              <div className="form-group">
                <label>Vending Machine Photo</label>
                <input type="file" onChange={onSelectFile} className="form-control" />
              </div>

              <div className="form-group">
                <label data-toggle="tooltip" data-placement="top" title="whether this vending machine should
                 be publicly discoverable on VendCoin. If the site is public, this option might be desirable.
                 Default value is private.">Public On VendCoin<span className = "text-info">*</span></label>
                <select className={"form-control"}
                        onChange={changePublicOnVendCoin}
                        value={vendCoinInfo.publicOnVendCoin ? "public" : "private"}>
                  <option value="public">Public</option>
                  <option value="private">Private</option>
                </select>
              </div>
              <div className="form-group">
                <label>
                  Swipe Before Selection
                </label>
                <select className={"form-control"}
                        onChange={changeSwipeBeforeSelection}
                        value={vendCoinInfo.allowSwipeBeforeSelection ? "allow" : "disallow"}>
                  <option value="allow">Allow</option>
                  <option value="disallow">Disallow</option>
                </select>
              </div>
              <div className="form-group">
                <label data-toggle="tooltip" data-placement="top" title='who would pay the transaction fee. Default value is "Consumer will pay the transaction fee", aka add surcharge to consumer.'>
                  Transaction Fee Payable By Consumer<span className = "text-info">*</span>
                </label>
                <select className={"form-control"}
                        onChange={changeChargeConsumerSurcharge}
                        value={vendCoinInfo.chargeConsumerSurcharge ? "true" : "false"}>
                  <option value="true">True</option>
                  <option value="false">False</option>
                </select>
              </div>
              <div className="form-group">
                <label data-toggle="tooltip" data-placement="top" title="whether virtual keypad should be enabled on this vending machine. Default value is false.">KeyPad Enable<span className = "text-info">*</span></label>
                <select className={"form-control"}
                        onChange = {changeKeypadEnable}
                        value={keypadEnable? "enable" : "disable"}>
                  <option value="enable">Enable</option>
                  <option value="disable">Disable</option>
                </select>
              </div>

              {keypadEnable && <div className="form-group">
                <label>KeyPad Options</label>
                <select className={"form-control"}
                        onChange = {changeKeypad}
                        value={selectedKeypad?.name}>
                  {keypadOptions.map((keypad)=>{
                    return <option key={keypad.id}>{keypad.name}</option>
                  })}
                </select>
              </div>}

              <input type="submit" className="btn btn-success" value="Save" />
            </form>
          </div>
          {keypadEnable && selectedKeypad &&
          <div className="col-xs-3">
            Keypad:
            <br />
            <KeypadDisplay data={selectedKeypad.keypad_items}/>
          </div>
          }
          <div className="col-xs-3">
            Current Photo:
            <br />
            <img height="450" src={vendingMachine.vendCoinInfo.externalPhotoUrl} alt="Current Vending Machine" />
          </div>
        </div>

        <hr />
        <hr />

        <a
            href={`/api/qrcode/${vendingMachine.uuid}`}
            target="_blank"
            rel="noopener noreferrer"
            download={`${vendingMachine.uuid}.png`}
        >
          <button className={"btn btn-default"}>Save the QRCode</button>
        </a>

        <button
            onClick={() => window.open(`/api/qrcode/${vendingMachine.uuid}`)}
            className={"btn btn-default"}
        >
          Open in a new window
        </button>
        <div>
          <img src={`/api/qrcode/${vendingMachine.uuid}`} width={"50%"} alt={"qrcode"} />
        </div>
      </div>
  );
}

export const VendCoinSettings = withGlobalData(_vendCoinSettings);
