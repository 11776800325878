import {Link} from "react-router-dom";
import {DashboardStatsComponent} from "./dashboard_stats_component";
import {VendingMachinesTable} from "./vending_machines_table";
import {DashboardLatestSales} from "./dashboard_latest_sales";
import {DashboardLatestTransaction} from "./dashboard_latest_transaction";
import {RefillsTable} from "./refills_table";
import React, {Fragment} from "react";
import {DailyRevenue, VendingMachine, VendingMachinesWithLoadingState} from "./models";

interface Props {
    stats: any,
    allVendingMachines: Array<VendingMachine>
    number_of_day: number

    handleNumOfDayChange(numberOfDay: number): void,

    loadRecent(),

    loadingRevenue: boolean
    revenues: Array<DailyRevenue>,
    online_vending_machines: VendingMachinesWithLoadingState,
    latest_sales,
    latest_virtual_financial_transactions,
    recentRefills

    reloadRefill(),

    currencySymbol: string
}

export function AdminDashBoardFragment(props: Props) {
    return <Fragment>
        <section className="content-header">
            <h1>
                Dashboard
            </h1>
        </section>

        <div className="visible-xs-block">
            <Link to="/vending_machines" className="btn btn-app">
                <i className="fa fa-align-left"> </i>
                Vending Machines
            </Link>

            <Link to="/buttons" className="btn btn-app">
                <i className="fa fa-check"> </i>
                Service
            </Link>

            <Link to="/" className="btn btn-app">
                <i className="fa fa-line-chart"> </i>
                Dashboard
            </Link>
        </div>

        <section className="content">
            {props.stats ? <DashboardStatsComponent stats={props.stats}
                                                         vendingMachines={props.allVendingMachines}
                                                         numberOfDays={props.number_of_day}
                                                         handleNumberOfDayChange={props.handleNumOfDayChange}
                                                         loading={props.loadingRevenue}
                                                         currencySymbol={props.currencySymbol}
                                                         revenues={props.revenues}
            /> : null}
            <div className="row">
                <div className={"col-xs-12"}>
                    <h2>Online Vending Machines <small><Link to={"/vending_machine_list"}>All Vending
                        Machines</Link></small></h2>


                    <VendingMachinesTable isLinkedToSettings={false}
                                          vending_machines={props.online_vending_machines}/>
                </div>
            </div>

            <div className="row">
                <div className={"col-md-7 col-xs-12"}>
                    <DashboardLatestSales
                        reloadData={props.loadRecent}
                        sales={props.latest_sales} />
                </div>
                <div className={"col-md-5 col-xs-12"}>
                    <DashboardLatestTransaction
                        vftrans={props.latest_virtual_financial_transactions} />
                </div>
            </div>

            <h2 className={""}>Recent Refills <small>
                    <Link to={"/refills"}>More refills</Link>
                </small>
            </h2>
            <div className={"row"}>
                <div className={"col-xs-12"}>
                    <div className="box with-border box-success">
                        <div className="box-body no-padding">
                            <RefillsTable refills={props.recentRefills}
                                          refillsShouldReload={props.reloadRefill}/>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    </Fragment>
}
