import React, {Component, Fragment} from "react";
import {Picking} from "./models";
import {getAPI} from "./api";
import {PickingsTable} from "./pickings_table";
import {Helmet} from "react-helmet";


interface Props {
}

interface State {
    pickings: Array<Picking>
}

export class RecentPickingsPage extends Component<Props, State> {
    constructor(props) {
        super(props);

        this.state = {
            pickings: []
        };

        this.init();
    }


    init() {
        getAPI("/api/prekittings").then((response) => {
            this.setState({pickings: response.data.prekittings.content});
        })
    }

    render() {
        return (
            <Fragment>
                <Helmet titleTemplate="%s - Vending on Track">
                    <title>Recent Picking List</title>
                </Helmet>
                <section className="content-header">
                    <h1>
                        Recent Picking List
                    </h1>
                </section>
                <section className="content">
                    <div className="box">
                        <div className={"box-header"}>
                            <PickingsTable pickings={this.state.pickings}/>
                        </div>
                    </div>
                </section>
            </Fragment>
        );
    }
}
