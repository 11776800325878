import React, {Component} from "react";
import {IGlobalDataContext, withGlobalData} from "./contexts/global-data";
import {KioskForceOrder} from "./connect-ts/telemetry/report/v1/api_pb";
import KioskOrderTr from "./components/kiosk_order_tr";


interface Props extends IGlobalDataContext {
    orders: Array<KioskForceOrder>
}

class _KioskOrdersTable extends Component<Props> {
    // eslint-disable-next-line
    constructor(props) {
        super(props);
    }


    render(): React.ReactElement<any, string | React.JSXElementConstructor<any>> | string | number | {} | React.ReactNodeArray | React.ReactPortal | boolean | null | undefined {
        return (
            <table className={"table table-condense"}>
                <thead>
                <tr>
                    <th>Vmid</th>
                    <th>MachineName</th>
                    <th>OrderUuid</th>
                    <th>OrderState</th>
                    <th>Creation Time (UTC)</th>
                    <th>DispenseTime (UTC)</th>
                    <th>TotalAmount</th>
                    <th>TotalPaid</th>
                    <th>Modified Time (UTC)</th>
                    <th>PaymentGateway</th>
                    {/*<th>GatewayReferenceId</th>*/}
                    {/*<th>GatewayTransactionId</th>*/}
                    <th>LineItems</th>
                    <th>Creation Time (local)</th>
                    <th>Modified Time (local)</th>
                </tr>
                </thead>
                <tbody>
                {

                    this.props.orders.map((order) => {
                    return (
                        <KioskOrderTr order={order} key={order.referenceId}/>
                    )

                    })

                }

                </tbody>
            </table>
        );
    }
}

export const KioskOrdersTable = withGlobalData(_KioskOrdersTable);
