import React, {Component} from 'react';
import KeypadRow from "./keypad_row";


interface Props {
    data:{
        id: number,
        w: number,
        h: number,
        x: number,
        y: number,
        display_value: string}[]
}


class KeypadDisplay extends Component<Props> {


    render() {
        let organisedData:{
            id: number,
            w: number,
            h: number,
            x: number,
            y: number,
            display_value: string}[][] = [];
        let count = -1;
        this.props.data.sort((x, y)=>{return x.id-y.id}).forEach(button => {
            if (button.x ===0){
                organisedData.push([])
                count++
                organisedData[count].push(button)
            } else {
                organisedData[count].push(button)
            }
        })

        let output:JSX.Element[] =[];
        organisedData.forEach((row, i) => {
            output.push(<KeypadRow key = {i} data={row}/>)
        })
        return (
            <div>
                {output}
            </div>
        )
    }
}

export default KeypadDisplay