import {UserEmailSettings} from "./models";
import React, {useState} from "react";
import {postAPI} from "./api";

interface Props {
    emailSettings: UserEmailSettings
}

export function UserEmailSettingsForm(props: Props) {
    const {emailSettings} = props;

    const [currentEmailSettings, setCurrentEmailSettings] = useState<UserEmailSettings>(emailSettings);

    const [busy, setBusy] = useState<boolean>(false);

    function saveEmailSettings(e) {
        setBusy(true);
        e.preventDefault();
        console.log(currentEmailSettings);

        postAPI("/api/users/email_settings", {
            disableAlertEmail: currentEmailSettings.disableAlertEmail,
            disableDailySummaryEmail: currentEmailSettings.disableDailySummaryEmail
        }).finally(() => {
            setBusy(false);
        })

    }

    function updateDisableAlertEmail(e) {
        if (e.target.checked) {
            setCurrentEmailSettings({
                ...currentEmailSettings,
                disableAlertEmail: true,
            })
        } else {
            setCurrentEmailSettings({
                ...currentEmailSettings,
                disableAlertEmail: false,
            })
        }
        console.log(currentEmailSettings);
    }

    function updateDisableDailySummaryEmail(e) {
        if (e.target.checked) {
            setCurrentEmailSettings({
                ...currentEmailSettings,
                disableDailySummaryEmail: true,
            })
        } else {
            setCurrentEmailSettings({
                ...currentEmailSettings,
                disableDailySummaryEmail: false,

            })
        }
    }

    return (
        <div className="box box-info">
            <div className="box-header">
                <h3 className="box-title">Override email settings</h3>
            </div>

            <div className="box-body">
                <form onSubmit={saveEmailSettings}>
                    <label>
                        Disable Alert Emails for me
                        &nbsp;
                        <input className="form-check-input" type={"checkbox"}
                               defaultChecked={currentEmailSettings.disableAlertEmail}
                               onChange={updateDisableAlertEmail}/>
                    </label>

                    <br/>
                    <label>
                        Disable Daily Summary Email for me
                        &nbsp;
                        <input className="form-check-input" type={"checkbox"}
                               defaultChecked={currentEmailSettings.disableDailySummaryEmail}
                               onChange={updateDisableDailySummaryEmail}/>
                    </label>

                    <br/>

                    {!busy &&
                    <input className={"btn btn-success"} type={"submit"} value={"Save"}/>
                    }
                </form>
            </div>
        </div>
    );
}
