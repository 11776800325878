import React, {Component, Fragment} from 'react';
import {Message, Notification, notificationSorting} from "./models";
import {getAPI, postAPI} from "./api";
import {NotificationComponent} from "./notification_component";
import moment from "moment";
import ReactPaginate from "react-paginate";
import {IGlobalDataContext, withGlobalData} from "./contexts/global-data";
import {Link} from "react-router-dom";
import {Helmet} from "react-helmet";


interface Props extends IGlobalDataContext {

}

interface State {
    notifications: Array<Notification>
    totalNotifications: number
    totalPages: number
    page: number
}

class NotificationsPageRaw extends Component<Props, State> {
    constructor(props) {
        super(props);

        this.state = {
            notifications: [],
            page: 0,
            totalPages: 1,
            totalNotifications: 0,
        }
    }

    componentDidMount(): void {
        this.loadNotifications(0);
    }

    loadNotifications(page): void {
        getAPI(`/api/notifications?page=${page}`).then((response) => {
            this.setState({
                notifications: response.data.notifications.content,
                totalPages: response.data.notifications.totalPages,
                totalNotifications: response.data.notifications.totalElements,
            });
        })
    }

    acknowledge(notification: Notification) {
        postAPI(`/api/notifications/${notification.id}/ack`, {}).then((response) => {
            console.log(response);
            if (response.data.success) {
                this.loadNotifications(this.state.page);
            } else {
                window.alert(response.data.message);
            }
            this.props.reloadNotificationCount();

        })
    }

    clearNotifications(e) {
        e.preventDefault();
        const mostRecentNotification = this.state.notifications.sort(notificationSorting)[0];
        const deadline = moment.unix(mostRecentNotification.createdTimestamp);
        if (window.confirm(`Acknowledge all notifications after ${deadline.toString()}`)) {
            postAPI(`/api/notifications/acknowledges`, {before: mostRecentNotification.createdTimestamp}).then((response) => {
                if (response.data.success) {
                    const previousPage = this.state.page > 0 ? this.state.page - 1  : 0;
                    this.setState({
                        page: previousPage,
                        notifications: []
                    });
                    this.props.pushFlashMessage(new Message("Successfully acknowledged all notifications", "success"));
                    this.loadNotifications(previousPage);

                    this.props.reloadNotificationCount();
                } else {
                    window.alert(response.data.message);
                }
            });
        }

        return false;
    }

    handlePageClick(selectedItem: { selected: number }) {
        this.setState({
            page: selectedItem.selected,
            notifications: [],
        });

        this.loadNotifications(selectedItem.selected);
    }

    render() {
        const notifications = this.state.notifications.sort(notificationSorting).map((notification: Notification) => {
           return (<NotificationComponent key={notification.id} ack={(e) => {e.preventDefault(); this.acknowledge(notification);this.props.reloadAllVendingMachines(); return false;}} notification={notification} />);
        });

        return (
            <Fragment>
                <Helmet titleTemplate="%s - Vending on Track">
                    <title>Notifications and Alerts</title>
                </Helmet>
                <section className="content-header">
                    <h1>
                        Notifications and Alerts
                        <small>
                            <Link
                                to={`/alert_settings`}>Settings
                            </Link>
                        </small>
                    </h1>
                </section>

                <section className="content">
                    <div className="box box-success">
                        <div className="box-header">
                            <h2>Current Notifications</h2>
                            <span>Total: {this.state.totalNotifications} notifications</span>
                        </div>
                        <div className="box-body with-border">

                            <ReactPaginate
                                pageRangeDisplayed={2}
                                marginPagesDisplayed={2}
                                pageCount={this.state.totalPages}
                                initialPage={0}
                                onPageChange={this.handlePageClick.bind(this)}
                                containerClassName={"pagination pagination-sm no-margin"}
                                activeClassName={'active'}
                            />

                            {this.state.notifications.length > 0 &&

                            <form className="form" onSubmit={this.clearNotifications.bind(this)}>
                                <input type="submit" className="btn btn-warning" value="Acknowledge all notifications"/>
                                <hr/>
                            </form>
                            }
                            <ul className="timeline">
                                {notifications}
                            </ul>
                        </div>
                    </div>
                </section>
            </Fragment>
        );
    }
}

export const NotificationsPage = withGlobalData(NotificationsPageRaw);
