import {Bar} from "react-chartjs-2";
import React from "react";
import {DailyRevenue} from "./models";

interface Props {
    dailyRevenueLoading:boolean
    reportDuration: number
    handleDurationChange(e: any)
    dailyRevenues: Array<DailyRevenue>,
    height: number
    currencySymbol:string
}

export function DailyRevenuesBox(props: Props) {
    return (<div className="box box-success well">
        <div className="box-header">
            <h3 className="box-title">Daily Revenues</h3>
            <div className="pull-right">
                {props.dailyRevenueLoading && <i className="fa fa-spinner fa-spin" style={{marginRight:'10px'}}/>}
                <select value={props.reportDuration} onChange={props.handleDurationChange}>
                    <option value={7}>7 Days</option>
                    <option value={28}>28 Days</option>
                    <option value={84}>3 Months</option>
                    <option value={168}>6 Months</option>
                    <option value={365}>1 Year</option>
                </select>
            </div>
        </div>
        <div className="box-body">
            <Bar
                options={{
                    responsive: true,

                    scales: {
                        x: {
                            stacked: true
                        },
                        y: {
                            stacked: true
                        }
                    }
                }}


                 data={{
                     labels: props.dailyRevenues.map((data) => {
                         return data.x
                     }),
                     datasets:
                         [
                             {
                                 label: `Cash Revenue (${props.currencySymbol})`,
                                 backgroundColor: "rgba(255,99,132,0.2)",
                                 borderColor: "rgba(255,99,132,1)",
                                 borderWidth: '1',
                                 hoverBackgroundColor: 'rgba(255,99,132,0.4)',
                                 hoverBorderColor: 'rgba(255,99,132,1)',
                                 data: props.dailyRevenues.map((data) => {
                                     return data.y_cash
                                 })
                             },
                             {
                                 label: `Cashless Revenue (${props.currencySymbol})`,
                                 backgroundColor: 'rgba(155,231,91,0.2)',
                                 borderColor: 'rgba(255,99,132,1)',
                                 borderWidth: '1',
                                 hoverBackgroundColor: 'rgba(155,231,91,0.4)',
                                 hoverBorderColor: 'rgba(255,99,132,1)',
                                 data: props.dailyRevenues.map((data) => {
                                     return data.y_cashless
                                 })
                             },

                         ]
                 }}
                 height={props.height}
            />
        </div>
    </div>);
}
