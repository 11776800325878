import React, {Component} from "react";
import ReactPaginate from "react-paginate";
import {Helmet} from "react-helmet";
import {PaymentEventsTable} from "./payment_events_table";
import {V1PaymentEvent} from "./telemetry/vending_machine/v1/typescript-axios";
import {vmcpOrderServiceApi} from "./api";

interface Props {

}

interface State {
    paymentEvents: Array<V1PaymentEvent>
    page: number
    pageCount: number
    perPage: number
    receiptId: string
}

export class PaymentEvents extends Component<Props, State> {
    constructor(props) {
        super(props);

        this.state = {
            paymentEvents: [],
            page: 0,
            perPage: 10,
            pageCount: 0,
            receiptId: "",
        };

        this.loadPaymentEvents(this.state.page, this.state.receiptId);
    }


    loadPaymentEvents(page, receiptId) {
        if (receiptId !== "") {
            new vmcpOrderServiceApi().vmcpOrderServiceSearchPaymentEvents(page, this.state.perPage, this.state.receiptId).then(res => {
                this.setState({
                    paymentEvents: res.data.paymentEvents ?? [],
                    pageCount: Math.ceil(res.data.total as number / this.state.perPage)
                })
            })
        } else {
            new vmcpOrderServiceApi().vmcpOrderServiceListPaymentEvents(page, this.state.perPage).then(res => {
                this.setState({
                    paymentEvents: res.data.paymentEvents ?? [],
                    pageCount: Math.ceil(res.data.total as number / this.state.perPage)
                })
            })
        }
    }

    handleReceiptIdChange(event: any) {
        this.setState({receiptId: event.target.value.trim()})
    }

    handleSearch(event: any) {
        event.preventDefault()
        this.setState({
            page: 0,
        })
        this.loadPaymentEvents(0, this.state.receiptId)
    }


    handlePageClick(selectedItem: { selected: number }): void {
        console.log("Selected: " + selectedItem.selected);
        this.setState({
            page: selectedItem.selected
        });
        this.loadPaymentEvents(selectedItem.selected, this.state.receiptId)
    }

    render() {
        return (
            <div>
                <Helmet titleTemplate="%s - Vending on Track">
                    <title>Payment Events</title>
                </Helmet>
                <section className="content">
                    <div className={"box statsBox"}>
                        <div className={"box-header"}>
                            <h1>
                                Payment Events
                            </h1>
                        </div>
                        <div className={"box-body"}>
                            <div>
                                <form className="form-inline">
                                    <div className="form-group">
                                        <label htmlFor="floatingInput" className={"textSecondary"}
                                               style={{marginRight: "5px"}}>ReceiptId: </label>
                                        <input type="text" className="form-control" value={this.state.receiptId}
                                               placeholder={"enter ReceiptId"}
                                               onChange={this.handleReceiptIdChange.bind(this)}/>
                                    </div>
                                    <button className="btn btn-success" onClick={this.handleSearch.bind(this)}>Search
                                    </button>
                                </form>
                            </div>
                            <br/>

                            <PaymentEventsTable paymentEvents={this.state.paymentEvents}/>

                            <ReactPaginate
                                pageRangeDisplayed={2}
                                marginPagesDisplayed={2}
                                pageCount={this.state.pageCount}
                                onPageChange={this.handlePageClick.bind(this)}
                                containerClassName={"pagination pagination-sm no-margin"}
                                activeClassName={'active'}
                                forcePage={this.state.page}
                            />
                        </div>
                    </div>
                </section>
            </div>
        );
    }

}