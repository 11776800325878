import React, {Component} from 'react';
import {postAPI} from "./api";
import {Redirect} from "react-router-dom";
import {IAuthenticationContext, withAuthentication} from "./authentication";

interface Props extends IAuthenticationContext{
}

interface State {
    company_name: string
    finished: boolean
    message: string | null
}

class _StartCompanyPanel extends Component<Props, State> {
    constructor(props) {
        super(props);

        this.state = {
            company_name: "",
            finished: false,
            message: null
        }
    }

    handleCompanyNameChange(event) {
        event.preventDefault();

        this.setState({company_name: event.target.value});
    }

    handleSubmit(e) {
        e.preventDefault();
        postAPI("/api/companies", {
            name: this.state.company_name
        }).then((result) => {
            if (result.data.companyId) {
                localStorage.setItem("hasCompany", "true");
                this.props.login(localStorage.getItem("authentication_token")!,
                    localStorage.getItem("email")!,
                    localStorage.getItem("isApproved")! === 'true',
                    true, false )
                this.setState({finished: true});
            } else {
                this.setState({message: result.data.message})
            }
        });

        return false;
    }

    render() {
        if (this.state.finished) {
            return (<Redirect to="/"/>);
        }

        return (

            <div className={"start-a-company"}>
                <h2>Start a Company</h2>

                <p>{this.state.message}</p>

                <form onSubmit={this.handleSubmit.bind(this)}>
                    <div className="form-group">
                        <label>Company Name</label>

                        <input className="form-control" value={this.state.company_name}
                               onChange={this.handleCompanyNameChange.bind(this)}/>
                    </div>

                    <input type="submit" className="btn btn-success" value="Create a company"/>
                </form>
            </div>
        );
    }
}

const StartCompanyPanel = withAuthentication(_StartCompanyPanel);

export {StartCompanyPanel};