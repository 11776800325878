import React, {Component} from "react";
import DatePicker from 'react-datepicker';
import {IGlobalDataContext, withGlobalData} from "./contexts/global-data";
import moment from "moment";
import {postAPI} from "./api";
import {Helmet} from "react-helmet";
import {DisplayTime} from "./display_time";
import {FormatMoney} from "./format_money";
import {Product, ProductMovement} from "./models";
import Select from "react-select";
import {VendingMachineLink} from "./components/vending_machine_link";
import {CSVLink} from 'react-csv';
import VendingMachineGroupSelector from "./vending_machine_group_selector";
import {handleTreeSelection, TreeItem} from "./utils/tree";
import formatUtilPrice from "./format_until_price";



interface Props extends IGlobalDataContext {

}

interface VendingMachineOption {
    value: string
    label: string
}

interface State {
    fromDate: Date
    toDate: Date
    reportLoading: boolean
    selectedVendingMachines: Array<VendingMachineOption>

    movements: Array<ProductMovement>
    productAggregate: boolean
}

interface AggregatedProductMovement {
    product: Product | null
    aggregatedQuantity: number
    unitPriceCents: number
}

class ProductMovementsReportRaw extends Component<Props, State> {
    constructor(props) {
        super(props);

        this.state = {
            selectedVendingMachines: [],

            fromDate: new Date(),
            toDate: new Date(),
            reportLoading: false,
            movements: [],
            productAggregate: false,
        }
    }

    report(e) {
        e.preventDefault();
        e.stopPropagation();

        const begin = moment(this.state.fromDate).startOf('day');
        const end = moment(this.state.toDate).endOf('day');

        console.log("Begin: " + begin.format());
        console.log("End: " + end.format());

        this.setState({reportLoading: true});

        postAPI(`/api/eod/report/product-movements`, {
            startEpoch: begin.unix(),
            endEpoch: end.unix(),
            vendingMachines: this.state.selectedVendingMachines.map((vm) => {
                return vm.value
            }),
        }).then((resp) => {
            this.setState({reportLoading: false});
            if (resp.data.success) {
                this.setState({
                    movements: resp.data.movements,
                })
            } else {
                window.alert(resp.data.message);
            }
        });
        return false;


    }

    fromDateChange(date) {
        this.setState({fromDate: date})
    }

    toDateChange(date) {
        this.setState({toDate: date})
    }


    selectVendingMachines(options) {
        let vms: Array<VendingMachineOption> = [];

        if (options) {
            options.forEach((selection) => {
                const vm = this.props.findVendingMachine(selection.value);
                if (vm) {
                    vms.push({label: vm.name, value: vm.uuid});
                }
            });

            this.setState({
                selectedVendingMachines: vms
            });
        }
    }

    handleProductAggregateToggle(e: any) {
        this.setState({productAggregate: !!e.target.checked});
    }

    _handleTreeSelection(treeNode: TreeItem):Promise<boolean>{
        const selectedVM = handleTreeSelection(treeNode, this.props.findVendingMachine.bind(this));
        this.setState({selectedVendingMachines: selectedVM})
        return Promise.resolve(true);
    }

    render() {
        let dateFormat = moment().locale(this.props.me.localeISO).localeData().longDateFormat("L").replace("DD", "dd").replace("YYYY", "yyyy");

        const vendingMachines = this.props.allVendingMachines.allVendingMachines.map((vm) => {
            return {
                value: vm.uuid,
                label: vm.name
            }
        })

        const rows = this.state.movements.map((movement: ProductMovement, idx) => {
            const p = this.props.findProduct("" + movement.productID);
            const vendingMachine = this.props.findVendingMachine(movement.vendingMachineUUID)

            return <tr key={idx}>
                <td><DisplayTime timestamp={movement.epochSecond} expanded={true}/></td>
                <td><FormatMoney cents={movement.cents}/></td>
                <td>
                    {vendingMachine ?
                        <VendingMachineLink vendingMachine={vendingMachine}/>
                        :
                        movement.vendingMachineName
                    }
                </td>
                <td>{p ? p.name : movement.productID} [{movement.productID}]</td>
                <td>{movement.quantity}</td>
            </tr>
        });

        let groups = [] as Array<AggregatedProductMovement>;
        this.state.movements.forEach((movement) => {
            // debugger;
            const idx = groups.findIndex((group) => {
                return ("" + group.product?.id) === ("" + movement.productID)
            })
            if (idx === -1) {
                groups.push({
                    product: this.props.findProduct("" + movement.productID),
                    aggregatedQuantity: movement.quantity,
                    unitPriceCents: movement.cents
                })
            } else {
                const group = groups[idx];
                group.aggregatedQuantity += movement.quantity;
                groups[idx] = group;
            }
        })

        const aggregatedRows = groups.map((group, idx) => {
            return <tr key={idx}>
                <td><FormatMoney cents={group.unitPriceCents}/></td>
                <td>{group.product ? group.product.name : "-"} [{group.product?.id}]</td>
                <td>{group.aggregatedQuantity}</td>
            </tr>
        })

        const headers = [
            {label: 'Time', key: 'time'},
            {label: 'Time (Readable)', key: 'time-human'},
            {label: "Unit Price", key: "unit-price"},
            {label: 'Vending Machine', key: 'vm'},
            {label: 'Product', key: 'product'},
            {label: 'Quantity', key: 'quantity'},
        ];

        const csvEntries = this.state.movements.map((movement) => {
            return {
                "time": movement.epochSecond,
                "time-human": moment.unix(movement.epochSecond).toString(),
                "unit-price": formatUtilPrice (movement.cents, this.props.me.mostRecentCompany.currencyDecimalPoints),
                "vm": movement.vendingMachineName,
                "product": this.props.findProduct("" + movement.productID)?.name,
                "quantity": movement.quantity
            }
        })


        const aggregatedHeaders = [
            {label: 'Unit Price', key: 'unit-price'},
            {label: 'Product', key: 'product'},
            {label: 'Aggregated Quantity', key: 'quantity'},
        ];

        const aggregatedCSVEntries = groups.map((group) => {
            return {
                "unit-price": formatUtilPrice (group.unitPriceCents,this.props.me.mostRecentCompany.currencyDecimalPoints),
                "product": (group.product ? group.product.name : "-") + " - " + group.product?.id,
                "quantity": group.aggregatedQuantity
            }
        })

        return (
            <div>
                <Helmet titleTemplate="%s - Vending on Track">
                    <title>Product Movements Report</title>
                </Helmet>
                <section className="content-header">
                    <h1>
                        Product Movements Report
                    </h1>
                    <div className={"alert alert-info"}>
                        Note: this is only for DEX-based machines.
                    </div>
                </section>
                <section className="content">
                    <div className={"box box-success"}>
                        <div className={"box-body with-border"}>

                            <div className="form-group">
                                <label className={"inline-label"} >Please choose machines to run report: </label> &nbsp; &nbsp;
                                <div className={"inline-div"}>
                                    <VendingMachineGroupSelector
                                        onSelectedGroup={this._handleTreeSelection.bind(this)}
                                        button={<button className={"btn btn-default"}>select</button>}
                                        selectGroup={true}
                                    />
                                </div>
                                <br/>
                                <Select
                                    defaultValue={[]}
                                    value={this.state.selectedVendingMachines}
                                    isMulti
                                    onChange={this.selectVendingMachines.bind(this)}
                                    options={vendingMachines}
                                />
                            </div>

                            <div className="form-group">
                                <label>From: </label>
                                <DatePicker className="form-control"
                                            selected={this.state.fromDate}
                                            onChange={this.fromDateChange.bind(this)} dateFormat={dateFormat}/>
                            </div>
                            <div className="form-group">
                                <label>To: </label>
                                <DatePicker className="form-control"
                                            selected={this.state.toDate}
                                            onChange={this.toDateChange.bind(this)} dateFormat={dateFormat}/>
                            </div>

                            <div className="form-group">
                                <button onClick={this.report.bind(this)} className="btn btn-success">Generate report
                                </button>
                            </div>
                        </div>
                    </div>
                    {this.state.reportLoading &&
                    <div className={"box box-success"}>
                        <div className={"box-header"}>
                            <h1>Loading ...</h1>
                        </div>
                    </div>
                    }
                    {!this.state.reportLoading && this.state.movements.length !== 0 &&
                    <div className="box box-success">
                        <div className={"box-header"}>
                            <h1>Report</h1>
                        </div>
                        <div className="box-body">
                            <div className="form-group">
                                <label>Aggregate on Product
                                    &nbsp;
                                    <input type="checkbox"
                                           checked={this.state.productAggregate}
                                           onChange={this.handleProductAggregateToggle.bind(this)}/>
                                </label>
                            </div>

                            {this.state.productAggregate ?
                                <div>
                                    <table className="table table-bordered">
                                        <thead>
                                        <tr>
                                            <th>Unit Price</th>
                                            <th>Product</th>
                                            <th>Aggregated Quantity</th>
                                        </tr>
                                        </thead>
                                        <tbody>
                                        {aggregatedRows}
                                        </tbody>
                                    </table>
                                    <hr/>

                                    <CSVLink
                                        headers={aggregatedHeaders}
                                        data={aggregatedCSVEntries}
                                        filename={"ProductMovementsReport-aggregated.csv"}
                                    >
                                        Download as CSV
                                    </CSVLink>
                                </div>
                                :
                                <div>
                                    <table className="table table-bordered">
                                        <thead>
                                        <tr>
                                            <th>Date & Time</th>
                                            <th>Unit Price</th>
                                            <th>Vending Machine</th>
                                            <th>Product</th>
                                            <th>Quantity</th>
                                        </tr>
                                        </thead>
                                        <tbody>
                                        {rows}
                                        </tbody>
                                    </table>
                                    <hr/>

                                    <CSVLink
                                        headers={headers}
                                        data={csvEntries}
                                        filename={"ProductMovementsReport.csv"}
                                    >
                                        Download as CSV
                                    </CSVLink>
                                </div>
                            }
                        </div>

                    </div>
                    }
                </section>
            </div>
        )
    }

}

const ProductMovementsReport = withGlobalData(ProductMovementsReportRaw);
export default ProductMovementsReport;
