import React, {Component} from 'react';
import {IAuthenticationContext, withAuthentication} from "./authentication";
import {Redirect} from "react-router-dom";
interface Props extends IAuthenticationContext {
}

class _WaitForApproval extends Component<Props>{
    render(){
        console.log("wait for approval page")
        console.log(this.props.authentication)
        if (this.props.authentication.isApproved){
            return (<Redirect to={"/"}/>);
        } else {
            return(
                <div className={"login-box"}>
                    <div className={"login-logo"}>
                        Vending on Track
                    </div>
                    <div className={"login-box-body"}>
                        <p className={"login-box-msg"}>Your account is waiting for administrator's approval </p>
                    </div>
                    <div className="row">
                        <div className="col-xs-2">
                        </div>
                        <div className="col-xs-8">
                            <button className="btn btn-primary btn-block btn-flat" onClick={()=>{
                                this.props.logout()
                                window.location.href='/login'
                            }}>Logout</button>
                        </div>
                    </div>
                </div>
            )
        }
    }
}
let WaitForApproval = withAuthentication(_WaitForApproval);

export {WaitForApproval};
