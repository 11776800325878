import React, {Component, Fragment} from "react";
import {Typeahead} from 'react-bootstrap-typeahead';
import {withAuthentication} from "./authentication";
import {VendingMachine} from "./models";
import {getAPI} from "./api";
import {TelemetryDeviceButtons} from './telemetry_device_buttons';

import {IGlobalDataContext, withGlobalData} from "./contexts/global-data";
import {Helmet} from "react-helmet";

interface Props extends IGlobalDataContext {
    match: any
    history: any
}

interface State {
    // vending machines for speed buttons
    shortcuts: Array<VendingMachine>
}

class _EasyButtons extends Component<Props, State> {
    constructor(props) {
        super(props);

        this.state = {
            shortcuts: [],
        }
    }

    componentDidMount(): void {
        getAPI('/api/vending_machines/refills_shortcuts').then((result) => {
            if (result.data.success) {
                this.setState({shortcuts: result.data.vendingMachines});
            } else {
                window.alert(result.data.message);
            }
        })
    }

    render() {
        const handleSelection = this.handleSelection.bind(this);

        const shortcuts = this.state.shortcuts.map((vendingMachine: VendingMachine, idx: number) => {
            return (
                <div key={`${vendingMachine.uuid}-${idx}`} style={{padding: "10px"}}>
                    <button className="btn btn-success btn-lg" onClick={() => {
                        handleSelection([vendingMachine]);
                    }}>
                        {vendingMachine.name}
                    </button>
                </div>
            );
        });

        let buttons: any = null;

        let vendingMachine: VendingMachine | null = null;

        const labeledVendingMachines = this.props.allVendingMachines.allVendingMachines.map((vendingMachine: VendingMachine) => {
            const label = vendingMachine.name + ' - ' + vendingMachine.address;

            return {label: label, ...vendingMachine}
        });

        if (this.props.match.params.uuid) {
            labeledVendingMachines.forEach((vm: VendingMachine) => {
                if (vm.uuid === this.props.match.params.uuid) {
                    vendingMachine = vm;
                }
            });

            if (vendingMachine != null) {
                buttons = (
                    <Fragment>
                        <hr/>
                        <TelemetryDeviceButtons vendingMachine={vendingMachine}/>
                    </Fragment>
                );
            }
        }

        return (
            <Fragment>
                <Helmet titleTemplate="%s - Vending on Track">
                    <title>Buttons</title>
                </Helmet>
                <section className="content-header">
                    <h1>
                        Machine / Telemetry Device
                    </h1>
                </section>
                <section className="content">
                    <Typeahead id={1}
                               selected={vendingMachine == null ? [] : [vendingMachine]}
                               placeholder={"Please type or click select"}
                               onChange={this.handleSelection.bind(this)}
                               labelKey={"label"}
                               options={labeledVendingMachines}/>

                    {vendingMachine ?
                        <div>
                            {buttons}
                        </div>
                        :
                        <div>
                            {shortcuts}
                        </div>
                    }
                </section>
            </Fragment>
        );
    }

    handleSelection(vms: Array<VendingMachine> | null) {
        if ((vms == null) || (vms.length === 0)) {
            this.props.history.push(`/buttons`)
        } else {
            this.props.history.push(`/buttons/${vms[0].uuid}`)
        }
    }
}

export const EasyButtons = withAuthentication(withGlobalData(_EasyButtons));
