import React, {Component, Fragment} from "react";
import {withAuthentication} from "./authentication";
import DataTable from 'react-data-table-component';
import {ProductMapping, VendingMachine} from "./models";
import {DisplayTime} from "./display_time";
import {postAPI} from "./api";
import {Link, Redirect} from "react-router-dom";
import {IGlobalDataContext, withGlobalData} from "./contexts/global-data";
import {FormatMoneyWithFlexibleSignSize} from "./format_money_without_style";
import {Helmet} from "react-helmet";


interface Props extends IGlobalDataContext {
}

interface State {
    redirect: string | null
}

class _PickingsPage extends Component<Props, State> {
    selectedRows: Array<VendingMachine>;

    constructor(props) {
        super(props);

        this.state = {
            redirect: null,
        };

        this.selectedRows = [];
    }


    render() {
        if (this.state.redirect) {
            return (<Redirect to={this.state.redirect}/>);
        }

        let vendingMachines = this.props.allVendingMachines.allVendingMachines.map((vendingMachine) => {
            let missing = 0;
            if ((vendingMachine.standardStockCount != null) && (null != vendingMachine.currentStockCount)) {
                missing = vendingMachine.standardStockCount - vendingMachine.currentStockCount;
            }

            let lowestStockMissing = 0;
            if (vendingMachine.lowestStock != null) {
                const lowestStock = vendingMachine.lowestStock;
                if ((lowestStock.currentStock != null) && (lowestStock.restock != null)) {
                    const missing = lowestStock.restock - lowestStock.currentStock;
                    lowestStockMissing = missing;
                }
            }

            return {
                ...vendingMachine,
                missing: missing,
                lowestStockMissing: lowestStockMissing
            }
        });

        const columns = [
            {
                name: "Name",
                selector: "name",
                sortable: true,
                cell: (vendingMachine: VendingMachine)=>{
                    return <span>{vendingMachine.name}</span>
                }
            },
            {
                name: "Address",
                selector: "address",
                sortable: true,
                cell: (vendingMachine: VendingMachine)=>{
                    return <span>{vendingMachine.address}</span>
                }
            },
            {
                name: "Today's Revenue",
                sortable: true,
                selector: "todayRevenue",
                format: (vendingMachine: VendingMachine) => {
                    return <FormatMoneyWithFlexibleSignSize cents={vendingMachine.todayRevenue}/>;
                }
            },
            {
                name: "Last Refill Time",
                sortable: true,
                selector: "lastRefill.createdTimestamp",
                format: ((vendingMachine: VendingMachine) => {
                    return vendingMachine.lastRefill ?
                        (<DisplayTime timestamp={vendingMachine.lastRefill.createdTimestamp}/>) : null
                })
            },
            {
                name: "Lowest Stock",
                sortable: true,
                selector: "lowestStockMissing",
                format: ((vendingMachine) => {
                    if (vendingMachine.lowestStock == null) {
                        return '-';
                    }
                    const productMapping: ProductMapping = vendingMachine.lowestStock;
                    return `${productMapping.product_name} - ${productMapping.currentStock}/${productMapping.restock} `;
                })
            },
            {
                name: "Stock Level",
                sortable: true,
                selector: "missing",
                format: ((vendingMachine) => {
                    return `${vendingMachine.currentStockCount}/${vendingMachine.standardStockCount}`;
                })
            },
            {
                name: "Online/Offline",
                sortable: true,
                selector: "online",
                format: ((vendingMachines: VendingMachine) => {
                    if (vendingMachines.online) {
                        return (<span><span style={{color: "green"}}
                                            className={"fa fa-circle"}>&nbsp;</span> Online</span>);
                    } else {
                        return (<span><span style={{color: "grey"}}
                                            className={"fa fa-circle"}>&nbsp;</span> Offline</span>);
                    }
                })
            }
        ];

        return (
            <Fragment>
                <Helmet titleTemplate="%s - Vending on Track">
                    <title>Pickings</title>
                </Helmet>
                <section className="content-header">
                    <h1>
                        Pickings <small><Link to="/pickings/recent">Recent Pick lists</Link></small>
                    </h1>
                </section>
                <section className="content">
                    <div className="box">
                        <div className={"box-header"}>
                            <h2>Prekitting for vending machines</h2>
                        </div>
                        <div className="box-body">
                            <button className={"btn btn-success"} onClick={this.generatePickingList.bind(this)}>
                                Generate Picking List
                            </button>
                            <hr/>
                            <DataTable
                                selectableRows={true}
                                highlightOnHover={true}
                                pointerOnHover={true}
                                defaultSortField="name"
                                title={"Please select"}
                                columns={columns}
                                data={vendingMachines}
                                onSelectedRowsChange={this.handleTableUpdate.bind(this)}
                            />
                        </div>
                    </div>
                </section>
            </Fragment>
        );
    }

    handleTableUpdate(state) {
        this.selectedRows = state.selectedRows;
    }

    generatePickingList(event) {
        event.preventDefault();

        console.log(this.selectedRows);

        postAPI(`/api/prekittings`, {
            selected: this.selectedRows.map((vendingMachine) => {
                return vendingMachine.uuid;
            })
        }).then((response) => {
            if (response.data.success) {
                this.setState({redirect: "/pickings/recent"});
            } else {
                window.alert(response.data.message);
            }
        });

        return false;
    }
}


export const PickingsPage = withGlobalData(withAuthentication(_PickingsPage));
