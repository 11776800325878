import React, {Fragment, useEffect, useState} from 'react';
import {VendingMachine} from "./models";
import SortableTree from 'react-sortable-tree';
import formATree, {TreeItem} from "./utils/tree";
import {IGlobalDataContext, withGlobalData} from "./contexts/global-data";
import TreeVendingMachineDisplay from "./tree_vending_machine_display";

interface Props extends IGlobalDataContext{
    skipGroupUuids?: Array<string>
    onSelectedGroup?(node:TreeItem):Promise<boolean>
    onSelectedVendingMachine?(vm:VendingMachine):Promise<boolean>
    button: React.ReactNode
    selectGroup: boolean
}

function VendingMachineGroupSelector(props:Props) {
    const [isExpend, setIsExpend] = useState<boolean>(false);
    const [tree, setTree] = useState<TreeItem[]>([]);
    const [treeNodeVendingMachines, setTreeNodeVendingMachines] = useState<VendingMachine[]>([]);
    const [isTreeNodeVendingMachinesModalOpen, setIsTreeNodeVendingMachinesModalOpen] = useState<boolean>(false);

    useEffect(()=>{
        const treeData = formATree(props.allVendingMachineGroups)
        setTree(treeData);
    }, [props.allVendingMachineGroups])

    const expendWidget = ()=>{
        setIsExpend(true)
    }

    const collapseWidget = ()=>{
        setIsExpend(false)
    }

    const toggleVendingMachineGroupSelector = ()=>{
        if (isExpend){
            collapseWidget();
        } else{
            expendWidget();
        }
    }


    const onModalClose = () => {
        setIsTreeNodeVendingMachinesModalOpen(false);
        setTreeNodeVendingMachines([])
    }

    const handleTreeSelection = (treeNode: TreeItem):Promise<boolean> => {
        const vms = treeNode.vendingMachineUuids.filter((uuid)=>{
            return props.findVendingMachine(uuid) !== null;

        }).map((uuid)=>{
            return props.findVendingMachine(uuid)!
        })
        setTreeNodeVendingMachines(vms);
        setIsTreeNodeVendingMachinesModalOpen(true)
        return Promise.resolve(true);
    }

    return (
        <Fragment>
            <TreeVendingMachineDisplay
                isModalOpen={isTreeNodeVendingMachinesModalOpen}
                vendingMachines={treeNodeVendingMachines}
                onModalClose={onModalClose}
                onSelected={props.onSelectedVendingMachine ?? ((vm)=>{console.log("missing on selected vending machine function"); return Promise.reject(false)})}/>
            <h4 className={"inline-div"}><i className="fa fa-sitemap fa-lg" onClick={toggleVendingMachineGroupSelector}></i></h4>
            {
                isExpend && <div>
                    <SortableTree
                        canDrag={false}
                        treeData={tree}
                        onChange={treeData => setTree(treeData)}
                        isVirtualized={false}
                        generateNodeProps={row=>({
                            buttons:[
                                <div className={"pull-right center-block"}>
                                    {
                                        !(props.skipGroupUuids ?? []).includes(row.node.uuid) && <div onClick={()=>{
                                                if (props.selectGroup){
                                                    if (props.onSelectedGroup){
                                                        props.onSelectedGroup(row.node).then((success)=>{
                                                            if (success){
                                                                collapseWidget();
                                                            }
                                                        })
                                                    } else {
                                                        console.log("missing on selected group function");
                                                    }
                                                } else {
                                                    handleTreeSelection(row.node);
                                                }
                                        }}>{props.button}</div>
                                    }
                                </div>
                            ]
                        })}
                    />
                </div>
            }
        </Fragment>
    );
}

export default withGlobalData(VendingMachineGroupSelector);