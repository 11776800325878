import React, {Component} from 'react';
import {getAPI, postAPI} from './api';
import {IAuthenticationContext, withAuthentication} from './authentication';
import {Redirect} from "react-router-dom";

interface Props extends IAuthenticationContext {
}

interface State {
    email: string,
    password: string,
    name:string,
    phone:string,
    in_progress: boolean,
    error_message: null,
}

class _CompanyUserSignUpPage extends Component<Props, State> {
    constructor(props) {
        super(props);

        this.state = {
            email: "",
            password: "",
            name:"",
            phone:"",
            in_progress: false,
            error_message: null,
        };

        this.handlePasswordChange = this.handlePasswordChange.bind(this);
        this.handlePhoneChange = this.handlePhoneChange.bind(this);
        this.handleSubmit = this.handleSubmit.bind(this);
    }

    componentDidMount() {
        const sessionToken = new URLSearchParams(window.location.search).get("session_token")
        getAPI(`/api/company_user_nomination/signup?sessionToken=${sessionToken}`).then((response) => {
            if (response.data.success) {
                this.setState({
                    email: response.data.driverSignUpInvitationInfo.email,
                    name: response.data.driverSignUpInvitationInfo.company,
                })
            }else {
                this.setState({error_message:response.data.message});
            }
        })
    }

    handlePasswordChange(event) {
        this.setState({password: event.target.value});
    }

    handlePhoneChange(event) {
        this.setState({phone: event.target.value})
    }

    handleSubmit(event) {
        event.preventDefault();
        this.setState({in_progress: true});
        const sessionToken = new URLSearchParams(window.location.search).get("session_token");
        postAPI("/api/company_user_nomination/signup", { password: this.state.password, phone:this.state.phone, sessionToken:sessionToken})
            .then(result => {
                this.setState({in_progress: false});
                return result.data;
            })
            .then((result) => {
                if (result.success) {
                    // success
                    this.props.login(result.securityToken, result.email, true, true, false);
                } else {
                    this.setState({error_message: result.reason});
                }
            })
    }

    render() {
        if (this.props.authentication?.authenticated) {
            return <Redirect to="/"/>;
        }

        let busy = (
            <div className="busyIndicator">
                <h1>
                    Please wait... <span className="fa fa-spinner fa-spin">&nbsp;</span>
                </h1>
            </div>
        );

        let error_message = this.state.error_message ?
            (<div className="alert alert-danger">{this.state.error_message}</div>)
            : null;

        let signup_form = (

            <div className="login-box">

                <div className="login-logo">
                    Vending on Track
                </div>
                <div className="login-box-body">
                    <p className="login-box-msg">Sign up for Vending on Track</p>


                    {error_message}

                    <form onSubmit={this.handleSubmit} className="form">


                        <div className="form-group has-feedback">
                            <input type="email" value={this.state.email}
                                   className="form-control" placeholder="Email" disabled={true}/>
                            <span className="fas fa-envelope form-control-feedback"></span>
                        </div>

                        <div className="form-group has-feedback">
                            <input type="text" value={this.state.name}
                                   className="form-control" placeholder="Company Name" disabled={true}/>
                            <span className="fas fa-user form-control-feedback"></span>
                        </div>
                        <div className="form-group has-feedback">
                            <input type="password" className="form-control" value={this.state.password}
                                   onChange={this.handlePasswordChange} placeholder="Password"/>
                            <span className="fas fa-lock form-control-feedback"></span>
                        </div>
                        <div className="form-group has-feedback">
                            <input type="text" value={this.state.phone}
                                   onChange={this.handlePhoneChange} className="form-control" placeholder="Phone"/>
                            <span className="fas fa-phone-alt form-control-feedback"></span>
                        </div>

                        <div className="row">
                            <div className="col-xs-2">
                            </div>
                            <div className="col-xs-8">
                                <input type="submit" className="btn btn-primary btn-block btn-flat" value="Sign Up"/>
                            </div>
                        </div>
                    </form>
                </div>
            </div>
        );

        return (
            <div className="container">
                {(this.state.in_progress) ? busy : signup_form}
            </div>
        )
    }
}

const CompanyUserSignUpPage = withAuthentication(_CompanyUserSignUpPage);
export {CompanyUserSignUpPage};