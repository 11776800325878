import React from "react";
import {VendingMachine} from "./models";

interface Props {
    vendingMachine: VendingMachine
}

export default function FillLevel(props: Props) {
    if (props.vendingMachine.fillLevel === null) {
        return <span>Fill Level:  -</span>;
    } else {
        return (<span>Fill Level: {props.vendingMachine.fillLevel}%</span>)
    }
}

