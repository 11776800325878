import React, {Component} from 'react';
import {Product} from "./models";
import {Bar, Pie} from "react-chartjs-2";
import {formatMoneyWithSymbol} from "./helpers";
import moment from "moment";
import 'moment/min/locales';

interface DailyRevenue {
    totalRevenue: number,
    totalSalesCount: number,
}

interface ProductRevenue {
    product: Product,
    dateSalesData: { [date: string]: DailyRevenue }
}

interface ProductRevenueReport {
    fromDate: string
    toDate: string
    productSalesInformationList: Array<ProductRevenue>
}

interface Props {
    products: Array<Product>
    report: ProductRevenueReport
    decimalPoints: number
    currencySymbol: string
    locale:string
}

interface State {
    selectedProduct:Product[]
    dates:string[] | null,
    data:number[][] | null
}

export class ProductRevenueReportBarchart extends Component<Props, State> {
    constructor(props) {
        super(props);
        this.state = {
            selectedProduct:props.products,
            dates:null,
            data:null
        }
    }

    componentDidMount() {
        this.formatData(this.props.report)
    }

    formatData(productRevenueReport){
        const dates = Object.keys(productRevenueReport.productSalesInformationList[0].dateSalesData);
        const datesDisplay = dates.map((date)=>{
            return moment(date).locale(this.props.locale).format("LL")
        });
        let dataList:number[][] = [];
        const columns = productRevenueReport.productSalesInformationList;
        columns.forEach((productSalesInformation, index) =>{
            dataList.push([]);
            dates.forEach((date) => {
                dataList[index].push(productSalesInformation.dateSalesData[date].totalRevenue)
            })
        })
        this.setState({dates:datesDisplay, data:dataList})
    }
    render() {
        const colors = ["#45b1d6", "#32d672", "#d62a3f", "#7642d6", "#2c4cd6", "#c93a1c"]
        const decimalPoints = this.props.decimalPoints;
        const currencySymbol = this.props.currencySymbol;


        const barOptions = {
            responsive: true,
            legend: {
                display: true
            },
            type: "bar",
            tooltips: {
                callbacks: {
                    label: function(t, d) {
                        return d.datasets[t.datasetIndex].label + ': ' + formatMoneyWithSymbol(t.value, decimalPoints, currencySymbol);
                    }
                }
            },
            scales: {
                yAxes: [{
                    ticks: {
                        callback: function(value, index, values) {
                            return formatMoneyWithSymbol(value, decimalPoints, currencySymbol);
                        }
                    }
                }]
            }
        }
        let databar = {}
        databar['labels'] = this.state.dates
        databar['datasets'] = []
        this.state.data?.forEach((item, index)=>{
            databar['datasets'].push({
                data:item,
                label:this.state.selectedProduct[index].name,
                backgroundColor: colors[index%colors.length]})
        })

        const pieOptions = {
            responsive: true,
            legend: {
                display: true
            },
            type: "pie",
            tooltips: {
                callbacks: {
                    label: function(t, d) {
                        const xLabel = d.labels[t.index];
                        const yLabel = formatMoneyWithSymbol(d.datasets[0].data[t.index], decimalPoints, currencySymbol);
                        return xLabel + ': ' + yLabel;
                    }
                }
            }
        }

        let datapie = {}
        datapie['labels'] = this.state.selectedProduct.map((product)=>{
            return product.name
        })
        datapie['datasets'] = [{data:[], backgroundColor:[]}]
        const reducer = (accumulator, currentValue) => accumulator + currentValue;
        this.state.data?.forEach((item, index)=>{
            datapie['datasets'][0].data.push(item.reduce(reducer))
            datapie['datasets'][0].backgroundColor.push(colors[index%colors.length])
        })


        return (
            <div className={"container"}>
                <div className={"row"}>
                    <div className={"col-xs-6"}>
                        <Bar data={databar} options={barOptions} />
                    </div>
                    <div className={"col-xs-6"}>
                        <Pie data={datapie} options={pieOptions}/>
                    </div>
                </div>
            </div>
        )
    }
}
