import React, {Component} from 'react';
import {withAuthentication} from './authentication';

import {StartCompanyPanel} from './start_company_panel';
import {JoinCompanyPanel} from './join_company_panel';
import {Redirect} from "react-router-dom";
import {Authentication} from "./models";


interface Props {
    authentication: Authentication
}

interface State {
    panel: string
}


class _Join extends Component<Props, State> {
    constructor(props) {
        super(props);

        this.state = {
            'panel': 'join'
        }
    }

    join_company() {
        this.setState({'panel': 'join'});
    }

    start_company() {
        this.setState({'panel': 'start'});
    }

    render() {
        if (this.props.authentication.authenticated) {
            // doing nothing
        } else {
            // redirect to login
            return (<Redirect to="/login"/>);
        }

        let panel: any = null;

        if (this.state.panel === 'join') {
            panel = (<JoinCompanyPanel/>);
        } else {
            panel = (<StartCompanyPanel/>);
        }
        return (
            <div className="container">
                <h1>Join a company or start one</h1>

                <button className="btn btn-success" onClick={this.join_company.bind(this)}>Join a
                    Company
                </button>
                &nbsp; &nbsp;
                <button className="btn btn-success" onClick={this.start_company.bind(this)}>Start a Company</button>

                {panel}
            </div>);
    }
}

const Join = withAuthentication(_Join);

export {Join};
