import React, {Component, Fragment} from 'react';
import {Helmet} from "react-helmet";
import {getAPI, postAPI} from "./api";
import {CreditCardSurchargeGroup, CreditCardSurchargeRule} from "./models";
import {IGlobalDataContext, withGlobalData} from "./contexts/global-data";

interface Props extends IGlobalDataContext {
    match:any
}

interface State {
    showAddRuleDialog: boolean
    rules: CreditCardSurchargeRule[]
    ruleGroup:CreditCardSurchargeGroup | null,
    newUpboundPrice: number
    newFixedFee: number
    newPercentageFee: number
}

class _CompanyCreditCardSurchargeGroupPage extends Component<Props, State> {
    constructor(props) {
        super(props);

        this.state = {
            showAddRuleDialog: false,
            rules: [],
            ruleGroup: null,
            newUpboundPrice: 0,
            newFixedFee: 0,
            newPercentageFee: 0,
        }
    }

    componentDidMount(): void {
        this.loadRuleGroup()
    }


    loadRuleGroup() {
        getAPI(`/api/credit_card_surcharge_group/${this.props.match.params.uuid}`).then((response) => {
            if (response.data.success) {
                this.setState({ruleGroup: response.data.creditCardSurchargeGroup})
            }
        })

        getAPI(`/api/credit_card_surcharge_group/${this.props.match.params.uuid}/credit_card_surcharge_rule`).then((response) => {
            if (response.data.success) {
                this.setState({rules: response.data.creditCardSurchargeRules})
            }
        })
    }

    handleAddRule(e) {
        e.preventDefault();
        postAPI(`/api/credit_card_surcharge_group/${this.props.match.params.uuid}/credit_card_surcharge_rule`,
            {
                upboundPrice: this.state.newUpboundPrice,
                fixedFee: this.state.newFixedFee,
                percentageFee: this.state.newPercentageFee / 100
            }).then((response) => {
            if (response.data.success) {
                this.loadRuleGroup()
                this.setState({showAddRuleDialog: false})
            } else {
                alert(response.data.message)
            }
        })
    }

    handleUpboundPriceChange(e) {
        e.preventDefault()
        this.setState({newUpboundPrice: e.target.value})
    }

    handleFixedFeeChange(e) {
        e.preventDefault()
        this.setState({newFixedFee: e.target.value})
    }

    handlePercentageFeeChange(e) {
        e.preventDefault()
        this.setState({newPercentageFee: e.target.value})
    }

    handleDeleteRule(e, id) {
        e.preventDefault();
        if (window.confirm("Are you sure to remove the credit card surcharge rule?")) {
            postAPI(`/api/credit_card_surcharge_group/${this.props.match.params.uuid}/credit_card_surcharge_rule/${id}/delete`,
                {}).then((response) => {
                if (response.data.success) {
                    this.loadRuleGroup()
                } else {
                    alert(response.data.message)
                }
            })
        }
    }

    render() {
        let rows;
        if (this.state.ruleGroup !== null){
            rows = this.state.rules.map((rule) => {
                return <tr key={rule.id}>
                    <td>{rule.upboundPrice} Cents</td>
                    <td>{rule.fixedFee} Cents</td>
                    <td>{(rule.percentageFee * 100).toFixed(4)} %</td>
                    <td>
                        <button className={"btn btn-danger"} onClick={(e) => {
                            this.handleDeleteRule(e, rule.id)
                        }}>Delete
                        </button>
                    </td>
                </tr>
            })
        }

        return (
            <Fragment>
                <Helmet titleTemplate="%s - Vending on Track">
                    <title>Company Credit Card Surcharge Rule Settings</title>
                </Helmet>
                <section className="content-header">
                    <h1>
                        Company Credit Card Surcharge Rule Settings
                    </h1>
                    <div className="help-block">
                        Credit Card Surcharge Rule Settings page lists all the Credit Card Surcharge Rule.
                    </div>
                </section>
                <section className="content">
                    <div className={"row"}>
                        <div className={"col-xs-12 col-sm-8"}>
                            <div className={"well"}>
                                {
                                    this.state.showAddRuleDialog && <form action="">
                                        <div className="form-group">
                                            <label>Upbound Amount (Cents)</label>
                                            <input className="form-control" placeholder={"Upbound Amount"} type="number"
                                                   onChange={this.handleUpboundPriceChange.bind(this)}/>
                                        </div>
                                        <div className="form-group">
                                            <label>Fixed Fee (Cents)</label>
                                            <input className="form-control" placeholder={"Fixed Fee"} type="number"
                                                   onChange={this.handleFixedFeeChange.bind(this)}/>
                                        </div>
                                        <div className="form-group">
                                            <label>Percentage Fee (%)</label>
                                            <input className="form-control" placeholder={"Percentage Fee"} type="number"
                                                   onChange={this.handlePercentageFeeChange.bind(this)}/>
                                        </div>
                                        <button className="btn btn-success" onClick={this.handleAddRule.bind(this)}>Save
                                        </button>
                                        &nbsp; &nbsp;
                                        <button className="btn btn-danger" onClick={() => {
                                            this.setState({showAddRuleDialog: false})
                                        }}>Cancel
                                        </button>
                                    </form>
                                }
                                {!this.state.showAddRuleDialog && <button className="btn btn-success" onClick={() => {
                                    this.setState({showAddRuleDialog: true})
                                }}>Add Rule</button>}
                            </div>
                            <div className="box box-success">
                                <div className="box-body no-padding">
                                    <div>
                                        <table className="table table-bordered">
                                            <thead>
                                            <tr>
                                                <th>Upbound Price</th>
                                                <th>Fixed Fee</th>
                                                <th>Percentage Fee</th>
                                                <th>Operation</th>
                                            </tr>
                                            </thead>
                                            <tbody>
                                            {rows}
                                            </tbody>
                                        </table>
                                    </div>
                                </div>
                            </div>
                        </div>

                    </div>
                </section>
            </Fragment>
        )
    }
}

const CompanyCreditCardSurchargeGroupPage = withGlobalData(_CompanyCreditCardSurchargeGroupPage);

export default CompanyCreditCardSurchargeGroupPage;
