import React, {Component} from 'react';
import {VendingMachine} from "./models";
import {FormatMoney} from "./format_money";
import moment from "moment";
import 'moment/min/locales';

interface DailyRevenue {
    totalRevenue: number,
    totalSalesCount: number,
}

interface VendingMachineRevenue {
    vendingMachine: VendingMachine,
    dateSalesData: { [date: string]: DailyRevenue }
}

interface RevenueReport {
    fromDate: string
    toDate: string
    vendingMachineSalesInformationList: Array<VendingMachineRevenue>
}

interface Props {
    report: RevenueReport
    locale: string
}

interface State {

}

export class RevenueReportTable extends Component<Props, State> {
    constructor(props) {
        super(props);
        this.state = {}
    }

    render() {
        if (this.props.report.vendingMachineSalesInformationList.length === 0) {
            return null;
        }
        const dates = Object.keys(this.props.report.vendingMachineSalesInformationList[0].dateSalesData);
        const columns = this.props.report.vendingMachineSalesInformationList;

        let labels: Array<any> = [];
        const vm_names = columns.map((v, idx) => {
            labels.push(<td key={'r'+idx}>Revenue</td>);
            labels.push(<td key={'s'+idx}>Sales</td>);
            return (<th colSpan={2} className="center" key={idx}>{v.vendingMachine.name}</th>);
        });

        let rows: Array<any> = [];

        let grandTotalRevenue = 0;
        let grandTotalSales = 0;

        dates.forEach((date, idx) => {
            let totalRevenue = 0;
            let totalSales = 0;

            const v = columns.map((c, idx) => {
                totalRevenue += c.dateSalesData[date].totalRevenue;
                totalSales += c.dateSalesData[date].totalSalesCount;

                return [
                    (<td key={idx * 2}>
                        <FormatMoney cents={c.dateSalesData[date].totalRevenue}/>
                    </td>),

                    (<td key={idx * 2 + 1}>
                            {c.dateSalesData[date].totalSalesCount}
                        </td>
                    )
                ];
            });

            grandTotalRevenue += totalRevenue;
            grandTotalSales += totalSales;

            const dateDisplay = moment(date).locale(this.props.locale).format("LL")

            rows.push((<tr key={idx}>
                <td>{dateDisplay}</td>
                {v}
                <td><FormatMoney cents={totalRevenue}/></td>
                <td>{totalSales}</td>
            </tr>));
        });

        return (
            <div>
                <table className="table table-bordered">
                    <tbody>
                    <tr>
                        <th className="v-center" rowSpan={2}>Date</th>
                        {vm_names}
                        <th colSpan={2}>Total</th>
                    </tr>
                    <tr>
                        {labels}
                        <th>Total Revenue</th>
                        <th>Total Sales</th>
                    </tr>
                    {rows}
                    </tbody>
                </table>
                <hr/>
                Total Revenue: <FormatMoney cents={grandTotalRevenue}/>, Total Sales: {grandTotalSales} sales.
            </div>
        );
    }
}
