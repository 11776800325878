import {md5} from "./md5";
import {Link} from "react-router-dom";
import React from "react";
import {Authentication} from "./models";

interface Props {
    handleClick(),
    authentication: Authentication
    reportMenuOpen: boolean
    toggleReportMenu(e: any)
}

export function ReportSidebar(props: Props) {
    return <aside onClick={props.handleClick} className="main-sidebar">
        <section className="sidebar">
            <div className="user-panel">
                <div className="pull-left image">
                    <img src={`https://www.gravatar.com/avatar/${md5(props.authentication.email)}?s=160`}
                         className="img-circle" alt="User" />
                </div>
                <div className="pull-left info">
                    <p>
                        {props.authentication.email}
                    </p>
                    <a href="/"><i className="fa fa-circle text-success"></i> Online</a>
                </div>
            </div>

            <ul className="sidebar-menu" data-widget="tree">
                <li className="header">MAIN NAVIGATION</li>

                <li className="treeview">
                    <Link to="/vending_machine_list">
                        <i className="fa fa-inbox"></i> <span>Vending Machines</span>
                        <span className="pull-right-container">
                                    <i className="fa fa-angle-left pull-right"></i>
                                </span>
                    </Link>
                </li>

                <li className={"treeview " + (props.reportMenuOpen ? "menu-open" : "")}>
                    <a onClick={props.toggleReportMenu} href={"/"}>
                        <i className="fa fa-pie-chart"> </i>
                        <span>Reports</span>
                        <span className="pull-right-container">
                                    <i className="fa fa-angle-left pull-right"> </i>
                                </span>
                    </a>

                    <ul className="treeview-menu">
                        <li>
                            <Link to="/eod" title={"End of Day Detail Report"}>
                                <i className="fa fa-table"></i> <span>EOD Reports</span>
                            </Link>
                        </li>

                        <li>
                            <Link to="/reports/duration" title={"Revenue Report"}>
                                <i className="fas fa-calculator"> </i> &nbsp;
                                <span>Machine Revenue Report</span>
                            </Link>
                        </li>

                        <li>
                            <Link to="/reports/product" title={"Product Report"}>
                                <i className={"fas fa-coffee"}></i> &nbsp;
                                <span>Product Revenue Report</span>
                            </Link>
                        </li>

                        <li>
                            <Link to="/reports/category" title={"Product Report"}>
                                <i className={"fa fa-list-alt"}></i> &nbsp;
                                <span>Category Revenue Report</span>
                            </Link>
                        </li>
                        <li>
                            <Link to="/kioskforce-orders" title={"KioskForce Order Report"}>
                                <i className={"fas fa-receipt"}></i> &nbsp;
                                <span>KioskForce Order Report</span>
                            </Link>
                        </li>
                    </ul>
                </li>
            </ul>
        </section>
    </aside>
}
