import {md5} from "./md5";
import {Link} from "react-router-dom";
import React from "react";
import {Authentication} from "./models";

interface Props {
    handleClick(),
    authentication: Authentication
    pickingMenuOpen: boolean
    togglePickingMenu(e: any)
}

export function DriverSidebar(props: Props) {
    return <aside onClick={props.handleClick} className="main-sidebar">
        <section className="sidebar">
            <div className="user-panel">
                <div className="pull-left image">
                    <img src={`https://www.gravatar.com/avatar/${md5(props.authentication.email)}?s=160`}
                         className="img-circle" alt="User" />
                </div>
                <div className="pull-left info">
                    <p>
                        {props.authentication.email}
                    </p>
                    <a href="/"><i className="fa fa-circle text-success"></i> Online</a>
                </div>
            </div>

            <ul className="sidebar-menu" data-widget="tree">
                <li className="header">MAIN NAVIGATION</li>
                <li className="treeview">
                    <Link to="/">
                        <i className="fa fa-dashboard"></i> <span>Dashboard</span>
                        <span className="pull-right-container">
                                    <i className="fa fa-angle-left pull-right"></i>
                                </span>
                    </Link>
                </li>

                <li className="treeview">
                    <Link to="/buttons">
                        <i className="fa fa-check"></i> <span>Buttons</span>
                        <span className="pull-right-container">
                            <i className="fa fa-angle-left pull-right"></i>
                        </span>
                    </Link>
                </li>

                <li className="treeview">
                    <Link to="/vending_machine_list">
                        <i className="fa fa-inbox"></i> <span>Vending Machines</span>
                        <span className="pull-right-container">
                                    <i className="fa fa-angle-left pull-right"></i>
                                </span>
                    </Link>
                </li>


                <li className="treeview">
                    <Link to="/refills">
                        <i className="fa fa-car"></i> <span>Refills</span>
                        <span className="pull-right-container">
                                    <i className="fa fa-angle-left pull-right"></i>
                                </span>
                    </Link>
                </li>


                <li className={"treeview " + (props.pickingMenuOpen ? "menu-open" : "")}>
                    <a onClick={props.togglePickingMenu} href={"/"}>
                        <i className="fa fa-hand-grab-o"> </i> Picking
                        <span className="pull-right-container">
                                    <i className="fa fa-angle-left pull-right"> </i>
                                </span>
                    </a>

                    <ul className="treeview-menu">
                        <li>
                            <Link to="/pickings/recent" title={"Revenue Report"}>
                                <i className="fa fa-clipboard-list"> </i> &nbsp;
                                <span>Picking list</span>
                            </Link>
                        </li>
                    </ul>
                </li>

                <li className="treeview">
                    <Link to="/scheduling">
                        <i className="fa fa-calendar-check-o"></i> <span>Schedule</span>
                        <span className="pull-right-container">
                                    <i className="fa fa-angle-left pull-right"></i>
                                </span>
                    </Link>
                </li>
            </ul>
        </section>
    </aside>
}