import React, {useState, useRef, useEffect} from "react";
import ReactPaginate from "react-paginate";
import { Helmet } from "react-helmet";
import { KioskOrdersTable } from "./kiosk_orders_table";
import VendingMachineGroupSelector from "./vending_machine_group_selector";
import Select from "react-select";
import DatePicker from "react-datepicker";
import { IGlobalDataContext, withGlobalData } from "./contexts/global-data";
import { handleTreeSelection, TreeItem } from "./utils/tree";
import moment from "moment/moment";
import { CSVLink } from 'react-csv';
import { ReportService } from "./connect-ts/telemetry/report/v1/api_connect";
import { useClient } from "./connect_api";
import { Timestamp } from "@bufbuild/protobuf";
import { KioskForceOrder } from "./connect-ts/telemetry/report/v1/api_pb";


interface Props extends IGlobalDataContext {

}

function _kioskForceOrders(props: Props) {
    const pageSize = 100;
    const downloadPageSize = 1000000000;

    const [orders, setOrders] = useState<Array<KioskForceOrder>>([]);
    const [page, setPage] = useState(0);
    const [pageCount, setPageCount] = useState(0);
    const [selectedVendingMachines, setSelectedVendingMachines] = useState(props.allVendingMachines.allVendingMachines.map((vm) => {
        return {
            value: vm.uuid,
            label: vm.name
        }
    }));

    const [exportOrders, setExportOrders] = useState<Array<KioskForceOrder>>([]);

    const [downloadLoading,setDownloadLoading] = useState<boolean>(false)

    const csvLinkRef = useRef(null);

    // Process order download
    useEffect(() =>{
        //@ts-ignore
        if(exportOrders.length > 0 && csvLinkRef && csvLinkRef.current && csvLinkRef.current.link){
            setTimeout(()=>{
                //@ts-ignore
                csvLinkRef.current.link.click();
                setExportOrders([])
            })
        }
    },[exportOrders])

    const updateSelectVendingMachines = (options) => {
        setSelectedVendingMachines(options !== null ? options : [])
    }

    const _handleTreeSelection = (treeNode: TreeItem): Promise<boolean> => {
        const selectedVM = handleTreeSelection(treeNode, props.findVendingMachine);

        setSelectedVendingMachines(selectedVM)
        return Promise.resolve(true);
    }


    const [fromDate, setFromDate] = useState(moment().subtract(7, 'day').startOf('day').toDate());
    const [toDate, setToDate] = useState(new Date());

    let dateFormat = moment().locale(props.me.localeISO).localeData().longDateFormat("L").replace("DD", "dd").replace("YYYY", "yyyy");

    function fromDateChange(date) {
        setFromDate(date)
    }

    function toDateChange(date) {
        setToDate(date)
    }


    const reportAPI = useClient(ReportService)

    const loadKioskForceOrders = (page) => {
        const uuids = selectedVendingMachines.map((vm) => {
            return vm.value;
        });

        reportAPI.generateKioskForceOrderReport({
            page: page,
            pageSize: pageSize,
            startTime: Timestamp.fromDate(fromDate),
            endTime: Timestamp.fromDate(toDate),
            vmids: uuids,
        }).then((res) => {
            let total = Number(res.total);

            let pageCount = Math.ceil(total / pageSize);

            setOrders(res.orders);
            setPageCount(pageCount);
        }).catch((err) => {
            alert("Failed to load KioskForce orders: " + err.toString());
        })


    }

    function handlePageClick(selectedItem: { selected: number }): void {
        setPage(selectedItem.selected)
        loadKioskForceOrders(selectedItem.selected)
    }


    function handleSearch(event: any) {
        event.preventDefault()
        setPage(0);

        loadKioskForceOrders(0)
    }


    function prepareCsvHeader() {
        return [
            { label: 'Vmid', key: 'vmid' },
            { label: 'VendingMachineName', key: 'vending_machine_name' },
            { label: 'OrderUUID', key: 'order_uuid' },
            { label: 'TotalAmount', key: 'total_amount' },
            { label: 'TotalPaid', key: 'total_paid' },
            { label: 'Code', key: 'code' },
            { label: 'Name', key: 'name' },
            { label: 'ItemPrice', key: 'item_price' },
            { label: 'ItemStatus', key: 'item_status' },
            { label: 'Quantity', key: 'quantity' },
            { label: 'PaymentGateway', key: 'payment_gateway' },
            { label: 'OrderState', key: 'order_state' },
            { label: 'Creation Time (UTC)', key: 'creation_time_utc' },
            { label: 'DispenseTime (UTC)', key: 'dispense_time' },
            { label: 'Modified Time (UTC)', key: 'modified_time_utc' },
            { label: 'DispensedSuccessfully', key: 'dispensed_successfully' },
            { label: 'GatewayReferenceId', key: 'gateway_reference_id' },
            { label: 'GatewayTransactionId', key: 'gateway_transaction_id' },
            { label: 'Creation Time (local)', key: 'creation_time_local' },
            { label: 'Modified Time (local)', key: 'modified_time_local' },
        ]
    }


    function prepareCsvData() {
        let csvData: any = []
        exportOrders.forEach((order) => {
            if (order.lineItems) {
                order.lineItems.forEach(((lineItemStatus) => {
                    let item: any = {}
                    item['vmid'] = order.vmid;
                    item['vending_machine_name'] = order.vendingMachineName;
                    item['order_uuid'] = order.referenceId;
                    item['total_amount'] = order.totalAmount;
                    item['total_paid'] = order.totalPaid;
                    item['code'] = lineItemStatus.lineItem?.code
                    item['name'] = lineItemStatus.lineItem?.name
                    item['item_price'] = lineItemStatus.lineItem?.price
                    item['item_status'] = lineItemStatus.lineItem?.status
                    item['quantity'] = lineItemStatus.lineItem?.quantity
                    item['payment_gateway'] = order.paymentGateway
                    item['order_state'] = order.orderState
                    item['creation_time_utc'] = moment.unix(Number(order.orderTime?.seconds)).utc().format('YYYY-MM-DDTHH:mm:ss[Z]').toString()
                    item['dispense_time'] = order.orderState === "ORDER_STATE_SUCCEEDED" || order.orderState === "ORDER_STATE_REFUNDED" ? moment.unix(Number(order.dispenseTime?.seconds)).utc().format('YYYY-MM-DDTHH:mm:ss[Z]').toString() : "";
                    item['modified_time_utc'] = moment.unix(Number(order.completeTime?.seconds)).utc().format('YYYY-MM-DDTHH:mm:ss[Z]').toString();
                    item['dispensed_successfully'] = lineItemStatus.dispensedSuccessfully ? "Yes" : "No";
                    item['gateway_reference_id'] = order.gatewayReferenceId;
                    item['gateway_transaction_id'] = order.gatewayTransactionId;
                    item['creation_time_local'] = moment(order.orderTime?.toDate()).tz(props.me.timezone).toString();
                    item['modified_time_local'] = moment(order.completeTime?.toDate()).tz(props.me.timezone).toString();
                    csvData.push(item)
                }))
            }
        });
        return csvData
    }

    async function handleDownload(){
        try{
            const uuids = selectedVendingMachines.map((vm) => {
                return vm.value;
            });

            setDownloadLoading(true)

            let res=  await reportAPI.generateKioskForceOrderReport({
                page: 0,
                pageSize: downloadPageSize,
                startTime: Timestamp.fromDate(fromDate),
                endTime: Timestamp.fromDate(toDate),
                vmids: uuids,
            })

            setExportOrders(res.orders)

            setDownloadLoading(false)

            return
        }catch (err){
            setDownloadLoading(false)
            if (err instanceof Error){
                alert("Download orders failed, "+err.message)
            }else{
                alert("Download orders failed")
            }
        }
    }

    return (
        <div>
            <Helmet titleTemplate="%s - Vending on Track">
                <title>KioskForce Order Report</title>
            </Helmet>
            <section className="content">
                <div className={"box statsBox"}>
                    <div className={"box-header"}>
                        <h1>
                            KioskForce Orders
                        </h1>
                    </div>
                    <div className={"box-body"}>
                        <div>
                            <section className="content">
                                <div className={"box box-success"}>
                                    <div className={"box-body with-border"}>
                                        <div className="form-group">
                                            <label className={"inline-label"}>Please choose machines to run report: </label> &nbsp; &nbsp;
                                            <div className={"inline-div"}>
                                                <VendingMachineGroupSelector
                                                    onSelectedGroup={_handleTreeSelection}
                                                    button={<button className={"btn btn-default"}>select</button>}
                                                    selectGroup={true}
                                                />
                                            </div>
                                            <div>
                                                <Select
                                                    value={selectedVendingMachines}
                                                    isMulti
                                                    onChange={updateSelectVendingMachines}
                                                    options={selectedVendingMachines}
                                                />
                                            </div>
                                        </div>
                                        <div className="form-group">
                                            <label>From: </label>
                                            <DatePicker className="form-control"
                                                selected={fromDate}
                                                onChange={fromDateChange} dateFormat={dateFormat} />
                                        </div>
                                        <div className="form-group">
                                            <label>To: </label>
                                            <DatePicker className="form-control"
                                                selected={toDate}
                                                onChange={toDateChange} dateFormat={dateFormat} />
                                        </div>

                                        <div className="form-group">
                                            {selectedVendingMachines.length > 0
                                                ?
                                                <button onClick={handleSearch} className="btn btn-success">
                                                    Query
                                                </button>
                                                :
                                                <button disabled className="btn btn-success">Query</button>}

                                            {orders.length > 0 &&
                                                <button className="btn btn-default" style={{marginLeft: "10px"}} disabled={downloadLoading}
                                                        onClick={handleDownload}>{downloadLoading ? "Loading..." : "Download As CSV"}</button>}
                                            <CSVLink
                                                ref={csvLinkRef}
                                                headers={prepareCsvHeader()}
                                                data={prepareCsvData()}
                                                filename="kiosk_force_orders.csv" style={{display: "none"}}
                                            />
                                        </div>
                                    </div>
                                </div>
                                {orders &&
                                    <div className="box box-success">
                                        <div className={"box-header"}>
                                            <h1>Report</h1>
                                        </div>
                                        <div className="box-body">
                                            <KioskOrdersTable orders={orders} />
                                        </div>
                                    </div>
                                }
                            </section>
                        </div>
                        <ReactPaginate
                            pageRangeDisplayed={2}
                            marginPagesDisplayed={2}
                            pageCount={pageCount}
                            onPageChange={handlePageClick}
                            containerClassName={"pagination pagination-sm no-margin"}
                            activeClassName={'active'}
                            forcePage={page}
                        />
                    </div>
                </div>
            </section>
        </div>
    );
}

export const KioskForceOrders = withGlobalData(_kioskForceOrders);

