import React, {useEffect, useState} from "react";
import {Helmet} from "react-helmet";
import {useClient} from "./connect_api";
import {ReportService} from "./connect-ts/telemetry/report/v1/api_connect";
import {IGlobalDataContext, withGlobalData} from "./contexts/global-data";
import {Message} from "./models";

interface Settings {
    enableMonthlyTransactionReport: boolean
}

interface Props extends IGlobalDataContext {
}

function _reportSettingsPage(props: Props) {
    const [loading, setLoading] = useState(true);
    const reportAPI = useClient(ReportService)

    const [summaryReportPreference, setSummaryReportPreference] = useState<Settings>({enableMonthlyTransactionReport: false});


    const loadSettings = () => {
        reportAPI.getSummaryReportPreference({}).then((response) => {
            setSummaryReportPreference({
                enableMonthlyTransactionReport: response.enableMonthlyTransactionReport
            });

            setLoading(false);
        }).catch((error) => {
            console.log(error);
            props.pushFlashMessage(new Message("Failed to load settings", "warning"));
        });
    }


    useEffect(() => {
        reportAPI.getSummaryReportPreference({}).then((response) => {
            setLoading(false);
            setSummaryReportPreference(response);
        }).catch((error) => {
            console.log(error);
            window.alert("Error loading settings");
        });
    }, [reportAPI]);

    const saveSettings = () => {
        reportAPI.updateSummaryReportPreference({
            enableMonthlyTransactionReport: summaryReportPreference.enableMonthlyTransactionReport
        }).then((response) => {
            props.pushFlashMessage(new Message("Preference updated", "success"));

            loadSettings();
        }).catch((error) => {
            console.log(error);
            props.pushFlashMessage(new Message("Failed to save preference", "warning"));
        });
    }

    if (loading) {
        return <h1>Loading...</h1>
    }

    const changeEnableMonthlyTransactionReport = (e) => {
        console.log(e);
        if (e.target.value === "true") {
            setSummaryReportPreference({
                ...summaryReportPreference,
                enableMonthlyTransactionReport: true,
            })

        } else {
            setSummaryReportPreference({
                ...summaryReportPreference,
                enableMonthlyTransactionReport: false,
            })
        }

    }

    return <div>
        <Helmet titleTemplate="%s - Vending on Track">
            <title>Report Settings</title>
        </Helmet>

        <section className="content">
            <div className={"box statsBox"}>
                <div className={"box-header"}>
                    <h1>
                        Report Settings
                    </h1>
                </div>
                <div className={"box-body"}>
                    <section className="content">
                        <div className={"box box-success"}>
                            <div className={"box-body with-border"}>

                                <div className={"form-group"}>
                                    <label>Enable Monthly Transaction Report</label>
                                    <select className={"form-control"} onChange={changeEnableMonthlyTransactionReport}
                                            value={summaryReportPreference?.enableMonthlyTransactionReport ? "true" : "false"}>
                                        <option value={"true"}>Yes</option>
                                        <option value={"false"}>No</option>
                                    </select>
                                </div>

                                <div className={"form-group"}>
                                    <button className={"btn btn-primary"} onClick={saveSettings}>Save</button>
                                </div>
                            </div>
                        </div>
                    </section>
                </div>
            </div>
        </section>
    </div>
}


export const ReportSettingsPage = withGlobalData(_reportSettingsPage);
