import React from "react";
import {IGlobalDataContext, withGlobalData} from "./contexts/global-data";

function _versionInfo(props: IGlobalDataContext) {
    const v = props.version
    console.log(v);

    return <span><b>Version: </b>{ v }</span>;
}

const VersionInfo = withGlobalData(_versionInfo);
export default VersionInfo;
