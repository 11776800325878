import {Product, ProductRecommendationForm} from "./models";
import {IGlobalDataContext, withGlobalData} from "./contexts/global-data";
import React, {Component} from "react";
import {Helmet} from "react-helmet";
import {getAPI, postAPI} from "./api";
import {Highlighter, Typeahead} from "react-bootstrap-typeahead";
import InlineProductPhoto from "./inline_product_photo";
import {Redirect} from "react-router-dom";

interface State {
    productRecommendationForm: ProductRecommendationForm | null
    productIds:string[]
    isAddProductFormOpen:boolean
    selectedProducts: Array<Product>
    redirect:boolean
}

interface Props extends IGlobalDataContext {
    match:any
}

const renderMenuItemChildren = (option: Product, props: any, index: any) => {
    return [
        <Highlighter key={"name"} search={props.text}>{option.name}</Highlighter>,
        <div key="productInfo">
            <InlineProductPhoto product={option}/> {option.name}
        </div>,
    ];
};

class _EditProductRecommendationPage extends Component<Props, State> {
    constructor(props) {
        super(props);

        this.state = {
            productRecommendationForm:null,
            productIds:[],
            isAddProductFormOpen:false,
            selectedProducts: [],
            redirect:false
        };
    }


    componentDidMount() {
        this.reloadProductRecommendationForm();
    }

    reloadProductRecommendationForm(){
        getAPI(`/api/product_recommendation_form/${this.props.match.params.uuid}`).then((response)=>{
            if (response.data.success){
                this.setState({
                    productRecommendationForm:response.data.productRecommendationForm
                }, ()=>{
                    if (this.state.productRecommendationForm !== null){
                        this.setState({productIds:this.state.productRecommendationForm?.productRecommendationMaps.map((productRecommendationMap)=>{return productRecommendationMap.product.id})})
                    }
                })
            }
        })
    }

    addAllProduct(){
        postAPI(`/api/product_recommendation_form/${this.props.match.params.uuid}/products/all`, {}).then((response)=>{
            if (response.data.success){
                this.reloadProductRecommendationForm();
            } else {
                window.alert(response.data.message)
            }
        })
    }

    toggleAddProductForm(e:any){
        e.preventDefault();
        e.stopPropagation();
        this.setState({isAddProductFormOpen:!this.state.isAddProductFormOpen})
    }

    selectProducts(productsArray: Array<Product>) {
        this.setState({selectedProducts: productsArray})
    }

    addProduct(e:any){
        e.preventDefault();
        e.stopPropagation();
        postAPI(`/api/product_recommendation_form/${this.props.match.params.uuid}/products`, {"productId":this.state.selectedProducts[0].id}).then((response)=>{
            if (response.data.success){
                this.reloadProductRecommendationForm();
                this.setState({
                    selectedProducts:[],
                    isAddProductFormOpen:false
                })
            } else {
                window.alert(response.data.message)
            }
        })
    }

    removeProduct(e:any, mapId:number){
        e.preventDefault();
        e.stopPropagation();
        if (window.confirm("Are you sure?")){
            postAPI(`/api/product_recommendation_form/${this.props.match.params.uuid}/product_recommendation_maps/${mapId}/delete`, {}).then((response)=>{
                if (response.data.success){
                    this.reloadProductRecommendationForm();
                } else {
                    window.alert(response.data.message)
                }
            })
        }
    }

    formReady(e:any){
        e.preventDefault();
        e.stopPropagation();
        if (window.confirm("After click form ready, you are unable to make further change to this form!")){
            postAPI(`/api/product_recommendation_form/${this.props.match.params.uuid}/ready`, {}).then((response)=>{
                if (response.data.success){
                    this.setState({redirect:true})
                } else {
                    window.alert(response.data.message)
                }
            })
        }
    }

    render() {
        if (this.state.redirect){
            return (<Redirect to={`/product_recommendation_form/${this.props.match.params.uuid}/view`}/>);
        }

        let rows;
        if(this.state.productRecommendationForm!= null){
            rows = this.state.productRecommendationForm.productRecommendationMaps.map((productRecommendationMap)=>{
                return <tr key={productRecommendationMap.id}>
                    <td>{productRecommendationMap.product.name}</td>
                    <td>
                        <button className={'btn btn-danger'} onClick={(e)=>{this.removeProduct(e, productRecommendationMap.id)}}>Remove</button>
                    </td>
                </tr>
            })
        }

        return <section className="content">
            <Helmet titleTemplate="%s - Vending on Track">
                <title>Edit Product Recommendation Form</title>
            </Helmet>
            <h1>Edit Product Recommendation Form For Site {this.state.productRecommendationForm !== null && this.state.productRecommendationForm.siteName}</h1>
            <div className={"well"}>
                {!this.state.isAddProductFormOpen && <div>
                    <button className={"btn btn-warning"} onClick={this.addAllProduct.bind(this)}>Add All Product</button>
                    <button className={"btn btn-success"} onClick={this.toggleAddProductForm.bind(this)}>Add a Product</button>
                    <button className={"btn btn-danger"} onClick={this.formReady.bind(this)}>Form Ready</button>
                </div>}
                {this.state.isAddProductFormOpen && <form onSubmit={this.addProduct.bind(this)}>
                    <div className="form-group">
                        <label>Product</label>
                        <Typeahead id={"typeahead"}
                                   renderMenuItemChildren={renderMenuItemChildren}
                                   selected={this.state.selectedProducts}
                                   labelKey="name"
                                   onChange={this.selectProducts.bind(this)}
                                   options={this.props.allProducts}/>
                    </div>
                    <input type="submit" className="btn btn-success" value="Add"/>
                    &nbsp; &nbsp;
                    <button className={'btn btn-danger'} onClick={this.toggleAddProductForm.bind(this)}>Cancel</button>
                </form>
                }
            </div>
            <div className="box box-success">
                <div className="box-body no-padding">
                    <div>
                        <table className = "table table-bordered" id="listBarcodes">
                            <thead>
                            <tr>
                                <th scope="col">Product</th>
                                <th scope="col">Operation</th>
                            </tr>
                            </thead>
                            <tbody>
                            {rows}
                            </tbody>
                        </table>
                    </div>
                </div>
            </div>
        </section>;
    }
}

export const EditProductRecommendationPage = withGlobalData(_EditProductRecommendationPage);




