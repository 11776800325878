import React, {Component} from "react";
import {CompanyUserNomination} from "./models";
import {CompanyUserNominationsTableRow} from "./company_user_nomination_row";

interface Props {
    nominations: Array<CompanyUserNomination>
    nominationsShouldReload()
    pushFlashMessage(Message)
}

interface State {
}

class CompanyUserNominationsTable extends Component<Props, State> {
    constructor(props) {
        super(props);

        this.state = {
        };
    }

    render(): React.ReactElement<any, string | React.JSXElementConstructor<any>> | string | number | {} | React.ReactNodeArray | React.ReactPortal | boolean | null | undefined {
        const rows = this.props.nominations.map((nomination) => {
            return (<CompanyUserNominationsTableRow key={nomination.id} nomination={nomination}
                               nominationsShouldReload={this.props.nominationsShouldReload} pushFlashMessage={this.props.pushFlashMessage}/>);
        });

        return (
            <table className={"table table-condense"}>
                <thead>
                <tr>
                    <th>Nominated Time</th>
                    <th>Email</th>
                    <th>Role</th>
                    <th>Status</th>
                    <th>Operation</th>
                </tr>
                </thead>

                <tbody>
                {rows}
                </tbody>
            </table>
        );
    }
}

export default CompanyUserNominationsTable
