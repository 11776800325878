import React from 'react';


interface Props {
    timestamp: number
}

function DaysAgo(props: Props) {
    const delta = Date.now() / 1000 - props.timestamp;
    const days = Math.floor(delta / 3600 / 24);
    return (<span>{days > 1 ? days + " days" : "less than 1 day"}</span>);
}

export default DaysAgo;