import React, {Component} from 'react';
import {postAPI} from "./api";

interface Props {

}

interface State {
    oldPassword: string
    newPassword: string
    newPassword2: string
}

export class ChangePasswordForm extends Component<Props, State> {
    constructor(props) {
        super(props);

        this.state = {
            newPassword: "", newPassword2: "", oldPassword: ""
        }
    }

    changePassword(e) {
        e.preventDefault();

        if (this.state.newPassword !== this.state.newPassword2) {
            window.alert("New passwords do not match.");
        } else {
            postAPI(`/api/users/password`, {
                oldPassword: this.state.oldPassword,
                newPassword: this.state.newPassword
            }).then((response) => {
                if (response.data.success) {
                    window.alert("Password updated.");
                    window.location.reload()
                } else {
                    window.alert(response.data.message);
                }
            })
        }

        return false;
    }

    updateCurrentPassword(e) {
        console.log(e);
        this.setState({oldPassword: e.target.value})
    }

    updateNewPassword(e) {
        console.log(e);
        this.setState({newPassword: e.target.value})
    }

    updateNewPassword2(e) {
        console.log(e);
        this.setState({newPassword2: e.target.value})
    }

    render() {
        return (
            <div className="box box-info">
                <div className="box-header">
                    <h3 className="box-title">Change password</h3>
                </div>
                <div className="box-body">
                    <form onSubmit={this.changePassword.bind(this)}>
                        <div className="form-group">
                            <label>Current Password: </label>
                            <input type="password" onChange={this.updateCurrentPassword.bind(this)}
                                   className="form-control"/>
                        </div>

                        <div className="form-group">
                            <label>New Password: </label>
                            <input type="password" onChange={this.updateNewPassword.bind(this)}
                                   className="form-control"/>
                        </div>


                        <div className="form-group">
                            <label>New Password again: </label>
                            <input type="password" onChange={this.updateNewPassword2.bind(this)}
                                   className="form-control"/>
                        </div>

                        <input type="submit" className="btn btn-success" value="Update"/>
                    </form>
                </div>
            </div>
        );
    }
}