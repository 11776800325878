import React, {Component} from "react";
import {DailyEODStats, Transaction, VendingMachineDailyEODStats} from "./models";
import ReactExport from 'react-export-excel';
import moment from 'moment';
import {IGlobalDataContext, withGlobalData} from "./contexts/global-data";
import {formatMoneyWithSymbol} from "./helpers";


const ExcelFile = ReactExport.ExcelFile;
const ExcelSheet = ReactExport.ExcelFile.ExcelSheet;
const ExcelColumn = ReactExport.ExcelFile.ExcelColumn;

interface Props extends IGlobalDataContext{
    stats: DailyEODStats
}

interface State {
}

class RawDownloadEodButton extends Component<Props, State> {
    render() {
        const strDate = this.props.stats.date;
        const decimalPoints = this.props.me.mostRecentCompany.currencyDecimalPoints;
        const currencySymbol = this.props.me.mostRecentCompany.currencySymbol
        const data = [
            {
                label: "Date",
                value: strDate
            },
            {
                label: "Total Sales Count",
                value: this.props.stats.totalSalesCount
            },
            {
                label: "Total Revenue",
                value: formatMoneyWithSymbol(this.props.stats.totalRevenue, decimalPoints, currencySymbol)
            },
            {
                label: "Total Cash",
                value: formatMoneyWithSymbol(this.props.stats.totalCashes, decimalPoints, currencySymbol)
            },
            {
                label: "Current Collectable Cash *",
                value: formatMoneyWithSymbol(this.props.stats.currentCollectableCashes, decimalPoints, currencySymbol)
            }
        ];

        const machines = this.props.stats.perMachineStats.map((perMachineStats: VendingMachineDailyEODStats) => {
            const transactions = perMachineStats.transactions;

            return (
                <ExcelSheet key={perMachineStats.vendingMachine.uuid} data={transactions} name={`Machines Sales ${perMachineStats.vendingMachine.name}`}>
                    <ExcelColumn label={perMachineStats.vendingMachine.name} value={(row: Transaction) => {return moment.unix(row.timestamp).toString()}} />
                    <ExcelColumn label={"Item"} value={(row: Transaction) => {
                        if (row.product != null){
                            return `${row.product.name}[${row.itemNumber}]`
                        }
                        return null;
                    }}/>
                    <ExcelColumn label={"Source"} value={(row: Transaction) => {return row.source; }} />
                    <ExcelColumn label={"Price"} value={(row: Transaction) => {return formatMoneyWithSymbol(row.itemPrice, decimalPoints, currencySymbol)}} />
                    <ExcelColumn label={"Payment Method"} value={(row: Transaction) => {return  row.paymentMethod}} />
                </ExcelSheet>
            );
        });

        const transactions = this.props.stats.aggregatedTransactions;

        return (
            <ExcelFile element={<button className="btn btn-success btn-lg">Export to Excel (Aggregated Transactions, per machine transactions, etc)</button>}>
                <ExcelSheet data={data} name={"Stats"}>
                    <ExcelColumn label={""} value={"label"} />
                    <ExcelColumn label={""} value={"value"} />
                </ExcelSheet>

                <ExcelSheet data={transactions} name={"Transactions"}>
                    <ExcelColumn label={"Transactions"} value={(row: Transaction) => {return moment.unix(row.timestamp).toString()}} />
                    <ExcelColumn label={"Item"} value={(row: Transaction) => {
                        if (row.product != null){
                            return `${row.product.name}[${row.itemNumber}]`
                        }
                        return null;
                    }}/>
                    <ExcelColumn label={"Source"} value={(row: Transaction) => {return row.source; }} />
                    <ExcelColumn label={"Price"} value={(row: Transaction) => {return formatMoneyWithSymbol(row.itemPrice, decimalPoints, currencySymbol)}} />
                    <ExcelColumn label={"Payment Method"} value={(row: Transaction) => {return  row.paymentMethod}} />
                </ExcelSheet>

                {machines}
            </ExcelFile>
        );
    }
}

export const DownloadEodButton = withGlobalData(RawDownloadEodButton)