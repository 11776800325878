import React, {useEffect, useState} from "react";
import {VendingMachine} from "./models";
import {ManagementServiceApi} from "./api";
import {V1MachineDetailResponse} from "./telemetry/vending_machine/v1/typescript-axios";
import {TelemetryvendingMachinev1BlackoutPeriod} from "./telemetry/vending_machine/v1/typescript-axios";
import 'react-bootstrap-typeahead/css/Typeahead.css';
import './assets/css/typeahead.scss'
import {VendingMachineBlackoutTimer} from "./vending_machin_blackout_timer";


interface Props {
    vendingMachine: VendingMachine
    machineDetail :V1MachineDetailResponse
}

export function VendingMachineBlackOutPanel(props: Props) {
    const [blackOutEnabled, setBlackOutEnabled] = useState<string>('0');
    const [timeLists, setTimeLists] = useState<TelemetryvendingMachinev1BlackoutPeriod[]>([]);

    useEffect(() =>{
        const periods = Array.isArray(props.machineDetail.blackoutPeriods)
            ? props.machineDetail.blackoutPeriods
            : [];
        setBlackOutEnabled(props.machineDetail.blackoutPeriodEnabled ? '1' : '0');
        setTimeLists(periods);
    },[props.vendingMachine, props.machineDetail])

    const save = () => {

        if (timeLists.length === 0 && blackOutEnabled === '1'){
            alert("At least one blackout period should be set");
            return false;
        }

        const canSettingTime: boolean = timeLists.every((item, index) => {
            const startTime = new Date(`1970-01-01T${item.startTime}`);
            const finishTime = new Date(`1970-01-01T${item.finishTime}`);

            if (startTime >= finishTime) {
                alert("Start time should be less than or equal to finish time");
                return false;
            }

            return true;
        });


        if (canSettingTime){
            new ManagementServiceApi().machineManagementServiceUpdateMachineBlackoutPeriod(props.vendingMachine.uuid, {
                blackoutPeriodEnabled: blackOutEnabled === '1',
                blackoutPeriods: timeLists,
            }).then(res => {
                alert("update machine successfully ")
            }).catch((e) => {
                alert("fail to update machine, err:" + e)
            });
        }
    }
    const add = () => {
        setTimeLists(prevTimeLists => {
            if (!Array.isArray(prevTimeLists)) {
                prevTimeLists = [];  // Reset if it's not an array
            }
            return [
                ...prevTimeLists,
                {
                    startTime: "00:00:00",  // Making assumptions here
                    finishTime: "00:00:00", // Again, making assumptions here
                }
            ];
        });
    }

    const handleChange = (e: React.ChangeEvent<HTMLSelectElement>) => {
        setBlackOutEnabled(e.target.value);
    }


    const remove = (index: number) => {
        setTimeLists(prevTimeLists => {
            if (!Array.isArray(prevTimeLists)) {
                prevTimeLists = [];
            }
            return prevTimeLists.filter((_, i) => i !== index);
        });
    }

    const updateBlackoutPeriod = (index: number, blackoutPeriod: TelemetryvendingMachinev1BlackoutPeriod) => {
        setTimeLists(prevTimeLists => {
            if (!Array.isArray(prevTimeLists)) {
                prevTimeLists = [];  // Reset if it's not an array
            }
            return prevTimeLists.map((item, i) => {
                if (i === index) {
                    return blackoutPeriod;
                }
                return item;
            });
        });
    }

    return (<div className="well">
            <h2>Blackout Period Configuration</h2>
                <div>
                    <div className={"form-group"}>
                        <label>Blackout Enabled:</label>
                        <select className={"form-control"} onChange={handleChange} value={blackOutEnabled}>
                            <option value="1">Yes</option>
                            <option value="0">No</option>
                        </select>

                    </div>
                    {
                        timeLists.map((item, index) =>
                            <VendingMachineBlackoutTimer
                                key={index}
                                index={index}
                                blackoutPeriod={item}
                                remove={remove}
                                updateBlackoutPeriod={updateBlackoutPeriod}
                            />
                        )}

                    <div className={"form-group"}>
                        <button className={"btn btn-success"} onClick={save}>Save</button>
                        &nbsp;&nbsp;
                        <button className={"btn btn-success"} onClick={add}>Add</button>
                    </div>
                </div>
    </div>);
}
