import React, {Component} from 'react';
import {Barcode, PrekittingDetail} from "./models";
import {getAPI} from "./api";
import PrekittingDetailPage from "./prekitting_detail_page";
import {Helmet} from "react-helmet";

interface Props {
    match: any
}

interface State {
    prekitting: PrekittingDetail | null
    barcodes: Barcode[]
}

export class PickingDetail extends Component<Props, State> {
    constructor(props) {
        super(props);

        this.state = {
            prekitting: null,
            barcodes:[]
        };

        this.init();
    }


    init() {
        getAPI(`/api/prekittings/${this.props.match.params.id}`).then((response) => {
            this.setState({prekitting: response.data.detail});
        })

        getAPI(`/api/barcodes`).then((response)=>{
            this.setState({barcodes:response.data.barcodeInfoAll.barcodeInfoList})
        })
    }

    render() {
        if (this.state.prekitting == null) {
            return null;
        }
        return (<div>            <Helmet titleTemplate="%s - Vending on Track">
            <title>Pickings</title>
        </Helmet>
            <PrekittingDetailPage reload={this.init.bind(this)} detail={this.state.prekitting} barcodes={this.state.barcodes}/></div>);
    }
}
