import React, {Component, Fragment} from 'react';
import {Helmet} from "react-helmet";

export class FeatureDisabledPage extends Component {

    render() {
        return (
            <Fragment>
                <Helmet titleTemplate="%s - Vending on Track">
                    <title>Feature Disabled</title>
                </Helmet>
                <section className="content-header">
                    <h1>
                        Feature Disabled
                    </h1>
                </section>

                <section className="content">
                    <div className="alert alert-danger">
                        <b>Note: </b> This feature is disabled for current user. Please contact
                        support@vendingontrack.com to activate this feature.
                    </div>
                </section>
            </Fragment>
        );
    }
}