import React, { Component }  from 'react';
import {Stock} from "./models";
import {PDFDownloadLink} from "@react-pdf/renderer";
import PDF from "./pdf_purchase_order";


interface Props{
    Stocks:Stock[]
    findProduct: Function
}

interface State {
}

class PurchaseOrder extends Component<Props, State> {
    constructor(props) {
        super(props);

        this.state = {
        }
    }
    render() {
        const purchaseOrderData = this.props.Stocks.map((stock)=>{
            const r = {
                product: this.props.findProduct(stock.productId),
                ...stock
            };
            if (!r.product?.placeholder && stock.purchaseQuantities){
                const purchaseQuantities = stock.purchaseQuantities.map((purchaseQuantity, idx)=>{
                    return {unit:purchaseQuantity.unit, quantity:purchaseQuantity.quantity}
                })
                return {name:r.product?.name, purchaseQuantities:purchaseQuantities, productUnit:r.product?.productUnit}
            } else {
                return null
            }
        }).filter(element => element!= null && element.name!=null).sort((a,b) => (a?.name > b?.name) ? 1 : ((b?.name > a?.name) ? -1 : 0))
        const rows = purchaseOrderData.map((item, index)=>{
            return <tr key={index}>
                <td>{index+1}</td>
                <td>{item?.name}</td>
                <td>
                    <table className={"table table-bordered"}>
                        <thead>
                        <tr>
                            <th>{item?.productUnit}</th>
                            <th>Quantity</th>
                        </tr>
                        </thead>
                        <tbody>
                        {item?.purchaseQuantities.map((purchaseQuantity, idx)=>{
                            return <tr key={idx}>
                                <td>{purchaseQuantity.unit}</td>
                                <td>{purchaseQuantity.quantity}</td>
                            </tr>
                        })}
                        </tbody>
                    </table>
                </td>
            </tr>
        })
        return <div className={"row"}>
            <PDFDownloadLink fileName={"Purchase Order "+Date().toString()} document={<PDF data={purchaseOrderData} findProduct={this.props.findProduct}/>}>Export As PDF</PDFDownloadLink>
            <div className={"col-xs-8"}>
            <table className={"table table-bordered"}>
                <thead>
                    <tr>
                        <th>No</th>
                        <th>Product</th>
                        <th>Purchase Options</th>
                    </tr>
                </thead>
                <tbody>
                    {rows}
                </tbody>
            </table>
            </div>
        </div>
    }
}

export default PurchaseOrder;