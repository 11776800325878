import React, {Component} from "react";
import {V1PaymentEvent} from "./telemetry/vending_machine/v1/typescript-axios";
import {IGlobalDataContext, withGlobalData} from "./contexts/global-data";


interface Props extends IGlobalDataContext {
    paymentEvents: Array<V1PaymentEvent>
}

interface State {

}

class _PaymentsEventTable extends Component<Props, State> {
    // eslint-disable-next-line
    constructor(props) {
        super(props);
    }

    render(): React.ReactElement<any, string | React.JSXElementConstructor<any>> | string | number | {} | React.ReactNodeArray | React.ReactPortal | boolean | null | undefined {

        const rows = this.props.paymentEvents.map((paymentEvent) => {
            return <tr>
                <td>{paymentEvent.uuid}</td>
                <td>{paymentEvent.orderUuid}</td>
                <td>{paymentEvent.paymentGateway}</td>
                <td>{paymentEvent.event}</td>
                <td>{paymentEvent.receiptId}</td>
                <td>{paymentEvent.bodyData}</td>
                <td>{paymentEvent.createdAt}</td>
            </tr>
        });

        return (
            <table className={"table table-condense"}>
                <thead>
                <tr>
                    <th>Uuid</th>
                    <th>OrderUuid</th>
                    <th>PaymentGateway</th>
                    <th>Event</th>
                    <th>ReceiptId</th>
                    <th>BodyData</th>
                    <th>CreatedAt</th>
                </tr>
                </thead>

                <tbody>
                {rows}
                </tbody>
            </table>
        );
    }
}

export const PaymentEventsTable = withGlobalData(_PaymentsEventTable);
