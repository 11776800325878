import React, {useEffect, useState} from "react";
import {GeoLocation, VendingMachine} from "./models";
import {GoogleMap, Marker, OverlayView} from "@react-google-maps/api";
import Route from "./routing";



interface Props {
    start: GeoLocation
    finish: GeoLocation
    routing: Array<VendingMachine>
}

function GeoLocation2LatLng(location:GeoLocation) : google.maps.LatLngLiteral {
    return {
        lat: location.latitude||0, lng: location.longitude||0
    }
}

export default function RoutingMap(props: Props) {
    const {start, finish, routing} = props;

    const [map, setMap] = useState<any>(null);


    console.log('load');

    function updateBound() {
        const bounds = new google.maps.LatLngBounds();
        bounds.extend({lat: start.latitude || 0, lng: start.longitude || 0});
        bounds.extend({lat: finish.latitude || 0, lng: finish.longitude || 0});


        routing.forEach((vm) => {
            const point = {lat: vm.geo_location?.latitude || 0, lng: vm.geo_location?.longitude || 0};
            if (!bounds.contains(point)) {
                bounds.extend(point)
            }
        });

        if (map) {
            map.fitBounds(bounds);
            console.log('fit to bounds');
        } else {
            console.log('no bounds');
        }
    }


    useEffect(updateBound, [routing, map, start, finish]);

    function onMapMounted(map) {
        // map.fitBounds(bounds);
        console.log('fit to bounds 2');
        setMap(map);
    }

    const startMarker = (<Marker key={"start"}  position={GeoLocation2LatLng(start)} ><div>Start</div></Marker>);
    const finishMarker= (<Marker key={"finish"} position={GeoLocation2LatLng(finish)} />);

    const stopMarkers = routing.map((vendingMachine, idx) => {
        return <OverlayView
            key={vendingMachine.uuid}
            position={
                {
                    lat: vendingMachine.geo_location?.latitude || 0,
                    lng: vendingMachine.geo_location?.longitude || 0
                }
            }
            mapPaneName={OverlayView.OVERLAY_MOUSE_TARGET}
        >
            <div style={{
                background: 'white',
                border: '1px solid #ccc',
                padding: 0
            }}>{idx + 1} - {vendingMachine.name}</div>
        </OverlayView>
    });

    const totalStops:Array<google.maps.LatLngLiteral> = [];

    totalStops.push(GeoLocation2LatLng(start));
    routing.forEach((stop) => {
        if (stop.geo_location) {
            totalStops.push(GeoLocation2LatLng(stop.geo_location));
        }
    });
    totalStops.push(GeoLocation2LatLng(finish));

    const chunks = totalStops.map((location, idx, self) => {
        if (idx + 1 === totalStops.length) {
            return null;
        }

        return {
            destination: self[idx+1],
            origin: location
        }
    }).filter((location) => !!location);

    const routingChunks = chunks.map((chunk, i) => {
        if (chunk) {
            return (
                <Route updateDistance={(meters, minutes) => {
                    console.log('distance:');
                    console.log(meters);
                    console.log(minutes);
                }} key={i} between={chunk}/>
            );
        }

        return null;
    });
    /*
    {routingResults[i] &&
    <DirectionsRenderer
        options={{
            directions: routingResults[i]
        }}/>
    }
    */


    return (
        <div style={{height: '100vh', width: '100%'}}>
            <GoogleMap
                onLoad={onMapMounted}
                id={"direction"}
                mapContainerStyle={{height: '100vh', width: '100%'}}
                zoom={1}
                >

                {startMarker}
                {finishMarker}
                {stopMarkers}
                {routingChunks}

            </GoogleMap>
        </div>
    );
}
