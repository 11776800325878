import React, { Component } from 'react'
import { CSVLink } from "react-csv";
import moment from "moment";
import { getAPI } from "./api";
import { ProductMappingWithProduct } from "./models"

interface Props {
    vendingMachineUuid: String
}

interface State {
    data: ProductMappingWithProduct[]
    ready: boolean
}

class DownloadInventoriesCsv extends Component<Props, State> {

    private csvLink: React.RefObject<any>;

    constructor(props) {
        super(props);

        this.state = {
            data: [],
            ready: false
        };

        this.csvLink = React.createRef();
    }

    getData() {
        getAPI(`/api/vending_machines/${this.props.vendingMachineUuid}/stock`).then(
            (response) => {
                console.log('all csv data', response)
                this.setState({
                    data: response.data.productMaps
                }, () => {
                    this.setState({ ready: true }, () => {
                        this.csvLink.current.link.click();
                    });
                });
            },
            (error) => {
                console.error('Error while fetching data:', error);
            }
        );
    }

    prepareCsvHeader() {
        return [
            { label: 'Code', key: 'code' },
            // { label: 'Date', key: 'date' },
            { label: 'Product', key: 'product' },
            { label: 'Price', key: 'price' },
            { label: 'CurrentStock', key: 'currentstock' },
            { label: 'Capacity', key: 'capacity' },
        ]
    }

    prepareCsvData() {
        return this.state.data.map(product => {
            let transactionExport: any = {}
            transactionExport['code'] = product.mdb_code;
            // transactionExpot['date'] = product.lastTransactionTimestamp?.toString;
            transactionExport['product'] = product.product_name;
            transactionExport['price'] = product.price;
            transactionExport['currentstock'] = product.currentStock;
            transactionExport['capacity'] = product.capacity;
            return transactionExport;
        })
    }

    render() {
        return (
            <div>
                <button className='btn btn-success btn-sm' onClick={this.getData.bind(this)}>Download Inventories CSV</button>

                <CSVLink
                    data={this.prepareCsvData()} headers={this.prepareCsvHeader()}
                    filename={'inventories_' + moment().unix() + '_.csv'}
                    className="hidden"
                    ref={this.csvLink}
                    target="_blank"
                />
            </div>
        )
    }
}

export { DownloadInventoriesCsv };