import React, {Component} from 'react';
import GoogleMapReact from 'google-map-react';
import {VendingMachineMarker} from "./vending_machine_marker";
import {VendingMachine} from "./models";

interface Props {
    vendingMachine: VendingMachine
    address: string
    center: {
        lat: number,
        lng: number
    }
    zoom: number
}

interface State {

}

export class LocationMap extends Component<Props, State> {
    constructor(props) {
        super(props);

        this.state = {}
    }

    render() {
        return (
            // Important! Always set the container height explicitly
            <div style={{height: '40vh', width: '100%'}}>
                <GoogleMapReact
                    bootstrapURLKeys={{key: "AIzaSyAiK6esuGcx32taWwFdMdQBGlNF0eNQkhs"}}
                    center={this.props.center}
                    defaultZoom={this.props.zoom}
                    zoom={this.props.zoom}
                >
                    <VendingMachineMarker
                        lat={this.props.center.lat}
                        lng={this.props.center.lng}
                        vendingMachine={this.props.vendingMachine}
                    />
                </GoogleMapReact>
            </div>
        );
    }
}
