import React from "react";
import {postAPI} from "../api";
import {Highlighter, Typeahead} from 'react-bootstrap-typeahead';
import {IGlobalDataContext, withGlobalData} from '../contexts/global-data';
import {Product} from "../models";
import InlineProductPhoto from "../inline_product_photo";

interface IProps extends IGlobalDataContext {
    mergeSrcProductId: string

    onSubmitted(): void
}

const InlineEditProduct: React.SFC<IProps> = props => {
    const [state, setState] = React.useState({
        mode: 'Update',
        distProductId: null, //Merge: product selected from dropdown, the merging distination product
        info: '',// hints message for user
        txt: '', // Update: new name for product
    });

    let {mergeSrcProductId, onSubmitted:notifyParentSubmitted, allProducts, reloadAllProducts} = props;

    const onTextChange = (txt, evt) => {
        let info = `Update product name to ${txt}`;
        setState({
            ...state,
            mode: 'Update',
            info,
            txt
        })
    };

    const onSelectChange = (selected) => {
        if (selected.length === 0) {
            return;
        }
        let [distProduct] = selected;
        let info = `Merge this product to ${distProduct.name}`;
        setState({
            ...state,
            info,
            distProductId: distProduct.id,
            mode: 'Merge'
        })
    };

    const afterSuccess = () => {
        notifyParentSubmitted();
        reloadAllProducts()
    };

    const onSubmitAction = () => {
        if (!window.confirm('Do you want continue: ' + state.info)) {
            return
        }

        if (state.mode === 'Merge') {
            console.log(`Merge current product ${mergeSrcProductId} to ${state.distProductId}`);

            postAPI(`/api/products/merges`, {
                destination: state.distProductId,
                products: [mergeSrcProductId]
            }).then((response) => {
                if (response.data.success) {
                  afterSuccess()
                } else {
                    alert(response.data.message);
                }
            })
        } else if (state.mode === 'Update') {
            console.log(`Update productID: ${mergeSrcProductId} name to ${state.txt}`);

            postAPI(`/api/products/${mergeSrcProductId}`, {name: state.txt}).then((response) => {
                if (response.data.success) {
                    afterSuccess()
                } else {
                    alert(response.data.message);
                }
            })
        } else {
            console.log('Error: InlineEditProduct: no action performed.')
        }
    };

    const renderMenuItemChildren = (option: Product, props, index) => {
        return [
            <Highlighter key={"name"} search={props.text}>{option.name}</Highlighter>,
            <div key="productInfo">
                <InlineProductPhoto product={option}/> {option.name}
            </div>,
        ];
    };

    return (
        <div className="input-group margin">
            <Typeahead
                id={`typeahead_${mergeSrcProductId}_${Date.now()}`}
                onInputChange={onTextChange}
                onChange={onSelectChange}
                labelKey="name"
                options={allProducts}
                renderMenuItemChildren={renderMenuItemChildren}
                />
                <span className="input-group-btn">
                  <button onClick={onSubmitAction} type="button" className="btn btn-success btn-flat">{state.mode}</button>
                </span>
            </div>
    );
};

export default withGlobalData(InlineEditProduct);
