import React, {useEffect} from "react";
import {mediaManagementServiceApi} from "./api";
import {ScreenSaversTable} from "./screen_savers_table";
import {V1ScreenSavers} from "./telemetry/vending_machine/v1/typescript-axios";
import {VendingMachineGroup} from "./models";

interface Props {
    vendingMachineGroup: VendingMachineGroup
}

export function ScreenSaverListPage(props: Props) {
    const [screenSavers, setScreenSavers] = React.useState<Array<V1ScreenSavers>>([]);


    const loadScreenSavers = () => {
        new mediaManagementServiceApi().mediaManagementServiceListScreenSavers({
            vendingMachineGroupUuid: props.vendingMachineGroup.uuid
        }).then((data) => {
            if (data.status !== 200) {
                alert("get screen savers list fail ")
            } else {
                setScreenSavers(data.data.screenSavers ?? []);
            }
        }).catch((e) => {
            alert("get screen savers list fail " + e)
        });
    }


    useEffect(() => {
        loadScreenSavers();

        // eslint-disable-next-line react-hooks/exhaustive-deps
    },[]);

    return (<div className="box">
            <div className={"box-header"}>
                <h1>Screen Savers</h1>

            </div>

            <div className={"box-body"}>
                <ScreenSaversTable screenSavers={screenSavers} onDelete={loadScreenSavers} onUpdate={loadScreenSavers}/>
            </div>
        </div>
    );
}
