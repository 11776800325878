import React, {Component} from "react";
import {Locale, Me} from "./models";
import {getAPI, postAPI} from "./api";
import {Typeahead} from 'react-bootstrap-typeahead';
import 'react-bootstrap-typeahead/css/Typeahead.css';
import {ChangePasswordForm} from "./change_password";
import {IGlobalDataContext, withGlobalData} from "./contexts/global-data";
import {Helmet} from "react-helmet";
import {UserEmailSettingsForm} from "./user_email_settings_form";
import {TfaSetupForm} from "./tfa";


interface Props extends IGlobalDataContext{

}

interface State {
    me: Me|null
    timezones: Array<string>
    locales: Array<Locale>
    new_timezone: string
    api_token: string
    webhook: string
    new_locale:string
}

class _UserSettingsPage extends Component<Props, State> {
    constructor(props) {
        super(props);

        this.state = {
            me: null,
            timezones: [],
            locales: [],
            new_timezone: '',
            new_locale:'',
            api_token: 'X'.repeat(32),
            webhook: 'https://webhook.somewebsite.com/secret_passphrase/partoflongurl',
        };

        this.load();
    }

    handleSaveAPI(e) {
        e.preventDefault();
        postAPI(`/api/users/api`, {
            webhook: this.state.webhook
        }).then((response) => {
            //
        })
    }

    load() {
        getAPI('/api/users/me').then((response) => {
            this.setState({me: response.data});
        });

        getAPI('/api/users/timezones').then((response) => {
            this.setState({timezones: response.data.timezones});
        })

        getAPI('/api/users/locales').then((response) => {
            this.setState({locales: response.data.locales});
        })
    }

    handleSaveTimezone(event) {
        event.preventDefault();

        console.log(this.state.new_timezone);
        postAPI(`/api/users/timezone`, {timezone: this.state.new_timezone}).then((response) => {
            if (response.data.success) {
                window.alert("Success. Please reload.");
            } else {
                window.alert(response.data.message);
            }
        });

        return false;
    }

    handleChange(selectedTimezone) {
        this.setState({new_timezone: selectedTimezone[0]});
    }

    handleSaveLocale(event) {
        event.preventDefault();

        console.log(this.state.new_locale);
        if (this.state.new_locale !== ""){
            postAPI(`/api/users/locale`, {locale: this.state.new_locale}).then((response) => {
                if (response.data.success) {
                    window.alert("Success. Please reload.");
                } else {
                    window.alert(response.data.message);
                }
            });
        } else {
            window.alert("Please update it locale before save");
        }
        return false;
    }

    handleLocaleChange(selectedLocale){
        const selectedLocaleRaw = this.state.locales.find((locale)=>{return locale.display === selectedLocale[0]})
        if (selectedLocaleRaw){
            this.setState({new_locale:selectedLocaleRaw.localeIso})
        }
    }


    render() {
        return (
            <section className="content">
                <Helmet titleTemplate="%s - Vending on Track">
                    <title>User Settings</title>
                </Helmet>
                <h1>User Settings</h1>

                <div className="row">
                    <div className="col-md-6">

                        {!(this.state.me && this.state.timezones.length > 0) ? <div>Loading...</div> :
                            <div className="box box-success">
                                <div className="box-header">
                                    <h3 className="box-title">Current Timezone</h3>
                                </div>
                                <div className="box-body">
                                    <form onSubmit={this.handleSaveTimezone.bind(this)}>
                                        <div className="form-group">
                                            <label>Specify timezone: </label>
                                            <Typeahead onChange={this.handleChange.bind(this)}
                                                       defaultSelected={[this.state.me.timezone]}
                                                       options={this.state.timezones}/>
                                        </div>

                                        <input type="submit" className="btn btn-success" value="Save"/>
                                    </form>
                                </div>
                            </div>
                        }

                        {!(this.state.me && this.state.timezones.length > 0) ? <div>Loading...</div> :
                            <div className="box box-success">
                                <div className="box-header">
                                    <h3 className="box-title">Current Locale</h3>
                                </div>
                                <div className="box-body">
                                    <form onSubmit={this.handleSaveLocale.bind(this)}>
                                        <div className="form-group">
                                            <label>Specify locale: </label>
                                            <Typeahead onChange={this.handleLocaleChange.bind(this)}
                                                       defaultSelected={[this.state.me.locale]}
                                                       options={this.state.locales.map((locale)=>{return locale.display})}/>
                                        </div>

                                        <input type="submit" className="btn btn-success" value="Save"/>
                                    </form>
                                </div>
                            </div>
                        }
                    </div>

                    <div className="col-md-6">
                        <div className="box box-success">
                            <div className="box-header">
                                <h3 className="box-title">API token and Webhook</h3>
                            </div>

                            <div className="box-body">
                                <form onSubmit={this.handleSaveAPI.bind(this)}>
                                    <div className="form-group">
                                        <label>API Token</label>
                                        <input disabled={true} className="form-control" type="password"
                                               value={this.state.api_token}/>
                                    </div>

                                    <div className="form-group">
                                        <label>Webhook URL</label>
                                        <input className="form-control" type="text" value={this.state.webhook}
                                               onChange={this.handleWebhookChange.bind(this)}/>
                                    </div>

                                    <br/>
                                    <input type="submit" className="btn btn-lg btn-success" value={"Save"}/>
                                </form>
                            </div>
                        </div>
                    </div>
                </div>

                {this.state.me?.userEmailSettings &&
                <UserEmailSettingsForm emailSettings={{
                    disableAlertEmail: this.state.me.userEmailSettings.disableAlertEmail,
                    disableDailySummaryEmail: this.state.me.userEmailSettings.disableDailySummaryEmail
                }}/>
                }

                <ChangePasswordForm/>
                <TfaSetupForm isTfaEnabled={this.state.me?.tfaEnabled} load={this.load.bind(this)}/>
            </section>
        );
    }

    handleWebhookChange(e) {
        e.preventDefault();
        this.setState({webhook: e.target.value});
        return false;
    }
}

export const UserSettingsPage = withGlobalData(_UserSettingsPage);
