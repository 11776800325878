import React, {Component, Fragment} from 'react';
import {CompanySettings} from "./models";
import {getAPI} from "./api";
import {CompanySettingsPanel} from "./company_settings_panel";
import {Helmet} from "react-helmet";

interface Props {

}

interface State {
    selectedCompany: string
    companySettings: CompanySettings | null
    loading: boolean
    timezones: Array<string>
}

class CompanySettingsPage extends Component<Props, State> {
    constructor(props) {
        super(props);

        this.state = {
            selectedCompany: '',
            loading: false,
            companySettings: null,
            timezones: [],
        }
    }

    componentDidMount(): void {
        this.loadSettings();
    }

    loadSettings(): void {
        this.setState({'loading': true});
        getAPI("/api/companies/mine").then((response) => {
            if (response.data.success) {
                this.setState({
                    loading: false,
                    companySettings: response.data.companySettings,
                    selectedCompany: response.data.companySettings.uuid
                });
            } else {
                window.alert(response.data.message)
            }
        })

        getAPI('/api/users/timezones').then((response) => {
            this.setState({timezones: response.data.timezones});
        })
    }


    render() {
        return (
            <Fragment>
                <Helmet titleTemplate="%s - Vending on Track">
                    <title>Company Settings</title>
                </Helmet>
                <section className="content-header">
                    <h1>
                        Company Settings
                    </h1>
                </section>

                <section className="content">
                    {this.state.companySettings &&
                    <CompanySettingsPanel reloadSettings={this.loadSettings.bind(this)} settings={this.state.companySettings} timezones={this.state.timezones}/>
                    }
                </section>
            </Fragment>
        );
    }
}

export default CompanySettingsPage;