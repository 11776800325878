import * as React from "react";
import {VirtualFinancialTransaction} from "./models";
import VirtualTransTr from "./components/virtual_trans_tr";
import {Default, Mobile} from "./components/responsive";

interface IProps {
	vftrans: Array<VirtualFinancialTransaction>
}

interface Props extends IProps {
	isMobile: boolean
}

const InternalDashboardLatestTransaction: React.SFC<Props> = props => {
	return (
		<div>
			<h2>Latest Transactions</h2>

			<div className="box with-border box-success">
				<div className="box-body no-padding">
					<table className={"table table-condensed"}>
						<thead>
						{props.isMobile ?
							<tr>
								<th>Time</th>
								<th>Dest</th>
								<th>Amount</th>
								<th>Machine</th>
							</tr>
							:
							<tr>
								<th>Time</th>
								<th>Sales Type</th>
								<th>Destination / Source</th>
								<th>Amount</th>
								<th>Machine</th>
							</tr>
						}
						</thead>
						<tbody>
						{props.vftrans.map((transaction, i) => {
							return (
								<VirtualTransTr
									vftrans={transaction}
									isExpandTime={false}
									isShowMachine={true}
									isShrunk={props.isMobile}
									key={transaction.uuid}/>
							)
						})}

						</tbody>
					</table>
				</div>
			</div>
		</div>
	);
};

export const DashboardLatestTransaction = (props: IProps) => (
	<div>
		<Mobile>
			<InternalDashboardLatestTransaction isMobile={true} {...props} />
		</Mobile>
		<Default>
			<InternalDashboardLatestTransaction isMobile={false} {...props} />
		</Default>
	</div>
);

