import React, {Component} from 'react';
import {IGlobalDataContext, withGlobalData} from "./contexts/global-data";
import {Link, Route, Switch} from "react-router-dom";
import {postAPI} from "./api";
import {AddCategoryPanel} from "./add_category_panel";
import {Helmet} from "react-helmet";


interface State {
}

interface Props extends IGlobalDataContext {
    match:any
}


class _Categories extends Component<Props, State> {
    constructor(props) {
        super(props);

        this.state = {
        };
    }


    componentDidMount() {
        this.props.reloadCategories();
    }


    handleAdd(category:string){
        postAPI("/api/categories", {
            category: category
        }).then((result) => {
            if (result.data.success) {
                this.props.reloadCategories();
            } else {
                alert(result.data.message);
            }
        });
    }

    handleDelete(id:number){
        postAPI("/api/categories/"+id+"/delete", {}).then((result)=>{
            if (result.data.success) {
                this.props.reloadCategories();
            } else {
                alert(result.data.message);
            }
        })
    }



    render() {
        return(
            <section className="content">
                <Helmet titleTemplate="%s - Vending on Track">
                    <title>Category Settings</title>
                </Helmet>
                <h1>Category Settings</h1>
                <div className="help-block">
                    Category Settings page lists all the product categories
                </div>
                <div className={"well"}>
                    <Switch>
                        <Route exact path={"/categories/add"}>
                            <AddCategoryPanel add={this.handleAdd.bind(this)}/>
                        </Route>
                        <Route>
                            <div>
                                <Link className={"btn btn-success"} to={"/categories/add"}>Add Category</Link>
                            </div>
                        </Route>
                    </Switch>
                </div>
                <div className="box box-success">
                    <div className="box-body no-padding">
                        <div>
                            <table className = "table table-bordered" id="listBarcodes">
                                <thead>
                                <tr>
                                    <th scope="col">Id</th>
                                    <th scope="col">Categories</th>
                                    <th scope="col">Operation</th>
                                </tr>
                                </thead>
                                <tbody>
                                {this.props.allCategories.map((category, index)=>{
                                    return (
                                        <tr key={index}>
                                            <td>
                                                {category.id}
                                            </td>
                                            <td>
                                                {category.source !== "VOT" ? category.category + " (" + category.source + ")" : category.category}
                                            </td>
                                            <td>
                                                {category.source === "VOT" && <button
                                                    className={"btn btn-danger"}
                                                    onClick={(event) => {this.handleDelete(category.id)}}>
                                                    Delete
                                                </button>}
                                                {category.source !== "VOT" && <div>
                                                    <p>category created by {category.source}, </p>
                                                    <p>It can only be deleted from {category.source} side</p>
                                                </div>}
                                            </td>
                                        </tr>
                                    )
                                })}
                                </tbody>
                            </table>
                        </div>
                    </div>
                </div>
            </section>

        )
    }
}

export const Categories = withGlobalData(_Categories);