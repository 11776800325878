import {Link, Route, Switch} from "react-router-dom";

import React, {useEffect, useState} from "react";

import {NavTab, RoutedTabs} from 'react-router-tabs';
import {mediaManagementServiceApi, S3Service} from "./api";
import {IGlobalDataContext, withGlobalData} from "./contexts/global-data";
import {VendingMachineGroup} from "./models";
import {ScreenSaverListPage} from "./screen_list_page";
import BusyIndicator, {LoadingSpanner} from "./busy_indicator";
import {TelemetryvendingMachinev1ScreenSaverMediaType} from "./telemetry/vending_machine/v1/typescript-axios";
import axios from "axios";


interface Props extends IGlobalDataContext {
    match: {
        params: {
            uuid: string
        }
    }
}

function Add(props: { vendingMachineGroup: VendingMachineGroup }) {
    const [duration, setDuration] = useState(60);
    const [UploadFile, setUploadFile] = useState(null);
    const [cta, setCta] = useState("");
    const [mediaType,setMediaType] = useState<TelemetryvendingMachinev1ScreenSaverMediaType>()

    const [saving, setSaving] = useState(false);

    const requestTimeout = 600000;
    const submit = async (e) => {
        e.preventDefault()
        setSaving(true);
        if (!UploadFile || duration === 0) {
            alert("Screen file and Duration is required")
            setSaving(false);
            return
        }
        let presignedUrl=await new S3Service().s3ServiceGetS3UploadPath()
       if (presignedUrl.status!==200){
           alert("Error get uploading path")
           return
       }
       if (presignedUrl.data.url){
           let result=await axios.put(presignedUrl.data?.url, UploadFile)
           if (result.status!==200){
               setSaving(false);
               alert("upload file error")
               return
           }
       }
        try {
            let data = await new mediaManagementServiceApi().mediaManagementServiceAddScreenSaver({
                screenSaverPath: presignedUrl.data.path,
                duration: duration.toString(),
                vendingMachineGroupUuid: props.vendingMachineGroup.uuid,
                cta:cta,
                mediaType:mediaType,
            },{
                // The request may include a video, set a larger timeout
                timeout:requestTimeout,
            })
            if (data.status !== 200) {
                alert("Internal Error - failed to process request")
            } else {
                alert("Successfully added screen saver")
            }
        } catch (e) {
            alert("Internal Error - failed to process request")
        } finally {
            setSaving(false);
        }

    }
    const saveFile = async (e) => {
        let file = e.target.files[0];
        setUploadFile(file)
        let reader = new FileReader();


        reader.onloadend =async function (e) {
            if(file.type.match('video.*')){
                let video = document.createElement('video');
                video.src = URL.createObjectURL(file);
                setMediaType(TelemetryvendingMachinev1ScreenSaverMediaType.Video)
                video.onloadedmetadata= function () {
                    let duration = video.duration;
                    setDuration(Math.floor(duration))
                    console.log("video duration:"+duration)
                }

            }else if(file.type.match('image.*')){
                setMediaType(TelemetryvendingMachinev1ScreenSaverMediaType.Image)
            }else{
                alert("Unsupported file type")
            }
        };
        if(file){
            reader.readAsDataURL(file);
        }

    }

    const changeMediaType = (e) => {
        setMediaType(e.target.value)
    }

    return <div style={{"marginTop": "20px"}}>
        <form onSubmit={submit}>
            <div className="form-group">
                <label>Vending Machine Group</label>
                <input className="form-control" disabled={true}
                       value={props.vendingMachineGroup.name}/>
            </div>

            <div className="form-group">
                <label>Media Type*</label>
                <select className="form-control" value={mediaType} onChange={changeMediaType} disabled={true}>
                    <option value={TelemetryvendingMachinev1ScreenSaverMediaType.Image}>Image</option>
                    <option value={TelemetryvendingMachinev1ScreenSaverMediaType.Video}>Video</option>
                </select>
            </div>
            <div className="form-group">
                <label>Screen file (only png/mp4)*</label>
                <input className="form-control" type="file" placeholder={"Quantity"}
                       onChange={saveFile}/>
            </div>
            <div className="form-group">
                <label>Duration*</label>
                <input className="form-control" value={duration} placeholder={"duration unit is second"}
                       onChange={(event) => {
                           let num = parseInt(event.target.value)
                           Number.isNaN(num) ? setDuration(0) : setDuration(num)
                       }}/>
            </div>
            <div className="form-group">
                <label>Description</label>
                <textarea rows={3} className="form-control" value={cta} placeholder={"input description"}
                       onChange={(event) => {
                           setCta(event.target.value)
                       }}/>
            </div>
            <BusyIndicator busy={saving} busyIndicator={<LoadingSpanner/>}>
                <input type="submit" className="btn btn-success" value="Add screen"/>
            </BusyIndicator>
            &nbsp; &nbsp;
            <Link className={"btn btn-danger"}
                  to={"/vending_machine_hierarchy_management"}>Cancel</Link>
        </form>
    </div>
}

function _ManageScreenSavers(props: Props) {

    const [vendingMachineGroup, setVendingMachineGroup] = useState<VendingMachineGroup | null>(null);

    useEffect(() => {
        setVendingMachineGroup(props.allVendingMachineGroups.find((vendingMachineGroup) => {
            return vendingMachineGroup.uuid === props.match.params.uuid
        }) || null);
    }, [props.match, props.allVendingMachineGroups]);

    return (<section className="content-header">
            <h1 style={{"marginBottom": "20px"}}>
                Manage Screen Savers for VoTi enabled vending machines - {vendingMachineGroup?.name}
            </h1>
            <RoutedTabs startPathWith={`/manage_screensavers/${props.match.params.uuid}`}>
                <NavTab exact to={`/`}>
                              <span className="hidden-xs">
                                add screen &nbsp;
                              </span>
                    <i className="fa  fa-bar-chart"></i>
                </NavTab>
                <NavTab exact to={`/list`}>
                              <span className="hidden-xs">
                                list screen &nbsp;
                              </span>
                    <i className="fa  fa-bar-chart"></i>
                </NavTab>
            </RoutedTabs>
            <Switch>
                <Route exact path={`/manage_screensavers/${props.match.params.uuid}`}>
                    {vendingMachineGroup &&
                        <Add vendingMachineGroup={vendingMachineGroup}/>
                    }
                </Route>

                <Route exact path={`/manage_screensavers/${props.match.params.uuid}/list`}>
                    {vendingMachineGroup &&
                        <ScreenSaverListPage vendingMachineGroup={vendingMachineGroup}/>
                    }
                </Route>

            </Switch>
        </section>
    );
}

export const ManageScreenSaversPage = withGlobalData(_ManageScreenSavers)
