import React, {Component} from 'react';

import {IAuthenticationContext, withAuthentication} from './authentication';
import {Link, Redirect} from 'react-router-dom';
import {getAPI, postAPI} from './api';

interface Props extends IAuthenticationContext {
    match: any
}

interface State {
    email: string
    message: string | null
    password: string
    success: boolean
    busy: boolean
}


class _ResetPassword extends Component<Props, State> {
    constructor(props) {
        super(props);

        this.state = {
            email: "",
            password: "",
            success: false,
            message: "",
            busy: false
        };

        this.handlePasswordChange = this.handlePasswordChange.bind(this);
        this.handleSubmit = this.handleSubmit.bind(this);

        this.init();
    }

    init () {
        this.setState({busy: true});
        getAPI(`/api/users/reset_password/${this.props.match.params.token}`).then((response) => {
            this.setState({busy: false});
            if (response.data.success) {
                this.setState({email: response.data.email});
            } else {
                this.setState({message: response.data.message})
            }
        })
    }

    handlePasswordChange(event) {
        this.setState({password: event.target.value});
    }

    handleSubmit(event) {
        event.preventDefault();
        if (this.state.password) {
            this.setState({busy: true});
            postAPI("/api/users/reset_password", {token: this.props.match.params.token, password: this.state.password})
                .then(result => {
                    this.setState({busy: false});
                    return result.data
                }).then(result => {
                if (result.success) {
                    this.props.login(result.securityToken, result.email, result.approved, result.hasCompany, result.tfaRequired);
                } else {
                    this.setState({message: result.message});
                }
            });
        } else {
            this.setState({message: "Please enter valid password."});
        }
    }

    render() {
        if (this.props.authentication.authenticated) {
            return <Redirect to="/"/>;
        }

        let busy = (
            <div className="busyIndicator">
                <h1>
                    Please wait... <span className="fa fa-spinner fa-spin">&nbsp;</span>
                </h1>
            </div>
        );

        let error_message = this.state.message ?
            (<div className="alert alert-danger">{this.state.message}</div>)
            : null;

        let signup_form = (

            <div className="login-box">

                <div className="login-logo">
                    Vending on Track
                </div>
                <div className="login-box-body">
                    <p className="login-box-msg">Please enter your new password.</p>


                    {error_message}

                    <form onSubmit={this.handleSubmit} className="form">
                        <div className="form-group has-feedback">
                            <input type="email" value={this.state.email} className="form-control" placeholder="Email" disabled={true}/>
                            <span className="fas fa-envelope sign-in-and-up-form-control"></span>
                        </div>
                        <div className="form-group has-feedback">
                            <input type="password" className="form-control" value={this.state.password}
                                   onChange={this.handlePasswordChange} placeholder="Password"/>
                            <span className="fas fa-lock sign-in-and-up-form-control"></span>
                        </div>

                        <div className="row">
                            <div className="col-xs-2">
                            </div>
                            <div className="col-xs-8">
                                <input type="submit" className="btn btn-primary btn-block btn-flat" value="Submit"/>
                            </div>
                        </div>
                    </form>


                    <br/>
                    <div style={{display: "flex", justifyContent: "center", alignItems: "center"}}>
                        <Link to="/login">Login here.</Link>
                    </div>
                </div>
            </div>
        );


        return (
            <div className="container">
                {(this.state.busy) ? busy : signup_form}
            </div>
        )
    }

}

let ResetPassword = withAuthentication(_ResetPassword);
export {ResetPassword};
