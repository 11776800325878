import React, {Component, Fragment} from "react";
import {CompanyUserRole, Message, Refill, RefillDetails, User} from "./models";
import {DisplayTime} from "./display_time";
import {VendingMachineLink} from "./components/vending_machine_link";
import {IGlobalDataContext, withGlobalData} from "./contexts/global-data";
import moment from "moment";
import {getAPI, postAPI} from "./api";
import {FormatMoneyWithFlexibleSignSize} from "./format_money_without_style";
import BusyIndicator, {LoadingSpanner} from "./busy_indicator";

interface Props extends IGlobalDataContext {
    refill: Refill

    refillShouldReload()

    users: Array<User>
}

interface State {
    isUpdate: boolean
    userUuid: string,
    isShowRefillDetail: boolean
    refillDetails: RefillDetails | null
    isRefillDetailLoading: boolean
}

class RefillRowRaw extends Component<Props, State> {
    constructor(props) {
        super(props);

        this.state = {
            isUpdate: false,
            userUuid: this.props.refill.user !== null ? this.props.refill.user.uuid : "",
            isShowRefillDetail: false,
            refillDetails: null,
            isRefillDetailLoading: false
        };
    }

    loadRefillDetail(){
        if (this.state.isRefillDetailLoading){
            return;
        }
        this.setState({
            isRefillDetailLoading: true
        })
        getAPI(`/api/refills/${this.props.refill.uuid}`).then((response)=>{
            if (response.data.success){
                this.setState({
                    refillDetails: response.data.refill,
                    isShowRefillDetail: true
                })
            } else {
                window.alert(response.data.message);
            }
        }).catch((e)=>{
            console.log(e);
            window.alert("Server error");
        }).finally(()=>{
            this.setState({
                isRefillDetailLoading: false
            })
        })
    }

    toggleRefillDetail() {
        if (!this.state.isShowRefillDetail){
            if (this.state.refillDetails === null){
                this.loadRefillDetail();
            } else {
                this.setState({
                    isShowRefillDetail:true
                })
            }
        } else {
            this.setState({
                isShowRefillDetail:false
            })
        }
    }

    removeRefill(event, refill: Refill) {
        event.stopPropagation();
        event.preventDefault();

        if (window.confirm("Are you sure to remove refill at " + moment.unix(refill.createdTimestamp).toISOString())) {
            postAPI(`/api/refills/${refill.uuid}`, null, "DELETE").then((resp) => {
                if (resp.data.success) {
                    this.props.pushFlashMessage(new Message("Removed.", "success"));
                    this.props.refillShouldReload();
                } else {
                    window.alert(resp.data.message);
                }
            })
        }
    }

    handleUpdateButtonClick(event) {
        event.stopPropagation();
        event.preventDefault();
        this.setState({isUpdate: true})
    }

    handleUserChange(event) {
        event.stopPropagation();
        event.preventDefault();
        console.log(event.target.value)
        this.setState({userUuid: event.target.value})
    }

    saveNewRefiller(event) {
        postAPI(`/api/refills/${this.props.refill.uuid}`, {"userUuid": this.state.userUuid !== "" ? this.state.userUuid : null}).then((response) => {
            if (response.data.success) {
                this.props.refillShouldReload();
                this.props.pushFlashMessage(new Message(response.data.message, "success"))
            } else {
                this.props.pushFlashMessage(new Message(response.data.message, "error"))
            }
            this.setState({isUpdate: false})
        })
    }

    render() {
        let refill = this.props.refill;
        this.props.allVendingMachines.allVendingMachines.forEach((vendingMachine) => {
            if (vendingMachine.uuid === refill.vendingMachineUuid) {
                refill.vendingMachine = vendingMachine;
            }
        });

        const userOptions = this.props.users.map((user) => {
            return <option key={user.uuid} value={user.uuid}>{user.email}</option>
        })

        const coilRefillRows = this.state.refillDetails?.coilRefills.sort((a, b)=>{
            return a.mdbRaw - b.mdbRaw
        }).map((coilRefill)=>{
            return <tr key={coilRefill.mdbCode}>
                <td>
                    {coilRefill.mdbCode}
                </td>
                <td>
                    {coilRefill.productName}
                </td>
                <td>
                    <FormatMoneyWithFlexibleSignSize cents={coilRefill.price}/>
                </td>
                <td>
                    {coilRefill.refillCount}
                </td>
            </tr>
        })

        return (
            <Fragment>
                <tr>
                    <td><DisplayTime timestamp={refill.createdTimestamp}/></td>
                    <td>
                        <BusyIndicator busy={this.state.isRefillDetailLoading} busyIndicator={<LoadingSpanner/>}>
                            <button className={"btn-link"} onClick={this.toggleRefillDetail.bind(this)}>{refill.changes}</button>
                        </BusyIndicator>
                    </td>
                    <td><FormatMoneyWithFlexibleSignSize cents={refill.coinAmount}/> - {refill.coinCount} coins</td>
                    <td>
                        <FormatMoneyWithFlexibleSignSize cents={refill.noteAmount}/> - {refill.noteCount} notes
                    </td>
                    <td>
                        {refill.vendingMachine &&
                            <VendingMachineLink vendingMachine={refill.vendingMachine}/>
                        }
                    </td>
                    <td>
                        {!this.state.isUpdate &&
                            <span>{refill.user? refill.user.email : '-'}</span>
                        }
                        {this.state.isUpdate &&
                            <select onChange={this.handleUserChange.bind(this)} value={this.state.userUuid}>
                                <option value="">No Filler</option>
                                {userOptions}
                            </select>
                        }
                    </td>
                    <td>
                        {this.props.me.role === CompanyUserRole.ADMIN.valueOf() && !this.state.isUpdate &&
                            <button onClick={(event) => {
                                return this.removeRefill(event, refill)
                            }} className="btn btn-danger">Delete
                            </button>
                        }
                        &nbsp;
                        {this.props.me.role === CompanyUserRole.ADMIN.valueOf() && !this.state.isUpdate &&
                            <button className={"btn btn-warning"} onClick={this.handleUpdateButtonClick.bind(this)}>
                                Modify
                            </button>}
                        {this.props.me.role === CompanyUserRole.ADMIN.valueOf() && this.state.isUpdate &&
                            <button className={"btn btn-success"} onClick={this.saveNewRefiller.bind(this)}>
                                Save
                            </button>}
                    </td>
                </tr>
                {
                    this.state.isShowRefillDetail && <tr>
                        <td colSpan={7}>
                            <table className={"table table-striped"}>
                                <thead>
                                <tr>
                                    <th>Coil</th>
                                    <th>Product</th>
                                    <th>Price</th>
                                    <th>Quantity</th>
                                </tr>
                                </thead>

                                <tbody>
                                {coilRefillRows}
                                </tbody>
                            </table>
                        </td>
                    </tr>
                }
            </Fragment>
        );
    }
}

const RefillRow = withGlobalData(RefillRowRaw);

export {RefillRow}
