import React, {Component} from 'react';
import {deviceSorting} from "./models";
import {TelemetryDeviceTableRow} from './telemetry_device_table_row';
import {Default, Mobile} from "./components/responsive";
import { Device } from './device/v1/api_pb';

interface Props {
    devices: Array<Device>

    refresh()

    isMobile?: boolean
}

interface State {
}

class TelemetryDeviceTableRaw extends Component<Props, State> {
    constructor(props) {
        super(props);

        this.state = {}
    }

    render() {
        let devices = this.props.devices.sort(deviceSorting).map((device) => {
            return (
                <TelemetryDeviceTableRow isMobile={!!this.props.isMobile} key={device.uuid} telemetryDevice={device}
                                         rowUpdate={this.props.refresh}/>);
        });

        return (
            <div className="box">
                <div className="box-header">
                    <h3 className="box-title">All telemetry devices associated with your company</h3>
                </div>
                <div className="box-body no-padding">
                    <table className="table table-condensed table-hover">
                        <thead>
                        {this.props.isMobile ?
                            <tr>
                                <th>Name/IMEI</th>
                                <th>Deployed on</th>
                                <th>Running Mode</th>
                            </tr>
                            :
                            <tr>
                                <th>Name</th>
                                <th>IMEI</th>
                                <th>Deployed on</th>
                                <th>Version</th>
                                <th>State</th>
                                <th>Uptime</th>
                                <th>Operation</th>
                            </tr>
                        }
                        </thead>
                        <tbody>
                        {devices}
                        </tbody>
                    </table>
                </div>
            </div>
        )
    }
}

export const TelemetryDeviceTable = (props: Props) => (
    <div>
        <Mobile>
            <TelemetryDeviceTableRaw isMobile={true} {...props} />
        </Mobile>
        <Default>
            <TelemetryDeviceTableRaw isMobile={false} {...props} />
        </Default>
    </div>
);
