import React, {Component} from 'react';
import {LaundryMachineSelection} from "./models";
import LaundryMachineSelectionRow from "./laundry_machine_selection_row";
import {AddLaundryMachineSelection} from "./add_laundry_machine_selection_dialog";
import {postAPI} from "./api";

interface Props {
    handleRefresh(): void;
    laundry_machine_selections: Array<LaundryMachineSelection>;
    vending_machine_uuid: string;
}

interface State {
    show_adding_dialog:boolean
}


class LaundryMachineSelectionPanel extends Component<Props, State>{
    constructor(props: Props) {
        super(props);

        this.state = {
            show_adding_dialog:false
        }
    }

    handleFormSubmit(e, laundryMachineSelection, vendingMachineUuid) {
        e.preventDefault();
        postAPI(`/api/vending_machines/${vendingMachineUuid}/laundry_machine_selection`, laundryMachineSelection).then((result) => {
            if (result.data.success) {
                this.setState({show_adding_dialog: false});
                this.props.handleRefresh();
            } else {
                alert(result.data.message);
            }
        })

    }


    handleRemove(e, machineCode) {
        if (window.confirm("Confirm delete?")) {
            postAPI(`/api/vending_machines/${this.props.vending_machine_uuid}/laundry_machine_selection/${machineCode}/remove`, {}).then((response) => {
                if (response.data.success) {
                    this.props.handleRefresh();
                } else {
                    alert(response.data.message);
                }
            })
        }
    }

    handleUpdate(e, machineCode, data){
        e.preventDefault();
        postAPI(`/api/vending_machines/${this.props.vending_machine_uuid}/laundry_machine_selection/${machineCode}`, data).then((result) => {
            if (result.data.success) {
                this.props.handleRefresh();
            } else {
                alert(result.data.message);
            }
        })
    }

    render() {
        let adding_dialog: any = null;

        if (this.state.show_adding_dialog) {
            adding_dialog = (<AddLaundryMachineSelection onSubmit={this.handleFormSubmit.bind(this)} hide_dialog={()=>{this.setState({show_adding_dialog: false})}} vm_id={this.props.vending_machine_uuid}/>)
        } else {
            adding_dialog = (
                <button onClick={()=>{this.setState({show_adding_dialog: true})}} className="btn btn-info">Add a selection</button>);
        }
        const rows = this.props.laundry_machine_selections.map((laundryMachineSelection)=>{
            return <LaundryMachineSelectionRow key={laundryMachineSelection.selection}
                                               selection={laundryMachineSelection}
                                               handleRemove={this.handleRemove.bind(this)}
                                               handleUpdate={this.handleUpdate.bind(this)}/>
        })
        return <div className="box">
            <div className="box-body">
                <h3>Laundry Machine Selection</h3>

                <div className="box box-success well">
                    <div className={"box-header"}>
                        <h4>Add new Selection</h4>
                    </div>
                    <div className={"box-body"}>
                        {adding_dialog}
                    </div>
                </div>

                <table className="table table-bordered table-hover">
                    <thead>
                    <tr>
                        <th>Name</th>
                        <th>Machine Code</th>
                        <th>Selection</th>
                        <th>Price</th>
                        <th>Discount</th>
                        <th>Processing Fee</th>
                        <th>Photo</th>
                        <th>Operation</th>
                    </tr>
                    </thead>
                    <tbody>
                    {rows}
                    </tbody>
                </table>
            </div>
        </div>;
    }
}

export {LaundryMachineSelectionPanel};
