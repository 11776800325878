import React, {Component, Fragment} from 'react';
import {Helmet} from "react-helmet";
import {getAPI, postAPI} from "./api";
import {CreditCardSurchargeGroup, Message} from "./models";
import {IGlobalDataContext, withGlobalData} from "./contexts/global-data";
import {DisplayTime} from "./display_time";
import {Link} from "react-router-dom";

interface Props extends IGlobalDataContext {

}

interface State {
    showAddRuleGroupDialog:boolean
    ruleGroups:CreditCardSurchargeGroup[],
    newGroupName:string,
    isCreditCardSurchargePayableByConsumer: boolean
    selectedGroupUuid: string | null
}

class _CompanyCreditCardSurchargeSettingsPage extends Component<Props, State> {
    constructor(props) {
        super(props);

        this.state = {
            showAddRuleGroupDialog: false,
            ruleGroups: [],
            newGroupName:"",
            isCreditCardSurchargePayableByConsumer: true,
            selectedGroupUuid:null
        }
    }

    componentDidMount(): void {
        this.loadRuleGroup()
    }


    loadRuleGroup() {
        getAPI("/api/credit_card_surcharge_group").then((response) => {
            if (response.data.success) {
                this.setState({ruleGroups: response.data.creditCardSurchargeGroups})
            }
        })
        getAPI("/api/companies/mine/credit_card_surcharge_payable_by_consumer").then((response) => {
            if (response.data.success) {
                this.setState({isCreditCardSurchargePayableByConsumer: response.data.creditCardSurchargePayableByConsumer})
            }
        })

        getAPI("/api/companies/mine/selected_credit_card_surcharge_group").then((response) => {
            if (response.data.success) {
                this.setState({selectedGroupUuid: response.data.creditCardSurchargeGroup ? response.data.creditCardSurchargeGroup.uuid : null})
            }
        })
    }

    handleAddRuleGroup(e) {
        e.preventDefault();
        postAPI("/api/credit_card_surcharge_group",
            {
                name:this.state.newGroupName
            }).then((response) => {
            if (response.data.success) {
                this.loadRuleGroup()
                this.setState({showAddRuleGroupDialog: false})
            } else {
                alert(response.data.message)
            }
        })
    }

    handleGroupNameChange(e) {
        e.preventDefault()
        this.setState({newGroupName: e.target.value})
    }

    handleCreditCardPayableByConsumer(e) {
        e.preventDefault()
        if (e.target.value === "true") {
            this.setState({isCreditCardSurchargePayableByConsumer: true}, () => {
                console.log(this.state.isCreditCardSurchargePayableByConsumer)
            })
        } else {
            this.setState({isCreditCardSurchargePayableByConsumer: false}, () => {
                console.log(this.state.isCreditCardSurchargePayableByConsumer)
            })
        }
    }

    selectSurchargeGroup(e) {
        e.preventDefault()
        if (e.target.value === "") {
            this.setState({selectedGroupUuid: null})
        } else {
            this.setState({selectedGroupUuid: e.target.value})
        }
    }

    handleDeleteRuleGroup(e, uuid) {
        e.preventDefault();
        if (window.confirm("Are you sure to remove the credit card surcharge rule group?")) {
            postAPI(`/api/credit_card_surcharge_group/${uuid}/delete`,
                {}).then((response) => {
                if (response.data.success) {
                    this.loadRuleGroup()
                } else {
                    alert(response.data.message)
                }
            })
        }
    }

    handleSaveIsCreditCardSurchargePayableByConsumer(e) {
        e.preventDefault();
        if (window.confirm("Are you sure to change the credit card surcharge payable setting?")) {
            postAPI(`/api/companies/mine/credit_card_surcharge_payable_by_consumer`,
                {"creditCardSurchargePayableByConsumer": this.state.isCreditCardSurchargePayableByConsumer}).then((response) => {
                if (response.data.success) {
                    this.loadRuleGroup()
                    this.props.pushFlashMessage(new Message("Saved", "success"))
                } else {
                    alert(response.data.message)
                }
            })
        }
    }

    handleSaveSelectedCreditSurchargeGroup(e) {
        e.preventDefault();
        if (window.confirm("Are you sure to change the credit card surcharge group setting?")) {
            postAPI(`/api/companies/mine/selected_credit_card_surcharge_group`,
                {"uuid": this.state.selectedGroupUuid}).then((response) => {
                if (response.data.success) {
                    this.loadRuleGroup()
                    this.props.pushFlashMessage(new Message("Saved", "success"))
                } else {
                    alert(response.data.message)
                }
            })
        }
    }

    render() {
        const rows = this.state.ruleGroups.map((group) => {
            return <tr key={group.uuid}>
                <td>{group.name}</td>
                <td>{group.uuid}</td>
                <td><DisplayTime timestamp={group.createdAt}/></td>
                <td>
                    <button className={"btn btn-danger"} onClick={(e) => {
                        this.handleDeleteRuleGroup(e, group.uuid)
                    }}>Delete
                    </button>
                    <Link className={'btn btn-success'} to={`/company_credit_card_surcharge_rules/${group.uuid}`}>View</Link>
                </td>
            </tr>
        })

        const options = this.state.ruleGroups.map((group)=>{
            return <option key={group.uuid} value={group.uuid}>{group.name}</option>
        })

        return (
            <Fragment>
                <Helmet titleTemplate="%s - Vending on Track">
                    <title>Company Credit Card Surcharge Rule Settings</title>
                </Helmet>
                <section className="content-header">
                    <h1>
                        Company Credit Card Surcharge Rule Settings
                    </h1>
                    <div className="help-block">
                        Credit Card Surcharge Rule Settings page lists all the Credit Card Surcharge Rule.
                    </div>
                </section>
                <section className="content">
                    <div className={"row"}>
                        <div className={"col-xs-12 col-sm-8"}>
                            <div className={"well"}>
                                {
                                    this.state.showAddRuleGroupDialog && <form action="">
                                        <div className="form-group">
                                            <label>Name</label>
                                            <input className="form-control" placeholder={"Group Name"} type="text"
                                                   onChange={this.handleGroupNameChange.bind(this)}/>
                                        </div>
                                        <button className="btn btn-success" onClick={this.handleAddRuleGroup.bind(this)}>Save
                                        </button>
                                        &nbsp; &nbsp;
                                        <button className="btn btn-danger" onClick={() => {
                                            this.setState({showAddRuleGroupDialog: false})
                                        }}>Cancel
                                        </button>
                                    </form>
                                }
                                {!this.state.showAddRuleGroupDialog && <button className="btn btn-success" onClick={() => {
                                    this.setState({showAddRuleGroupDialog: true})
                                }}>Add Rule Group</button>}
                            </div>
                            <div className="box box-success">
                                <div className="box-body no-padding">
                                    <div>
                                        <table className="table table-bordered">
                                            <thead>
                                            <tr>
                                                <th>Name</th>
                                                <th>UUID</th>
                                                <th>Create At</th>
                                                <th>Operation</th>
                                            </tr>
                                            </thead>
                                            <tbody>
                                            {rows}
                                            </tbody>
                                        </table>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className={"col-xs-12 col-sm-4"}>
                            <div className={"well"}>
                                <form action="">
                                    <div className={"form-group"}>
                                        <label>Credit Card Surcharge Payable By Consumer:</label>
                                        <select className={"form-control"}
                                                value={this.state.isCreditCardSurchargePayableByConsumer ? "true" : "false"}
                                                onChange={this.handleCreditCardPayableByConsumer.bind(this)}>
                                            <option value="true">Yes</option>
                                            <option value="false">No</option>
                                        </select>
                                    </div>
                                    <div>
                                        <button className="btn btn-success"
                                                onClick={this.handleSaveIsCreditCardSurchargePayableByConsumer.bind(this)}> Save
                                        </button>
                                    </div>
                                </form>
                            </div>

                            <div className={"well"}>
                                <form action="">
                                    <div className={"form-group"}>
                                        <label>Selected Credit Surcharge Group:</label>
                                        <select className={"form-control"}
                                                value={this.state.selectedGroupUuid ? this.state.selectedGroupUuid : ""}
                                                onChange={this.selectSurchargeGroup.bind(this)}>
                                            <option value={""}>None</option>
                                            {options}
                                        </select>
                                    </div>
                                    <div>
                                        <button className="btn btn-success"
                                                onClick={this.handleSaveSelectedCreditSurchargeGroup.bind(this)}> Save
                                        </button>
                                    </div>
                                </form>
                            </div>
                        </div>

                    </div>
                </section>
            </Fragment>
        )
    }
}

const CompanyCreditCardSurchargeSettingsPage = withGlobalData(_CompanyCreditCardSurchargeSettingsPage);

export default CompanyCreditCardSurchargeSettingsPage;
