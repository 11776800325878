import React, {Component} from "react";
import DatePicker from 'react-datepicker';
import "react-datepicker/dist/react-datepicker.css";
import {EodReport} from './eod_report';
import {Helmet} from "react-helmet";
import {IGlobalDataContext, withGlobalData} from "./contexts/global-data";
import moment from "moment";
import 'moment/min/locales';

interface Props extends IGlobalDataContext{
    match: any
    history: any
}

interface State {
}


class _EodReportPage extends Component<Props, State> {
    constructor(props) {
        super(props);

        this.state = {};
    }

    handleDateChange(date) {
        const strDate = `${date.getFullYear()}-${date.getMonth() + 1}-${date.getDate()}`;
        this.props.history.push(`/eod/${strDate}`);
    }

    render() {
        let date = this.props.match.params['date'] ? new Date(this.props.match.params.date) : null;
        let dateFormat = moment().locale(this.props.me.localeISO).localeData().longDateFormat("L").replace("DD", "dd").replace("YYYY", "yyyy");
        return (
            <div>
                <Helmet titleTemplate="%s - Vending on Track">
                    <title>View End of Day reports</title>
                </Helmet>
                <section className="content-header">
                    <h1>
                        View End of Day reports
                    </h1>
                </section>
                <section className="content">
                    <div className={"box box-success"}>
                        <div className={"box-body with-border"}>
                            <div className="form-group">
                                <label>Please pick a date for EOD report:</label>
                                <DatePicker className="form-control"
                                    selected={date}
                                    onChange={this.handleDateChange.bind(this)}
                                            dateFormat={dateFormat}/>
                            </div>
                        </div>
                    </div>

                    {date == null ? null : <EodReport date={date} locale={this.props.me.localeISO}/>}

                </section>
            </div>
        );
    }

}

const EodReportPage = withGlobalData(_EodReportPage);

export {EodReportPage}