import React, {Component, Fragment} from 'react';
import {Notification} from "./models";
import {DisplayTime} from "./display_time";
import {VendingMachineLink} from "./components/vending_machine_link";
import {IGlobalDataContext, withGlobalData} from "./contexts/global-data";

interface Props extends IGlobalDataContext {
    notification: Notification
    ack(e: any): boolean
}

interface State {

}

class NotificationComponentWithoutOnline extends Component<Props, State> {
    render() {
        const uuid = this.props.notification.vendingMachine ? this.props.notification.vendingMachine.uuid : '';
        const vendingMachine = this.props.findVendingMachine(uuid);
        return (
            <Fragment>
                <li className="time-label">
                    <span className="bg-red">
                       <DisplayTime timestamp={this.props.notification.createdTimestamp} />
                    </span>
                </li>
                <li>
                    <i className="fa fa-envelope bg-blue"></i>
                    <div className="timeline-item">
                        <span className="time">
                            <i className="fa fa-clock-o"></i>
                            &nbsp;
                            <DisplayTime expanded={true} timestamp={this.props.notification.createdTimestamp} />
                        </span>

                        <h3 className="timeline-header">
                            {vendingMachine ?
                                <span>
                                    Vending Machine [
                                    <VendingMachineLink vendingMachine={vendingMachine}/>
                                    ]
                                </span>
                                :
                                <span>System</span>
                            }
                        </h3>

                        <div className="timeline-body">
                            { this.props.notification.message }
                        </div>

                        <div className="timeline-footer">
                            <button onClick={this.props.ack} className="btn btn-primary btn-xs">Acknowledge and Hide</button>
                        </div>
                    </div>
                </li>
            </Fragment>
        );
    }
}

export const NotificationComponent = withGlobalData(NotificationComponentWithoutOnline);