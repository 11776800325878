import {IAuthenticationContext, withAuthentication} from './authentication';

import React, {Component} from 'react';
import {Link} from "react-router-dom";
import {md5} from "./md5";
import {IGlobalDataContext, withGlobalData} from "./contexts/global-data";
import Select from "react-select";
import {postAPI} from "./api";
import {CompanyUserRole} from "./models";
import {DateTime} from "luxon";

interface Props extends IAuthenticationContext, IGlobalDataContext {
}

interface State {
    userPanelOpen: boolean
    switchCompanyPanelOpen: boolean
}

class BareHeader extends Component<Props, State> {
    constructor(props: any) {
        super(props);

        this.state = {
            userPanelOpen: false,
            switchCompanyPanelOpen: false,
        }
    }

    componentDidMount(): void {
    }

    handleSidebarCollapse(event) {
        event.preventDefault();
        document.body.classList.toggle("sidebar-collapse");
        document.body.classList.toggle("sidebar-open");

        document.body.classList.toggle("manual-controlled"); // user is manually controlling sidebar
        return false;
    }

    toggleUserPanel(event: React.MouseEvent<HTMLAnchorElement>): void {
        event.preventDefault();

        this.setState({userPanelOpen: !this.state.userPanelOpen});
    }


    toggleSwitchCompanyPanel(event: React.MouseEvent<HTMLAnchorElement>): void {
        event.preventDefault();

        this.setState({switchCompanyPanelOpen: !this.state.switchCompanyPanelOpen});
    }

    selectCompany(option) {
        postAPI(`/api/users/company`, {companyUuid: option.value}).then((response) => {
            if (response.data.success) {
                window.location.href="/"
            } else {
                window.alert(response.data.message);
            }
        });
    }

    render() {
        const userTimeZoneOffset = DateTime.local().setZone(this.props.me.timezone).offset;
        const browserTimezoneOffset = DateTime.local().offset;
        if (this.props.authentication.authenticated) {
            let selections = this.props.me?.allAssociatedCompanies
                .filter((companyInfo) => companyInfo.companyUuid !== this.props.me.mostRecentCompany.companyUuid)
                .map((companyInfo) => {
                    return {
                        value: companyInfo.companyUuid,
                        label: companyInfo.name
                    }
                });

            let currentCompanyName = '';
            this.props.me?.allAssociatedCompanies.forEach((company) => {
                if (company.companyUuid === this.props.me.mostRecentCompany.companyUuid) {
                    currentCompanyName = company.name;
                }
            })

            return (
                <header className="main-header">
                    <Link to="/" className="logo">
                        <span className="logo-lg">Vending on Track</span>
                    </Link>

                    <nav className="navbar navbar-static-top">
                        <a href="/" className="sidebar-toggle" data-toggle="push-menu" role="button"
                           onClick={this.handleSidebarCollapse}>

                            <i className="fa fa-bars"> </i>
                        </a>

                        <div className="navbar-custom-menu">

                            {userTimeZoneOffset !== browserTimezoneOffset &&
                                <ul className="nav navbar-nav">
                                    <li>
                                        <Link to="/settings" style={{ color: 'yellow' }}>
                                            <i className="fas fa-clock"></i>&nbsp;
                                            Your browser timezone differs from your profile timezone.
                                        </Link>
                                    </li>
                                </ul>
                            }

                            <ul className="nav navbar-nav">
                                {this.props.me?.role === CompanyUserRole.ADMIN.valueOf() &&
                                <li className="notifications-menu">
                                    <Link to="/notifications" className="dropdown-toggle">
                                        <i className="fa fa-bell-o"></i>

                                        {this.props.notificationCount === 0 ?
                                            <span className="label label-success">{this.props.notificationCount}</span>
                                            :
                                            <span className="label label-warning">{this.props.notificationCount}</span>
                                        }
                                    </Link>
                                </li>
                                }

                                {!(selections && selections.length > 0) ? null :
                                    <li className={"dropdown user user-menu " + (this.state.switchCompanyPanelOpen ? 'open' : '')}>
                                        <a href="/" onClick={this.toggleSwitchCompanyPanel.bind(this)} className="dropdown-toggle"
                                           data-toggle="dropdown">
                                            <i className="fas fa-house-user d-xs-none" aria-hidden="true"/>
                                            <span className="hidden-xs">
                                            switch company
                                        </span>
                                        </a>
                                        <ul className="dropdown-menu">
                                            <div>
                                                <div>
                                                    <div className="box-header">
                                                        <h5>Current Company: {currentCompanyName}</h5>
                                                    </div>
                                                    <div className="box-body">
                                                        <form>
                                                            <div className="form-group">
                                                                <label>Switch to new company: </label>
                                                                <Select
                                                                    onChange={this.selectCompany.bind(this)}
                                                                    options={selections}
                                                                />
                                                            </div>
                                                        </form>
                                                    </div>
                                                </div>
                                            </div>
                                        </ul>
                                    </li>
                                }

                                <li className={"dropdown user user-menu " + (this.state.userPanelOpen ? 'open' : '')}>
                                    <a href="/" onClick={this.toggleUserPanel.bind(this)} className="dropdown-toggle"
                                       data-toggle="dropdown">
                                        <i className="fas fa-user d-xs-none" aria-hidden="true"/>
                                        <span className="hidden-xs">
                                            {this.props.authentication.email}
                                        </span>
                                    </a>
                                    <ul className="dropdown-menu">
                                        <li className="user-header">
                                            <img
                                                src={`https://www.gravatar.com/avatar/${md5(this.props.authentication.email)}?s=160`}
                                                className="img-circle" alt="User"/>

                                            <p>
                                                {this.props.authentication.email}
                                            </p>
                                        </li>

                                        <li className="user-footer">
                                            <div className="pull-left">
                                                <Link onClick={() => {
                                                    this.setState({userPanelOpen: false})
                                                }} to="/settings" className="btn btn-default btn-flat">Settings</Link>
                                            </div>
                                            <div className="pull-right">
                                                <a onClick={() => {
                                                    this.props.logout()
                                                }} href="/" className="btn btn-default btn-flat">Sign out</a>
                                            </div>
                                        </li>
                                    </ul>
                                </li>

                            </ul>
                        </div>
                    </nav>
                </header>
            );
        } else {
            return null;
        }
    }


}

const Header = withGlobalData(withAuthentication(BareHeader));

export {Header};
