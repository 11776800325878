import React, {useEffect, useState} from 'react';
import {Barcode, ItemPicking, PickingList, Product, VendingMachine} from "./models";
import {IGlobalDataContext, withGlobalData} from "./contexts/global-data";
import {postAPI} from "./api";
import {Tooltip} from "react-tippy";

interface Props extends IGlobalDataContext {
    prekittingId: number
    preview: Array<PickingList>
    pickings: Array<ItemPicking>
    vendingMachine: VendingMachine | null
    displaySlot: boolean
    displayLagging: boolean
    product: Product
    reload: Function
    barcodes:{barcode: Barcode}[]
}

function _PickingQuantity(props: Props) {

    const [editQuantity, setEditQuantity] = useState(new Array(props.pickings.length).fill(false))
    // JSON.parse(JSON.stringify(props.pickings)) use to deep cope the props.pickings to avoid props change when local state change
    const [pickings, setPickings] = useState(JSON.parse(JSON.stringify(props.pickings)))
    useEffect(()=>{
        setPickings(JSON.parse(JSON.stringify(props.pickings)))
    }, [props.pickings])

    const total = pickings.reduce((v, picking) => {
        return v + picking.quantity
    }, 0);
    const slots = props.pickings.length;

    const previewPickingList = props.preview.find((pickingList) => {
        return pickingList.vendingMachineUuid === props.vendingMachine?.uuid;
    });

    const previewItemPickings = previewPickingList == null ? [] : previewPickingList.pickings.filter((value) => {
        return value.product_id === parseInt(props.product.id);
    });

    const totalRequired = previewItemPickings.map((v) => {
        return v.quantity
    })
        .reduce((previousValue, currentValue) => {
            return currentValue + previousValue
        }, 0);

    const totalGap = totalRequired - total;
    const pickingPackage = props.barcodes.filter((barcode)=>{
        return barcode.barcode.product.id === props.product.id
    }).map((barcode)=>{
        const packages = Math.floor(totalRequired/barcode.barcode.quantity) < 0 ? 1:Math.floor(totalRequired/barcode.barcode.quantity)+1;
        const packagesQuantity = barcode.barcode.quantity;
        return <><span>{packages}&nbsp;Packs, &nbsp;{packagesQuantity}&nbsp;{props.product.productUnit}&nbsp; Package</span>
            &nbsp;
            <Tooltip title="Click to Accept Suggestion">
                <span style={{color: "green"}}>
                <i className="fa fa-check" aria-hidden="true" onClick={(e)=>{
                    handlePickingPackage(e, pickings, packages*packagesQuantity)
                }}></i>
            </span>
            </Tooltip>
            {/*<button className={"btn btn-info"}>Update Quantity</button>*/}
            <br/>
            </>
    })


    const [showDetail, setShowDetail] = useState<boolean>(false);

    function toggleDetail(e) {
        e.preventDefault();
        e.stopPropagation();
        setShowDetail(!showDetail);
    }

    function toggleEdit(index) {
        let newEditQuantity = [...editQuantity]
        newEditQuantity[index] = !editQuantity[index]
        setEditQuantity(newEditQuantity)
    }

    function submitQuantity(e, index, id){
        e.preventDefault();
        e.stopPropagation();
        postAPI(`/api/prekitting_items/${id}`, {'quantity':pickings[index].quantity}).then((response)=>{
            if (response.data.success){
                props.reload()
                toggleEdit(index)
            }
        })
    }

    function changeQuantity(e, index){
        let newPickings = [...pickings]
        newPickings[index].quantity = parseInt(e.target.value)
        setPickings(newPickings)
    }

    function handlePickingPackage(e, pickings, quantity){
        if (pickings.length > 0){
            let newPickings = [...pickings]
            newPickings[0].quantity = parseInt(quantity)
            setPickings(newPickings)
            submitQuantity(e, 0, pickings[0].prekitting_item_id)
        }
    }

    function handleCancel(index){
        let newPickings = [...pickings]
        newPickings[index].quantity = props.pickings[index].quantity
        setPickings(newPickings)
        toggleEdit(index)
    }

    const coils = pickings.map((v, idx) => {
        return <li key={idx}>
            {!editQuantity[idx] && <p>{v.quantity} for [#{v.coil}] <button className={"btn btn-info"} onClick={()=>{toggleEdit(idx)}}>Edit</button></p>}
            {editQuantity[idx] &&
                <div>
                    <form onSubmit={(e)=>{submitQuantity(e, idx, v.prekitting_item_id)}}>
                        <input type="number" onChange={(e)=>{changeQuantity(e, idx)}} value={v.quantity}/>
                        for [#{v.coil}]
                        <input className={"btn btn-success"} type="submit"/>
                        <button className={"btn btn-danger"} onClick={()=>{handleCancel(idx)}}>Cancel</button>
                    </form>
                </div>}
        </li>
    });

    return (<div>
        {total > 0 ?
            <>
                    <a onClick={toggleDetail} href={"/"} className={"text-success"}>
                        Total Picking: {total} {props.product.productUnit}
                        {props.displaySlot && <> for {slots} slots</>}
                        {props.displayLagging && <> +{totalGap}</>}
                    </a>
                    {showDetail &&
                    <ul>
                        {coils}
                    </ul>
                    }
                    <br/>
                    <hr/>
                    {pickingPackage.length ? "VOT Suggestion: " : "-"}
                    <br/>
                    {pickingPackage}
            </>
            : <>- {props.displayLagging && <> +{totalGap}</>}</>
        }
    </div>)
}

const PickingQuantity = withGlobalData(_PickingQuantity);

export default PickingQuantity;

