import {Category} from "./models";
import React, {Component} from "react";
import moment from "moment";
import {getAPI, postAPI} from "./api";
import Select from "react-select";
import DatePicker from "react-datepicker";
import {IGlobalDataContext, withGlobalData} from "./contexts/global-data";
import {CategoryRevenueReportChart} from "./category_revenue_report_chart";
import {Helmet} from "react-helmet";

interface Props extends IGlobalDataContext{

}

interface State {
    selectedCategories: Array<Category>
    fromDate: Date
    toDate: Date
    reportLoading: boolean
    report: any
    interval: number
    allCategories: Array<Category>
}

class RawDurationRevenueReportByCategory extends Component<Props, State> {
    constructor(props) {
        super(props);

        this.state = {
            selectedCategories: [],
            fromDate: new Date(),
            toDate: new Date(),
            reportLoading: false,
            report: null,
            interval: 1,
            allCategories:[]
        }
    }

    componentDidMount() {

        getAPI('/api/categories').then((response)=>{
            if (response.data.success){
                this.setState({allCategories:response.data.productCategories}, ()=>{console.log("AllCategories", this.state.allCategories)});
            } else {
                alert(response.data.message);
            }
        })
    }

    report(e) {
        e.preventDefault();
        e.stopPropagation();

        console.log(moment(this.state.fromDate).format("YYYY-MM-D"));
        console.log(moment(this.state.toDate).format("YYYY-MM-D"));

        const ids = this.state.selectedCategories.map((category) => {
            return category.id;
        });

        postAPI(`/api/eod/category`, {
            fromDate: moment(this.state.fromDate).format("YYYY-MM-D"),
            toDate: moment(this.state.toDate).format("YYYY-MM-D"),
            categoryIdList: ids,
            interval:this.state.interval
        }).then((resp) => {
            this.setState({reportLoading: false});
            if (resp.data.success) {
                this.setState({report: resp.data.categoryRevenueReport});
                console.log("report")
                console.log(resp.data.categoryRevenueReport);
            } else {
                window.alert(resp.data.message);
            }
        });
        this.setState({reportLoading: true, report: null});

        return false;
    }

    fromDateChange(date) {
        this.setState({fromDate: date})
    }

    toDateChange(date) {
        this.setState({toDate: date})
    }

    selectCategory(options) {
        let categories: Array<Category> = [];

        if (options !== null){
            options.forEach((selection) => {
                const category = this.state.allCategories.find((category)=>{return category.id === selection.value})
                if (category) {
                    categories.push(category);
                }
            });

        }
        this.setState({
            selectedCategories: categories
        });
    }

    selectInterval(event){
        console.log(event.target.value)
        this.setState({interval:event.target.value})
    }

    render() {
        const categoriesOption = this.state.allCategories.map((category)=>{
            return {
                value:category.id,
                label:category.category
            }
        })
        let dateFormat = moment().locale(this.props.me.localeISO).localeData().longDateFormat("L").replace("DD", "dd").replace("YYYY", "yyyy");
        return (
            <div>
                <Helmet titleTemplate="%s - Vending on Track">
                    <title>Category Revenue Report</title>
                </Helmet>
                <section className="content-header">
                    <h1>
                        Category Revenue Report [Duration]
                    </h1>
                </section>
                <section className="content">
                    <div className={"box box-success"}>
                        <div className={"box-body with-border"}>
                            <div className="form-group">
                                <label>Please choose category to run report: </label>
                                <Select
                                    isMulti
                                    onChange={this.selectCategory.bind(this)}
                                    options={categoriesOption}
                                />
                            </div>
                            <div className="form-group">
                                <label>From: </label>
                                <DatePicker className="form-control"
                                            selected={this.state.fromDate}
                                            onChange={this.fromDateChange.bind(this)} dateFormat={dateFormat}/>
                            </div>
                            <div className="form-group">
                                <label>To: </label>
                                <DatePicker className="form-control"
                                    selected={this.state.toDate}
                                    onChange={this.toDateChange.bind(this)} dateFormat={dateFormat}/>
                            </div>
                            <div className="form-group">
                                <label>Interval: </label>
                                <select onChange={this.selectInterval.bind(this)} value={this.state.interval} className="form-control">
                                    <option value={1}>1 Day</option>
                                    <option value={7}>1 Week</option>
                                    <option value={14}>2 Weeks</option>
                                    <option value={28}>4 Weeks</option>
                                </select>
                            </div>
                            <div className="form-group">
                                {this.state.selectedCategories.length > 0 ? <button onClick={this.report.bind(this)} className="btn btn-success">Generate report
                                </button> : <button disabled className="btn btn-success">Generate report
                                </button>}
                            </div>
                        </div>
                    </div>
                    {this.state.reportLoading &&
                    <div className={"box box-success"}>
                        <div className={"box-header"}>
                            <h1>Loading ...</h1>
                        </div>
                    </div>
                    }

                    {!this.state.reportLoading && this.state.report &&
                    <div className="box box-success">
                        <div className={"box-header"}>
                            <h1>Report</h1>
                        </div>
                        <div className="box-body">
                            <CategoryRevenueReportChart categories={this.state.selectedCategories}
                                                          report={this.state.report}
                                                          decimalPoints={this.props.me.mostRecentCompany.currencyDecimalPoints}
                                                          currencySymbol={this.props.me.mostRecentCompany.currencySymbol} locale={this.props.me.localeISO}/>
                        </div>
                    </div>
                    }
                </section>
            </div>
        )
    }
}

const DurationRevenueReportByCategory = withGlobalData(RawDurationRevenueReportByCategory);
export default DurationRevenueReportByCategory;
