import {IAuthenticationContext, withAuthentication} from "./authentication";
import React, {Component} from "react";
import {Helmet} from "react-helmet";
import {ProductRecommendationForm} from "./models";
import {getAPI, postAPI} from "./api";
import RecommendProductElement from "./recommend_product_element";

interface Props extends IAuthenticationContext {
    match: any
}

interface State {
    productRecommendationForm: ProductRecommendationForm | null
    additionalProduct:string
    comment:string
    categoryFilter:string,
    invalidToken:boolean
}

class _SiteOwnerProductRecommendationForm extends Component<Props, State> {
    constructor(props) {
        super(props);

        this.state = {
            productRecommendationForm:null,
            additionalProduct:"",
            comment:"",
            categoryFilter:"ALL",
            invalidToken:false
        };
    }

    componentDidMount() {
        this.reloadProductRecommendationForm();
    }

    reloadProductRecommendationForm(){
        getAPI(`/api/site_owner/product_recommendation_form/${this.props.match.params.token}`).then((response)=>{
            if (response.data.success){
                this.setState({
                    productRecommendationForm:response.data.productRecommendationForm
                })
            } else {
                this.setState({invalidToken:true})
            }
        })
    }

    checkProduct(e, id, wantLevel:string){
        e.preventDefault();
        e.stopPropagation();
        postAPI(`/api/site_owner/product_recommendation_form/${this.props.match.params.token}/product_recommendation_map/${id}`, {wantLevel:wantLevel}).then((response)=>{
            if (response.data.success){
                this.reloadProductRecommendationForm()
            } else {
                window.alert(response.data.message)
            }
        })
    }

    handleAdditionalProductChange(e){
        e.preventDefault();
        e.stopPropagation();
        this.setState({additionalProduct:e.target.value})
    }

    handleCommentChange(e){
        e.preventDefault();
        e.stopPropagation();
        this.setState({comment:e.target.value})
    }

    submitForm(e){
        e.preventDefault();
        e.stopPropagation();
        postAPI(`/api/site_owner/product_recommendation_form/${this.props.match.params.token}/complete`, {
            additionalProduct:this.state.additionalProduct,
            comment:this.state.comment
        }).then((response)=>{
            if (response.data.success){
                this.reloadProductRecommendationForm();
            } else {
                window.alert(response.data.message);
            }
        });
    }

    filterCategory(e:any){
        e.preventDefault();
        e.stopPropagation();
        this.setState({categoryFilter:e.target.value})
    }

    render() {
        let rows;
        let uniqueCategories:string[] = [];
        if(this.state.productRecommendationForm !== null){
            if (this.state.productRecommendationForm.completed){
                return <section className="content">
                    <Helmet titleTemplate="%s - Vending on Track">
                        <title>Product Recommendation Form</title>
                    </Helmet>
                    <h1>Product Recommendation Form For Site {this.state.productRecommendationForm.siteName} has been submitted</h1>
                </section>;
            }
            rows = this.state.productRecommendationForm.productRecommendationMaps.filter((productRecommendationMap)=>{
                if (this.state.categoryFilter === "ALL"){
                    return productRecommendationMap
                } else {
                    return productRecommendationMap.product.category === this.state.categoryFilter
                }
            }).map((productRecommendationMap)=>{
                return <RecommendProductElement productRecommendationMap={productRecommendationMap} checkProduct={this.checkProduct.bind(this)} key={productRecommendationMap.id}/>
            })
            uniqueCategories = Array.from(new Set(this.state.productRecommendationForm.productRecommendationMaps.map((prm)=>{
                return prm.product.category;
            })));
        }

        const categoriesOption = uniqueCategories.filter((category)=>{return category !== ""}).map((category)=>{
            return <option key={category} value={category}>{category}</option>
        })
        if (!this.state.invalidToken){
            return <section className="content">
                <Helmet titleTemplate="%s - Vending on Track">
                    <title>Product Recommendation Form</title>
                </Helmet>
                <nav className="navbar navbar-default navbar-fixed-top">
                    <div className="container">
                        <div className={"nav navbar-nav navbar-toggle collapsed"} style={{float:"left"}}>
                            <h3 className="navbar-text">Product Recommendation Form For Site {this.state.productRecommendationForm !== null && this.state.productRecommendationForm.siteName}</h3>
                            <form className="navbar-form navbar-right">
                                <div className="form-group">
                                    <select className="form-control" onChange={this.filterCategory.bind(this)}>
                                        <option value="ALL">All</option>
                                        {categoriesOption}
                                    </select>
                                </div>
                            </form>
                            <button className="btn btn-success navbar-btn" onClick={this.submitForm.bind(this)}>Save</button>
                        </div>
                        <div className={"nav navbar-nav navbar-collapse collapse"}>
                            <h3 className="navbar-text">Product Recommendation Form For Site {this.state.productRecommendationForm !== null && this.state.productRecommendationForm.siteName}</h3>
                            <form className="navbar-form navbar-left">
                                <div className="form-group">
                                    <select className="form-control" onChange={this.filterCategory.bind(this)}>
                                        <option value="ALL">All</option>
                                        {categoriesOption}
                                    </select>
                                </div>
                            </form>
                        </div>
                        <div className={"nav navbar-nav navbar-right navbar-collapse collapse"}>
                            <button className="btn btn-success navbar-btn" onClick={this.submitForm.bind(this)}>Save</button>
                        </div>
                    </div>
                </nav>
                <div className={"container product-recommendation-form-padding"}>
                    <div className="box box-success">
                        <div className="box-body no-padding">
                            <div className={"row"}>
                                {rows}
                            </div>
                            <div>
                                <form onSubmit={this.submitForm.bind(this)}>
                                    <div className="form-group">
                                        <label>Additional Products</label>
                                        <textarea className={"form-control"} onChange={this.handleAdditionalProductChange.bind(this)} value={this.state.additionalProduct}>
                                    </textarea>
                                    </div>
                                    <div className="form-group">
                                        <label>Comments</label>
                                        <textarea className={"form-control"} onChange={this.handleCommentChange.bind(this)} value={this.state.comment}>
                                    </textarea>
                                    </div>
                                    <input type="submit" className="btn btn-success" value="Save"/>
                                </form>
                            </div>
                        </div>
                    </div>
                </div>
            </section>;
        } else {
            return <section className="content">
                <Helmet titleTemplate="%s - Vending on Track">
                    <title>Product Recommendation Form Not Exist</title>
                </Helmet>
                <h3 className="navbar-text">Product Recommendation Form not exist</h3>
            </section>
        }
    }

}

let SiteOwnerProductRecommendationForm = withAuthentication(_SiteOwnerProductRecommendationForm);
export {SiteOwnerProductRecommendationForm};
