import React, {Fragment} from 'react';
import {useHistory} from 'react-router-dom';
import {getAPI} from "./api";
import {Helmet} from "react-helmet";


export function InventoryRedirect(props) {
    const history = useHistory();

    getAPI(`/api/users/me/warehouses`).then((response) => {
        if (response.data.success) {
            if (response.data.warehouses.length === 1) {
                history.push(`/warehouses/${response.data.warehouses[0].id}`)
            } else {
                window.alert("More than 1 warehouses");
            }
        }
    });

    return (
        <Fragment>
            <Helmet titleTemplate="%s - Vending on Track">
                <title>Inventory & Warehouse</title>
            </Helmet>
            <section className="content-header">
                <h1>
                    Inventory & Warehouse
                </h1>
            </section>

            <section className="content">
                <h1>Loading...</h1>
            </section>
        </Fragment>
    )
}
