import React, {Component} from 'react';

class JoinCompanyPanel extends Component {
    constructor(props) {
        super(props);

        this.state = {}
    }

    render() {
        return (<div>
            <h2>Join a Company</h2>

            <div className="alert alert-danger"><b>Note: </b>Please contact company admins to invite you.</div>
        </div>);
    }
}

export {JoinCompanyPanel};