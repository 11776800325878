import { Device } from "./device/v1/api_pb"

export interface AllVendingMachines {
    initialLoading: boolean
    refreshing: boolean
    allVendingMachines: Array<VendingMachine>

    errorMessage: string | null
    failedToLoad: boolean
}

export interface VendingMachinesWithLoadingState {
    loading: boolean,
    vendingMachines: Array<VendingMachine>,
    errorMessage: string | null
    failedToLoad: boolean
}

export interface Me {
    timezone: string
    company_id: number
    allAssociatedCompanies: Array<CompanyInfo>,
    mostRecentCompany: CompanyInfo,
    role: string
    locale: string,
    localeISO: string,

    userEmailSettings: UserEmailSettings

    tfaEnabled: boolean
}

export interface UserEmailSettings {
    disableAlertEmail: boolean
    disableDailySummaryEmail: boolean
}

export interface Notification {
    acknowledgedDate: any,
    id: number,
    vendingMachine: VendingMachine | null,
    type: string,
    state: string,
    level: string,
    message: string,
    createdTimestamp: number
}

export interface Login {
    id: number;
    browser: string;
    platform: string;
    ip: string;
    location: string;
    isApp:boolean;

    deletedTimestamp: number | null;
    createdTimestamp: number;
    lastAccessTimestamp: number;
}

interface VMCFeature {
    feature: string
    age: number
}

interface FinancialStats {
    today_revenue: number
    salesCount: number
}

export function BarcodeSorting(a: Barcode, b: Barcode): number {
    const result1 = parseInt(a.product.id)-parseInt(b.product.id)
    const result2 = a.quantity-b.quantity
    return result1 || result2
}

export function BarcodeSortingByQuantity(a: Barcode, b: Barcode): number {
    return a.quantity-b.quantity
}

export function productSorting(a: Product, b: Product): number {
    return a.name.localeCompare(b.name);
}

export function notificationSorting(a: Notification, b: Notification): number {
    return b.createdTimestamp - a.createdTimestamp;
}

export function sortProductRecommendationForm(a: ProductRecommendationForm, b: ProductRecommendationForm): number {
    return b.createdAt - a.createdAt;
}

export function productMappingSorting(a: ProductMapping, b: ProductMapping): number {

    if (a.mdbRaw === undefined) {
        return 1;
    }

    if (b.mdbRaw === undefined) {
        return -1;
    }

    return a.mdbRaw > b.mdbRaw ? 1 : -1;
}

export function vendingMachineEodSorting(a: VendingMachineDailyEODStats, b: VendingMachineDailyEODStats): number {
    return vendingMachineSorting(a.vendingMachine, b.vendingMachine);
}

export function vendingMachineSorting(a: VendingMachine, b: VendingMachine): number {
    if (a.uuid > b.uuid) {
        return 1;
    }

    if (a.uuid === b.uuid) {
        return 0;
    }

    return -1;
}

export function deviceSorting(a: Device, b: Device): number {
    if (a.uuid > b.uuid) {
        return 1;
    }

    if (a.uuid === b.uuid) {
        return 0;
    }

    return -1;
}

export interface Refill {
    vendingMachine: VendingMachine;
    createdTimestamp: number,
    uuid: string,
    vendingMachineUuid: string,
    changes: number, // item changes
    coinAmount: number, // cents
    coinCount: number,
    noteAmount: number, // dollar
    noteCount: number,
    user: User|null
}

export function refillSorting(a: Refill, b: Refill): number {
    if (a.createdTimestamp > b.createdTimestamp) {
        return -1;
    }

    return 1;
}

export interface DashboardStats {
    vendCoinMerchantBalance: number;
    totalCollectibleCash: number;

    totalCollectibleNotes: number;
    totalCollectibleNoteCount: number;

    totalCollectibleCoinCount: number;
    totalCollectibleCoins: number;

    todayRevenue: number
    todaySalesRevenue: number
    todaySales: number

    vendCoinSales:number
    vendCoinRevenue: number

    weekRevenue: number
    weekSalesRevenue: number
    weekSales: number

    vendingMachineCount: number
    onlineVendingMachineCount: number
    telemetryDeviceCount: number
    revenueStats: Array<DailyRevenue>
}

export interface VendingMachineFinancialStats extends FinancialStats {
    timestamp: number,

    coin_box_amount: number,
    coin_box_count: number,

    refill: Refill | null,

    revenueSinceRefill: number, // cents
    salesSinceRefill: number,

    weekRevenue: number, // cents
    weekSales: number
}

export interface Tube {
    faulty: boolean
    full: boolean
    coin_count: number
    value: number
}

export interface Transaction {
    itemNumber: string
    source: string
    transactionUUID: string
    brand: string

    product: Product | null

    itemPrice: number
    cashlessSurcharge: number
    timestamp: number
    machineName: string
    vending_machine_uuid: string
    vendCoinTxnRef: string | null
    mdbRaw:number
    refunded: boolean
    paymentMethod:string

    basket:boolean
    basketTransactions:Array<BasketTransactionInformation>
    mobilePaymentTransaction:boolean
    creditCardTransaction:boolean,
    last4:string | null
    vmcpOrderUuid:string|null
}


export interface BasketTransactionInformation {
    mdbItemNumber: number
    itemPrice: number
    success: boolean
    product: Product
}

export interface ConfigTimes {
    coin_changer_setup_timestamp?: number | null
    note_reader_setup_timestamp?: number | null
    communication_gateway_setup_response_timestamp: number | null
    cashless_setup_timestamp: number | null
    communication_gateway_setup_request_timestamp: number | null
    cashless_setup_request_timestamp: number | null
}

export interface GeoLocation {
    address: string | null
    longitude: number | null
    latitude: number | null
}

export enum SalesInfoSourceType {
    MDB = "MDB",
    DEX = "DEX",
}

export interface VendingMachine {
    activeNotificationCount: number
    archived: boolean
    lastSales: Transaction | null
    lastTransaction: VirtualFinancialTransaction | null
    format: string // how we should display selection ID, A08 or 2568
    uuid: string
    name: string
    online: boolean
    vmcfeatures: Array<VMCFeature>
    address: string | null
    geo_location: GeoLocation | null

    lastRefill: Refill | null

    todayRevenue: number // cents

    revenueSinceRefill: number, // cents
    salesSinceRefill: number,

    weekRevenue: number, // cents
    weekSales: number

    salesInfoSource: SalesInfoSourceType

    standardStockCount: number | null
    currentStockCount: number | null
    lowestStock: ProductMapping | null
    configTimes?: ConfigTimes
    installedTelemetryDeviceUuid: string | null

    fillLevel: number | null

    vendCoinInfo: VendCoinInfo

    coinAmountInBox: number

    noteAmount: number

    coinChangerDetail: CoinChangerDetail | null

    noteReaderDetail: NoteReaderDetail | null

    cashlessDetail: CashlessDetail | null

    cashlessSetupDetail: CashlessSetupDetail | null

    expansionRequestDetail: ExpansionRequestDetail | null

    emailDexFile: boolean

    vendingMachineType: string

    creditCardReaderOnTestEnv: boolean

    vendingMachineGroupUuid: string
}

export interface CashlessSetupDetail {
    featureLevel: number | null
    row: number | null
    column: number | null
    alphanumeric: boolean | null
    fullASCII: boolean | null
}

export interface CoinChangerDetail {
    featureLevel: number | null
    country: string | null
    scalingFactor: number | null
    decimalPlace: number | null
    coinTypeRoutingDescription: string | null
    coinTypeCreditDescription: string | null
}

export interface NoteReaderDetail {
    featureLevel: number | null
    country: string | null
    scalingFactor: number | null
    decimalPlace: number | null
    stackCapacity: number | null
    creditDescription: string | null
    securityLevel: number | null
    supportEscrow: boolean | null
}

export interface CashlessDetail {
    featureLevel: number | null
    country: string | null
    scalingFactor: number | null
    decimalPlace: number | null
    maxResponseTime: number | null
    noRefund: boolean | null
    multiVend: boolean | null
    ownDisplay: boolean | null
    cashSalesReport: boolean | null
}


export enum TelemetryDeviceWorkingMode {
    AUDITING = "auditing",
    CASHLESS_1 = "cashless_1",
    CASHLESS_2 = "cashless_2",
    COEXISTING = "coexisting",
    SILENCE = "silence"
}

export interface TelemetryDevice {
    version: string;
    name: string
    uuid: string
    id: string
    imei: string
    vendingMachine: VendingMachine | null
    online: boolean
    start_time: number | null
    mode: TelemetryDeviceWorkingMode,
    dexEnabled: boolean
    signalQuality: number | null,
    signalQualityTimestamp: number | null
    inTestingMode: boolean
    latpayAccountId: string | null
}

export interface Authentication {
    authenticated: boolean | null; // null means authentication is loading
    email?: string;
    authentication_token?: string;
    isApproved: boolean;
    hasCompany: boolean;
    tfaRequired: boolean;
}

export interface Product {
    id: string;
    name: string;
    hidden: boolean;
    placeholder: boolean;

    // expiry info
    shelftimeNotice: number | null;
    shelftimeAlert: number | null;

    imageUploadKey: string | null;
    productCategory:number | null;
    productType:string;
    productUnit:string;
    source:string;

    formulas:{
        ingredientProduct:{
            id:string;
            name:string;
            productUnit:string;
        };
        consumeQuantity:number
    }[] | null;

    category: string,
    nutrition:string,
}

export interface IngredientMapping {
    id:number;
    uuid:string;
    ingredientName:string;
    ingredientId:number;
    capacity:number;
    restockCapacity:number;
    currentLevel:number | null;
    productUnit:string;
    cycleCounting: boolean;
    lastTransactionTimestamp: number | null;
    coilShelfTimes?: Array<CoilShelftime>
}

export interface LaundryMachineSelection {
    name:string,
    price:number,
    processingFee:number,
    discount:number,
    machineCode:string,
    selection:number,
    externalPhotoUrl:string
}

export interface CoilShelftime {
    timestamp: number,
    count: number,
    notice: boolean | null,
    alert: boolean | null,
}

export interface ProductMapping {
    id?: number;

    code: string;
    mdbRaw?: number; // this is used to store raw code instead of HEX code for Crane
    mdb_code: string;
    price: number; // for convenient, cents
    original_price: number; 
    capacity: number;
    cycleCounting: boolean;
    restock: number;
    currentStock?: number;

    product_id: string;
    product_name?: string;
    lastTransactionTimestamp?: number | null;
    warnLastTransactionFlag?: boolean;

    coilShelfTimes?: Array<CoilShelftime>

    tray: number;
    position: number;
    width: number;
    height: number;
    is_faulty: boolean | null;
}

export interface ProductMappingWithProduct extends ProductMapping{
    product: Product | null;
}

export interface IngredientMappingWithProduct extends IngredientMapping{
    product:Product | null;
}

// PA1
export interface DexProduct {
    identifier: string; // PA101, recommendation: selection id
    price?: number; // PA102
    identification?: string; // PA103

    capacity?: number; // PA104
    standard_filling_level?: number; // PA105
    standard_dispensed_quality?: number; // PA106

    current_level?: number; // PA108
    minimum_level?: number; // PA109, for triggering alert

    formatCode(code: string): string;
}


export interface DexReading {
    id: number
    previewing: boolean
    preview: string
    createdDate: string
    size: number
    checksum_verified: boolean
}

export interface ProductMovement {
    code: string
    cents: number
    quantity: number
    vendingMachineName: string
    vendingMachineUUID: string
    epochSecond: number
    productID: number
}

export interface DexSettingInfo {
    machine_serial_number?: string;
    machine_model_number?: string;
    machine_location?: string;
    machine_asset_number?: string;

    decimal_point_position?: number;
    currency_alphabetic_code?: string;

    changer_serial_number?: string;
    coin_mech_model_number?: string;

    bill_validator_serial_number?: string;
    bill_validator_model_number?: string;

    cashless_1_serial_number?: string;
    cashless_1_model_number?: string;

    coils: Array<DexProduct>
}

export interface DexSalesColumnInfo {
    setting: DexProduct
    numberOfProductsVended: number
    valueOfPaidProductSales: number
    numberOfProductsVendedSinceReset: number
    valueOfPaidProductSalesSinceReset: number
    soldOutDate: string
    soldOutTime: string
}

export interface DexSalesInfoResponse {
    success: boolean
    products: Array<DexSalesColumnInfo>
    last_reading_ts: number
}

export interface DeviceUptimeResponse {
    success: boolean
    message: string | null
    uptime24hrs: number
    uptime7Day: number
    uptime28Day: number
}

export interface ConnectionSession {
    connectedTimestamp: number
    disconnectedTimestamp: number | null,
    version: string | null,
    duration: number | null
    restarted: boolean
}


export enum PickingState {
    SCHEDULED = "SCHEDULED",
    PICKED = "PICKED",
    REFILLED = "REFILLED",
    ABANDONED = "ABANDONED"
}

export enum CreditCardType {
    VISA = 'VISA',
    MASTERCARD = "MASTERCARD",
    AMEX = "AMEX",
    DISCOVER = "DISCOVER"
}

export interface Picking {
    id: number
    vendingMachineCount: number
    totalQuantity: number

    state: PickingState

    createTime: number
    updateTime: number
    pickingTime: number
    refillTime: number
    abandonTime: number

    scheduledVisitTime: number | null
}


export interface PrekittingDetail {
    id: number
    vendingMachineCount: number
    totalQuantity: number

    state: PickingState

    createTime: number
    updateTime: number
    pickingTime: number | null
    refillTime: number | null
    abandonTime: number | null

    scheduledVisitTime: number | null

    pickingLists: Array<PickingList>
}

export interface PickingList {
    vendingMachineUuid: string
    pickings: Array<ItemPicking>
}

export interface ItemPicking {
    quantity: number
    product_id: number
    coil: string
    prekitting_item_id: number
}

export interface DailyRevenue {
    x: string, // date like "2019-05-02"
    y: number, // revenue
    y_cashless: number, // cashless
    y_cash: number // cash
}

export enum SalesType {
    CASH,
    CASHLESS,
}

export enum Destination {
    NOTE_READER,
    COIN_BOX,
    COIN_TUBE,
}

export enum CompanyUserRole {
    DRIVER = "DRIVER",
    ADMIN = "ADMIN",
    REPORT = "REPORT"
}

export enum CompanyUserNominationStatus {
    PENDING_REGISTRATION = "PENDING_REGISTRATION",
    PENDING_ALLOCATION = "PENDING_ALLOCATION",
    ACTIVE = "ACTIVE",
    DELETED = "DELETED"
}

export interface VirtualFinancialTransaction {
    uuid: string
    timestamp: number
    salesType: SalesType
    destination: Destination
    amount: number
    machineName: string
    vending_machine_uuid: string
}

export interface VendingMachineDailyEODStats {
    date: Date,
    vendingMachine: VendingMachine,

    totalSalesCount: number,
    totalRevenue: number,

    coinBoxCount: number,
    coinBoxValue: number,

    noteReaderCount: number,
    noteReaderValue: number,

    changeInCoinTubeCount: number,
    changeInCoinTubeValue: number,

    cashlessCount: number,
    cashlessValue: number,

    transactions: Array<Transaction>
    financialTransactions: Array<VirtualFinancialTransaction>
}

export interface DailyEODStats {
    date: Date,

    totalSalesCount: number,
    totalRevenue: number,
    totalCashes: number,

    currentCollectableCashes: number,

    aggregatedTransactions: Array<Transaction>
    aggregatedFinancialTransactions: Array<VirtualFinancialTransaction>

    perMachineStats: Array<VendingMachineDailyEODStats>
}

export interface MarketDemanding {
    daily: number,
    weekly: number,
    monthly: number,
}

export interface ProductStats {
    pricing: Map<number, number>
    demand: MarketDemanding
    coils: number
    vendingMachines: number
}

export interface CoilProductStats {
    stock: number,
    restock: number,
    capacity: number,
    productName: string
}

export interface CompanySettings {
    name: string,
    abn: string | null,
    gst: boolean,
    gst_type: string,
    uuid: string,
    admins: Array<string>
    // defaultStartAddress: string | null
    defaultStartLocation: GeoLocation
    // defaultFinishAddress: string | null
    defaultFinishLocation: GeoLocation
    // address:string | null
    location:GeoLocation
    sqsDexFile:boolean
    sqsUrl:string | null
    blockFailedCoil:boolean
    timezone:string
}

// flash message
export class Message {
    message: string;
    cls: string;

    constructor(message: string, cls: string) {
        this.message = message;
        this.cls = cls;
    }
}

export interface Selection {
    selection: string
    cents: number
    description?: string
}

export interface RemoteVending {
    txnRef: string
    selection: Selection | null
}

export function productPicture(product: Product | null): string {
    /*
    console.log("Product is:");
    console.log(product);
     */

    if (product == null) {
        return "/blank.png";
    }

    if (product.imageUploadKey) {
        return `/api/products/${product.id}/picture/external?key=${product.imageUploadKey}`;
    }

    return "/blank.png";
}


export interface Stock {
    productId: string
    id: number
    quantity: number
    lastQuantityChangeDate: Date
    totalShortage: number
    expiryDate: Date | null
    desiredStockLevel: number | null
    purchaseQuantities: PurchaseQuantity[] | null
}

export interface PurchaseQuantity {
    unit: string
    quantity: number
}

export interface StockChange {
    id: number
    type: string
    eventDate: Date
    eventTimestamp: number
    quantity: number
}

export interface CheckInProduct{
    barcode: String
    product: Product
    quantity: number
}

export interface Barcode{
    id:number
    content: string
    quantity: number
    product: Product
}

export interface BarcodeSpan {
    id: number
    content: string
    quantity: number
    product: Product
    productspanflag: boolean
    productspannum: number
    quantityspanflag: boolean
    quantityspannum: number
}

export interface VendCoinInfo {
    uuid: string
    operatorId: string
    externalAddress: string
    externalPhotoUrl: string
    externalContactName: string
    externalContactNumber: string
    publicOnVendCoin: boolean
    allowSwipeBeforeSelection: boolean
    chargeConsumerSurcharge: boolean
    keypad_configuration: KeypadConfiguration
}

export interface CoilDisplay {
    format: string
    coilDisplayResult: String
}

export interface Keypad {
    "id": number,
    "total_row_number": number,
    "total_column_number": number,
    "total_action_needed": number,
    "name": string,
    "keypad_items":KeypadItem[]
}

export interface KeypadItem {
    "id": number,
    "w": number,
    "h": number,
    "x": number,
    "y": number,
    "display_value": string
}

export interface KeypadConfiguration {
    "total_row_number":number,
    "total_column_number":number,
    "total_action_needed":number,
    "virtual_keypad_enabled":boolean,
    "id":number,
    "name": string,
    "keypad_items": KeypadItem[]
}

export interface Category{
    id:number
    category: string
    source:string
}

export interface CompanyUserNomination {
    id: number
    email: string
    status: string
    role: string
    createdTimestamp: number
}

export interface VendingMachineAllocation {
    userId: number
    vmIds: string[]
    email: string
}

export interface CompanyInfo {
    name: string
    companyUuid: string
    currencyDecimalPoints: number
    currencyCode: string
    currencySymbol: string
}

export interface CompanyClient {
    uuid: string,
    vmIds: string[],
    name: string,
    status: string,
    adminEmails: string,
    createAt: number,
}

export interface ExpansionRequestDetail {
    manufacturerCode: string
    serialNumber: string
    modelNumber: string
    softwareVersion: string
}

export interface Locale{
    display:string,
    localeIso:string
}

export interface CreditCardSurchargeRule{
    id:number
    upboundPrice:number
    fixedFee:number
    percentageFee:number
}

export interface CreditCardSurchargeGroup{
    id:number
    createdAt:number
    name:string
    uuid:string
}

export interface VendingMachineCreditCardSetting{
    creditCardSurchargeGroup: CreditCardSurchargeGroup | null
    creditCardSurchargePayableByConsumer: boolean
}

export enum ProductFilter{
    ALL = "ALL",
    NORMAL = "NORMAL",
    PLACEHOLDER = "PLACEHOLDER"
}

export interface User{
    uuid:string,
    email:string
}

export interface ProductRecommendationForm {
    createdAt:number,
    siteName:string,
    siteOwnerEmail:string,
    uuid:string
    completed:boolean,
    current:boolean,
    ready:boolean,
    productRecommendationMaps: ProductRecommendationMap[],
    additionalProduct:string
    comment:string
    formUrl:string
}

export interface ProductRecommendationMap {
    id:number,
    createdAt:number,
    product:Product,
    wantLevel:string
}


export enum ProductWantLevel{
    FAVORITE = "FAVORITE",
    STOCK = "STOCK",
    NEGATIVE = "NEGATIVE"
}


export enum LoginFilter{
    ALL = "ALL",
    APP = "APP",
    WEB = "WEB"
}

export interface Refund {
    refundedBy: string
    refundedUnixTimestamp: number,
    vendingMachineName: string
    selection: number
    originalTransactionTimestamp: number
    totalRefundCents: number,
    originalTxnRef: string
}

export interface Settlement {
    createdAt: number
    amount: number
    cutOffTimestamp: number
    merchantWithdraw: Array<MerchantWithdraw>
}

export interface MerchantWithdraw {
    createdAt: number
    amount: number
    type: string
    vendCoinMerchantTransaction: VendCoinMerchantTransaction
}

export interface VendCoinMerchantTransaction {
    createdAt: number
    price: number
    type: string
    surcharge: number
    amount: number
    txnRef: string
}

export interface VendingMachineGroup {
    description: string
    name: string
    parentUuid: string | null
    uuid: string
    vendingMachineUuids: string[]
}

export interface RefillDetails {
    refillEpoch: number,
    uuid: string,
    vendingMachineUuid: string,
    changes: number, // item changes
    coinAmount: number, // cents
    coinCount: number,
    noteAmount: number, // dollar
    noteCount: number,
    user: User|null,
    coilRefills: CoilRefill[]
}

export interface CoilRefill {
    productId: number,
    productName: string
    mdbCode: string
    mdbRaw: number
    price: number
    refillCount: number
}