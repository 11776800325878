import * as React from "react";
import {Transaction} from "./models";
import SalesTr from "./components/sales_tr";
import {Default, Mobile} from "./components/responsive";


interface IProps {
	sales: Array<Transaction>

	reloadData(): void
}

interface Props extends IProps {
	isMobile: boolean
}

const InternalDashboardLatestSales: React.SFC<Props> = props => {
	return (
		<div>
			<h2>Latest Sales</h2>

			<div className="box with-border box-success">
				<div className="box-body no-padding">
					<table className={"table table-condensed"}>
						<thead>
						{!props.isMobile ?
							<tr>
								<th>Time</th>
								<th>Selection</th>
								<th>Price</th>
								<th>Payment Method</th>
								<th>Machine</th>
							</tr>
							:
							<tr>
								<th>Product</th>
								<th>Amount</th>
								<th>Time</th>
							</tr>
						}
						</thead>
						<tbody>
						{props.sales.map((transaction, i) => {
							return (
								<SalesTr isShrunk={props.isMobile} key={transaction.transactionUUID}
										 isExpandTime={false}
										 reloadData={props.reloadData}
										 isShowMachine={true}
										 trans={transaction}
										 isShowLast4={false}/>
							)
						})}
						</tbody>
					</table>
				</div>
			</div>
		</div>
	);
};

export const DashboardLatestSales = (props: IProps) => (
	<div>
		<Mobile>
			<InternalDashboardLatestSales isMobile={true} {...props} />
		</Mobile>
		<Default>
			<InternalDashboardLatestSales isMobile={false} {...props} />
		</Default>
	</div>
);
