import React, {Component} from "react";
import {V1PaymentEvent,V1VmcpOrder,V1VmcpOrderItem} from "./telemetry/vending_machine/v1/typescript-axios";
import {IGlobalDataContext, withGlobalData} from "./contexts/global-data";
import {FormatMoney} from "./format_money";
import moment from 'moment';
import { DisplayTime } from "./display_time";

interface Props extends IGlobalDataContext {
    order: V1VmcpOrder
    orderItems: Array<V1VmcpOrderItem>
    paymentEvents: Array<V1PaymentEvent>
}

interface State {

}

class _SearchOrderTable extends Component<Props, State> {
    // eslint-disable-next-line
    constructor(props) {
        super(props);
    }

    render(): React.ReactElement<any, string | React.JSXElementConstructor<any>> | string | number | {} | React.ReactNodeArray | React.ReactPortal | boolean | null | undefined {



        const rows = this.props.paymentEvents.map((paymentEvent) => {
            return <tr>
                <td>{paymentEvent.uuid}</td>
                <td>{paymentEvent.orderUuid}</td>
                <td>{paymentEvent.event}</td>
                <td>{paymentEvent.receiptId}</td>
                <td>{paymentEvent.bodyData}</td>
                <td>{paymentEvent.createdAt}</td>
            </tr>
        });

        const items = this.props.orderItems.map((orderItem, index) => {
            return (
                <tr key={index}>
                    <td>{orderItem.name}</td>
                    <td>{orderItem.mdbItemNumber}</td>
                    <td><FormatMoney cents={orderItem.price}/></td>
                    <td>{orderItem.status}</td>
                </tr>);
        });

        return (
            <div>
                <div className={"row"}>
                    <div className={"col-xs-6"}>
                        <div className={"box-header"}>
                            <h4>Order Detail</h4>
                        </div>
                        <div className="box with-border box-success"></div>
                        <table className = "table table-bordered" id="listBarcodes">
                            <tbody>
                                <tr>
                                    <td>
                                        state
                                    </td>
                                    <td>
                                        {this.props.order.state}
                                    </td>
                                </tr>
                                <tr>
                                    <td>
                                        PaymentGateway
                                    </td>
                                    <td>
                                        {this.props.order.paymentGateway}
                                    </td>
                                </tr>
                                <tr>
                                    <td>
                                        QrcodeId
                                    </td>
                                    <td>
                                        {this.props.order.qrcodeId}
                                    </td>
                                </tr>
                                <tr>
                                    <td>
                                        QrpayId
                                    </td>
                                    <td>
                                        {this.props.order.qrpayId}
                                    </td>
                                </tr>
                                <tr>
                                    <td>
                                        PaidAt
                                    </td>
                                    <td>
                                        <DisplayTime timestamp={moment(this.props.order.paidAt).unix()} />
                                    </td>
                                </tr>
                                <tr>
                                    <td>
                                        RefundedAt
                                    </td>
                                    <td>
                                        <DisplayTime timestamp={moment(this.props.order.refundedAt).unix()} />
                                    </td>
                                </tr>
                            </tbody>
                        </table>
                    </div>
                    <div className={"col-xs-6"}>
                        <div className={"box-header"}>
                            <h4>Order Items</h4>
                        </div>
                        <div className="box with-border box-success"></div>
                        <table className = "table table-bordered" id="listBarcodes">
                            <thead>
                                <tr>
                                    <th>Name</th>
                                    <th>Mdb Item Number</th>
                                    <th>Item Price</th>
                                    <th>Status</th>
                                </tr>
                            </thead>
                            <tbody>
                                {items}
                            </tbody>
                        </table>
                    </div>
                </div>
                <br />
                <div className={"box-header"}>
                    <h4>Payment Events</h4>
                </div>
                <div className="box with-border box-success"></div>
                <table className={"table table-condense"}>
                    <thead>
                        <tr>
                            <th>Uuid</th>
                            <th>OrderUuid</th>
                            <th>Event</th>
                            <th>ReceiptId</th>
                            <th>BodyData</th>
                            <th>CreatedAt</th>
                        </tr>
                    </thead>
                    <tbody>
                        {rows}
                    </tbody>
                </table>
            </div>
        );
    }
}

export const SearchOrderTable = withGlobalData(_SearchOrderTable);
