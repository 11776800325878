import React, {Component} from "react";
import Select from 'react-select'

import DatePicker from 'react-datepicker';
import {IGlobalDataContext, withGlobalData} from "./contexts/global-data";
import {Product} from "./models";
import moment from "moment";
import {postAPI} from "./api";
import {ProductRevenueReportBarchart} from "./product_revenue_report_barchart";
import {Helmet} from "react-helmet";

interface Props extends IGlobalDataContext {

}

interface State {
    selectedProducts: Array<Product>
    fromDate: Date
    toDate: Date
    reportLoading: boolean
    report: any
    interval: number
}

class RawDurationRevenueReportByProduct extends Component<Props, State> {
    constructor(props) {
        super(props);

        this.state = {
            selectedProducts: [],
            fromDate: new Date(),
            toDate: new Date(),
            reportLoading: false,
            report: null,
            interval: 1
        }
    }

    report(e) {
        e.preventDefault();
        e.stopPropagation();

        console.log(moment(this.state.fromDate).format("YYYY-MM-D"));
        console.log(moment(this.state.toDate).format("YYYY-MM-D"));

        const ids = this.state.selectedProducts.map((product) => {
            return product.id;
        });

        postAPI(`/api/eod/product`, {
            fromDate: moment(this.state.fromDate).format("YYYY-MM-D"),
            toDate: moment(this.state.toDate).format("YYYY-MM-D"),
            productIdList: ids,
            interval:this.state.interval
        }).then((resp) => {
            this.setState({reportLoading: false});
            if (resp.data.success) {
                this.setState({report: resp.data.productRevenueReport});
                console.log("report")
                console.log(resp.data.productRevenueReport);
            } else {
                window.alert(resp.data.message);
            }
        });
        this.setState({reportLoading: true, report: null});

        return false;
    }

    fromDateChange(date) {
        this.setState({fromDate: date})
    }

    toDateChange(date) {
        this.setState({toDate: date})
    }

    selectProduct(options) {
        let products: Array<Product> = [];

        if (options !== null){
            options.forEach((selection) => {
                const product = this.props.findProduct(selection.value);
                if (product) {
                    products.push(product);
                }
            });

        }
        this.setState({
            selectedProducts: products
        });
    }

    selectInterval(event){
        console.log(event.target.value)
        this.setState({interval:event.target.value})
    }


    render() {
        const temp_products = this.props.allProducts.filter(product => !product.placeholder).filter((product)=>{return product.productType !== 'INGREDIENT'});
        const products = temp_products.map((product)=>{
            return {
                value:product.id,
                label:product.name
            }
        })
        let dateFormat = moment().locale(this.props.me.localeISO).localeData().longDateFormat("L").replace("DD", "dd").replace("YYYY", "yyyy");
        return (
            <div>
                <Helmet titleTemplate="%s - Vending on Track">
                    <title>Product Revenue Report</title>
                </Helmet>
                <section className="content-header">
                    <h1>
                        Product Revenue Report [Duration]
                    </h1>
                </section>
                <section className="content">
                    <div className={"box box-success"}>
                        <div className={"box-body with-border"}>
                            <div className="form-group">
                                <label>Please choose product to run report: </label>
                                <Select
                                    isMulti
                                    onChange={this.selectProduct.bind(this)}
                                    options={products}
                                />
                            </div>
                            <div className="form-group">
                                <label>From: </label>
                                <DatePicker className="form-control"
                                    selected={this.state.fromDate}
                                    onChange={this.fromDateChange.bind(this)} dateFormat={dateFormat}/>
                            </div>
                            <div className="form-group">
                                <label>To: </label>
                                <DatePicker className="form-control"
                                    selected={this.state.toDate}
                                    onChange={this.toDateChange.bind(this)} dateFormat={dateFormat}/>
                            </div>
                            <div className="form-group">
                                <label>Interval: </label>
                                <select onChange={this.selectInterval.bind(this)} value={this.state.interval} className="form-control">
                                    <option value={1}>1 Day</option>
                                    <option value={7}>1 Week</option>
                                    <option value={14}>2 Weeks</option>
                                    <option value={28}>4 Weeks</option>
                                </select>
                            </div>
                            <div className="form-group">
                                {this.state.selectedProducts.length > 0 ? <button onClick={this.report.bind(this)} className="btn btn-success">Generate report
                                </button> : <button disabled className="btn btn-success">Generate report
                                </button>}
                            </div>
                        </div>
                    </div>
                    {this.state.reportLoading &&
                    <div className={"box box-success"}>
                        <div className={"box-header"}>
                            <h1>Loading ...</h1>
                        </div>
                    </div>
                    }

                    {!this.state.reportLoading && this.state.report &&
                    <div className="box box-success">
                        <div className={"box-header"}>
                            <h1>Report</h1>
                        </div>
                        <div className="box-body">
                            {/*<ProductRevenueReportTable products={this.state.selectedProducts} report={this.state.report}/>*/}
                            <ProductRevenueReportBarchart products={this.state.selectedProducts}
                                                          report={this.state.report}
                                                          decimalPoints={this.props.me.mostRecentCompany.currencyDecimalPoints}
                                                          currencySymbol={this.props.me.mostRecentCompany.currencySymbol} locale={this.props.me.localeISO}/>
                        </div>
                    </div>
                    }
                </section>
            </div>
        );
    }
}

const DurationRevenueReportByProduct = withGlobalData(RawDurationRevenueReportByProduct);
export default DurationRevenueReportByProduct;