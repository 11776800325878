import React, {Component} from 'react';
import {Link, Route, Router, Switch} from 'react-router-dom'
import { ResizeObserver } from '@juggle/resize-observer';

import './App.css';
import 'react-tippy/dist/tippy.css';


import {GlobalDataContext} from './contexts/global-data';
import {AuthenticationContext} from './authentication';
import {AuthenticationProtected} from './authentication_protected';
import {Header} from './header';
import {Login} from './login';
import {Register} from './register';
import {ForgotPassword} from './forgot_password';
import {ResetPassword} from './reset_password';
import {getAPI, ManagementServiceApi, postAPI} from './api';
import {Join} from './join';
import {Sidebar} from './sidebar';

import './App.scss';
import ReactGA from 'react-ga';
import {createBrowserHistory} from "history";
import track from "./utils/tracking";
import {getAllVendingMachines} from "./magic";
import {WaitForApproval} from "./wait_for_approval";
import VersionInfo from "./version_info";
import {CompanyUserSignUpPage} from "./nominated_user_sign_up";
import {CompanyUserRole} from "./models";
import {DriverSwitchRoute} from "./driver_app_switch_route.tsx";
import {AdminSwitchRoute} from "./admin_app_switch_route";
import ScrollToTop from "./scroll_to_top";
import {VerifyTfa} from "./verify_tfa";
import {SiteOwnerProductRecommendationForm} from "./site_owner_product_recommendation_form";
import {ReportSwitchRoute} from "./report_app_switch_route.tsx.js";

ReactGA.initialize('UA-139700180-2', {
    gaOptions: {
        siteSpeedSampleRate: 100
    }
});

ReactGA.pageview(window.location.pathname + window.location.search);
track("page_view", window.location);


const history = createBrowserHistory();
const location = history.location;
console.log('well...' + location);

history.listen((location, action) => {
    ReactGA.pageview(window.location.pathname + window.location.search);
    track("followup_page_view", {"location": location, "action": action, "window_location": window.location});
});

class App extends Component {
    constructor(props) {
        super(props);

        this.flashTimer = null;

        this.login = (token, email, isApproved, hasCompany, tfaRequired) => {
            localStorage.setItem("email", email);
            localStorage.setItem("authentication_token", token);
            localStorage.setItem("isApproved", isApproved);
            localStorage.setItem("hasCompany", hasCompany);
            localStorage.setItem("tfaRequired", tfaRequired);

            this.setState({
                allProducts: [],
                allVendingMachines: {
                    initialLoading: true,
                    refreshing: false,
                    allVendingMachines: [],
                },
                allVendingMachineGroups: [],
                allCategories: [],
                authentication: {
                    authenticated: true,
                    email: email,
                    authentication_token: token,
                    notificationCount: null,
                    isApproved: isApproved,
                    hasCompany: hasCompany,
                    tfaRequired: tfaRequired
                }
            }, () => {
                if (isApproved && hasCompany && !tfaRequired) {
                    this.reloadMyInfo();
                    this.reloadAllProducts();
                    this.reloadAllVendingMachines();
                    this.reloadCategories();
                    this.loadNotifications();
                    this.reloadAllVendingMachineGroups()
                }
            });
        };

        this.logout = () => {
            this.setState({
                authentication: {
                    authenticated: false,
                    email: null,
                    authentication_token: null
                }
            });

            postAPI("/api/users/logout", {
                token: this.state.authentication.authentication_token,
                email: this.state.email
            });

            localStorage.removeItem("email");
            localStorage.removeItem("authentication_token");
            localStorage.removeItem("isApproved");
            localStorage.removeItem("hasCompany");
            localStorage.removeItem("tfaRequired");
        };

        this.state = {
            authentication: {
                authenticated: null,
                email: null,
                authentication_token: null
            },
            login: this.login,
            logout: this.logout,
            version: this.version,
        }

        getAPI("/api/systems/version", {}).then((response) => {
            if (response.data && response.data.serverVersion) {
                this.setState({version: response.data.serverVersion});
            } else {
                this.setState({version: "Unknown version"})
            }
        })
    }

    checkTokenStatus = (authentication_token) => {
        return new Promise((resolve, reject) => {
            if (authentication_token !== null) {
                getAPI("/api/users/check_token").then((response) => {
                    if (response.data.success) {
                        const hasCompany = response.data.tokenStatus.hasCompany
                        const isApproved = response.data.tokenStatus.isApproved
                        const tfaRequired = response.data.tokenStatus.tfaRequired
                        localStorage.setItem("isApproved", isApproved);
                        localStorage.setItem("hasCompany", hasCompany);
                        localStorage.setItem("tfaRequired", tfaRequired);
                        resolve(hasCompany, isApproved, tfaRequired);
                    } else {
                        reject()
                    }
                });
            } else {
                reject()
            }
        })
    }

    verifyToken = (authentication_token) => {
        return new Promise((resolve, reject) => {
            if (authentication_token !== null) {
                postAPI("/api/users/verify", {token: authentication_token}).then((response) => {
                    if (response.data.success) {
                        resolve()
                    } else {
                        reject()
                    }
                }).catch(() => {
                    reject()
                });
            } else {
                reject()
            }
        })
    }

    componentDidMount() {
        let email = localStorage.getItem("email");
        let authentication_token = localStorage.getItem("authentication_token");
        console.log(email);
        console.log(authentication_token);
        if (email != null && authentication_token != null) {
            this.verifyToken(authentication_token).then(this.checkTokenStatus).then(() => {
                const isApproved = localStorage.getItem("isApproved") === "true";
                const hasCompany = localStorage.getItem("hasCompany") === "true";
                const tfaRequired = localStorage.getItem("tfaRequired") === "true";
                this.login(authentication_token, email, isApproved, hasCompany, tfaRequired);
            }).catch(() => {
                this.logout();
            }).catch(() => {
                this.logout();
            })
        } else {
            this.logout();
        }
    }

    reloadAllProducts() {
        getAPI(`/api/products`).then((response) => {
            this.setState({
                allProducts: response.data.products,
            })
        })
    }

    reloadAllVendingMachineGroups() {
        getAPI(`/api/vending_machine_groups`).then((response) => {
            this.setState({
                allVendingMachineGroups: response.data.groups,
            })
        })
    }

    loadNotifications() {
        getAPI(`/api/notifications?page=0`).then((response) => {
            this.setState({
                notificationCount: response.data.notifications.totalElements
            })
        });
    }

    reloadAllVendingMachines() {
        getAllVendingMachines().then((vendingMachines) => {
            // If the machine is offline, obtain the status again from mdb proxy
            let offlineMachines = [];
            vendingMachines.forEach((machine) => {
                if(!machine.online){
                    offlineMachines.push(machine.uuid)
                }
            })
            if(offlineMachines.length > 0){
                let machinesOnlineStatus = this.loadMachinesOnlineStatus(offlineMachines)
                machinesOnlineStatus.then(res => {
                    let machinesOnlineStatusMap = {}
                    res.machinesOnlineStatus.forEach((machineStatus) => {
                        machinesOnlineStatusMap[machineStatus.vmid] = machineStatus.online
                    })

                    vendingMachines = vendingMachines.map((vendingMachine) => {
                        if(!vendingMachine.online && machinesOnlineStatusMap[vendingMachine.uuid] !== undefined){
                            vendingMachine.online = machinesOnlineStatusMap[vendingMachine.uuid]
                        }
                        return vendingMachine
                    })

                    const allVendingMachines = {
                        allVendingMachines: vendingMachines,
                        initialLoading: false,
                        refreshing: false,
                        errorMessage: null,
                        failedToLoad: false,
                    }
                    this.setState({
                        allVendingMachines: allVendingMachines
                    });
                })
                return
            }

            const allVendingMachines = {
                allVendingMachines: vendingMachines,
                initialLoading: false,
                refreshing: false,
                errorMessage: null,
                failedToLoad: false,
            }
            this.setState({
                allVendingMachines: allVendingMachines
            });

        }).catch((error) => {

            const allVendingMachines = {
                allVendingMachines: [],
                initialLoading: false,
                refreshing: false,
                errorMessage: "Could not retrieve vending machine list.",
                failedToLoad: true,
            }
            this.setState({
                allVendingMachines: allVendingMachines
            });
        })
    }

    loadMachinesOnlineStatus(vmids) {
        return new Promise(((resolve, reject) =>
            new ManagementServiceApi().machineManagementServiceListMachinesOnlineStatus({
                vmids: vmids
            }).then(response => {
                resolve(response.data)
            }).catch(err => {
                reject(err)
            })
        ))
    }

    reloadCategories() {
        getAPI('/api/categories').then(response => {
            if (response.data.success) {
                const categories = []
                response.data.productCategories.forEach((element) => {
                    categories.push({id: element.id, category: element.category, source: element.source})
                });
                this.setState({allCategories: categories})
            } else {
                alert(response.data.message)
            }
        })
    }

    reloadMyInfo() {
        return new Promise(((resolve, reject) =>
                getAPI('/api/users/me').then((response) => {
                    this.setState({me: response.data});
                    resolve(response.data);
                }).catch(err => {
                    reject(err);
                })
        ))

    }

    findVendingMachine(uuid) {
        return this.state.allVendingMachines.allVendingMachines.find((vm) => {
            return vm.uuid === uuid;
        });
    }

    findProduct(id) {
        return this.state.allProducts.find((product) => {
            return parseInt(product.id) === parseInt(id);
        })
    }

    findCategory(id) {
        return this.state.allCategories.find((category) => {
            return parseInt(category.id) === parseInt(id);
        })
    }

    findVendingMachineGroup(uuid) {
        return this.state.allVendingMachineGroups.find((group) => {
            return group.uuid === uuid;
        })
    }

    pushFlashMessage(message) {
        let timer = window.setTimeout(() => {
            this.setState({flash: null});
        }, 10 * 1000);

        this.setState({
            flash: message
        });

        if (this.flashTimer) {
            window.clearTimeout(this.flashTimer);
        }

        this.flashTimer = timer;
    }

    switchRoute() {
        if (this.state.me.role === CompanyUserRole.DRIVER.valueOf()) {
            return DriverSwitchRoute();
        }
        if (this.state.me.role === CompanyUserRole.REPORT.valueOf()) {
            return ReportSwitchRoute();
        }
        return AdminSwitchRoute();
    }


    render() {
        const ro = new ResizeObserver((entries, observer) => {
        });

        ro.observe(document.body); // Watch dimension changes on body

        let display_login_bar = true;

        if (this.state.authentication.authenticated) {
            display_login_bar = false;
        }
        return (

            <Router history={history}>

                <GlobalDataContext.Provider value={{
                    version: this.state.version,
                    allProducts: this.state.allProducts,
                    reloadAllProducts: this.reloadAllProducts.bind(this),
                    findProduct: this.findProduct.bind(this),
                    pushFlashMessage: this.pushFlashMessage.bind(this),
                    findVendingMachine: this.findVendingMachine.bind(this),
                    allVendingMachines: this.state.allVendingMachines,
                    reloadAllVendingMachines: this.reloadAllVendingMachines.bind(this),
                    notificationCount: this.state.notificationCount,
                    reloadNotificationCount: this.loadNotifications.bind(this),
                    me: this.state.me,
                    reloadMyInfo: this.reloadMyInfo.bind(this),
                    reloadCategories: this.reloadCategories.bind(this),
                    findCategory: this.findCategory.bind(this),
                    allCategories: this.state.allCategories,
                    allVendingMachineGroups: this.state.allVendingMachineGroups,
                    reloadVendingMachineGroups: this.reloadAllVendingMachineGroups.bind(this),
                    findVendingMachineGroups: this.findVendingMachineGroup.bind(this)
                }}>
                    <AuthenticationContext.Provider value={this.state}>
                        <Switch>
                            <Route exact path="/login" component={Login}/>
                            <Route exact path="/join" component={Join}/>
                            <Route exact path="/register" component={Register}/>
                            <Route exact path="/forgot_password" component={ForgotPassword}/>
                            <Route exact path="/reset_password/:token" component={ResetPassword}/>
                            <Route exact path="/site_feedback/:token" component={SiteOwnerProductRecommendationForm}/>
                            <Route exact path="/company_user/signup" component={CompanyUserSignUpPage}/>
                            <Route exact path="/notApproved" component={WaitForApproval}/>
                            <Route exact path="/verify_tfa" component={VerifyTfa}/>

                            <Route>
                                <AuthenticationProtected>
                                    <ScrollToTop/>
                                    <div className="wrapper">
                                        {display_login_bar ?
                                            (<div className={"container alert alert-danger"}>
                                                    <Link to="/login">
                                                        <div className="row">
                                                            <div className="col-xs-12">
                                                                Login
                                                            </div>
                                                        </div>
                                                    </Link>
                                                </div>
                                            )
                                            :
                                            null
                                        }
                                        {this.state.me ? <Header/> : null}
                                        <Sidebar/>


                                        <div className="content-wrapper">
                                            {this.state.flash &&
                                            <div className="flash-message flash-{this.state.flash.cls}">
                                                {this.state.flash.message}
                                            </div>
                                            }
                                            {this.state.me && this.switchRoute()}
                                        </div>

                                        <footer className="main-footer">
                                            <div className="pull-right hidden-xs">
                                                <VersionInfo/>
                                            </div>
                                            <strong>Copyright © 2018-2021</strong>
                                        </footer>
                                    </div>
                                </AuthenticationProtected>
                            </Route>


                        </Switch>
                    </AuthenticationContext.Provider>
                </GlobalDataContext.Provider>
            </Router>
        );
    }
}

export default App;
