import React from "react";
import {ProductMapping} from "../models";

interface IProps {
  restock: number
  currentStock: number
  coil: ProductMapping
  manualBalance(coil: ProductMapping, newStock: number)
  isEdit?:boolean
}

let getWidth = (currentStock, restock) =>{
  if (restock === 0) {
    return 0;
  }
  if (!currentStock) {
    return 0;
  }

  let width = 0;
  width = 100.0 * currentStock / restock;
  if (width > 100) {
    return 100;
  }
  if (width < 10) {
    return 10;
  }
  return Math.floor(width);
};

const CoilsStatsDataTableCell: React.SFC<IProps> = props => {
  let { currentStock, restock, coil, manualBalance} = props;
  const [state, setState] = React.useState({
    isEdit: props.isEdit? true:false,
    new_stock: currentStock
  });

  const showEdit = () => {
    setState({
      ...state,
      isEdit: true
    })
  };

  const handleChange = evt => {
    setState({
      ...state,
      new_stock: parseInt(evt.target.value) || 0
    })
  };

  const stopEditing = () => {
    setState({
      ...state,
      isEdit: false
    })
  };

  const save = () => {
    //console.log('save:', coil, state.new_stock)
    stopEditing();
    manualBalance(coil, state.new_stock);
  };

  const cycleCounting = coil.cycleCounting;
  let overStock : Boolean;
  if ((restock >= currentStock)){
    overStock = false
  } else{
    overStock = true
  }

  return (
      <div>
        {!state.isEdit && (
          <div onClick={showEdit} style={{"width": "150px"}} className="progress-group">
          {!cycleCounting && (
            <div>
              <span className="progress-text"><b>{currentStock}</b> / {restock} {cycleCounting && '[cycle counting]'}</span>
              <div className="progress sm">
                <div className="progress-bar progress-bar-aqua" style={{width: getWidth(currentStock, restock) + "%"}}></div>
              </div>
            </div>)}
          {cycleCounting && (
            <div>
              {!overStock && (
                <span className="progress-text"><b>{currentStock - restock}</b>&nbsp;[cycle counting]</span>
              )}
              {overStock && (
                <span className="progress-text"><b>{0}</b>&nbsp;[cycle counting]</span>
              )}
            </div>)
          }
          </div>
  )}
  {state.isEdit && (
    <div>
      <input className="form-control" onChange={handleChange} value={state.new_stock}/>
      <br />
      <br />
      <button className={"btn btn-sm  btn-default"} onClick={stopEditing}>Cancel</button>
      &nbsp;
      &nbsp;
      <button className="btn btn-sm btn-success" onClick={save} value={"Save"}>
        Save
      </button>
    </div>
  )}
      </div>
  )
};

export default CoilsStatsDataTableCell;
