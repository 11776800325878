import React, {Component, Fragment} from 'react';
import {getAPI} from "./api";
import {DexReadingButton} from "./dex_reading_button";
import {DexReading} from "./models";
import {Helmet} from "react-helmet"

interface State {
    vending_machine_name: string | null
    dex_readings: Array<DexReading>
}

interface Props {
    match: any
}

class VendingMachineDexReadings extends Component<Props, State> {
    constructor(props) {
        super(props);

        this.state = {
            vending_machine_name: null,
            dex_readings: []
        };
    }


    componentDidMount() {
        this.loadDexReadings();
    }

    preview(dex_reading_id) {
        let new_dex_readings = this.state.dex_readings.map((r) => {
            if (r.id === dex_reading_id) {
                r.previewing = !r.previewing;
            }

            if (r.previewing) {
                getAPI(`/api/dex_readings/${r.id}/preview`).then((response) => {
                    this.updatePreview(r.id, response.data.preview);
                });
            }

            return r;
        });

        this.setState({dex_readings: new_dex_readings});
    }

    updatePreview(dex_reading_id, content) {
        let new_dex_readings = this.state.dex_readings.map((r) => {
            console.log(dex_reading_id);
            if (r.id === dex_reading_id) {
                r.preview = content;
            }

            return r;
        });

        this.setState({dex_readings: new_dex_readings});
    }

    loadDexReadings() {
        getAPI(`/api/vending_machines/${this.props.match.params.uuid}/dex_readings`).then((response) => {
            if (response.data.vending_machine_name && response.data.dex_readings) {
                this.setState({
                    'vending_machine_name': response.data.vending_machine_name,
                    'dex_readings': response.data.dex_readings.map((reading) => {
                        reading.previewing = false;
                        return reading;
                    })
                });
            }
        });
    }

    render() {
        const dex_readings = this.state.dex_readings.map((r) => {
            let preview_content = !r.previewing ? null : (
                <pre>{r.preview}</pre>
            );

            let preview = (
                <div>
                    <button className={"btn btn-default"} onClick={(e) => {
                        e.preventDefault();
                        this.preview(r.id);
                        return false;
                    }}>Preview
                    </button>
                    <br/>
                    {preview_content}
                </div>
            );

            let checksum_status = (<div className={"label label-danger"}>Checksum Failed</div>);
            if (r.checksum_verified) {
                checksum_status = (<div className={"label label-success"}>Success</div>);
            }

            return (
                <Fragment>
                    <tr>
                        <td>
                            <b>{r.createdDate}</b>

                        </td>
                        <td>{r.size}</td>
                        <td>
                            {checksum_status}
                        </td>
                    </tr>
                    {preview ? (<tr>
                        <td colSpan={3}>{preview}</td>
                    </tr>) : null}
                </Fragment>
            )
        });

        return (
            <section className="content">
                <Helmet titleTemplate="%s - Vending on Track">
                    <title>Dex Readings</title>
                </Helmet>
                <h1>Dex Readings for {this.state.vending_machine_name}</h1>

                <DexReadingButton last_dex_reading_at={-1} update={() => {
                }} last_dex_reading={""} vending_machine_uuid={this.props.match.params.uuid}/>

                <table className="table table-condensed">
                    <thead>
                    <tr>
                        <th>Time</th>
                        <th>Size</th>
                        <th>Checksum</th>
                    </tr>
                    </thead>
                    <tbody>
                    {dex_readings}

                    </tbody>
                </table>
            </section>);
    }

}


export {VendingMachineDexReadings}
