import React, {Component} from 'react';
import {Product, productPicture} from "./models";
import {Tooltip} from "react-tippy";


interface Props {
    product: Product|null
    hover?: boolean
}

interface State {

}

class InlineProductPhoto extends Component<Props, State> {
    constructor(props) {
        super(props);

        this.state = {};
    }

    render() {
        if (!this.props.product?.imageUploadKey) {
            return null;
        }

        const html = (
            <img src={productPicture(this.props.product)} style={{width: "300px"}} alt={this.props.product.name}/>);

        if (!!this.props.hover) {
            return (
                <Tooltip html={html}>
                    <img alt={this.props.product.name} src={productPicture(this.props.product)}
                         style={{height: "2em"}}/>
                </Tooltip>
            );
        } else {
            return <img alt={this.props.product.name} src={productPicture(this.props.product)} style={{height: "2em"}}/>
        }
    }
}

export default InlineProductPhoto;
