import React, {Component, Fragment} from "react";
import {ConnectionSession, DeviceUptimeResponse} from "./models";
import {getAPI} from "./api";
import {DisplayTime} from "./display_time";
import ReactPaginate from "react-paginate";
import {Helmet} from "react-helmet"
interface Props {
    match: any
}

interface State {
    sessions: Array<ConnectionSession>;
    uptime: DeviceUptimeResponse | null;

    page: number; // current page
    pages: number; // total pages
    totalSessions: number;
}

export class TelemetryDeviceSessions extends Component<Props, State> {
    constructor(props) {
        super(props);

        this.state = {
            page: 0,
            totalSessions: 0,
            pages: 0,
            sessions: [],
            uptime: null
        }
    }

    handlePageClick(selectedItem: { selected: number }) {
        this.setState({page: selectedItem.selected});

        this.init(selectedItem.selected);
    }

    componentDidMount(): void {
        this.load_sessions();
    }

    load_sessions(): void {
        getAPI(`/api/telemetry_devices/${this.props.match.params.uuid}/uptime`).then((response) => {
            this.setState({'uptime': response.data});
        });

        // load page 0
        this.init(0);
    }

    render() {
        let rows = this.state.sessions.sort((a, b) => {
            return b.connectedTimestamp - a.connectedTimestamp;
        }).map((connectionSession: ConnectionSession, i: number) => {
            return (<tr key={i}>

                <td>
                    <DisplayTime timestamp={connectionSession.connectedTimestamp / 1000}/>
                    <span
                        className={"connection-just-restarted badge bg-yellow"}>{connectionSession.restarted ? "Just Restarted" : ""}</span>
                </td>
                <td><DisplayTime
                    timestamp={connectionSession.disconnectedTimestamp ? connectionSession.disconnectedTimestamp / 1000 : null}/>
                </td>
                <td>{connectionSession.disconnectedTimestamp == null ? "online" : "" + (connectionSession.disconnectedTimestamp - connectionSession.connectedTimestamp) / 1000 + " seconds"} </td>
            </tr>);
        });

        let metro: any = null;

        if (this.state.uptime != null) {
            metro = (<div className="row">

                <div className="col-md-4">
                    <div className="info-box">
                            <span className="info-box-icon bg-red">
                                <i className="fa fa-arrow-up"></i></span>

                        <div className="info-box-content">
                            <span className="info-box-text">24-hour Uptime</span>
                            <span
                                className="info-box-number">{(1.0 * this.state.uptime.uptime24hrs / (24.0 * 3600 * 1000 / 100)).toFixed(2)}%</span>
                        </div>
                    </div>
                </div>

                <div className="clearfix visible-sm-block"></div>

                <div className="col-md-4">
                    <div className="info-box">
                            <span className="info-box-icon bg-red">
                                <i className="fa fa-arrow-up"></i></span>

                        <div className="info-box-content">
                            <span className="info-box-text">7-day Uptime</span>
                            <span
                                className="info-box-number">{(1.0 * this.state.uptime.uptime7Day / (24.0 * 7 * 3600 * 1000 / 100)).toFixed(2)}%</span>
                        </div>
                    </div>
                </div>

                <div className="clearfix visible-sm-block"></div>

                <div className="col-md-4">
                    <div className="info-box">
                            <span className="info-box-icon bg-red">
                                <i className="fa fa-arrow-up"></i></span>

                        <div className="info-box-content">
                            <span className="info-box-text">28-day Uptime</span>
                            <span
                                className="info-box-number">{(1.0 * this.state.uptime.uptime28Day / (24.0 * 28 * 3600 * 1000 / 100)).toFixed(2)}%</span>
                        </div>
                    </div>
                </div>

            </div>);
        }
        return (
            <Fragment>
                <Helmet titleTemplate="%s - Vending on Track">
                <title>Connection Stats</title>
                </Helmet>
                <section className="content-header">
                    <h1>
                        Connection Stats
                    </h1>
                </section>

                <section className="content">
                    {metro}

                    <div className="box box-success">
                        <div className="box-header">
                            <h2>Connection Sessions</h2>
                        </div>

                        <div className="box-body">

                            <ReactPaginate
                                pageRangeDisplayed={2}
                                marginPagesDisplayed={2}
                                pageCount={this.state.pages}
                                initialPage={0}
                                onPageChange={this.handlePageClick.bind(this)}
                                containerClassName={"pagination pagination-sm no-margin"}
                                activeClassName={'active'}
                            />

                            <table className={"table"}>
                                <thead>
                                <tr>
                                    <th>Start Time</th>
                                    <th>Finish Time</th>
                                    <th>Duration</th>
                                </tr>
                                </thead>

                                <tbody>
                                {rows}
                                </tbody>
                            </table>
                        </div>
                    </div>
                </section>
            </Fragment>
        );
    }

    init(page: number) {
        getAPI(`/api/telemetry_devices/${this.props.match.params.uuid}/sessions?page=${page}`).then((result) => {
            if (result.data.success) {
                this.setState({
                    "sessions": result.data.sessions.content,
                    "totalSessions": result.data.sessions.totalElements,
                    "pages": result.data.sessions.totalPages,
                });
            } else {
                alert(result.data.message);
            }
        });
    }
}
