import React, {Component} from 'react';
import {postAPI} from "./api";
import {FlashMessageContext, withGlobalData} from "./contexts/global-data";
import {Message} from "./models";


interface Props extends FlashMessageContext {
    vending_machine_uuid: string
    last_dex_reading_at: number
    last_dex_reading: string | JSX.Element

    update(): void
}

interface State {
    requesting_dex_reading: boolean
    dex_error: boolean
}


class RawDexReadingButton extends Component<Props, State> {
    constructor(props) {
        super(props);

        this.state = {
            requesting_dex_reading: false,
            dex_error: false
        }
    }


    requestDexReading(e) {
        e.preventDefault();

        this.setState({requesting_dex_reading: true});

        postAPI(`/api/vending_machines/${this.props.vending_machine_uuid}/dex_readings`, {}).then((response) => {
            console.log(response);
            this.setState({
                requesting_dex_reading: false
            });

            if (response.data.success) {
                this.setState({dex_error: false});

                if (this.props.update) {
                    this.props.update();
                }

            } else {
                this.setState({dex_error: true});
                this.props.pushFlashMessage(new Message(response.data.message, "error"));
            }
        });

        return false;
    }

    render() {
        return (
            <span>
                {this.props.last_dex_reading_at ? this.props.last_dex_reading : null}

                &nbsp;

                {this.state.requesting_dex_reading ? null :
                    (<button className={"btn btn-success"} style={{'cursor': 'pointer'}} onClick={(e) => {
                        this.requestDexReading(e);
                    }}>Request a new Dex Reading</button>)}
            </span>
        );
    }
}

export const DexReadingButton = withGlobalData(RawDexReadingButton);