import React, {useEffect, useState} from 'react';
import DatePicker from "react-datepicker";
import {postAPI} from "./api";
import {Message} from "./models";
import DateDisplay from "./date_display";


interface Props {
    expiryDate: Date | null
    stockId: number
    warehouseId: number
    pushFlashMessage(Message)
    reloadStock()
}

function ExpiryDateDisplay(props: Props) {
    const [expiryDate, setExpiryDate] = useState(props.expiryDate)
    const [expiryDateSnapshot, setExpiryDateSnapshot] = useState(props.expiryDate)
    const [isEditing, setEditing] = useState<boolean>(false)
    const [isDeleting, setDeleting] = useState<boolean>(false)

    useEffect(()=>{
        setExpiryDate(props.expiryDate)
    }, [props.expiryDate])

    useEffect(() => {
        setExpiryDate(expiryDate)
    }, [expiryDate])

    const toggleDelete = ()=>{
        setDeleting(!isDeleting)
    }

    const updateExpiryDate = ()=>{
        postAPI(`/api/warehouses/${props.warehouseId}/${props.stockId}/expirydate`, {expiryDate:expiryDate}).then((response) =>{
            if (response.data.success) {
                props.pushFlashMessage(new Message("Saved", "success"));
                props.reloadStock();
            } else {
                window.alert(response.data.message);
            }
        })
        setEditing(false)
    }

    const deleteExpiryDate = () =>{
        postAPI(`/api/warehouses/${props.warehouseId}/${props.stockId}/expirydate`, null, "DELETE").then((response) =>{
            if (response.data.success) {
                props.pushFlashMessage(new Message("Saved", "success"));
                setExpiryDate(null);
                props.reloadStock();
            } else {
                window.alert(response.data.message);
            }
        })
        setEditing(false)
    }

    const handleEdit = () => {
        setEditing(true)
        setDeleting(false);
        setExpiryDateSnapshot(expiryDate);
            if (expiryDate == null){
                setExpiryDate(new Date())
            }
        }

    const cancelExpiryDate = () =>{
        setExpiryDate(expiryDateSnapshot);
        setEditing(false)
    }

    let expiryDateShowOnDatePicker:Date;
    if (expiryDate != null){
        expiryDateShowOnDatePicker = expiryDate;
    } else{
        expiryDateShowOnDatePicker = new Date();
    }

    return (
        <>
            { isEditing ?
                <div>
                    {<DatePicker dateFormat="dd/MM/yyyy" selected={new Date(expiryDateShowOnDatePicker.toString())}
                                 onChange={
                                     (e) => {
                                         setExpiryDate(e)
                                     }}
                                 onSelect={(e) => {
                                     setExpiryDate(e)
                                 }}
                    />
                    }
                    <div className={"text-center"}>
                    {<button onClick={updateExpiryDate} className={"btn btn-success"}>Save</button>}
                        &nbsp;
                    {<button onClick={cancelExpiryDate} className={"btn btn-danger"}>Cancel</button>}
                    </div>
                </div> :
                <div>
                    <div className={"page-link inline"} onClick={handleEdit}>
                        {!expiryDate && "Expiry Date not set"}
                        {expiryDate && <DateDisplay date={expiryDate}/>}
                    </div>
                        &nbsp;&nbsp;
                    {(expiryDate &&!isDeleting) && <button className={"btn btn-danger inline"} onClick={toggleDelete}>Delete</button>}
                    {isDeleting &&
                    <div className={"inline"}>
                        <button className={"btn btn-success btn-sm"} onClick={deleteExpiryDate}>confirm</button>
                        &nbsp;
                        <button className={"btn btn-danger btn-sm"} onClick={toggleDelete}>cancel</button>
                    </div>}

                </div>


            }
        </>
    )
}
export default ExpiryDateDisplay;
