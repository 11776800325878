import React from 'react';
import {Picking, PickingState} from "./models";

interface Props {
    picking: Picking
}

export default function DisplayPickingState(props: Props) {
    let cls = '';

    if (props.picking.state === PickingState.SCHEDULED) {
        cls = cls + 'text-yellow';
    }

    if (props.picking.state === PickingState.ABANDONED) {
        cls = cls + 'text-striked';
    }

    if (props.picking.state === PickingState.PICKED) {
        cls = cls + 'text-green';
    }

    return (<span className={cls}>{props.picking.state}</span>)
}
