import React, { useState, useEffect, useCallback } from 'react';
import { Message } from "./models";
import { withGlobalData } from "./contexts/global-data"; 
import { Helmet } from "react-helmet";
import { useClient } from "./connect_api";
import { DeviceManagementService } from "./device/v1/api_connect";
import { Device } from "./device/v1/api_pb";
import { postAPI } from "./api";
import { TelemetryDeviceTable } from './telemetry_device_table';
import ReactPaginate from 'react-paginate';

const pageSize = 20;

const TelemetryDevices = ({ pushFlashMessage }) => {
    const client = useClient(DeviceManagementService);
    const [pageCount, setPageCount] = useState<number>(1);
    const [loadingDevices, setLoadingDevices] = useState(true);
    const [devices, setDevices] = useState<Array<Device>>([]);
    const [error, setError] = useState<string | null>(null);
    const [page, setPage] = useState(0);

    const handlePageChange = (selectedItem: { selected: number }) => {
        setPage(selectedItem.selected);
    };
    
    const refreshDataCallback = useCallback(async () => {
        try {
            const result = await client.listDevices({ page, pageSize });
            setDevices(result.devices);
            setPageCount(Math.ceil(result.totalCount / pageSize));
            setLoadingDevices(false);
            setError(null);
        } catch (error) {
            setError('List devices failed. Please try again later.');
            pushFlashMessage(new Message('List devices failed. Please try again later.', 'danger'));
            setLoadingDevices(false);
            setDevices([]);
        }
    }, [page, client, pushFlashMessage]);
    
    useEffect(() => {
        refreshDataCallback();
    }, [page, refreshDataCallback]);
    
    
    // useEffect(() => {
    //     refreshData();
    //      // eslint-disable-next-line
    // }, []);

    const requestCSQ = (event) => {
        event.preventDefault();
        postAPI(`/api/telemetry_devices/csq`, {}).then((response) => {
            if (response.data.success) {
                window.alert(`Successfully requested signal reports from ${response.data.count} online devices.`);
                pushFlashMessage(new Message(`Successfully requested signal reports from ${response.data.count} online devices.`, 'success'));
            }
        });
        return false;
    };

    return (
        <section className="content">
            <Helmet titleTemplate="%s - Vending on Track">
                <title>Telemetry Devices</title>
            </Helmet>
            <h1>Telemetry Devices</h1>

            <p>
                <button onClick={requestCSQ} className={"btn btn-success"}>
                    Request signal report
                </button>
            </p>

            {error ?
                <div className="callout callout-danger">
                    <h4>There was an error when we tried to load your telemetry devices.</h4>
                    <p>{error}</p>
                </div>
                :
                (loadingDevices ?
                    <div className="callout callout-info">
                        <h4>Loading devices...</h4>
                        <p>We are listing all your telemetry devices. It won't be long.</p>
                    </div>
                    :
                    <TelemetryDeviceTable devices={devices} refresh={refreshDataCallback} />
                    )
            }
            <ReactPaginate
            pageRangeDisplayed={2}
            marginPagesDisplayed={2}
            initialPage={page}
            onPageChange={handlePageChange}
            containerClassName={"pagination mb-0"}
            pageClassName={"page-item"}
            pageLinkClassName={"page-link"}
            previousClassName={"page-item page-link"}
            nextClassName={"page-item page-link"}
            activeClassName={"active"} forcePage={page}
            pageCount={pageCount} />
        </section>
    );
};

export default withGlobalData(TelemetryDevices);
