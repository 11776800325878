import React, {Component} from "react";
import {DexProduct, DexSettingInfo, Message} from "./models";
import {getAPI, postAPI} from "./api";
import {IGlobalDataContext, withGlobalData} from "./contexts/global-data";
import formatUtilPrice from './format_until_price'


interface Props extends IGlobalDataContext{
    dex: DexSettingInfo | null;

    emailDexFile: boolean

    uuid:string

    handleRefresh(): void;
}

interface State {
    emailDexFile: boolean
}

// this class is used to display setting info from last dex reading
class _DexReadingSettingInfoPanel extends Component<Props, State> {
    constructor(props) {
        super(props);

        this.state = {
            emailDexFile:this.props.emailDexFile
        }
    }

    changeEmailDexFile(e) {
        if (e.target.value === "yes") {
            this.setState({emailDexFile:true})
        } else {
            this.setState({emailDexFile:false})
        }
        e.preventDefault();
        e.stopPropagation();
    }


    handleSubmit(e){
        e.preventDefault();
        e.stopPropagation();
        postAPI(`/api/vending_machines/${this.props.uuid}/virtual_dex_setting`, this.state).then((response) => {
            if (response.data.success) {
                this.props.pushFlashMessage(new Message("Saved", "success"));
                this.props.handleRefresh();
            }
        })

    }
    downloadDexFile = () => {
        getAPI(`/api/vending_machines/${this.props.uuid}/generating_dex_report`).then((response)=>{
            if (response.data.success){
                const element = document.createElement("a");
                const file = new Blob([response.data.dexFileData], {type: 'text/plain'});
                element.href = URL.createObjectURL(file);
                element.download = "dexfile_"+this.props.uuid+"_"+(new Date()).getTime();
                document.body.appendChild(element); // Required for this to work in FireFox
                element.click();
            }
        })
    }

    render() {
        if (this.props.dex == null) {
            return (
                <div>
                    <div className={"row"}>
                        <div className={"col-xs-6"}>
                            <h3>Virtual Dex Settings</h3>
                            <form onSubmit={this.handleSubmit.bind(this)}>
                                <div className="form-group">
                                    <label>Enable Email DEX File (Daily)</label>
                                    <select className={"form-control"}
                                            onChange={this.changeEmailDexFile.bind(this)}
                                            value={this.state.emailDexFile ? "yes" : "no"}>
                                        <option value="yes">Yes</option>
                                        <option value="no">No</option>
                                    </select>
                                </div>
                                <input type="submit" className="btn btn-success" value="Save" />
                            </form>
                        </div>
                    </div>
                    <div className={"row"}>
                        <div className={"col-xs-6"}>
                            <button className={"btn btn-info"} onClick={this.downloadDexFile}>Download DEX File</button>
                        </div>
                    </div>
                    <h1>No dex reading yet.</h1>
                </div>
            )
        }

        const coils = this.props.dex.coils ? (this.props.dex.coils.map((dexProduct: DexProduct) => {
            return (<tr key={dexProduct.identifier}>
                <td>{dexProduct.identifier}</td>
                <td>$ {dexProduct.price ? formatUtilPrice(dexProduct.price, this.props.me.mostRecentCompany.currencyDecimalPoints) : '-'}</td>
                <td>{dexProduct.identification}</td>
                <td>{dexProduct.capacity}</td>
                <td>{dexProduct.standard_filling_level}</td>
                <td>{dexProduct.standard_dispensed_quality}</td>
                <td>{dexProduct.current_level}</td>
                <td>{dexProduct.minimum_level}</td>
            </tr>)
        })) : null;

        return (
            <div>
                <div>
                    <div className={"row"}>
                        <div className={"col-xs-6"}>
                            <h3>Virtual Dex Settings</h3>
                            <form onSubmit={this.handleSubmit.bind(this)}>
                                <div className="form-group">
                                    <label>Enable Email DEX File (Daily)</label>
                                    <select className={"form-control"}
                                            onChange={this.changeEmailDexFile.bind(this)}
                                            value={this.state.emailDexFile ? "yes" : "no"}>
                                        <option value="yes">Yes</option>
                                        <option value="no">No</option>
                                    </select>
                                </div>
                                <input type="submit" className="btn btn-success" value="Save" />
                            </form>
                        </div>
                    </div>
                    <div className={"row"}>
                        <div className={"col-xs-6"}>
                            <button className={"btn btn-info"} onClick={this.downloadDexFile}>Download DEX File</button>
                        </div>
                    </div>
                </div>
                
                <h1>DEX</h1>

                <hr/>

                <h2>Basic Info</h2>
                <table className={"table"}>
                    <thead>
                    <tr>
                        <th>Attribute</th>
                        <th>Value</th>
                    </tr>
                    </thead>
                    <tbody>
                    <tr>
                        <td>Machine Serial Number</td>
                        <td>{this.props.dex.machine_serial_number}</td>
                    </tr>
                    <tr>
                        <td>Machine Location</td>
                        <td>{this.props.dex.machine_location}</td>
                    </tr>
                    <tr>
                        <td>Machine Asset Number</td>
                        <td>{this.props.dex.machine_asset_number}</td>
                    </tr>
                    <tr>
                        <td>Decimal Point Position</td>
                        <td>{this.props.dex.decimal_point_position}</td>
                    </tr>
                    <tr>
                        <td>Currency Code</td>
                        <td>{this.props.dex.currency_alphabetic_code}</td>
                    </tr>
                    <tr>
                        <td>Coin Mech Serial Number</td>
                        <td>{this.props.dex.changer_serial_number}</td>
                    </tr>
                    <tr>
                        <td>Coin Mech Model Number</td>
                        <td>{this.props.dex.coin_mech_model_number}</td>
                    </tr>
                    <tr>
                        <td>Bill Validator Serial Number</td>
                        <td>{this.props.dex.bill_validator_serial_number}</td>
                    </tr>
                    <tr>
                        <td>Bill Validator Model Number</td>
                        <td>{this.props.dex.bill_validator_model_number}</td>
                    </tr>
                    <tr>
                        <td>Cashless Device #1 Serial Number</td>
                        <td>{this.props.dex.cashless_1_serial_number}</td>
                    </tr>
                    <tr>
                        <td>Cashless Device #1 Model Number</td>
                        <td>{this.props.dex.cashless_1_model_number}</td>
                    </tr>
                    <tr>
                        <td>Coil Count</td>
                        <td>{this.props.dex.coils.length}</td>
                    </tr>
                    </tbody>

                </table>

                <hr/>
                <h2>Coils</h2>

                <table className={"table"}>
                    <thead>
                    <tr>
                        <th>identifier (dex code)</th>
                        <th>price</th>
                        <th>identification</th>
                        <th>capacity</th>
                        <th>standard filling level (default service level)</th>
                        <th>standard dispensed quantity</th>
                        <th>current level</th>
                        <th>minimum level for alert</th>
                    </tr>
                    </thead>

                    <tbody>
                    {coils}
                    </tbody>
                </table>
            </div>
        )
    }
}

export const DexReadingSettingInfoPanel = withGlobalData(_DexReadingSettingInfoPanel);
