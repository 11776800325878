import React, {Component} from 'react';
import {Tube as TubeModel} from './models';
import {FormatMoneyWithFlexibleSignSize} from "./format_money_without_style";

interface Props {
    tube: TubeModel
}

interface State {
}

class Tube extends Component<Props, State> {
    constructor(props) {
        super(props);

        this.state = {}
    }

    render() {
        return (
            <div
                className={'info-box ' + (this.props.tube.faulty ? 'bg-red' : (this.props.tube.full ? 'bg-green' : 'bg-blue'))}>
                                <span className="tube-stats-box-icon">
                                    <FormatMoneyWithFlexibleSignSize cents={this.props.tube.value} smallSign={true}/>
                                </span>

                <div className="info-box-content">
                    <span className="info-box-text">Coin Count</span>
                    <span
                        className="info-box-number">{this.props.tube.coin_count} {this.props.tube.faulty && "- Faulty"} {this.props.tube.full && "- Full"}</span>
                </div>
            </div>
        );
    }
}

export {Tube};