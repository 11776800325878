import axios, {AxiosPromise} from 'axios';
import {
    Configuration,
    MachineManagementServiceApi,
    MediaManagementServiceApi,
    OrganisationSettingsServiceApi,
    S3ServiceApi,
    VendingMachineGroupServiceApi,
    VmcpOrderServiceApi
} from "./telemetry/vending_machine/v1/typescript-axios";

const GrpcAxios = axios.create({timeout:10000});
GrpcAxios.interceptors.request.use(
    (config) => {
        const authentication_token = localStorage.getItem("authentication_token");
        config.headers={
            'content-type': 'application/json',
            'Grpc-Metadata-Authorization':(authentication_token === null ? "" : authentication_token)
        }
        return config;
    },
    (error) => {
        // Handle request error
        return Promise.reject(error);
    }
);
function postAPI(path: string, data: any, operation?: string, contentType?: string): AxiosPromise<any> {
    const authentication_token = localStorage.getItem("authentication_token");

    const instance = axios.create({
        headers: {
            'content-type': contentType ? contentType : 'application/json',
            'authentication': (authentication_token === null ? "" : authentication_token)
        }
    });

    let op = instance.post;
    if (operation === "DELETE") {
        op = instance.delete;
        return op(path)
    }
    return op(path, JSON.stringify(data));
}

function getAPI(path: string): AxiosPromise<any> {

    const authentication_token = localStorage.getItem("authentication_token");

    const instance = axios.create({
        headers: {
            'content-type': 'application/json',
            'authentication': (authentication_token === null ? "" : authentication_token)
        }
    });

    return instance.get(path);
}

const Service:GrpcApi ={
    MediaManagementServiceAPI:null,
    MachineManagementServiceAPI:null,
    OrganisationSettingsServiceAPI:null,
    VendingMachineGroupServiceAPI:null,
    S3ServiceApi:null,
    VmcpOrderServiceAPI:null,
}
interface GrpcApi {
    MediaManagementServiceAPI: MediaManagementServiceApi|null
    MachineManagementServiceAPI:MachineManagementServiceApi|null
    OrganisationSettingsServiceAPI:OrganisationSettingsServiceApi|null
    VendingMachineGroupServiceAPI:VendingMachineGroupServiceApi|null
    S3ServiceApi:S3ServiceApi|null
    VmcpOrderServiceAPI:VmcpOrderServiceApi|null
}
export const mediaManagementServiceApi = (function (){
    if (Service.MediaManagementServiceAPI){
        return Service.MediaManagementServiceAPI
    }else {
        const MediaManagementServiceAPI=new MediaManagementServiceApi(new Configuration(), window.location.origin, GrpcAxios);
        Service.MediaManagementServiceAPI=MediaManagementServiceAPI
        return Service.MediaManagementServiceAPI
    }

} as unknown) as {new ():MediaManagementServiceApi}

export const S3Service = (function (){
    if (Service.S3ServiceApi){
        return Service.S3ServiceApi
    }else {
        const S3Service=new S3ServiceApi(new Configuration(), window.location.origin, GrpcAxios);
        Service.S3ServiceApi=S3Service
        return Service.S3ServiceApi
    }

} as unknown) as {new ():S3ServiceApi}


export const ManagementServiceApi = (function (){
    if (Service.MachineManagementServiceAPI){
        return Service.MachineManagementServiceAPI
    }else {
        const MachineManagementServiceAPI=new MachineManagementServiceApi(new Configuration(), window.location.origin, GrpcAxios);
        Service.MachineManagementServiceAPI=MachineManagementServiceAPI
        return Service.MachineManagementServiceAPI
    }

} as unknown) as {new ():MachineManagementServiceApi}

export const organisationSettingsServiceApi = (function() {
    if (Service.OrganisationSettingsServiceAPI){
        return Service.OrganisationSettingsServiceAPI
    }else {
        const OrganisationSettingsServiceAPI=new OrganisationSettingsServiceApi(new Configuration(), window.location.origin, GrpcAxios);
        Service.OrganisationSettingsServiceAPI=OrganisationSettingsServiceAPI
        return Service.OrganisationSettingsServiceAPI
    }
}as unknown) as {new ():OrganisationSettingsServiceApi}


export const vendingMachineGroupServiceApi = (function() {
    if (Service.VendingMachineGroupServiceAPI){
        return Service.VendingMachineGroupServiceAPI
    }else {
        const VendingMachineGroupAPI=new VendingMachineGroupServiceApi(new Configuration(), window.location.origin, GrpcAxios);
        Service.VendingMachineGroupServiceAPI=VendingMachineGroupAPI
        return Service.VendingMachineGroupServiceAPI
    }
}as unknown) as {new ():VendingMachineGroupServiceApi}

export const vmcpOrderServiceApi = (function() {
    if (Service.VmcpOrderServiceAPI){
        return Service.VmcpOrderServiceAPI
    }else {
        const VmcpOrderServiceAPI=new VmcpOrderServiceApi(new Configuration(), window.location.origin, GrpcAxios);
        Service.VmcpOrderServiceAPI=VmcpOrderServiceAPI
        return Service.VmcpOrderServiceAPI
    }
}as unknown) as {new ():VmcpOrderServiceApi}

export {postAPI, getAPI}
