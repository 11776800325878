import {ProductRecommendationForm, sortProductRecommendationForm} from "./models";
import {IGlobalDataContext, withGlobalData} from "./contexts/global-data";
import React, {Component} from "react";
import {Helmet} from "react-helmet";
import {Link} from "react-router-dom";
import {getAPI, postAPI} from "./api";
import {DisplayTime} from "./display_time";

interface State {
    productRecommendationForms: ProductRecommendationForm[]
}

interface Props extends IGlobalDataContext {
    match:any
}


class _ProductRecommendationPage extends Component<Props, State> {
    constructor(props) {
        super(props);

        this.state = {
            productRecommendationForms:[]
        };
    }


    componentDidMount() {
        this.reloadProductRecommendationPage()
    }

    reloadProductRecommendationPage(){
        getAPI(`/api/product_recommendation_form`).then((response)=>{
            if (response.data.success){
                this.setState({productRecommendationForms:response.data.productRecommendationForms})
            }
        })
    }

    removeProductRecommendationForm(e:any, uuid:string){
        e.preventDefault()
        e.stopPropagation()
        if (window.confirm("are you sure to remove this form?")){
            postAPI(`/api/product_recommendation_form/${uuid}/delete`, {}).then((response)=>{
                if (response.data.success){
                    this.reloadProductRecommendationPage();
                }
            })
        }
    }

    render() {
        const rows = this.state.productRecommendationForms.sort(sortProductRecommendationForm).map((productRecommendationForm)=>{
            return <tr key={productRecommendationForm.uuid}>
                <td>
                    <DisplayTime timestamp={productRecommendationForm.createdAt} expanded={false}/>
                </td>
                <td>
                    {productRecommendationForm.uuid}
                </td>
                <td>
                    {productRecommendationForm.siteName}
                </td>
                <td>
                    {productRecommendationForm.siteOwnerEmail}
                </td>
                <td>
                    {!productRecommendationForm.ready && <Link className={'btn btn-warning'} to={`/product_recommendation_form/${productRecommendationForm.uuid}/edit`}>Edit</Link>}
                    {!productRecommendationForm.ready && <button className={'btn btn-danger'} onClick={(e)=>{this.removeProductRecommendationForm(e, productRecommendationForm.uuid)}}>Remove</button>}
                    {productRecommendationForm.ready && !productRecommendationForm.completed && <Link className={'btn btn-success'} to={`/product_recommendation_form/${productRecommendationForm.uuid}/view`}>View</Link>}
                    {productRecommendationForm.ready && productRecommendationForm.completed && <Link className={'btn btn-success'} to={`/product_recommendation_form/${productRecommendationForm.uuid}/view`}>View Result</Link>}
                </td>
            </tr>
        })

        return <section className="content">
            <Helmet titleTemplate="%s - Vending on Track">
                <title>Product Recommendation Form</title>
            </Helmet>
            <h1>Product Recommendation Form</h1>

            <div className="help-block">
                Product Recommendation Form page lists all the form you have generated.
            </div>
            <div className={"well"}>
                <div>
                    <Link to={"/add_product_recommendation_form"}>Create Product Recommendation Form</Link>
                </div>
            </div>
            <div className="box box-success">
                <div className="box-body no-padding">
                    <div>
                        <table className = "table table-bordered" id="listBarcodes">
                            <thead>
                            <tr>
                                <th scope="col">Date Time</th>
                                <th scope="col">UUID</th>
                                <th scope="col">Site Name</th>
                                <th scope="col">Site Owner Email</th>
                                <th scope="col">Operation</th>
                            </tr>
                            </thead>
                            <tbody>
                            {rows}
                            </tbody>
                        </table>
                    </div>
                </div>
            </div>
        </section>;
    }

}

export const ProductRecommendationPage = withGlobalData(_ProductRecommendationPage);
