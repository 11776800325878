import React, {Component} from "react";
import {Helmet} from "react-helmet";

interface Props {

}

interface State {

}

export class PageNotFound extends Component<Props, State> {
    render() {
        return (
            <div>
                <Helmet titleTemplate="%s - Vending on Track">
                    <title>The page does not exist</title>
                </Helmet>
                <h1>The page does not exist.</h1>
            </div>
        );
    }
}