import React from "react";
import CoilsStatsDataTableCell from "./components/CoilsStatsDataTableCell";

interface IExpanableRowProps {
    data: any
    manualBalance: any
}

export const ExpanableRow: React.SFC<IExpanableRowProps> = ({data, manualBalance}) => {
    return (<div style={{padding: '5px'}}>
        <CoilsStatsDataTableCell manualBalance={manualBalance}
                                 isEdit={true}
                                 coil={data} currentStock={data.currentStock}
                                 restock={data.restock}/>
    </div>)
};
