import React, {Component} from 'react';
import {VendingMachine, VendingMachineDailyEODStats} from "./models";
import moment from "moment";
import {FormatMoney} from "./format_money";
import {getAPI} from "./api";


interface Props {
    headingTime: moment.Moment
    vendingMachine: VendingMachine | null
}

interface State {
    loading: boolean
    stats: VendingMachineDailyEODStats | null
}

export class DateSplitter extends Component<Props, State> {
    constructor(props) {
        super(props);

        this.state = {
            loading: true,
            stats: null
        }

        this.loadStats();
    }

    loadStats() {
        if (this.props.vendingMachine == null) {
            return;
        }

        getAPI(`/api/vending_machines/${this.props.vendingMachine.uuid}/stats/${this.props.headingTime.format("YYYY-MM-DD")}`).then((response) => {
            console.log(response.data);
            this.setState({
                stats: response.data.perMachineEodStats
            })
        })
    }

    componentDidUpdate(prevProps: Readonly<Props>, prevState: Readonly<State>, snapshot?: any) {
        if (prevProps.vendingMachine !== this.props.vendingMachine) {
            this.loadStats();
        }
    }

    render() {
        return (
            <div>
                <b> {this.props.headingTime.format("DD MMM YYYY")} </b>

                -

                {this.state.stats && <FormatMoney cents={this.state.stats.totalRevenue}/>}
            </div>
        );
    }

}