import React, {useEffect, useState} from 'react';
import {Message, Product, CheckInProduct} from "./models";
import {getAPI, postAPI} from "./api";
import {IGlobalDataContext, withGlobalData} from "./contexts/global-data";
import InlineProductPhoto from "./inline_product_photo";




interface Props extends IGlobalDataContext {
    whetherReEntry: boolean,
    warehouseId:any,
    reloadStock: Function,
    action: Function,
}


function _BarcodeScanner(props: Props) {
    const {findProduct} = props
    const {warehouseId} = props
    const {reloadStock} = props
    const {action} = props
    const {whetherReEntry} = props
    const [selectedProducts, selectProducts] = useState<Array<string>>([" "]);
    const [quantity, _setQuantity] = useState<number>(0);
    const setQuantity = (v) => {
        _setQuantity(parseInt(v));
    };
    const [barcode, setBarcode] = useState<String>('');
    const [tempBarcode, setTempBarcode] = useState<string>('');
    const [scannedProducts, scanProducts] = useState<Array<CheckInProduct>>([]);


    useEffect(() => {

        const queryBarcode = () => {

            const url = '/api/barcodes/query';
    
            getAPI(url+'?content='+barcode).then((response) =>{
                if (response.data.success){
                    setQuantity(response.data.barcodeInfo.barcode.quantity)
                    let p:Product = findProduct(response.data.barcodeInfo.barcode.product.id)!
                    selectProducts([p.name])
                    let checkinproduct: CheckInProduct = {
                        barcode: barcode,
                        product: p,
                        quantity: response.data.barcodeInfo.barcode.quantity
                    }
                    scanProducts(scannedProducts => scannedProducts.concat([checkinproduct]))
                    setBarcode('')
                } else{
                    setQuantity(0)
                    selectProducts([])
                }
            })
        }

        if (barcode.length){
            queryBarcode()
        }
    }, [findProduct, barcode]);


    const submitBarcodeCheckin = (e) => {
        e.preventDefault();
        e.stopPropagation();
        const url = `/api/warehouses/${warehouseId}/checkins`;
        if (scannedProducts.length === 0){
            return;
        } else{
            scannedProducts.forEach((product, index)=>{
                if (whetherReEntry){
                    postAPI(url, {productId: product.product.id,quantity: product.quantity, type: "REENTRY"}).then((response) => {
                        if (response.data.success) {
                            props.pushFlashMessage(new Message("Saved", "success"));
                            reloadStock();
                            action(null);
                        } else {
                            window.alert(response.data.message);
                        }
                    })
                }else{
                    postAPI(url, {productId: product.product.id,quantity: product.quantity, type: "PURCHASE"}).then((response) => {
                        if (response.data.success) {
                            props.pushFlashMessage(new Message("Saved", "success"));
                            reloadStock();
                            action(null);
                        } else {
                            window.alert(response.data.message);
                        }
                    })
                }
            })
        }
    }

    return (
        <div>
            {whetherReEntry && <h2>Checkin (Barcode Re-Entry)</h2>}
            {!whetherReEntry && <h2>Checkin (Barcode)</h2>}
            <div id = "CheckInBarcode">
                <form onSubmit = {(e) => {
                            e.preventDefault();
                            e.stopPropagation();
                            setBarcode(tempBarcode);
                            setTempBarcode('');
                        }}>
                    <div className="form-group">
                        <label>Barcode</label>
                        <input className="form-control" type="text" value={tempBarcode} autoFocus={true} onChange={(e) => {
                            setTempBarcode(e.target.value)
                        }}></input>
                    </div>
                </form>
            </div>
            <hr className = "customhr"></hr>
            <div>
                    <div className="form-group">
                        <label htmlFor="">Product Name</label>
                        <input disabled className="form-control" type="text" value = {selectedProducts[0]}/>
                    </div>
                    <div className="form-group">
                        <label htmlFor="">Quantity</label>
                        <input disabled className="form-control" type="text" value={quantity} onChange={()=>{}}></input>
                    </div>
            </div>
            <hr className = "customhr"></hr>
            <div>
                <h4>Scanned Product</h4>
                <table className = "table">
                    <thead>
                    <tr>
                        <th>Barcode</th>
                        <th>Name</th>
                        <th>Quantity</th>
                        <th></th>
                    </tr>
                    </thead>
                    <tbody>
                    {scannedProducts.map((item, index)=>
                        <tr key={index}>
                        <th>{item.barcode}</th>
                        <th>
                            <div>
                                <InlineProductPhoto product={item.product}/>
                                {item.product.name}
                            </div>
                        </th>
                        <th>{item.quantity}</th>
                        <th><i className="fas fa-trash-alt hover-cursor-pointer" onClick = {() =>{
                            scanProducts(scannedProducts.filter((product)=>{
                                return product !== scannedProducts[index]
                            }))
                        }}></i></th>
                    </tr>
                    )}
                    </tbody>
                </table>
                {scannedProducts.length >0 && <form onSubmit={submitBarcodeCheckin}>
                    <div className="form-group">
                        <input type="submit" className="btn btn-success" value="Submit"/>
                    </div>
                </form>}
            </div>
        </div>
    )
}

export const BarcodeScanner = withGlobalData(_BarcodeScanner);
