import {Route, Switch} from "react-router-dom";
import {VendingMachineList} from "./vending_machine_list";
import {VendingMachines} from "./vending_machines";
import {VendingMachineStats} from "./vending_machine_stats";
import {PageNotFound} from "./page_not_found";
import React from "react";
import {KioskForceOrders} from "./kiosk_force_orders";
import DurationRevenueReport from "./duration_revenue_report";
import DurationRevenueReportByProduct from "./duration_revenue_report_by_product";
import {EodReportPage} from "./eod_report_page";
import DurationRevenueReportByCategory from "./duration_revenue_report_by_category";

export function ReportSwitchRoute() {
    return <Switch>
        <Route exact path="/kioskforce-orders" component={KioskForceOrders}/>
        <Route exact path="/eod" component={EodReportPage}/>
        <Route exact path="/eod/:date" component={EodReportPage}/>
        <Route exact path="/reports/duration" component={DurationRevenueReport}/>
        <Route exact path="/reports/product" component={DurationRevenueReportByProduct}/>
        <Route exact path="/reports/category" component={DurationRevenueReportByCategory}/>

        {/* this is for vending machine list */}
        <Route exact path="/vending_machine_list" component={VendingMachineList} />

        {/* this is vending machine settings */}
        <Route exact path="/" component={VendingMachines} />

        <Route path="/vending_machines/:uuid" component={VendingMachineStats} />
        <Route path={"/"} component={PageNotFound} />
    </Switch>
}