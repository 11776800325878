import React, {useEffect, useState} from "react";
import {VendingMachine} from "./models";
import {getAPI, ManagementServiceApi} from "./api";
import {
    TelemetryvendingMachinev1DefaultBuyPage, TelemetryvendingMachinev1SelectionCodeLength,
    V1MachineDetailResponse
} from "./telemetry/vending_machine/v1/typescript-axios";
import 'react-bootstrap-typeahead/css/Typeahead.css';
import {Typeahead} from "react-bootstrap-typeahead";
import './assets/css/typeahead.scss'


interface Props {
    vendingMachine: VendingMachine
}


export function VendingMachineConfigurationPanel(props: Props) {

    const [machineDetail, setMachineDetail] = useState<V1MachineDetailResponse | null>(null)

    const [timezones,setTimezones] = useState([])

    const PIN_CODE_LENGTH = 6;

    useEffect(() =>{
        loadTimezones();
        loadMachineConfiguration();
        // eslint-disable-next-line
    },[props.vendingMachine])


    const loadMachineConfiguration = () => {
        new ManagementServiceApi().machineManagementServiceMachineDetail(props.vendingMachine.uuid).then(
            res => {
                setMachineDetail(res.data)
            }).catch((e) => {
            alert("get machine detail error " + e)
        });
    }
    const loadTimezones = () => {
        getAPI('/api/users/timezones').then((response) => {
            setTimezones(response.data.timezones)
        })
    }

    const save = () => {
        let pinCode = machineDetail?.pinCode
        if (pinCode?.length !== PIN_CODE_LENGTH) {
            alert("The pin code length is "+PIN_CODE_LENGTH)
            return
        }

        let patt = RegExp("^\\d{" + PIN_CODE_LENGTH + "}$")
        if (patt.test(pinCode) !== true) {
            alert("The pin code needs to be "+PIN_CODE_LENGTH+" digits")
            return
        }

        new ManagementServiceApi().machineManagementServiceUpdateMachine(props.vendingMachine.uuid, {
            timezone: machineDetail?.timezone,
            currencyName: machineDetail?.currencyName,
            currencySymbol: machineDetail?.currencySymbol,
            currencyDecimalPoint: machineDetail?.currencyDecimalPoint,
            pinCode: machineDetail?.pinCode,
            disabled:machineDetail?.disabled,
            defaultBuyPage:machineDetail?.defaultBuyPage !== TelemetryvendingMachinev1DefaultBuyPage.SelectionCode ? TelemetryvendingMachinev1DefaultBuyPage.Products : TelemetryvendingMachinev1DefaultBuyPage.SelectionCode,
            globalLowStockAlertPercentage:machineDetail?.globalLowStockAlertPercentage,
            oneBasedNumberingEnabled: machineDetail?.oneBasedNumberingEnabled,
            selectionCodeLength: machineDetail?.selectionCodeLength,
            shoppingCartDisabled: machineDetail?.shoppingCartDisabled,
        }).then(res => {
            alert("update machine successfully ")
        }).catch((e) => {
            alert("fail to update machine, err:" + e)
        });
    }

    const setMachineValue = (field, value) => {
        let obj = {...machineDetail}
        obj[field] = value
        setMachineDetail(obj)
    }

    const handleTimezoneChange = (selectedTimezone) => {
        setMachineValue("timezone", selectedTimezone[0])
    }

    const handleDisableMachineChange = (event:any) =>{
        if (event.target.value === "1") {
            setMachineValue("disabled",true)
        } else {
            setMachineValue("disabled",false)
        }
    }

    const handleIndexPageChange = (event: any) => {
        setMachineValue("defaultBuyPage", event.target.value);
    };

    const handleShoppinCartDisabledChange = (event: any) => {
        if (event.target.value === "1") {
            setMachineValue("shoppingCartDisabled",true)
        } else {
            setMachineValue("shoppingCartDisabled",false)
        }
    };


    const handleCodeSelectionDigitChange = (event: any) => {
        setMachineValue("selectionCodeLength", event.target.value);
    };

    const handleOneOrZeroBasedNumberingChange = (event: any) => {
        setMachineValue("oneBasedNumberingEnabled", event.target.value === "1");
    };


    const handleInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        let inputValue = e.target.value;
        inputValue = inputValue.replace(/^0+/, '');
        let intValue = parseInt(inputValue, 10);
        intValue = isNaN(intValue) ? 0 : intValue;
        intValue = Math.min(Math.max(intValue, 0), 99);
        setMachineValue("globalLowStockAlertPercentage",intValue);
      };

    return (<div className="well">
            <h2>Machine Configuration</h2>
            {(machineDetail == null) ? <div>Loading...</div> :
                <div>
                    <div className={"form-group"}>
                        <label>Timezone:</label>

                        <Typeahead
                            id="vending-machine-timezones"
                            options={timezones}
                            onChange={handleTimezoneChange}
                            placeholder="Choose a timezone"
                            defaultInputValue={machineDetail?.timezone}
                        />
                    </div>
                    <div className={"form-group"}>
                        <label>PIN Code:({PIN_CODE_LENGTH}-digit number)</label>
                        <input type="text" className={"form-control"} value={machineDetail?.pinCode} onChange={(e) => {
                            setMachineValue("pinCode", e.target.value)
                        }}/>
                    </div>
                    <div className={"form-group"}>
                        <label>Currency Name:(USD, CNY, AUD)</label>
                        <input type="text" className={"form-control"} value={machineDetail?.currencyName}
                               onChange={(e) => {
                                   setMachineValue("currencyName", e.target.value)
                               }}/>
                    </div>

                    <div className={"form-group"}>
                        <label>Currency Symbol:($, ￥, A$)</label>
                        <input type="text" className={"form-control"} value={machineDetail?.currencySymbol}
                               onChange={(e) => {
                                   setMachineValue("currencySymbol", e.target.value)
                               }}/>
                    </div>
                    <div className={"form-group"}>
                        <label>Currency Decimal Point:(0, 1, 2)</label>
                        <input type="number" min={0} max={3} className={"form-control"}
                               value={machineDetail?.currencyDecimalPoint}
                               onChange={(e) => {
                                   setMachineValue("currencyDecimalPoint", parseInt(e.target.value))
                               }}/>
                    </div>

                    <div className={"form-group"}>
                        <label>Disabled:</label>
                        <select className={"form-control"}
                                onChange={handleDisableMachineChange}
                                value={machineDetail?.disabled ? "1" : "0"}>
                            <option value="1">Yes</option>
                            <option value="0">No</option>
                        </select>
                    </div>
                    <div className={"form-group"}>
                        <label>Default Homepage:</label>
                        <select
                            className={"form-control"}
                            onChange={handleIndexPageChange}
                            value={machineDetail.defaultBuyPage}
                        >
                            <option value={TelemetryvendingMachinev1DefaultBuyPage.Products}>Product Selection Page
                            </option>
                            <option value={TelemetryvendingMachinev1DefaultBuyPage.SelectionCode}>Code Input Page
                            </option>
                        </select>
                    </div>
                    <div className={"form-group"}>
                        <label>Instant Purchase:</label>
                        <h6>Disable shopping cart</h6>
                        <select
                            className={"form-control"}
                            onChange={handleShoppinCartDisabledChange}
                            value={machineDetail.shoppingCartDisabled ? "1" : "0"}
                        >
                            <option value="1">Yes
                            </option>
                            <option value="0">No
                            </option>
                        </select>
                    </div>
                    <div className={"form-group"}>
                        <label>Enable Two-Digit/Three-Digit/Four-Digit Code Selection:</label>
                        <select
                            className={"form-control"}
                            onChange={handleCodeSelectionDigitChange}
                            value={machineDetail.selectionCodeLength === TelemetryvendingMachinev1SelectionCodeLength.Unspecified ? TelemetryvendingMachinev1SelectionCodeLength.Four : machineDetail.selectionCodeLength}
                        >
                            <option value={TelemetryvendingMachinev1SelectionCodeLength.Four}>Four-Digit
                            </option>
                            <option value={TelemetryvendingMachinev1SelectionCodeLength.Three}>Three-Digit
                            </option>
                            <option value={TelemetryvendingMachinev1SelectionCodeLength.Two}>Two-Digit
                            </option>
                        </select>
                    </div>
                    <div className={"form-group"}>
                        <label>Enable One-Based Or Zero-Based Numbering:</label>
                        <select
                            className={"form-control"}
                            onChange={handleOneOrZeroBasedNumberingChange}
                            value={machineDetail.oneBasedNumberingEnabled ? "1" : "0"}
                        >
                            <option value="1">One-Based Numbering
                            </option>
                            <option value="0">Zero-Based Numbering
                            </option>
                        </select>
                    </div>
                    <div className={"form-group"}>
                        <div>
                            <label>Global Low Stock Alert Percentage:(0~99)%</label>
                        </div>
                        <div className="row" style={{display: "flex", alignItems: "center"}}>
                            <div className="col-sm-1">
                                <input type="number" className="form-control"
                                       value={machineDetail?.globalLowStockAlertPercentage}
                                       onChange={handleInputChange}/>
                            </div>
                            <div className="input-group-append">
                                %
                            </div>
                        </div>
                    </div>
                    <div className={"form-group"}>
                        <button className={"btn btn-success"} onClick={save}>Save</button>
                    </div>
                </div>
            }
        </div>
    );
}
