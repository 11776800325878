import React, {Component} from 'react';
import {Product, ProductStats} from "./models";
import {getAPI} from "./api";
import {FormatMoney} from "./format_money";


interface Props {
    product: Product
}

interface State {
    loading: boolean
    stats: ProductStats | null
}

export class ProductStatsComponent extends Component<Props, State> {
    constructor(props) {
        super(props);

        this.state = {
            loading: true,
            stats: null
        };

        this.init();
    }

    init() {
        // load product stats information
        getAPI(`/api/products/${this.props.product.id}/stats`).then((response) => {
            if (response.data.success) {
                this.setState({
                    stats: response.data.stats,
                    loading: false,
                })
            } else {
                window.alert(response.data.message)
            }
        })
    }

    render() {
        if (this.state.loading || this.state.stats == null) {
            return <span>Loading...</span>
        }

        console.log(this.state.stats.pricing);
        const pricings: Array<any> = [];

        Object.keys(this.state.stats.pricing).forEach((k) => {
            let price = parseInt(k);
            let c = this.state.stats ? this.state.stats.pricing[price] : null;
            pricings.push(<span className={"price-stats"} key={price}>
                        <FormatMoney cents={price}/>
                        x{c}
                    </span>);
        });

        return (
            <div>
                On {this.state.stats.vendingMachines} vending machines, {this.state.stats.coils} slots.<br/>
                Demand: daily {this.state.stats.demand.daily}, weekly {this.state.stats.demand.weekly},
                monthly {this.state.stats.demand.monthly}<br/>
                Pricing: {pricings}
            </div>
        );
    }
}


