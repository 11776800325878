import React, {Component} from 'react';


interface Props {
    title: string
    body: string
    pictureFile: File | null
}


class CampaignMessagePreview extends Component<Props> {
    readImg(file){

        var reader = new FileReader();
        reader.onload = function(){
            var dataURL = reader.result;
            var output = document.getElementById('output');
            // @ts-ignore
            output.src = dataURL;
        };
        reader.readAsDataURL(file);
    }

    componentDidUpdate() {
        if (this.props.pictureFile !== null){
            this.readImg(this.props.pictureFile)
        }
    }

    render() {
        return (
            <div>
                <label>Preview</label>
                <div  className={"row"} id={"iOSBackground"}>
                    <div className={"col-xs-12 messageBox"}>
                        <div className={"row"}>
                            <div className={"col-xs-9"}>
                                <p className={"messageTitle"}>{this.props.title}</p>
                                <p className={"messageBody"}>{this.props.body}</p>
                            </div>
                            <div className={"col-xs-3"}>
                                <img className={"messageImg"} id='output' src="" alt=""/>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        )
    }
}

export default CampaignMessagePreview