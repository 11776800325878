import React, {Component} from 'react';
import {IGlobalDataContext, withGlobalData} from "./contexts/global-data";
import {Link, Route, Switch} from "react-router-dom";
import {getAPI, postAPI} from "./api";
import {BarcodeSorting, BarcodeSpan, Product} from "./models";
import InlineProductPhoto from "./inline_product_photo";
import {AddBarcodePanel} from './add_barcode_panel';
import {Helmet} from "react-helmet";


interface State {
    barcodes: Array<BarcodeSpan>
}

interface Props extends IGlobalDataContext {
    match:any
}


class _Barcodes extends Component<Props, State> {
    constructor(props) {
        super(props);

        this.state = {
            barcodes: []
        };
    }


    componentDidMount() {
        this.reloadBarcodes();
    }

    //get all Barcodes from the Database, for each barcodes add four proporties "productspanflag", "productspannum", "quantityspanflag", "quantityspannum",
    //which use to determine whether the "rowSpan" should be applied and how many row should it merged
    getdata = (response, callback:Function)=>{
        if (response.data.success) {
            const tempList:Array<BarcodeSpan> = []
            response.data.barcodeInfoAll.barcodeInfoList.forEach((element) => {
                const barcodeId:number = element.barcode.id;
                const content:string = element.barcode.content;
                const quantity: number = element.barcode.quantity;
                const product: Product = element.barcode.product
                tempList.push({id:barcodeId, content:content, quantity:quantity, product:product, productspanflag:true, productspannum:1, quantityspanflag:true, quantityspannum:1})
            });
            callback(tempList)
        }
    }

    reloadBarcodes(){
        getAPI('/api/barcodes').then(response =>{
            if (response.data.success){
                this.getdata(response, tempList=>{
                    // sort the barcodes first by the product any quantity
                    tempList.sort(BarcodeSorting);
                    // determine whether the barcode should have the rowSpan property on the Product column
                    for(let i=0; i<tempList.length;i++){
                        if (i>0){
                            if(tempList[i].product.id === tempList[i-1].product.id){
                                tempList[i].productspanflag = false
                            }else{
                                tempList[i].productspanflag = true
                            }
                        }else{
                            tempList[i].productspanflag = true
                        }
                    }
                    //determine how many row it will merged for the row that has the rowSpan property on the product column
                    let count = 1
                    for (let i=tempList.length-1; i>=0; i--){
                        if (tempList[i].productspanflag === false){
                            count = count + 1
                        }else{
                            tempList[i].productspannum = count
                            count = 1
                        }
                    }
                    // determine whether the barcode should have the rowSpan property on the quantity column
                    let sameProductFlag = true;
                    for (let i=0; i< tempList.length;i++){
                        if (i>0){
                            if (tempList[i].productspanflag === true){
                                sameProductFlag = false
                            }
                            if(tempList[i].quantity === tempList[i-1].quantity && sameProductFlag === true ){
                                tempList[i].quantityspanflag = false
                            }else{
                                tempList[i].quantityspanflag = true
                                sameProductFlag = true
                            }
                        }else{
                            tempList[i].quantityspanflag = true
                        }
                    }
                    //determine how many row it will merged for the row that has the rowSpan property on the quantity column
                    count = 1
                    for (let i=tempList.length-1; i>= 0; i--){
                        if(tempList[i].quantityspanflag === false){
                            count = count + 1
                        }else{
                            tempList[i].quantityspannum = count
                            count = 1
                        }
                    }
                    this.setState({barcodes:tempList})
                })
            }else{
                alert(response.data.message)
            }
        })
    }

    handleAdd(barcode:string, quantity:number, productId:number){
        postAPI("/api/barcodes", {
            content:barcode,
            quantity:quantity,
            product_id:productId
        }).then((result) => {
            if (result.data.success) {
                this.reloadBarcodes();
            } else {
                alert(result.data.message);
            }
        });
    }

    handleDelete(barcodeId:number){
        postAPI("/api/barcodes/"+barcodeId, null, 'DELETE').then((result)=>{
            if (result.data.success) {
                this.reloadBarcodes();
            } else {
                alert(result.data.message);
            }
        })
    }



    render() {
        let barcodes = this.state.barcodes;
        console.log('test')
        console.log(barcodes)

        return(
            <section className="content">
                <Helmet titleTemplate="%s - Vending on Track">
                    <title>Barcode Settings</title>
                </Helmet>
                <h1>Barcode Settings</h1>

                <div className="help-block">
                    Barcode Settings page lists all the Barcodes.
                </div>
                <div className={"well"}>
                    <Switch>
                        <Route exact path={"/barcodes/add"}>
                            <AddBarcodePanel add={this.handleAdd.bind(this)} allProducts={this.props.allProducts}/>
                        </Route>
                        <Route>
                            <div>
                                <Link className={"btn btn-success"} to={"/barcodes/add"}>Add Barcode</Link>
                            </div>
                        </Route>
                    </Switch>
                </div>
                <div className="box box-success">
                    <div className="box-body no-padding">
                        <div>
                            <table className = "table table-bordered" id="listBarcodes">
                                <thead>
                                    <tr>
                                        <th scope="col">Product</th>
                                        <th scope="col">Quantity</th>
                                        <th scope="col">Barcode</th>
                                        <th scope="col">Operation</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {barcodes.map((barcode, index)=>{
                                        // both product and quantity have the rowSpan property
                                        if (barcode.productspanflag && barcode.quantityspanflag){
                                            return (
                                                <tr key = {index} onMouseOver={()=>{}}>
                                                    <td rowSpan={barcode.productspannum}>
                                                        <div>
                                                            <InlineProductPhoto product={barcode.product}/>
                                                            {barcode.product.name}
                                                        </div>
                                                    </td>
                                                    <td rowSpan={barcode.quantityspannum}>{barcode.quantity}</td>
                                                    <td>{barcode.content}</td>
                                                    <td><button className={"btn btn-danger"} onClick={(event) => {this.handleDelete(barcode.id)}}> Delete </button></td>
                                                </tr>
                                            )
                                        // only the product has the rowSpan property
                                        }else if(barcode.productspanflag && !barcode.quantityspanflag){
                                            return(
                                            <tr key = {index}>
                                                <td rowSpan={barcode.productspannum}>
                                                        <div>
                                                            <InlineProductPhoto product={barcode.product}/>
                                                            {barcode.product.name}
                                                        </div>
                                                </td>
                                                <td>{barcode.content}</td>
                                                <td><button className={"btn btn-danger"} onClick={(event) => {this.handleDelete(barcode.id)}}> Delete </button></td>
                                            </tr>)
                                        //only the quantity has the rowSpan property
                                        }else if(!barcode.productspanflag && barcode.quantityspanflag){
                                            return(
                                            <tr key = {index}>
                                                <td rowSpan={barcode.quantityspannum}>{barcode.quantity}</td>
                                                <td>{barcode.content}</td>
                                                <td><button className={"btn btn-danger"} onClick={(event) => {this.handleDelete(barcode.id)}}> Delete </button></td>
                                            </tr>)
                                        // neither the product and quantity have the rowSpan property
                                        }else{
                                            return(
                                                <tr key = {index}>
                                                    <td>{barcode.content}</td>
                                                    <td><button className={"btn btn-danger"} onClick={(event) => {this.handleDelete(barcode.id)}}> Delete </button></td>
                                                </tr>
                                            )
                                        }
                                    })}
                                </tbody>
                            </table>
                        </div>
                    </div>
                </div>
            </section>

        )
    }
}

export const Barcodes = withGlobalData(_Barcodes);
