import React, {Component} from 'react'
import {withGlobalData} from "./contexts/global-data";
import {getAPI, postAPI} from "./api";
import {ProductMapping, RemoteVending, VendingMachine} from "./models";
import CustomerSelection from "./customer_selection";

interface Props {
    vendingMachine: VendingMachine
    coils: Array<ProductMapping>
}

interface State {
    remoteVendingInProgress: boolean
    approved: boolean
    remoteVending: RemoteVending | null
}

class RemoteVendPanel extends Component<Props, State> {
    constructor(props) {
        super(props);

        this.state = {
            remoteVendingInProgress: false,
            remoteVending: null,
            approved: false
        };

    }

    approveVend() {
        console.log("approve vend");
        if (this.state.remoteVending) {
            postAPI(`/api/vending_machines/${this.props.vendingMachine.uuid}/remote_vendings/${this.state.remoteVending.txnRef}/approve`, {}).then((resp) => {
                console.log(resp.data);

                if (resp.data.success) {
                    this.setState({approved: true});
                } else {
                    window.alert(resp.data.message);
                }
            });
        }
    }

    updateRemoteVending() {
        if (this.state.remoteVending && this.state.remoteVendingInProgress) {
            getAPI(`/api/vending_machines/${this.props.vendingMachine.uuid}/remote_vendings/${this.state.remoteVending.txnRef}`).then((resp) => {
                console.log(resp.data);
                const data = resp.data;
                if (data.success) {
                    this.setState({remoteVending: resp.data.remoteVending});
                    if (resp.data.remoteVending.vended) {
                        window.alert("Vended successfully.");
                        this.setState({
                            remoteVendingInProgress: false,
                            remoteVending: null,
                            approved: false
                        })
                    } else if (resp.data.remoteVending.failure) {
                        window.alert("Fail to vend, please contact support.");
                        this.setState({
                            remoteVendingInProgress: false,
                            remoteVending: null,
                            approved: false
                        })
                    } else {
                        window.setTimeout(this.updateRemoteVending.bind(this), 1000);
                    }
                } else {
                    window.alert(data.message);
                    this.setState({remoteVendingInProgress: false});
                }
            });
        }
    }

    startRemoteVending(e) {
        e.stopPropagation();
        e.preventDefault();

        postAPI(`/api/vending_machines/${this.props.vendingMachine.uuid}/remote_vendings`, {}).then((resp) => {
            console.log(resp);
            if (resp.data.success) {
                const remoteVending = resp.data.remoteVending;
                console.log(remoteVending);
                console.log('started');
                this.setState({
                    remoteVendingInProgress: true,
                    remoteVending: remoteVending
                });

                window.setTimeout(() => {
                    this.updateRemoteVending();
                }, 1000);
            } else {
                window.alert(resp.data.message);
            }
        });

        return false;
    }

    cancelRemoteVending(e) {
        e.stopPropagation();
        e.preventDefault();


        if (this.state.remoteVendingInProgress) {
            if (this.state.remoteVending) {
                postAPI(`/api/vending_machines/${this.props.vendingMachine.uuid}/remote_vendings/${this.state.remoteVending.txnRef}`, null, "DELETE").then((resp) => {
                    console.log(resp);
                    if (resp.data.success) {
                        this.setState({remoteVendingInProgress: false, remoteVending: null, approved: false})
                    } else {
                        window.alert(resp.data.message);
                    }
                })
            }
        }
    }

    render() {
        const step_check_customer = <i className={"fas fa-check text-green"}> </i>;
        const step_click_button = this.state.remoteVendingInProgress ? (
            <i className="fas fa-check text-green"> </i>) : (<i className="fas fa-spinner fa-pulse text-red"> </i>);
        const step_make_selection = this.state.remoteVendingInProgress ? (!this.state.remoteVending?.selection ?
            <i className="fas fa-spinner fa-pulse text-red"> </i> : <i className="fas fa-check text-green"> </i>) :
            <i className="fas fa-ellipsis-h text-muted"> </i>;
        const step_approve = this.state.remoteVending?.selection ? (this.state.approved ?
            <i className="fas fa-check text-green"> </i> : <i className="fas fa-spinner fa-pulse text-red"> </i>) : (
            <i className="fas fa-ellipsis-h text-muted"> </i>);
        const step_confirm = this.state.approved ? <i className={"fas fa-spinner fa-pulse text-red"}> </i> :
            <i className="fas fa-ellipsis-h text-muted"> </i>;

        return (
            <div className={"box statsBox"}>

                <div className={"box-header"}>
                    <h1>Remote Vend</h1>
                    <p>Remote Vend allows operators to give one free vend to customers. </p>
                    <p><b className={"text-danger"}>Note: </b>Customers need to be at the vending machine ready to make
                        selection, otherwise remote vend would not work.</p>

                    <div className={"alert alert-warning"}>
                        <strong>Warning! </strong> Please contact Vending on Track to make sure your vending machine is
                        compatible. Remote Vending is only available to vending machines whose cashless peripheral is
                        always enabled. Most modern vending machines (manufactured after 2006) support it. However, some
                        old vending machine controller boards may not be fully compatible.
                    </div>
                </div>

                <div className={"box-body"}>
                    <div className="box box-success">
                        <div className="box-header with-border">
                            <div className="box-title">
                                <button disabled={this.state.remoteVendingInProgress}
                                        onClick={this.startRemoteVending.bind(this)} className={"btn btn-success"}>Start
                                </button>
                                &nbsp; &nbsp;
                                <button disabled={!this.state.remoteVendingInProgress}
                                        onClick={this.cancelRemoteVending.bind(this)}
                                        className={"btn btn-danger"}>Cancel
                                </button>
                            </div>
                        </div>
                    </div>
                    <hr/>
                    <div className="row">
                        <div className="col-sm-4">
                            <div className="box box-success">
                                <div className="box-header with-border">
                                    <div className="box-title">
                                        Steps
                                    </div>

                                    <ol className="steps">
                                        <li className="step">
                                            {step_check_customer}
                                            Make sure customer is standing in front of
                                            [<b>{this.props.vendingMachine?.name}</b>]
                                        </li>
                                        <li className="step">
                                            {step_click_button}
                                            Lock Vending Machine by clicking "Start" button above
                                        </li>
                                        <li className="step">
                                            {step_make_selection}
                                            Ask customer to make selection
                                        </li>
                                        <li className="step">
                                            {step_approve}
                                            Approve the selection on the right
                                        </li>
                                        <li className="step">
                                            {step_confirm}
                                            Confirm with customer if the vend is successful
                                        </li>
                                    </ol>
                                </div>
                            </div>
                        </div>
                        <div className="col-sm-8">
                            <div className="box box-info">
                                <div className="box-header with-border">
                                    <div className="box-title">
                                        Customer's Selection
                                    </div>

                                    <div className="box-body">
                                        {this.state.remoteVending?.selection ?
                                            <CustomerSelection selection={this.state.remoteVending.selection}
                                                               approved={this.state.approved}
                                                               productMappings={this.props.coils}
                                                               approve={this.approveVend.bind(this)}/> : "Customer has not made selection yet"}
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}

const RemoteVend = withGlobalData(RemoteVendPanel);

export default RemoteVend;
