import React, {Component} from "react";

interface Props {
    online: boolean
}

export class OnlineIndicator extends Component<Props> {
    render(): React.ReactElement<any, string | React.JSXElementConstructor<any>> | string | number | {} | React.ReactNodeArray | React.ReactPortal | boolean | null | undefined {
        if (this.props.online) {
            return (
                <span className={"text text-green"}><i className="fa fa-circle text-green">&nbsp;</i> Online</span>);
        } else {
            return (<span><i className="fa fa-circle text-gray">&nbsp;</i> <span
                className={"text text-warning"}> Offline</span></span>);
        }
    }
}