import React, {Component} from "react";
import Select from 'react-select'
import DatePicker from 'react-datepicker';
import {CSVLink} from 'react-csv';
import {IGlobalDataContext, withGlobalData} from "./contexts/global-data";
import moment from "moment";
import {postAPI} from "./api";
import {RevenueReportTable} from "./revenue_report_table";
import {formatMoneyWithSymbol} from "./helpers";
import {Helmet} from "react-helmet";
import VendingMachineGroupSelector from "./vending_machine_group_selector";
import {handleTreeSelection, TreeItem} from "./utils/tree";

interface Props extends IGlobalDataContext {

}

interface vendingMachineOption {
    value: string
    label: string
}

interface State {
    selectedVendingMachines: Array<vendingMachineOption>
    fromDate: Date
    toDate: Date
    reportLoading: boolean
    report: any
    interval: number
}

class RawDurationRevenueReport extends Component<Props, State> {
    constructor(props) {
        super(props);

        this.state = {
            selectedVendingMachines: this.props.allVendingMachines.allVendingMachines.map((vm)=>{return {value: vm.uuid, label: vm.name}}),
            fromDate: new Date(),
            toDate: new Date(),
            reportLoading: false,
            report: null,
            interval: 1,
        }
    }

    report(e) {
        e.preventDefault();
        e.stopPropagation();

        console.log(moment(this.state.fromDate).format("YYYY-MM-D"));
        console.log(moment(this.state.toDate).format("YYYY-MM-D"));

        const uuids = this.state.selectedVendingMachines.map((vm) => {
            return vm.value;
        });

        postAPI(`/api/eod/report`, {
            fromDate: moment(this.state.fromDate).format("YYYY-MM-D"),
            toDate: moment(this.state.toDate).format("YYYY-MM-D"),
            vendingMachineUuidList: uuids,
            interval:this.state.interval
        }).then((resp) => {
            this.setState({reportLoading: false});
            if (resp.data.success) {
                this.setState({report: resp.data.revenueReportResponse});
                console.log(resp.data.revenueReportResponse);
            } else {
                window.alert(resp.data.message);
            }
        });
        this.setState({reportLoading: true, report: null});

        return false;
    }

    fromDateChange(date) {
        this.setState({fromDate: date})
    }

    toDateChange(date) {
        this.setState({toDate: date})
    }

    selectVendingMachines(options) {
        this.setState({
            selectedVendingMachines: options !== null ? options : []
        });
    }

    selectInterval(event){
        console.log(event.target.value)
        this.setState({interval:event.target.value})
    }

    prepareCsvHeader(){
        let csvHeader = [{ label: 'Date', key: 'date' }]
        this.state.report.vendingMachineSalesInformationList.forEach((item, index)=>{
            csvHeader.push({label: item.vendingMachine.name+" Revenue", key: item.vendingMachine.uuid+'revenue'})
            csvHeader.push({label: item.vendingMachine.name+" Sales", key: item.vendingMachine.uuid+'sales'})
        })
        csvHeader.push({label: 'Total Revenue', key: 'totalRevenue'})
        csvHeader.push({label: 'Total Sales', key: 'totalSales'})
        return csvHeader
    }

    prepareCsvData(){
        let csvData:{}[] = []
        const dates = Object.keys(this.state.report.vendingMachineSalesInformationList[0].dateSalesData);
        const decimalPoints = this.props.me.mostRecentCompany.currencyDecimalPoints;
        const currencySymbol = this.props.me.mostRecentCompany.currencySymbol;
        dates.forEach((date, dateindex)=>{
            csvData.push({date:date})
            let totalRevenue = 0
            let totalSales = 0
            this.state.report.vendingMachineSalesInformationList.forEach((item, index)=>{
                csvData[dateindex][item.vendingMachine.uuid+'revenue'] = formatMoneyWithSymbol(item.dateSalesData[date].totalRevenue, decimalPoints, currencySymbol)
                totalRevenue = totalRevenue + item.dateSalesData[date].totalRevenue
                csvData[dateindex][item.vendingMachine.uuid+'sales'] = item.dateSalesData[date].totalSalesCount
                totalSales = totalSales + item.dateSalesData[date].totalSalesCount
            })
            csvData[dateindex]['totalRevenue'] = formatMoneyWithSymbol(totalRevenue, decimalPoints, currencySymbol)
            csvData[dateindex]['totalSales'] = totalSales
        })
        console.log(csvData)
        return csvData
    }

    _handleTreeSelection(treeNode: TreeItem):Promise<boolean>{
        const selectedVM = handleTreeSelection(treeNode, this.props.findVendingMachine.bind(this));
        this.setState({selectedVendingMachines: selectedVM})
        return Promise.resolve(true);
    }

    render() {
        const vendingMachines = this.props.allVendingMachines.allVendingMachines.map((vm) => {
            return {
                value: vm.uuid,
                label: vm.name
            }
        });
        let dateFormat = moment().locale(this.props.me.localeISO).localeData().longDateFormat("L").replace("DD", "dd").replace("YYYY", "yyyy");
        return (
            <div>
                <Helmet titleTemplate="%s - Vending on Track">
                    <title>Revenue Report</title>
                </Helmet>
                <section className="content-header">
                    <h1>
                        Revenue Report [Duration]
                    </h1>
                </section>
                <section className="content">
                    <div className={"box box-success"}>
                        <div className={"box-body with-border"}>
                            <div className="form-group">
                                    <label className={"inline-label"} >Please choose machines to run report: </label> &nbsp; &nbsp;
                                    <div className={"inline-div"}>
                                        <VendingMachineGroupSelector
                                            onSelectedGroup={this._handleTreeSelection.bind(this)}
                                            button={<button className={"btn btn-default"}>select</button>}
                                            selectGroup={true}
                                        />
                                    </div>
                                <div>
                                    <Select
                                        value={this.state.selectedVendingMachines}
                                        isMulti
                                        onChange={this.selectVendingMachines.bind(this)}
                                        options={vendingMachines}
                                    />
                                </div>
                            </div>
                            <div className="form-group">
                                <label>From: </label>
                                <DatePicker className="form-control"
                                    selected={this.state.fromDate}
                                    onChange={this.fromDateChange.bind(this)} dateFormat={dateFormat}/>
                            </div>
                            <div className="form-group">
                                <label>To: </label>
                                <DatePicker className="form-control"
                                    selected={this.state.toDate}
                                    onChange={this.toDateChange.bind(this)} dateFormat={dateFormat}/>
                            </div>
                            <div className="form-group">
                            <label>Interval: </label>
                                <select onChange={this.selectInterval.bind(this)} value={this.state.interval} className="form-control">
                                    <option value={1}>1 Day</option>
                                    <option value={7}>1 Week</option>
                                    <option value={14}>2 Weeks</option>
                                    <option value={28}>4 Weeks</option>
                                </select>
                            </div>
                            <div className="form-group">
                                {this.state.selectedVendingMachines.length > 0 ? <button onClick={this.report.bind(this)} className="btn btn-success">Generate report
                                </button> : <button disabled className="btn btn-success">Generate report
                                </button>}
                            </div>
                        </div>
                    </div>
                    {this.state.reportLoading &&
                    <div className={"box box-success"}>
                        <div className={"box-header"}>
                            <h1>Loading ...</h1>
                        </div>
                    </div>
                    }

                    {!this.state.reportLoading && this.state.report &&
                    <div className="box box-success">
                        <div className={"box-header"}>
                            <h1>Report</h1>
                        </div>
                        <div className="box-body">
                            <RevenueReportTable
                                                report={this.state.report} locale={this.props.me.localeISO}/>
                        </div>
                        <div>
                            <CSVLink headers = {this.prepareCsvHeader()}
                                     data={this.prepareCsvData()}
                                     filename={"Vending Machine Revenue Report.csv"}>Download As CSV</CSVLink>
                        </div>
                    </div>
                    }
                </section>
            </div>
        );
    }
}

const DurationRevenueReport = withGlobalData(RawDurationRevenueReport);
export default DurationRevenueReport;
