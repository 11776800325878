import React, {useEffect, useState} from "react";
import {useHistory} from "react-router-dom";
import {DailyRevenue, DashboardStats, VendingMachine} from "./models";
import {Bar} from "react-chartjs-2";
import {BirdView} from "./bird_view";
import {FormatMoney} from "./format_money";
import {FormatMoneyWithFlexibleSignSize} from "./format_money_without_style";

interface Props {
    stats: DashboardStats
    vendingMachines: Array<VendingMachine>
    numberOfDays:number
    handleNumberOfDayChange:Function
    loading:boolean
    revenues:DailyRevenue[]
    currencySymbol: string
}

export function DashboardStatsComponent(props: Props) {
    const history = useHistory();
    const [reportDuration, setReportDuration] = useState(props.numberOfDays);
    const [durationDisplay, setDurationDisplay] = useState<string>();

    const [onlineMachineCount,setOnlineMachineCount] = useState<number>(0)

    const ref = React.createRef<any>();

    const processClick = (e, item) => {

        if (item && item.length > 0) {
            const idx = item[0].index;
            const dailyRevenue = props.revenues[idx];
            const date = dailyRevenue.x;
            console.log(`analysing: ${date}`);

            window.setTimeout(function () {
                if (ref.current) {
                    ref.current.destroy();
                }
                history.push(`/eod/${date}`);
            }, 1);
        }
    };

    const handleDurationDisplay = ()=>{
        if (reportDuration === 7){
            setDurationDisplay("7 Days")
        } else if(reportDuration === 28){
            setDurationDisplay("28 Days")
        } else if(reportDuration === 84){
            setDurationDisplay("3 Months")
        } else if(reportDuration === 168){
            setDurationDisplay("6 Months")
        } else if(reportDuration === 365){
            setDurationDisplay("1 Year")
        }
    }
    useEffect(handleDurationDisplay, [reportDuration])

    useEffect(() => {
        let onlineCount = 0;
        props.vendingMachines.forEach((machine) => {
            if(machine.online){
                onlineCount ++
            }
        })
        setOnlineMachineCount(onlineCount)
        // eslint-disable-next-line
    },[])

    const handleDurationChange = (e) => {
        const duration = Number(e.target.value)
        setReportDuration(duration)
        props.handleNumberOfDayChange(duration)
    }

    return (
        <div>
            <div className="row">

                <div className="col-lg-4 col-md-6 col-xs-12">
                    <div className="info-box">
                                <span className="info-box-icon bg-green">
                                    <i className="fa fa-ticket"></i>
                                </span>

                        <div className="info-box-content">
                            <span className="info-box-text">Today</span>
                            <span className="info-box-number">
                                    {props.stats.todaySales} sales (Total <FormatMoneyWithFlexibleSignSize
                                cents={props.stats.todaySalesRevenue}/>)<br/>
                                    +<FormatMoneyWithFlexibleSignSize cents={props.stats.todayRevenue}/>
                                </span>
                        </div>
                    </div>
                </div>
                <div className="col-lg-4 col-md-6 col-xs-12">
                    <div className="info-box">
                        <span className="info-box-icon bg-yellow"><i className="fa fa-dollar"></i></span>

                        <div className="info-box-content">
                            <span className="info-box-text">Week to date</span>
                            <span className="info-box-number">
                                      {props.stats.weekSales} sales (Total <FormatMoneyWithFlexibleSignSize cents={props.stats.weekSalesRevenue}/>)<br/>
                                    +<FormatMoneyWithFlexibleSignSize cents={props.stats.weekRevenue}/>
                                </span>
                        </div>
                    </div>
                </div>

                <div className="clearfix visible-sm-block"></div>


                <div className="col-lg-4 col-md-6 col-xs-12">
                    <div className="info-box">
                                <span className="info-box-icon bg-aqua"><i
                                    className="fa fa-shopping-cart"></i></span>

                        <div className="info-box-content">
                            <span className="info-box-text">Total Collectible Cash</span>
                            <span className="info-box-number">
                                    <FormatMoney cents={props.stats.totalCollectibleCash}/><br/>

                                {props.stats.totalCollectibleNoteCount} Notes, {props.stats.totalCollectibleCoinCount} Coins

                                </span>
                        </div>
                    </div>
                </div>
                <div className="col-lg-4 col-md-6 col-xs-12">
                    <div className="info-box">
                                <span className="info-box-icon bg-red">
                                    <i className="fa fa-wifi"></i></span>

                        <div className="info-box-content">
                            <span className="info-box-text">Vending Machines</span>
                            <span className="info-box-number">
                                    Online: {onlineMachineCount} <br/>
                                    Total: {props.stats.vendingMachineCount}</span>
                        </div>
                    </div>
                </div>
                <div className="col-lg-4 col-md-6 col-xs-12">
                    <div className="info-box">
                                <span className="info-box-icon bg-maroon">
                                    <i className="fa fa-money"></i></span>

                        <div className="info-box-content">
                            <span className="info-box-text">Today VendCoin</span>
                            <span className="info-box-number">
                                    Number of Transactions: {props.stats.vendCoinSales} <br/>
                                    Total Value: <FormatMoney cents={props.stats.vendCoinRevenue}/></span>
                        </div>
                    </div>
                </div>
                <div className="col-lg-4 col-md-6 col-xs-12">
                    <div className="info-box">
                                <span className="info-box-icon bg-lime">
                                    <i className="fa fa-shopping-cart"></i></span>

                        <div className="info-box-content">
                            <span className="info-box-text">VendCoin Balance</span>
                            <span className="info-box-number">
                                VendCoin Balance: <FormatMoney cents={props.stats.vendCoinMerchantBalance}/>
                            </span>
                        </div>
                    </div>
                </div>
            </div>

            <div className={"row"}>
                <div className={`hidden-xs col-md-6`}>
                    <div className="box box-success">
                        <div className="box-header with-border">
                            <h3 className="box-title">{durationDisplay} Revenue</h3>
                            <div className="pull-right">
                                {props.loading && <i className="fa fa-spinner fa-spin" style={{marginRight:'10px'}}/>}
                                <select value={reportDuration} onChange={handleDurationChange}>
                                    <option value={7}>7 Days</option>
                                    <option value={28}>28 Days</option>
                                    <option value={84}>3 Months</option>
                                    <option value={168}>6 Months</option>
                                    <option value={365}>1 Year</option>
                                </select></div>
                        </div>
                        <div className="box-body">
                            <div className="chart">
                                <Bar
                                    ref={ref}
                                    options={{
                                        onElementsClick: (elems) => {
                                        },
                                        getElementsAtEvent: (elems) => {
                                        },
                                        onClick: processClick,
                                        responsive: true,

                                        legend: {
                                            display: true,
                                        },
                                        scales: {
                                            x: {
                                                stacked: true
                                            },
                                            y: {
                                                stacked: true
                                            }
                                        }
                                    }}

                                    data={{
                                        labels:props.revenues.map((data)=>{return data.x}),
                                        datasets:
                                            [
                                                {
                                                    label: `Cash Revenue (${props.currencySymbol})`,
                                                    backgroundColor: "rgba(255,99,132,0.2)",
                                                    borderColor: "rgba(255,99,132,1)",
                                                    borderWidth: '1',
                                                    hoverBackgroundColor: 'rgba(255,99,132,0.4)',
                                                    hoverBorderColor: 'rgba(255,99,132,1)',
                                                    data: props.revenues.map((data) => {
                                                        return data.y_cash
                                                    })
                                                },
                                                {
                                                    label: `Cashless Revenue (${props.currencySymbol})`,
                                                    backgroundColor: 'rgba(155,231,91,0.2)',
                                                    borderColor: 'rgba(255,99,132,1)',
                                                    borderWidth: '1',
                                                    hoverBackgroundColor: 'rgba(155,231,91,0.4)',
                                                    hoverBorderColor: 'rgba(255,99,132,1)',
                                                    data: props.revenues.map((data) => {
                                                        return data.y_cashless
                                                    })
                                                },

                                            ]
                                    }}

                                    height={100}
                                />
                            </div>
                        </div>
                    </div>
                </div>
                <div className={"hidden-xs col-md-6"}>
                    <div className="box box-success">
                        <div className="box-header with-border">
                            <h3 className="box-title">Bird View</h3>
                        </div>
                        <div className="box-body">
                            <div style={{height: '100', width: '100%'}}>
                                <BirdView vendingMachines={props.vendingMachines}/>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

