import React, {Component} from "react";
import DatePicker from 'react-datepicker';
import {IGlobalDataContext, withGlobalData} from "./contexts/global-data";
import moment from "moment";
import {postAPI} from "./api";
import {Helmet} from "react-helmet";
import {DisplayTime} from "./display_time";
import {CSVLink} from 'react-csv';

import {FormatMoney} from "./format_money";
import {Refund} from "./models";
import {formatMoneyWithSymbol} from "./helpers";


interface Props extends IGlobalDataContext {

}

interface State {
    fromDate: Date
    toDate: Date
    reportLoading: boolean

    refunds: Array<Refund>
}

class RefundsReportRaw extends Component<Props, State> {
    constructor(props) {
        super(props);

        this.state = {
            fromDate: new Date(),
            toDate: new Date(),
            reportLoading: false,
            refunds: []
        }
    }


    prepareCsvHeader() {
        const csvHeader = [
            {label: 'Time', key: 'time'},
            {label: 'Time (Readable)', key: 'time-human'},

            {label: 'Original Time', key: 'original-time'},
            {label: 'Original Time (Readable)', key: 'original-time-human'},

            {label: 'Vending Machine', key: 'vm'},

            {label: 'Code', key: 'code'},

            {label: 'Total Refund', key: 'cents'},
            {label: 'Total Refund: ($)', key: 'cents-human'},

            {label: 'Authorization', key: 'authorization'},
        ]

        return csvHeader
    }

    prepareCsvData() {
        const refunds = this.state.refunds.map((refund: Refund) => {
            return {
                time: refund.refundedUnixTimestamp,
                'time-human': moment.unix(refund.refundedUnixTimestamp).toString(),


                "original-time": refund.originalTransactionTimestamp,
                "original-time-human": moment.unix(refund.originalTransactionTimestamp).toString(),

                'vm': refund.vendingMachineName,
                'code': refund.selection,

                'authorization': refund.refundedBy,

                'cents': refund.totalRefundCents,
                'cents-human': formatMoneyWithSymbol(refund.totalRefundCents, 2, "$")
            }
        })

        return refunds
    }

    report(e) {
        e.preventDefault();
        e.stopPropagation();

        const begin = moment(this.state.fromDate).startOf('day');
        const end = moment(this.state.toDate).endOf('day');

        console.log("Begin: " + begin.format());
        console.log("End: " + end.format());

        this.setState({reportLoading: true});

        postAPI(`/api/eod/refund-reports`, {
            startEpoch: begin.unix(),
            endEpoch: end.unix()
        }).then((resp) => {
            this.setState({reportLoading: false});
            if (resp.data.success) {
                this.setState({refunds: resp.data.refundedTransactions});
            } else {
                window.alert(resp.data.message);
            }
        });
        return false;


    }

    fromDateChange(date) {
        this.setState({fromDate: date})
    }

    toDateChange(date) {
        this.setState({toDate: date})
    }


    render() {
        let dateFormat = moment().locale(this.props.me.localeISO).localeData().longDateFormat("L").replace("DD", "dd").replace("YYYY", "yyyy");

        const rows = this.state.refunds.map((refund: Refund) => {
            return <tr key={refund.originalTxnRef}>
                <td><DisplayTime timestamp={refund.refundedUnixTimestamp} expanded={true}/></td>
                <td><DisplayTime timestamp={refund.originalTransactionTimestamp} expanded={true}/></td>
                <td>{refund.vendingMachineName}</td>
                <td>{refund.selection}</td>
                <td><FormatMoney cents={refund.totalRefundCents}/></td>
                <td>{refund.refundedBy}</td>

            </tr>
        });

        return (
            <div>
                <Helmet titleTemplate="%s - Vending on Track">
                    <title>Refunded Transactions Report</title>
                </Helmet>
                <section className="content-header">
                    <h1>
                        Refunded Purchases
                    </h1>
                </section>
                <section className="content">
                    <div className={"box box-success"}>
                        <div className={"box-body with-border"}>
                            <div className="form-group">
                                <label>From: </label>
                                <DatePicker className="form-control"
                                            selected={this.state.fromDate}
                                            onChange={this.fromDateChange.bind(this)} dateFormat={dateFormat}/>
                            </div>
                            <div className="form-group">
                                <label>To: </label>
                                <DatePicker className="form-control"
                                            selected={this.state.toDate}
                                            onChange={this.toDateChange.bind(this)} dateFormat={dateFormat}/>
                            </div>
                            <div className="form-group">
                                <button onClick={this.report.bind(this)} className="btn btn-success">Generate report
                                </button>
                            </div>
                        </div>
                    </div>
                    {this.state.reportLoading &&
                    <div className={"box box-success"}>
                        <div className={"box-header"}>
                            <h1>Loading ...</h1>
                        </div>
                    </div>
                    }
                    {!this.state.reportLoading && this.state.refunds.length !== 0 &&
                    <div className="box box-success">
                        <div className={"box-header"}>
                            <h1>Report</h1>
                        </div>
                        <div className="box-body">
                            <table className="table table-bordered">
                                <thead>
                                <tr>
                                    <th>Date & Time</th>
                                    <th>Original Transaction Time</th>
                                    <th>Vending Machine</th>
                                    <th>MDB Code</th>
                                    <th>Total Refund</th>
                                    <th>Authorization</th>
                                </tr>
                                </thead>
                                <tbody>
                                {rows}
                                </tbody>
                            </table>

                            <div>
                                <CSVLink
                                    headers={this.prepareCsvHeader()}
                                    data={this.prepareCsvData()}
                                    filename={"Vending Machine Revenue Report.csv"}
                                >
                                    Download as CSV
                                </CSVLink>
                            </div>
                        </div>

                    </div>
                    }
                </section>
            </div>
        )
    }

}

const RefundsReport = withGlobalData(RefundsReportRaw);
export default RefundsReport;
