import React, {Component, Fragment} from 'react';
import {Notification} from "./models";
import {DisplayTime} from "./display_time";
import {IGlobalDataContext, withGlobalData} from "./contexts/global-data";

interface Props extends IGlobalDataContext {
    notification: Notification
    ack(e: any): boolean
}

interface State {

}

class NotificationTabComponentWithoutOnline extends Component<Props, State> {
    render() {
        return (
            <Fragment>
                <li className="time-label">
                    <span className="bg-red">
                       <DisplayTime timestamp={this.props.notification.createdTimestamp} />
                    </span>
                </li>
                <li>
                    <i className="fa fa-envelope bg-blue"></i>
                    <div className="timeline-item">
                        <span className="time">
                            <i className="fa fa-clock-o"></i>
                            &nbsp;
                            <DisplayTime expanded={true} timestamp={this.props.notification.createdTimestamp} />
                        </span>

                        <h3 className="timeline-header">
                                <span> { this.props.notification.message } </span>
                        </h3>
                        <div className="timeline-footer">
                            <button onClick={this.props.ack} className="btn btn-primary btn-xs">Acknowledge and Hide</button>
                        </div>
                    </div>
                </li>
            </Fragment>
        );
    }
}

export const NotificationTabComponent = withGlobalData(NotificationTabComponentWithoutOnline);