import React, {Component, Fragment} from 'react';
import {DeviceUptime} from "./device_uptime";
import {postAPI} from "./api";
import {DisplayTime} from "./display_time";
import {OnlineIndicator} from "./online_indicator";
import {VendingMachineLink} from "./components/vending_machine_link";
import {IGlobalDataContext, withGlobalData} from "./contexts/global-data";
import { Link } from 'react-router-dom';
import { Device,DeviceWorkingMode } from './device/v1/api_pb';

interface Props extends IGlobalDataContext {
    telemetryDevice: Device

    rowUpdate()

    isMobile: boolean
}

interface State {
    proposedRunningMode: DeviceWorkingMode;
    wip: boolean;
}

class TelemetryDeviceTableRowRaw extends Component<Props, State> {

    constructor(props) {
        super(props);
        this.state = {
            proposedRunningMode: props.telemetryDevice.workingMode,
            wip: false
        }
    }

    getModeString() {
        switch(this.state.proposedRunningMode) {
            case DeviceWorkingMode.UNSPECIFIED:
                return 'UNSPECIFIED';
            case DeviceWorkingMode.AUDITING:
                return 'AUDITING';
            case DeviceWorkingMode.CASHLESS_1:
                return 'CASHLESS_1';
            case DeviceWorkingMode.CASHLESS_2:
                return 'CASHLESS_2';
            case DeviceWorkingMode.COEXISTING:
                return 'COEXISTING';
            case DeviceWorkingMode.SILENCE:
                return 'SILENCE';
            default:
                return '-';
        }
    }

    render() {
        const vendingMachine = this.props.telemetryDevice.vendingMachineName ? this.props.findVendingMachine(this.props.telemetryDevice.vendingMachineUuid) : null;
        if (this.props.isMobile) {
            return (
                <tr key={this.props.telemetryDevice.uuid}>
                    <td>{this.props.telemetryDevice.name}<br/>{this.props.telemetryDevice.imei}</td>
                    <td>{vendingMachine ?
                        (<VendingMachineLink vendingMachine={vendingMachine}
                                             setting={true}/>)
                        : '-'}
                    </td>
                    <td>
                        {this.getModeString()}
                    </td>
                </tr>
            );
        }

        return (<tr key={this.props.telemetryDevice.uuid}>
            <td>{this.props.telemetryDevice.name}</td>
            <td>{this.props.telemetryDevice.imei}</td>
            <td>{vendingMachine ?
                (<VendingMachineLink vendingMachine={vendingMachine} setting={true}/>)
                : '-'}
            </td>
            <td>{this.props.telemetryDevice.version}</td>
            <td>
                {this.props.telemetryDevice.online ?
                    <OnlineIndicator online={true}/>
                    :
                    <OnlineIndicator online={false}/>
                }
                <br/>
                Signal Strength: {this.props.telemetryDevice.signalQuality}
                <br/>
                {
                    this.props.telemetryDevice.latpayAccountId && <Fragment>
                    Credit Card Reader: {this.props.telemetryDevice.inTestingMode ? <span style={{color:"red"}}>Testing</span> : <span style={{color:"green"}}>Production</span>}
                    <br/>
                    </Fragment>
                }
                <DisplayTime timestamp={(Number(this.props.telemetryDevice.signalQuality)) / 1000}/>
            </td>
            <td>
                <DeviceUptime device={this.props.telemetryDevice}/>
            </td>
            <td>
                <div>
                    <Link className="btn btn-warning" to={`/devices/${this.props.telemetryDevice.uuid}/detail`} >Edit Device Detail</Link>
                </div>
            </td>
        </tr>);
    }

    handleChange(e) {
        this.setState({proposedRunningMode: e.target.value});
        e.preventDefault();
        return false;
    }

    shouldShowSaveMode() {
        if (this.state.proposedRunningMode !== this.props.telemetryDevice.workingMode) {
            return true;
        }
        return false;
    }

    onSave() {
        this.setState({wip: true});

        postAPI(`/api/telemetry_devices/${this.props.telemetryDevice.uuid}/features`, {
            mode: this.getMode(),
            dexEnabled: true
        })
            .then((response) => {
                this.setState({wip: false});
                this.props.rowUpdate();
            })
    }

    getMode() {
        return this.state.proposedRunningMode;
    }
}

export const TelemetryDeviceTableRow = withGlobalData(TelemetryDeviceTableRowRaw);
