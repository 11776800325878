import React, {Fragment, useEffect, useState} from 'react';
import {Highlighter, Typeahead} from 'react-bootstrap-typeahead';
import {Message, Product, PurchaseQuantity, Stock} from "./models";
import {getAPI, postAPI} from "./api";
import {IGlobalDataContext, withGlobalData} from "./contexts/global-data";
import moment from "moment";
import {DisplayTime} from "./display_time";
import InlineProductPhoto from "./inline_product_photo";
import {Link} from "react-router-dom";
import {BarcodeScanner} from "./barcodeScanner"
import ExpiryDateDisplay from "./expiry_date_display";
import DesiredStockLevelDisplay from "./desired_stock_level_display";
import PurchaseOrder from "./purchase_order";
import {Helmet} from "react-helmet";



interface Props extends IGlobalDataContext {
    match: any
}

interface RowRaw {
    r:{
        product:Product,
        productId: string
        id: number
        quantity: number
        lastQuantityChangeDate: Date
        totalShortage: number
        expiryDate: Date | null
        desiredStockLevel: number | null
        purchaseQuantities: PurchaseQuantity[] | null
    }
    warning:boolean
}

const renderMenuItemChildren = (option: Product, props, index) => {
    return [
        <Highlighter key={"name"} search={props.text}>{option.name}</Highlighter>,
        <div key="productInfo">
            <InlineProductPhoto product={option}/> {option.name}
        </div>,
    ];
};


function _warehouseDashboard(props: Props) {
    
    const {findProduct, allProducts} = props;
    const [stocks, setStocks] = useState<Array<Stock>>([]);
    const [loading, setLoading] = useState<boolean>(false);

    const [selectedProducts, selectProducts] = useState<Array<Product>>([]);
    const [quantity, _setQuantity] = useState<number>(0);
    const setQuantity = (v) => {
        _setQuantity(parseInt(v));
    };


    const warehouseId = props.match.params.warehouseId;

    const reloadStock = () => {
        setLoading(true);
        getAPI(`/api/warehouses/${warehouseId}`).then((response) => {
            setLoading(false);
            if (response.data.success) {
                console.log("stockdata")
                console.log(response.data)
                setStocks(response.data.warehouseDetail.stocks);
            }
        });
    };
    useEffect(reloadStock, [warehouseId]);


    // rowRaws contains all the stock data that corresponding to the non-hidden product
    // it makes sure that all the elements in the rowRaws list are not null and have a corresponding product
    const rowRaws:RowRaw[] = stocks.map((stock)=>{
        const r = {
            product: findProduct(stock.productId),
            ...stock
        };
        let warning = (r.quantity <= r.totalShortage);
        if (r.quantity <= 0) {
            warning = true;
        }
        return{r:r, warning:warning}
    }).filter(element => element.r.product != null)as RowRaw[]


    const rows = rowRaws.map((rowRaw, index)=>{
            return (<tr className={rowRaw.warning ? 'bg-danger' : ''} key={rowRaw.r.id}>
                <td>
                    <Link to={`/warehouses/${warehouseId}/stocks/${rowRaw.r.id}`}>
                        <InlineProductPhoto product={rowRaw.r.product}/> {rowRaw.r.product.name}
                    </Link>
                </td>
                <td>
                    {rowRaw.r.quantity}
                </td>
                <td>
                    {rowRaw.r.totalShortage}
                </td>
                <td><DisplayTime timestamp={moment(rowRaw.r.lastQuantityChangeDate).unix()} expanded={true}/></td>
                <td>
                    <ExpiryDateDisplay expiryDate = {rowRaw.r.expiryDate}
                                       stockId={rowRaw.r.id}
                                       reloadStock={reloadStock}
                                       warehouseId={warehouseId}
                                       pushFlashMessage={props.pushFlashMessage}/>
                </td>
                <td>
                    <DesiredStockLevelDisplay desiredStockLevel={rowRaw.r.desiredStockLevel}
                                              stockId={rowRaw.r.id}
                                              reloadStock={reloadStock}
                                              warehouseId={warehouseId}
                                              pushFlashMessage={props.pushFlashMessage}/>
                </td>
            </tr>)
    })

    const [operation, _action] = useState<string|null>(null);

    const action = (newOp) => {
        console.log("newop: " , newOp, " old: " , operation);

        if (newOp === operation) {
            _action(null);
        } else {
            _action(newOp);
        }
    };

    const checkinOpen = operation === 'checkin';
    const checkoutOpen = operation === 'checkout';
    const reentryOpen = operation === 'reentry';
    const shrinkageOpen = operation === 'shrinkage';
    const barcodeOpen = operation === 'barcode';
    const barcodeReEntryOpen = operation === 'barcodereentry'
    const purchaseOrderOpen = operation === 'purchaseorder'


    const submitCheckin = (e) => {
        e.preventDefault();
        e.stopPropagation();
        console.log('submit');

        const url = `/api/warehouses/${warehouseId}/checkins`;

        if (selectedProducts.length === 0) {
            return;
        }

        postAPI(url, {productId: selectedProducts[0].id,quantity: quantity, type: "PURCHASE"}).then((response) => {
            if (response.data.success) {
                props.pushFlashMessage(new Message("Saved", "success"));
                reloadStock();
                action(null);
            } else {
                window.alert(response.data.message);
            }
        })
    };

    const submitCheckout = (e) => {
        e.preventDefault();
        e.stopPropagation();

        const url = `/api/warehouses/${warehouseId}/checkouts`;

        if (selectedProducts.length === 0) {
            return;
        }

        postAPI(url, {productId: selectedProducts[0].id, quantity: quantity}).then((response) => {
            if (response.data.success) {
                props.pushFlashMessage(new Message("Saved", "success"));
                reloadStock();
                action(null);
            } else {
                window.alert(response.data.message);
            }
        })
    };


    const submitReEntry = (e) => {
        e.preventDefault();
        e.stopPropagation();

        const url = `/api/warehouses/${warehouseId}/checkins`;

        if (selectedProducts.length === 0) {
            return;
        }

        postAPI(url, {productId: selectedProducts[0].id, quantity: quantity, type: "REENTRY"}).then((response) => {
            if (response.data.success) {
                props.pushFlashMessage(new Message("Saved", "success"));
                reloadStock();
                action(null);
            } else {
                window.alert(response.data.message);
            }
        })
    };


    const submitShrinkage = (e) => {
        e.preventDefault();
        e.stopPropagation();

        const url = `/api/warehouses/${warehouseId}/shrinkages`;

        if (selectedProducts.length === 0) {
            return;
        }

        postAPI(url, {productId: selectedProducts[0].id, quantity: quantity}).then((response) => {
            if (response.data.success) {
                props.pushFlashMessage(new Message("Saved", "success"));
                reloadStock();
                action(null);
            } else {
                window.alert(response.data.message);
            }
        })
    };




    return (
        <Fragment>
            <Helmet titleTemplate="%s - Vending on Track">
                <title>Inventory & Warehouse</title>
            </Helmet>
            <section className="content-header">
                <h1>
                    Inventory & Warehouse
                </h1>
            </section>

            <section className="content">
                <div className="box box-info">
                    <div className="box-header">
                        <h2>Operations</h2>
                    </div>
                    <div className="box-body">
                        <button onClick={() => action('checkin')} className={"btn btn-success"}>Check-in
                            (purchase)
                        </button>
                        &nbsp;
                        <button onClick={() => action('checkout')} className={"btn btn-success"}>Check-out</button>
                        &nbsp;
                        <button onClick={() => action('reentry')} className={"btn btn-success"}>Check-in (re-entry)
                        </button>
                        &nbsp;
                        <button onClick={() => action('shrinkage')} className={"btn btn-success"}>Shrinkage</button>
                        <br />
                        <br />
                        <button onClick={() => action('barcode')} className={"btn btn-success"}>Check-in (barcode)</button>
                        &nbsp;
                        <button onClick={() => action('barcodereentry')} className={"btn btn-success"}>Check-in (barcode re-entry)</button>
                        <hr/>
                        <button onClick={() => action('purchaseorder')} className={"btn btn-success"}>Generate Purchase Order</button>
                        <hr/>

                        {checkinOpen &&
                        <form onSubmit={submitCheckin}>
                            <h2>Checkin</h2>

                            <div className="form-group">
                                <label>Product</label>


                                <Typeahead id={"typeahead"}
                                           renderMenuItemChildren={renderMenuItemChildren}
                                           selected={selectedProducts}
                                           labelKey="name"
                                           onChange={selectProducts}
                                           options={allProducts}/>
                            </div>

                            <div className="form-group">
                                <label>Quantity</label>
                                <input className="form-control" type="text" value={quantity} onChange={(e) => {
                                    setQuantity(e.target.value)
                                }}/>
                            </div>

                            <div className="form-group">
                                <input type="submit" className="btn btn-success" value="Submit"/>
                            </div>
                        </form>
                        }
                        {checkoutOpen &&
                        <form onSubmit={submitCheckout}>
                            <h2>Check-out</h2>

                            <div className="form-group">
                                <label>Product</label>


                                <Typeahead id={"typeahead"}
                                           renderMenuItemChildren={renderMenuItemChildren}
                                           selected={selectedProducts}
                                           labelKey="name"
                                           onChange={selectProducts}
                                           options={allProducts}/>
                            </div>

                            <div className="form-group">
                                <label>Quantity</label>
                                <input className="form-control" type="text" value={quantity} onChange={(e) => {
                                    setQuantity(e.target.value)
                                }}/>
                            </div>

                            <div className="form-group">
                                <input type="submit" className="btn btn-success" value="Submit"/>
                            </div>
                        </form>
                        }
                        {reentryOpen &&
                        <form onSubmit={submitReEntry}>
                            <h2>Re-Entry</h2>

                            <div className="form-group">
                                <label>Product</label>


                                <Typeahead id={"typeahead"}
                                           renderMenuItemChildren={renderMenuItemChildren}
                                           selected={selectedProducts}
                                           labelKey="name"
                                           onChange={selectProducts}
                                           options={allProducts}/>
                            </div>

                            <div className="form-group">
                                <label>Quantity</label>
                                <input className="form-control" type="text" value={quantity} onChange={(e) => {
                                    setQuantity(e.target.value)
                                }}/>
                            </div>

                            <div className="form-group">
                                <input type="submit" className="btn btn-success" value="Submit"/>
                            </div>
                        </form>
                        }
                        {shrinkageOpen &&
                        <form onSubmit={submitShrinkage}>
                            <h2>Shrinkage</h2>

                            <div className="form-group">
                                <label>Product</label>


                                <Typeahead id={"typeahead"}
                                           renderMenuItemChildren={renderMenuItemChildren}
                                           selected={selectedProducts}
                                           labelKey="name"
                                           onChange={selectProducts}
                                           options={allProducts}/>
                            </div>

                            <div className="form-group">
                                <label>Quantity</label>
                                <input className="form-control" type="text" value={quantity} onChange={(e) => {
                                    setQuantity(e.target.value)
                                }}/>
                            </div>

                            <div className="form-group">
                                <input type="submit" className="btn btn-success" value="Submit"/>
                            </div>
                        </form>
                        }
                        {barcodeOpen && 
                            <BarcodeScanner whetherReEntry = {false}
                            warehouseId = {warehouseId}
                            reloadStock = {reloadStock}
                            action = {action}
                            findProduct = {findProduct}
                            allProducts = {allProducts}/>
                        }{barcodeReEntryOpen && 
                            <BarcodeScanner whetherReEntry = {true}
                                            warehouseId = {warehouseId}
                                            reloadStock = {reloadStock}
                                            action = {action}
                                            findProduct = {findProduct}
                                            allProducts = {allProducts}/>
                        }{purchaseOrderOpen &&
                            <PurchaseOrder Stocks={stocks} findProduct={findProduct} />
                        }
                    </div>
                </div>
                {
                    loading?
                        (<h1>Loading...</h1>):
                    <>
                        <div className="box box-success">
                            <div className="box-header">
                                <h1>Warehouse Inventory</h1>
                            </div>
                            <div className="box-body">
                                <table className="table table-bordered">
                                    <thead>
                                    <tr>
                                        <td>Product</td>
                                        <td>QoH (Quantity on Hand)</td>
                                        <td>Total Shortage Across All Vending Machines</td>
                                        <td>Last Quantity Change Time</td>
                                        <td>Expiry Date</td>
                                        <td>Desired Stock Level</td>
                                    </tr>
                                    </thead>
                                    <tbody>
                                    {rows}
                                    </tbody>
                                </table>
                            </div>
                        </div>
                    </>
                }
            </section>
        </Fragment>
    )
}

export const WarehouseDashboard = withGlobalData(_warehouseDashboard);
