import React, {Component} from 'react';
import {Link} from "react-router-dom";
import {Highlighter, Typeahead} from 'react-bootstrap-typeahead';
import {Product} from "./models";
import InlineProductPhoto from "./inline_product_photo";


interface Props {
    add: Function
    allProducts: Array<Product>;
}

interface State {
    barcode: string
    quantity: number
    selectedProducts: Array<Product>
}

const renderMenuItemChildren = (option: Product, props: any, index: any) => {
    return [
        <Highlighter key={"name"} search={props.text}>{option.name}</Highlighter>,
        <div key="productInfo">
            <InlineProductPhoto product={option}/> {option.name}
        </div>,
    ];
};


class AddBarcodePanel extends Component<Props, State> {
    constructor(props: Props) {
        super(props);

        this.state = {
            barcode: '',
            quantity: 0,
            selectedProducts: []
        };
    }

    selectProducts(productsArray: Array<Product>) {
        this.setState({selectedProducts: productsArray})
    }

    render() {
        return (
            <div>
                <form onSubmit={(e) => {
                    e.preventDefault();
                    if (this.state.selectedProducts.length > 0 && this.state.quantity > 0 && this.state.barcode !== '') {
                        this.props.add(this.state.barcode, this.state.quantity, this.state.selectedProducts[0].id);
                        this.setState({barcode: '', quantity: 0, selectedProducts: []})
                    } else {
                        alert("All Field is Mandatory")
                    }
                }}>
                    <div className="form-group">
                        <label htmlFor="">Product*</label>
                        <Typeahead id={"typeahead"}
                                   renderMenuItemChildren={renderMenuItemChildren}
                                   selected={this.state.selectedProducts}
                                   labelKey="name"
                                   onChange={this.selectProducts.bind(this)}
                                   options={this.props.allProducts}/>
                    </div>
                    <div className="form-group">
                        <label>Quantity*</label>
                        <input className="form-control" type='number' value={this.state.quantity} placeholder={"Quantity"}
                               onChange={(event) => this.setState({quantity: parseInt(event.target.value)})}/>
                    </div>
                    <div className="form-group">
                        <label>Barcode*</label>
                        <input className="form-control" value={this.state.barcode} placeholder={"Barcode"}
                               onChange={(event) => this.setState({barcode: event.target.value})}/>
                    </div>
                    <input type="submit" className="btn btn-success" value="Add barcode"/>
                    &nbsp; &nbsp;
                    <Link className={"btn btn-danger"} to={"/barcodes"}>Cancel</Link>
                </form>
            </div>
        )
    }
}

export {AddBarcodePanel};
