import React, {Component} from "react";
import moment from 'moment';

interface Props {
    timestamp?: number | null// Seconds, not milliseconds
    plainText?: boolean
    expanded?: boolean
}

interface State {

}

export class DisplayTime extends Component<Props, State> {
    constructor(props) {
        super(props);

        this.state = {}
    }

    render() {
        if (this.props.timestamp) {
            const ts = moment.unix(this.props.timestamp);
            return (
                <span style={{cursor: "pointer", textDecoration: "dotted underline"}} title={ts.toString()}>
                {this.props.expanded ? ts.toString() + " - " : null}{ts.fromNow()}
            </span>
            );
        } else {
            return "-";
        }
    }
}
