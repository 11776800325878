import {Route, Switch} from "react-router-dom";
import {Dashboard} from "./dashboard";
import ProductDetail from "./product_detail";
import {Products} from "./products";
import {Categories} from "./categories";
import {Barcodes} from "./barcodes";
import {EasyButtons} from "./easy_buttons";
import {RefillsPage} from "./refills_page";
import Campaign from "./campaign";
import {RecentPickingsPage} from "./recent_pickings_page";
import {PickingsPage} from "./pickings_page";
import NewSchedulingPage from "./new_scheduling_page";
import {FeatureDisabledPage} from "./feature_disabled_page";
import {PickingDetail} from "./picking_detail";
import {VendingMachineList} from "./vending_machine_list";
import {VendingMachines} from "./vending_machines";
import TelemetryDevices from "./telemetry_devices";
import {TelemetryDeviceSessions} from "./telemetry_device_sessions";
import {VendingMachineDexReadings} from "./vending_machine_dex_readings";
import {VendingMachineSetting} from "./vending_machine";
import {VendingMachineStats} from "./vending_machine_stats";
import {InventoryRedirect} from "./inventory_redirect";
import {WarehouseDashboard} from "./warehouse_dashboard";
import {StockPage} from "./stock_page";
import DurationRevenueReport from "./duration_revenue_report";
import DurationRevenueReportByProduct from "./duration_revenue_report_by_product";
import {EodReportPage} from "./eod_report_page";
import {UserSettingsPage} from "./user_settings_page";
import {LoginsPage} from "./logins_page";
import {NotificationsPage} from "./notifications_page";
import CompanySettingsPage from "./company_settings_page";
import NotificationSettingsPage from "./alert_settings_page";
import {CompanyUsersPage} from "./company_user_management";
import {PageNotFound} from "./page_not_found";
import CompanyClientsPage from "./company_clients";
import React from "react";
import CompanyClientEditPage from "./company_client_edit_page";
import DurationRevenueReportByCategory from "./duration_revenue_report_by_category";
import CompanyCreditCardSurchargeSettingsPage from "./company_credit_card_surcharge_settings_page";
import CreditCardReport from "./credit_card_report";
import {ProductRecommendationPage} from "./product_recommendation_form_page";
import {AddProductRecommendationFormPanel} from "./add_product_recommendation_form_panel";
import {EditProductRecommendationPage} from "./edit_product_recommendation_page";
import {ViewProductRecommendationPage} from "./view_product_recommendation_page";
import RefundsReport from "./refunds_report";
import ProductMovementsReport from "./product_movements_report";
import BankCardReport from "./bank_card_report";
import {OrganizationManagement} from "./organization_management";
import {ForecastPage} from "./forecast_page";
import SettlementReport from "./settlement_report";
import VendingMachineHierarchyManagement from "./vending_machine_hierarchy_management";
import CompanyCreditCardSurchargeGroupPage from "./company_credit_card_surcharge_group_page";
import {ManageScreenSaversPage} from "./manage_screen_savers";
import {PaymentEvents} from "./vmcp_payment_events";
import {SearchOrder} from "./vmcp_search_order";
import {KioskForceOrders} from "./kiosk_force_orders";
import {ReportSettingsPage} from "./report_settings_page";
import TelemetryDeviceDetail from "./telemetry_device_detail";


export function AdminSwitchRoute() {
    return <Switch>
        <Route exact path="/" component={Dashboard}/>
        <Route exact path="/products/:id/settings" component={ProductDetail}/>
        <Route path={"/products"} component={Products}/>

        <Route path={"/categories"} component={Categories}/>

        <Route path={"/barcodes"} component={Barcodes}/>

        <Route exact path={"/product_recommendation_form"} component={ProductRecommendationPage}/>

        <Route exact path={"/product_recommendation_form/:uuid/edit"} component={EditProductRecommendationPage}/>

        <Route exact path={"/product_recommendation_form/:uuid/view"} component={ViewProductRecommendationPage}/>

        <Route exact path={"/add_product_recommendation_form"} component={AddProductRecommendationFormPanel}/>

        <Route exact path="/buttons" component={EasyButtons}/>
        <Route exact path="/buttons/:uuid" component={EasyButtons}/>

        <Route exact path="/refills" component={RefillsPage}/>

        <Route exact path="/forecast" component={ForecastPage}/>

        <Route exact path="/campaign" component={Campaign}/>

        <Route exact path={"/pickings/recent"} component={RecentPickingsPage}/>
        <Route exact path={"/pickings"} component={PickingsPage}/>

        <Route exact path={"/scheduling"} component={NewSchedulingPage}/>

        <Route exact path={"/routings"} component={FeatureDisabledPage}/>

        <Route exact path="/pickings/:id" component={PickingDetail}/>

        {/* this is for vending machine list */}
        <Route exact path="/vending_machine_list" component={VendingMachineList}/>

        {/* this is vending machine settings */}
        <Route exact path="/vending_machines" component={VendingMachines}/>

        <Route exact path="/devices" component={TelemetryDevices}/>
        <Route exact path="/devices/:uuid/detail" component={TelemetryDeviceDetail}/>
        <Route exact path="/devices/:uuid/sessions"
               component={TelemetryDeviceSessions}/>

        <Route exact path="/vending_machines/:uuid/dex_readings"
               component={VendingMachineDexReadings}/>
        <Route path="/vending_machines/:uuid/setting"
               component={VendingMachineSetting}/>
        <Route path="/vending_machines/:uuid" component={VendingMachineStats}/>

        <Route exact path="/inventories" component={InventoryRedirect}/>
        <Route exact path="/warehouses/:warehouseId"
               component={WarehouseDashboard}/>
        <Route path="/warehouses/:warehouseId/stocks/:stockId"
               component={StockPage}/>

        <Route exact path="/reports/credit_card" component={CreditCardReport}/>
        <Route exact path="/reports/credit_card_purchase" component={BankCardReport}/>
        <Route exact path="/reports/duration" component={DurationRevenueReport}/>
        <Route exact path="/reports/product" component={DurationRevenueReportByProduct}/>
        <Route exact path="/reports/category" component={DurationRevenueReportByCategory}/>
        <Route exact path="/reports/product-movements" component={ProductMovementsReport}/>
        <Route exact path="/reports/settlement" component={SettlementReport}/>
        <Route exact path="/eod" component={EodReportPage}/>
        <Route exact path="/reports/refunds" component={RefundsReport}/>
        <Route exact path="/eod/:date" component={EodReportPage}/>
        <Route exact path="/payment/events" component={PaymentEvents}/>
        <Route exact path="/searchorder" component={SearchOrder}/>
        <Route exact path="/kioskforce-orders" component={KioskForceOrders}/>

        <Route exact path="/report-settings" component={ReportSettingsPage}/>

        <Route exact path="/settings" component={UserSettingsPage}/>

        <Route exact path="/security/logins" component={LoginsPage}/>
        <Route exact path="/notifications" component={NotificationsPage}/>

        <Route exact path="/companies" component={CompanySettingsPage}/>
        <Route exact path="/company_credit_card_surcharge_rules" component={CompanyCreditCardSurchargeSettingsPage}/>
        <Route exact path="/company_credit_card_surcharge_rules/:uuid" component={CompanyCreditCardSurchargeGroupPage}/>
        <Route exact path="/alert_settings" component={NotificationSettingsPage}/>
        <Route exact path="/company_users" component={CompanyUsersPage}/>
        <Route exact path="/company_driver_vending_machine_allocation" component={OrganizationManagement}/>
        <Route exact path="/company_clients" component={CompanyClientsPage}/>
        <Route exact path="/company_clients/:uuid" component={CompanyClientEditPage}/>
        <Route exact path="/vending_machine_hierarchy_management" component={VendingMachineHierarchyManagement}/>
        <Route path="/manage_screensavers/:uuid" component={ManageScreenSaversPage}/>
        <Route path={"/"} component={PageNotFound}/>
    </Switch>;
}
