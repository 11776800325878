import React from 'react';
import Modal from 'react-modal';
import {VendingMachine} from "./models";

interface Props {
    isModalOpen: boolean
    vendingMachines: VendingMachine[]
    onModalClose()
    onSelected(vendingMachine:VendingMachine):Promise<boolean>
}

function TreeVendingMachineDisplay(props:Props) {
    const rows = props.vendingMachines.map((vendingMachine)=>{
        return <tr key={vendingMachine.uuid}>
            <td>{vendingMachine.name}</td>
            <td>{vendingMachine.address}</td>
            <td><button className={"btn btn-default"} onClick={(e)=>{
                e.preventDefault();
                e.stopPropagation();
                props.onSelected(vendingMachine).then(()=>{
                    props.onModalClose();
                });
            }}>Select</button></td>
        </tr>
    })
    return (
        <Modal
            style={{overlay: {zIndex: 1000}}}
            ariaHideApp={false}
            isOpen={props.isModalOpen}
            onRequestClose={props.onModalClose}
        >
            <div className="box-header with-border">
                <h3 className="box-title">Vending Machines</h3> &nbsp;
                <button className={"btn btn-default"} onClick={props.onModalClose}>Close</button>
            </div>
            <div className="box-body">
                {props.vendingMachines.length > 0 && <table className="table table-bordered">
                    <thead>
                    <tr>
                        <th>Vending Machine</th>
                        <th>Address</th>
                        <th>Operation</th>
                    </tr>
                    </thead>
                    <tbody>
                    {rows}
                    </tbody>
                </table>}
                {props.vendingMachines.length === 0 && <p>No Vending Machine</p>}
            </div>

        </Modal>
    );
}

export default TreeVendingMachineDisplay;