import React, {Component} from 'react';
import {IngredientMapping, Product} from "./models";
import IngredientMapRow from "./ingredient_map_row";
import {postAPI} from "./api";
import {AddIngredientMappingDialog} from "./add_ingredient_mapping_dialog";

interface Props {
    vending_machine_uuid: string;
    ingredient_list: Array<Product>;
    ingredient_mappings: Array<IngredientMapping>;
    handleRefresh(): void;
}

interface State {
    show_adding_dialog: boolean
}

class VendingMachineIngredientMappingPanel extends Component<Props, State> {
    constructor(props: Props) {
        super(props);

        this.state = {
            show_adding_dialog: false,
        }
    }

    handleRemove(id) {
        if (window.confirm("Confirm delete?")) {
            postAPI(`/api/ingredient_mappings/${id}/remove`, {vendingMachineUuid:this.props.vending_machine_uuid}).then((response) => {
                if (response.data.success) {
                    this.props.handleRefresh();
                } else {
                    alert(response.data.message);
                }
            })
        }
    }

    updateIngredientMapping(mapping: IngredientMapping): Promise<any> {
        let m = {...mapping};
        return postAPI(`/api/ingredient_mappings/${m.id}/edit`, {
            vendingMachineUuid:this.props.vending_machine_uuid,
            ingredientId:m.ingredientId,
            capacity:m.capacity,
            restockCapacity:m.restockCapacity,
            currentLevel:m.currentLevel
        }).then((response) => {
            if (response.data.success) {
                this.props.handleRefresh();
                return response;
            } else {
                window.alert(response.data.message);
            }
        });
    }

    add_ingredient_mapping(e) {
        e.preventDefault();

        this.setState({
            show_adding_dialog: true,
        });

        return false;
    }

    handleFormSubmit(e, ingredientMapping) {
        e.preventDefault();
        postAPI('/api/ingredient_mappings', {
            vendingMachineUuid: this.props.vending_machine_uuid,
            ingredientId: ingredientMapping.ingredientId,
            capacity: ingredientMapping.capacity,
            restockCapacity: ingredientMapping.restockCapacity
        }).then((result) => {
            if (result.data.success) {
                this.setState({show_adding_dialog: false});
                this.props.handleRefresh();
            } else {
                alert(result.data.message);
            }
        })

    }

    render() {
        const ingredient_mappings = this.props.ingredient_mappings.map((ingredientMapping)=>{
            return <IngredientMapRow key={ingredientMapping.id}
                                     mapping={ingredientMapping}
                                     handleRemove={()=>{this.handleRemove(ingredientMapping.id)}}
                                     updateIngredientMapping={this.updateIngredientMapping.bind(this)}/>
        })

        let adding_dialog: any = null;

        if (this.state.show_adding_dialog) {
            adding_dialog = (<AddIngredientMappingDialog ingredient_list={this.props.ingredient_list}
                                                         onSubmit={this.handleFormSubmit.bind(this)}
                                                         hide_dialog={()=>{this.setState({show_adding_dialog: false})}}/>)
        } else {
            adding_dialog = (
                <button onClick={this.add_ingredient_mapping.bind(this)} className="btn btn-info">Add a Ingredient Mapping</button>);
        }
        return <div className="box">
            <div className="box-body">
                <h3>Ingredient Mapping</h3>

                <div className="box box-success well">
                    <div className={"box-header"}>
                        <h4>Add new Ingredient Mapping</h4>
                    </div>
                    <div className={"box-body"}>
                        {adding_dialog}
                    </div>
                </div>

                <table className="table table-bordered table-hover">
                    <thead>
                        <tr>
                            <th>Ingredient Name</th>
                            <th>Capacity</th>
                            <th>Restock Capacity</th>
                            <th>Operations</th>
                        </tr>
                    </thead>
                    <tbody>
                    {ingredient_mappings}
                    </tbody>
                </table>
            </div>
        </div>
    }
}

export {VendingMachineIngredientMappingPanel};
