import React, {Component} from "react";
import {DailyEODStats, vendingMachineEodSorting} from "./models";
import {getAPI} from "./api";
import {FormatMoney} from "./format_money";
import {DownloadEodButton} from "./download_eod_button";
import moment from "moment";
import 'moment/min/locales';

interface Props {
    date: Date | null
    locale:string
}

interface States {
    loading: boolean
    stats: DailyEODStats | null
}

export class EodReport extends Component<Props, States> {
    constructor(props) {
        super(props);

        this.state = {
            stats: null,
            loading: true
        };
    }

    componentDidMount(): void {
        this.loadData(this.props.date);
    }

    componentDidUpdate(prevProps: Readonly<Props>, prevState: Readonly<States>, snapshot?: any): void {
        if (this.props.date !== prevProps.date) {
            this.loadData(this.props.date);
        }
    }

    loadData(date: Date | null) {
        console.log(date);
        if (date == null) {

        } else {
            this.setState({loading: true});
            const strDate = `${date.getFullYear()}-${date.getMonth() + 1}-${date.getDate()}`;

            getAPI(`/api/eod/${strDate}`).then((response) => {
                this.setState({loading: false});

                if (response.data.success) {
                    this.setState({stats: response.data.stats});
                } else {
                    window.alert(response.data.message);
                }
            });
        }
    }

    render() {
        if (this.props.date == null) {
            return null;
        }

        if (this.state.loading) {
            return (<div>
                <div className="progress  active">
                    <div className="progress-bar progress-bar-success progress-bar-striped" style={{"width": "50%"}}>
                        <i className="fa fa-spinner fa-spin"> </i> &nbsp; loading...
                    </div>
                </div>

            </div>);
        }

        if (this.state.stats == null) {
            return null;
        }

        let vending_machine_rows = this.state.stats.perMachineStats.sort(vendingMachineEodSorting).map((vendingMachineStats) => {
            return (<tr key={vendingMachineStats.vendingMachine.uuid}>
                <td>{vendingMachineStats.vendingMachine.name}</td>
                <td>{vendingMachineStats.totalSalesCount}</td>
                <td><FormatMoney cents={vendingMachineStats.totalRevenue}/></td>
                <td>{vendingMachineStats.cashlessCount}</td>
                <td><FormatMoney cents={vendingMachineStats.cashlessValue}/></td>
            </tr>);
        });
        const date = moment(this.props.date).locale(this.props.locale).format("LL")
        return (
            <div>
                <h1>EOD report for {date}</h1>

                <div className={"box box-success"}>
                    <div className={"box-header"}>
                        Overall Stats for {date}
                    </div>

                    <div className={"box-body with-border"}>
                        <table className={"table table-condensed"}>
                            <tbody>
                            <tr>
                                <th>Total Sales Count</th>
                                <td>{this.state.stats.totalSalesCount}</td>
                            </tr>
                            <tr>
                                <th>Total Revenue</th>
                                <td><FormatMoney cents={this.state.stats.totalRevenue}/></td>
                            </tr>
                            <tr>
                                <th>Total Cash</th>
                                <td><FormatMoney cents={this.state.stats.totalCashes}/></td>
                            </tr>
                            <tr>
                                <th>Current Collectable Cash</th>
                                <td><FormatMoney cents={this.state.stats.currentCollectableCashes}/></td>
                            </tr>
                            </tbody>
                        </table>
                    </div>
                </div>


                <div className={"box box-success"}>
                    <div className={"box-header"}>
                        Financial Stats per Vending Machine
                    </div>
                    <div className={"box-body with-border"}>

                        <table className={"table table-condensed"}>
                            <thead>
                            <tr>
                                <th>Vending Machine</th>
                                <th>Total Sales Count</th>
                                <th>Total Revenue</th>
                                <th>Cashless Sales Count</th>
                                <th>Cashless Sales Value</th>
                            </tr>
                            </thead>
                            <tbody>
                            {vending_machine_rows}
                            </tbody>
                        </table>
                    </div>
                </div>

                <hr />

                <DownloadEodButton stats={this.state.stats} />
            </div>
        );
    }
}
