import React, { useState, useEffect } from 'react';
import { Message } from "./models";
import { IGlobalDataContext, withGlobalData } from "./contexts/global-data";
import { Link } from "react-router-dom";
import { Helmet } from "react-helmet"
import { OnlineIndicator } from './online_indicator';
import { DisplayTime } from './display_time';
import { DeviceUptime } from './device_uptime';
import { Device, DeviceMode, DeviceWorkingMode } from './device/v1/api_pb';
import { useClient } from './connect_api';
import { DeviceManagementService } from './device/v1/api_connect';
import { VendingMachineLink } from './components/vending_machine_link';

interface Props extends IGlobalDataContext {
    match: any;
    pushFlashMessage(message: Message): void;
}

const TelemetryDeviceDetail = (props: Props) => {
    const client = useClient(DeviceManagementService);
    const [workingMode, setWorkingMode] = useState<DeviceWorkingMode>(DeviceWorkingMode.AUDITING);
    const [deviceModel, setDeviceModel] = useState<DeviceMode>(DeviceMode.MDB);
    const [pulseDuration, setPulseDuration] = useState("0");
    const [device, setDevice] = useState<Device | null>(null);
    const [loading, setLoading] = useState(true);
    const { match, pushFlashMessage } = props;

    useEffect(() => {
        client.getDeviceDetail({ uuid: match.params.uuid })
            .then((response) => {
                setDevice(response.device || null);
                setWorkingMode(response.device?.workingMode || DeviceWorkingMode.AUDITING);
                setDeviceModel(response.device?.deviceMode || DeviceMode.MDB);
                setPulseDuration(response.device?.pulseDuration.toString() || "-");
                setLoading(false);
            })
            .catch((error) => {
                pushFlashMessage(new Message("error", error));
                setLoading(true);
            });
    }, [client, match.params.uuid,pushFlashMessage]);


    const handleSubmit = (event) => {
        event.preventDefault();
        event.stopPropagation();
        const pulseDurationBigInt = BigInt(pulseDuration);
    
        client.updateDevice({ uuid: match.params.uuid, workingMode: parseInt(workingMode.toString()), deviceMode: parseInt(deviceModel.toString()) ,pulseDuration:pulseDurationBigInt}).then((response) => {
            pushFlashMessage(new Message("success", "Successfully updated device"));
        }).catch((error) => {
            console.log('update error', error);
            pushFlashMessage(new Message("error123", error));
        });
    };
    useEffect(() => {
        console.log('deviceModel changed:', deviceModel);
    }, [deviceModel]);

    const handleWorkModeChange = (e) => {
        setWorkingMode(e.target.value);
    };

    const handleDeviceModeChange = (e) => {
        setDeviceModel(e.target.value);
    };

    const handleInputChange = (e) => {
        let inputValue = e.target.value;
        inputValue = inputValue.replace(/\D/g, '');
        let intValue = parseInt(inputValue, 10) || 0;
        intValue = Math.max(intValue, 0);
        setPulseDuration(BigInt(intValue).toString());
    };

    const vendingMachine = device?.vendingMachineName ? props.findVendingMachine(device.vendingMachineUuid) : null;
    let selections = Object.keys(DeviceWorkingMode).map((key, index) => {
        if (!isNaN(Number(DeviceWorkingMode[key]))) {
            return (
                <option key={index} value={DeviceWorkingMode[key]}>{key}</option>
            );
        }
        return null;
    });

    let deviceModes = Object.keys(DeviceMode).map((key, index) => {
        if (!isNaN(Number(DeviceMode[key]))) {
            return (
                <option key={index} value={DeviceMode[key]}>{key}</option>
            );
        }
        return null;
    });


    return (
        <section className="content">
            <Helmet titleTemplate="%s - Vending on Track">
                <title>Telemetry Device Detail</title>
            </Helmet>
            <h1>Telemetry Device Detail</h1>
            <Link to={"/devices"}>&lt;Back</Link>

            <div className=' well'>
                {loading ? (
                    <p>Loading...</p>
                ) : (<form onSubmit={handleSubmit}>
                    <div className="form-group">
                        <label>Name: {device?.name}</label>
                    </div>
                    <div className="form-group">
                        <label>IMEI: {device?.imei}</label>
                    </div>
                    <div className="form-group">
                        <label>Deployed on: {vendingMachine ?
                            (<VendingMachineLink vendingMachine={vendingMachine} setting={true} />)
                            : '-'}</label>
                    </div>
                    <div className="form-group">
                        <label>Version: {device?.version}</label>
                    </div>
                    <div className="form-group">
                        <label>State: {device?.online ?
                            <OnlineIndicator online={true} />
                            :
                            <OnlineIndicator online={false} />
                        }
                            <br />
                            Signal Strength: {device?.signalQuality}
                            <br />
                            {
                                device?.latpayAccountId && <>
                                    Credit Card Reader: {device?.inTestingMode ? <span style={{ color: "red" }}>Testing</span> : <span style={{ color: "green" }}>Production</span>}
                                    <br />
                                </>
                            }
                            <DisplayTime timestamp={(Number(device?.signalQuality)) / 1000} /></label>
                    </div>
                    {device && <div className="form-group">
                        <label>Uptime: <DeviceUptime device={device} /></label>
                    </div>}
                    <div className="form-group">
                        <label>Running Mode:</label>
                        <select className={"form-control"} onChange={handleWorkModeChange} value={workingMode}>
                            {selections}
                        </select>
                    </div>
                    <div>
                        <label>Parse Model:</label>
                        <select className={"form-control"} onChange={handleDeviceModeChange} value={deviceModel}>
                            {deviceModes}
                        </select>
                    </div>
                    &nbsp;
                    {
                        deviceModel.toString() === DeviceMode.PULSE.toString() &&
                        <div className="row" style={{ display: "flex", alignItems: "center" }}>
                            <div className="col-sm-2">
                                <input type="number" className="form-control"
                                    value={pulseDuration}
                                    onChange={handleInputChange} />
                            </div>
                            <div className="input-group-append">
                                ms
                            </div>
                        </div>
                    }
                    &nbsp;
                    <div className="form-group">
                        <input type="submit" className="btn btn-success" value={"Save"} />
                    </div>
                </form>)}
            </div>
        </section>
    );
};

export default withGlobalData(TelemetryDeviceDetail);
