function formatUtilPrice(
    cents: number,
    currencyDecimalPoints: number,
): string {
    const divisor = (cents || 0) / Math.pow(10, currencyDecimalPoints);
    const formattedCents = divisor.toFixed(currencyDecimalPoints);

    return formattedCents;
}

export default formatUtilPrice