import React, {useState} from "react";
import {IGlobalDataContext, withGlobalData} from "./contexts/global-data";
import axios from "axios";
import {postAPI} from "./api";


interface Props extends IGlobalDataContext {

}

enum PredictionDuration {
    OneDay = "One Day",
    TwoDays = "Two Days",
}

const mlModels = [
    "REGRESSION",
    "BINARY",
    "SGD"
]

function _forecastPage(props: Props) {
    const [predictionDuration, setPredictionDuration] = useState<PredictionDuration>(PredictionDuration.OneDay);

    const durationOptions = [PredictionDuration.OneDay, PredictionDuration.TwoDays].map((duration) => {
        return <option value={duration}>{duration.toString()}</option>
    })

    const [processing, setProcessing] = useState(false);
    const [errorMessage, setErrorMessage] = useState("");

    const [entries, setEntries] = useState([] as Array<any>)

    function handlePredict(e) {
        e.preventDefault();

        const awsPredictionModelId = "SCOBI7K3RI";
        const model = mlModels[0];

        const machines = props.allVendingMachines.allVendingMachines;

        setProcessing(true);
        setErrorMessage("");

        machines.forEach((machine) => {
            postAPI("/api/predictions", {
                machineUUID: machine.uuid,
                model: model,
                awsPredictionModel: awsPredictionModelId,
                predictionDuration: predictionDuration
            }).then((res)=>{
                if (res.data.success){
                    setEntries(res.data.entries);
                    axios.post("/api/predictions/{res.data.predictionID}/notifications");
                } else {
                    setErrorMessage(res.data.message)
                    window.setTimeout(() => {
                        setErrorMessage("");
                    }, 5000);
                }
            }).catch(()=>{
                setErrorMessage("Server error. Please try again later")
                window.setTimeout(() => {
                    setErrorMessage("");
                }, 5000);
            }).finally(()=>{
                setProcessing(false);
            })
        })
    }

    function handleChangeDuration(e: any) {
        setPredictionDuration(e.target.value);
    }

    const outOfStockRows = entries.map((entry) => {
        return <tr>
            <td>{entry.vendingMachine}</td>
            <td>{entry.score} [{entry.ci}/{entry.p}]</td>
            <td title={"TODO: format it nicely"}>{entry.coils}</td>
        </tr>
    })

    return <section className="content-header">
        <h1>
            AI Advisory
        </h1>

        <br/>
        <div className={"alert alert-info"}>
            Please note: the recommendations or predictions proposed on this page are experimental.
        </div>

        <form className={"form"} onSubmit={handlePredict}>
            <label>Please specify forecast duration: </label>&nbsp;
            <select onChange={handleChangeDuration}>
                {durationOptions}
            </select>
            <br/>
            {processing ?
                <div>
                    <h3>Processing</h3>
                </div>
                :
                <input className={"btn btn-success"} type={"submit"} value={"Predict"}/>
            }

            <hr/>
            {errorMessage &&
            <div className={"alert alert-danger"}>
                {errorMessage}
            </div>
            }
        </form>

        <table className={"table table-condensed"}>
            <thead>
            <tr>
                <th>Vending Machine</th>
                <th>Score (CI / P-Value)</th>
                <th>Coils</th>
            </tr>
            </thead>
            <tbody>
            {outOfStockRows}
            </tbody>
        </table>
    </section>
}

export const ForecastPage = withGlobalData(_forecastPage);
