import React, {Component} from "react";
import {IGlobalDataContext, withGlobalData} from "./contexts/global-data";
import {FormatMoneyWithFlexibleSignSize} from "./format_money_without_style";

interface Props extends IGlobalDataContext{
    cents?: number
    dollar?: number
}

interface State {

}

class _FormatMoney extends Component<Props, State> {
    constructor(props) {
        super(props);

        this.state = {}
    }

    render() {
        return (
            <span className="money"><FormatMoneyWithFlexibleSignSize cents={this.props.cents} dollar={this.props.dollar}/></span>
        );
    }
}

export const FormatMoney = withGlobalData(_FormatMoney);