import React, {useEffect, useState} from 'react';
import {postAPI} from "./api";
import {Message} from "./models";


interface Props {
    desiredStockLevel: number | null
    stockId: number
    warehouseId: number
    reloadStock()
    pushFlashMessage(Message)
}

function DesiredStockLevelDisplay(props:Props) {
    const [desiredStockLevel, setDesiredStockLevel] = useState(props.desiredStockLevel)
    const [stockLevelSnapshot, setStockLevelSnapshot] = useState(props.desiredStockLevel)
    const [isEditing, setEditing] = useState<boolean>(false)
    const [isDeleting, setDeleting] = useState<boolean>(false)

    useEffect(()=>{
        setDesiredStockLevel(props.desiredStockLevel)
    }, [props.desiredStockLevel])

    const toggleEdit = ()=>{
        setEditing(!isEditing)
    }

    const toggleDelete = ()=>{
        setDeleting(!isDeleting)
    }

    const handleDesiredStockLevelChange = (e)=>{
        setDesiredStockLevel(e.target.value)
    }

    const updateDesiredStockLevelChange = (newDesiredStockLevel)=>{
        postAPI(`/api/warehouses/${props.warehouseId}/${props.stockId}/desiredStockLevel`, {desiredStockLevel:newDesiredStockLevel}).then((response)=>{
            if (response.data.success){
                props.pushFlashMessage(new Message("Saved", "success"));
                props.reloadStock();
            } else {
                window.alert(response.data.message);
            }
        })
        setEditing(false)
    }

    const deleteDesiredStockLevel = ()=>{
        setDesiredStockLevel(null)
        updateDesiredStockLevelChange(null);
    }

    const cancelDesiredStockLevel = ()=>{
        setDesiredStockLevel(stockLevelSnapshot);
        toggleEdit();
    }

    const handleEdit =()=>{
        toggleEdit();
        setDeleting(false);
        setStockLevelSnapshot(desiredStockLevel);
        if (!desiredStockLevel){
            setDesiredStockLevel(1)
        }
    }

    return (
        <>
            {isEditing ?
                <div className={"row"}>
                    <div className={"col-xs-12"}>
                        <input type="number" min={1} value={desiredStockLevel? desiredStockLevel:1} onChange={handleDesiredStockLevelChange}/>
                    </div>
                    <div className={"row"}>
                        <div className={"col-xs-12 text-center"}>
                            <button className={"btn btn-success"} onClick={()=>{updateDesiredStockLevelChange(desiredStockLevel)}}>Save</button>
                            &nbsp;
                            <button className={"btn btn-danger"} onClick={cancelDesiredStockLevel}>cancel</button>
                        </div>
                    </div>
                </div>
                :
                <div>
                    <div className={"page-link inline"} onClick={handleEdit}>
                        {!desiredStockLevel && <p>Desired Stock Level not set</p>}
                        {desiredStockLevel && <p className={"inline"}>{desiredStockLevel}</p>}
                    </div>
                    &nbsp;&nbsp;
                    {(desiredStockLevel &&!isDeleting) && <button className={"btn btn-danger inline"} onClick={toggleDelete}>Delete</button>}
                    {isDeleting &&
                    <div className={"inline"}>
                        <button className={"btn btn-success btn-sm"} onClick={deleteDesiredStockLevel}>confirm</button>
                        &nbsp;
                        <button className={"btn btn-danger btn-sm"} onClick={toggleDelete}>cancel</button>
                    </div>}
                </div>
            }
        </>
    )
}

export default DesiredStockLevelDisplay;
