import React, {Component} from "react";
import {VendingMachine} from "./models";
import {postAPI} from "./api";

interface Props {
    vendingMachine: VendingMachine

    updated()
}

interface State {
    saving: boolean
}

export class VendingMachineArchivePanel extends Component<Props, State> {
    constructor(props) {
        super(props);

        this.state = {
            saving: false
        }
    }

    toggleArchive(e) {
        e.preventDefault();

        // we only want to confirm for archive action, not unarchive
        if (!this.props.vendingMachine.archived) {
            if (!window.confirm("Do you want to archive this vending machine?")) {
                return false;
            }
        }

        this.setState({saving: true});

        postAPI(`/api/vending_machines/${this.props.vendingMachine.uuid}/archive`, {
            archived: !this.props.vendingMachine.archived
        }).then((response) => {
            if (response.data.success) {
                this.props.updated();
            } else {
                window.alert(response.data.message);
            }
        }).finally(() => {
            this.setState({saving: false});
        });

        return false;
    }

    render() {
        return (
            <div>
                <div className="box box-info">
                    <div className="box-header">
                        <h2>Archive</h2>
                    </div>

                    <div className="box-body">
                        <div className="alert alert-success">
                            Vending machine [{this.props.vendingMachine.name}] is currently in
                            [{this.props.vendingMachine.archived ? "Archived" : "Normal"}] state.
                        </div>


                        <form onSubmit={this.toggleArchive.bind(this)}>
                            <input disabled={this.state.saving} type="submit" className="btn btn-danger"
                                   value={this.props.vendingMachine.archived ? "Unarchive" : "Archive"}/>
                        </form>
                    </div>
                </div>
            </div>
        );
    }
}