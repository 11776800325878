import React, {Component} from 'react';
import {postAPI} from "./api";
import Select from "react-select";
import {IGlobalDataContext, withGlobalData} from "./contexts/global-data";
import {VendingMachine} from "./models";
import CampaignMessagePreview from "./campaign_message_preview";
import {Helmet} from "react-helmet";


interface Props extends IGlobalDataContext {
}

interface State {
    selectedVendingMachines: Array<VendingMachine>
    title: string
    body: string
    pictureFile: File | null
}

class _campaign extends Component<Props, State> {
    private pictureEncodedContent: string | null = null;

    constructor(props) {
        super(props);

        this.state = {
            selectedVendingMachines: [],
            title: "", body: "", pictureFile: null
        };
    }


    // vms is an array consists of vending machine uuids
    handleSubmit(event) {
        event.preventDefault();
        event.stopPropagation();

        let ps: Array<Promise<any>> = [];

        if (this.pictureEncodedContent) {
            let vms = this.state.selectedVendingMachines.map(machine => {
                return machine.uuid;
            });
            ps.push(postAPI(`/api/push_notification_campaign`, { title: this.state.title, body: this.state.body, imageContent: this.pictureEncodedContent, vms }).then((response) => {
                if (response.data.success) {
                    console.log('campaign created.');
                } else {
                    window.alert(response.data.message);
                }
                return;
            }));
        }


        Promise.all(ps).then(() => {
            console.log('all done');
            window.alert('campaign sent');
        });

        return false;
    }


    selectVendingMachines(options) {
        let vms: Array<VendingMachine> = [];

        options.forEach((selection) => {
            const vm = this.props.findVendingMachine(selection.value);
            if (vm) {
                vms.push(vm);
            }
        });

        this.setState({
            selectedVendingMachines: vms
        });
    }

    onSelectFile(e) {
        console.log(e.target.files);
        if (e.target.files && e.target.files.length > 0) {
            console.log(e.target.files[0]);
            this.setState({ pictureFile: e.target.files[0] })
        } else {
            this.setState({ pictureFile: null })
        }

        const fileReader = new FileReader();
        fileReader.onerror = (e) => {
            window.alert("Failed to read file");
        };
        fileReader.onload = (e) => {
            const pictureContent: string = fileReader.result as string;
            this.pictureEncodedContent = window.btoa(pictureContent);
        };
        console.log(e.target.files[0])
        if (e.target.files[0] !== undefined){
            fileReader.readAsBinaryString(e.target.files[0]);
        }
    }

    render() {
        const vendingMachines = this.props.allVendingMachines.allVendingMachines.map((vm) => {
            return {
                value: vm.uuid,
                label: vm.name
            }
        })

        return (<section className="content">
            <Helmet titleTemplate="%s - Vending on Track">
                <title>Campaign</title>
            </Helmet>
            <h1>Campaign</h1>

            <form onSubmit={this.handleSubmit.bind(this)}>
                <div className="row">
                    <div className="col-sm-12">
                        <div className="form-group">
                            <label>Message Title</label>
                            <input onChange={(e) => this.setState({ title: e.target.value })} type={"text"}
                                value={this.state.title}
                                className="form-control" />
                        </div>
                    </div>
                    <div className="col-sm-12">
                        <div className="form-group">
                            <label>Message Body</label>
                            <textarea onChange={(e) => this.setState({ body: e.target.value })} value={this.state.body}
                                className="form-control" />
                        </div>
                    </div>
                    <div className="col-sm-12">
                        <div className="form-group">
                            <label>Message Image</label>
                            <input type="file" onChange={this.onSelectFile.bind(this)} className={"form-control"} />
                        </div>
                    </div>

                    <div className="col-sm-12">

                        <div className="form-group">
                            <label>Please choose machines to run report</label> &nbsp;
                            <br />
                            <Select
                                defaultValue={vendingMachines}
                                isMulti
                                onChange={this.selectVendingMachines.bind(this)}
                                options={vendingMachines}
                            />
                        </div>
                    </div>
                </div>

                <div className="form-group">
                    <input type="submit" className="btn btn-success" value={"Create"} />
                </div>
            </form>

            <CampaignMessagePreview title={this.state.title}
                                    body={this.state.body}
                                    pictureFile={this.state.pictureFile}/>
        </section>);
    }
}

const Campaign = withGlobalData(_campaign);
export default Campaign;
