import React, {useEffect, useState} from "react";
import {IGlobalDataContext, withGlobalData} from "./contexts/global-data";
import {Helmet} from "react-helmet";
import {VendingMachine, VendingMachineAllocation} from "./models";
import {getAPI, postAPI} from "./api";
import Select from "react-select";
import {Option} from "react-select/src/filters";

interface Props extends IGlobalDataContext {

}

interface EditProps extends IGlobalDataContext {
    selectedVendingMachines: Array<VendingMachine | null>
    onChange: (vendingMachines: Array<VendingMachine>) => void
}

const EditableVendingMachineList = withGlobalData(function _editableVendingMachineList(props: EditProps) {
    const [selected, setSelected] = useState<Array<Option>>([]);

    function onChange(options) {
        let vms: Array<VendingMachine> = [];

        if (options != null) {
            options.forEach((selection) => {
                const vm = props.findVendingMachine(selection.value);
                if (vm) {
                    vms.push(vm);
                }
            });
        }

        props.onChange(vms)
    }

    useEffect(() => {
        const selections: Array<Option> = [];
        console.log("Selected");
        console.log(props.selectedVendingMachines);

        props.selectedVendingMachines.forEach((vm) => {
            if (vm) {
                selections.push({
                    value: vm.uuid,
                    label: vm.name,
                    data: vm
                })
            }
        })

        setSelected(selections)
    }, [props.allVendingMachines, props.selectedVendingMachines]);

    const vendingMachineOptions = props.allVendingMachines.allVendingMachines.map(vendingMachine => {
        return {
            value: vendingMachine.uuid,
            label: vendingMachine.name,
            data: vendingMachine
        }
    })

    return (<Select
        isMulti
        onChange={onChange}
        value={selected}
        options={vendingMachineOptions}
    />);
});

interface AllocationProps extends IGlobalDataContext {
    allocation: VendingMachineAllocation
}

const AllocationRecord = withGlobalData(
    function _allocationRecord(props: AllocationProps) {
        const [selectedVendingMachines, setSelectedVendingMachines] = useState<Array<VendingMachine>>([]);

        const [saving, setSaving] = useState(false);

        useEffect(() => {
            const selections: Array<VendingMachine> = []
            props.allocation.vmIds.forEach((vmid) => {
                const vm = props.findVendingMachine(vmid)
                if (vm) {
                    selections.push(vm);
                }
            });

            setSelectedVendingMachines(selections);
        }, [props, props.allocation, props.allVendingMachines]);

        function saveVmIds(event: any) {
            event.preventDefault();
            console.log(selectedVendingMachines);
            setSaving(true);

            postAPI(`/api/vending_machine_allocation`, {
                userId: props.allocation.userId,
                vmIds: selectedVendingMachines.map((vm) => vm.uuid)
            }).then((response) => {
                if (response.data.success) {
                    setSaving(false);
                } else {
                    window.alert(response.data.message)
                }
            })
        }

        return (<tr>
            <td>{props.allocation.email}</td>
            <td>
                <EditableVendingMachineList allVendingMachines={props.allVendingMachines.allVendingMachines}
                                            onChange={setSelectedVendingMachines}
                                            selectedVendingMachines={selectedVendingMachines}/>
            </td>
            <td>
                {saving ? <span>Saving...</span> :
                    <button onClick={saveVmIds} className={"btn btn-success"}>Save</button>}
            </td>
        </tr>);
    }
);

const _organizationManagement = (props: Props) => {
    const [loading, setLoading] = useState(true);
    const [allocations, setAllocations] = useState<Array<VendingMachineAllocation>>([]);

    function reloadAllocations() {
        getAPI(`/api/vending_machine_allocation`).then((response) => {
            if (response.data.success) {
                const allocations: Array<VendingMachineAllocation> = response.data.allocations;

                console.log(allocations);
                setAllocations(allocations.sort((a, b) => {
                    return a.email.localeCompare(b.email)
                }));
            } else {
                window.alert(response.data.message)
            }
        }).finally(() => {
            setLoading(false);
        })
    }

    useEffect(() => {
        reloadAllocations();
    }, [props.allVendingMachines, props.allProducts])

    const rows = allocations.map((allocation) => {
        return <AllocationRecord allocation={allocation}/>
    })

    return (
        <section className="content">
            <Helmet titleTemplate="%s - Vending on Track">
                <title>Vending Machine Allocation</title>
            </Helmet>
            <h1>Vending Machine Allocation</h1>

            <div className={"box box-success"}>
                {loading ?
                    <p>Loading...</p>
                    :
                    <table className={"table table-bordered"}>
                        <thead>
                        <tr>
                            <th>Personnels</th>
                            <th>Vending Machines under Management</th>
                            <th>Actions</th>
                        </tr>
                        </thead>
                        <tbody>
                        {rows}
                        </tbody>
                    </table>
                }
            </div>
        </section>
    );
}


export const OrganizationManagement = withGlobalData(_organizationManagement);
