import React, {useState} from "react";
import {
    V1ScreenSavers
} from "./telemetry/vending_machine/v1/typescript-axios";

import Modal from 'react-modal';
import {mediaManagementServiceApi} from "./api";

interface Props {
    screenSaver: V1ScreenSavers,
    closeEditScreenSaver,
    onUpdate:() => {},
    openEditModal:boolean
}

export function ScreenSaverEditPage(props: Props) {
    const [duration, setDuration] = useState(props.screenSaver.duration);
    const [cta, setCta] = useState(props.screenSaver.cta);

    const customStyles = {
        content: {
            top: '40%',
            left: '50%',
            right: 'auto',
            bottom: 'auto',
            marginRight: '-50%',
            transform: 'translate(-50%, -50%)',
            width:'50%',
        },
    };

    const updateScreenSaver =(e) =>{
        e.preventDefault()
        let uuid = props.screenSaver.uuid as string;
        new mediaManagementServiceApi().mediaManagementServiceUpdateScreenSaver(uuid,{
            duration:duration,
            cta:cta,
        }).then(res => {
            props.closeEditScreenSaver();
            props.onUpdate();
        }).catch(err => {
            alert("update screen saver error")
        })
    }

    const closeModal=() =>{
        props.closeEditScreenSaver()
    }

    return (
        <Modal
            style={customStyles}
            ariaHideApp={false}
            isOpen={props.openEditModal}
            contentLabel="Edit Screen Saver"
        >
            <h3>Edit Screen Saver</h3>
            <form>
                <div className="form-group">
                    <label>ID</label>
                    <input className="form-control" value={props.screenSaver.uuid} disabled={true}/>
                </div>
                <div className="form-group">
                    <label>Duration*</label>
                    <input className="form-control" value={duration} placeholder={"duration unit is second"}
                           onChange={(event) => {
                               let num = parseInt(event.target.value)
                               Number.isNaN(num) ? setDuration('0') : setDuration(num.toString())
                           }}/>
                </div>
                <div className="form-group">
                    <label>Description</label>
                    <textarea rows={3} className="form-control" value={cta} placeholder={"input description"}
                              onChange={(event) => {
                                  setCta(event.target.value)
                              }}/>
                </div>
                <div>
                    <button className={"btn btn-success"} onClick={updateScreenSaver} style={{marginRight:"10px"}}>Submit</button>
                    <button onClick={closeModal} className={"btn btn-default"}>Cancel</button>
                </div>
            </form>

        </Modal>

    );
}
