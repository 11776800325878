import React, {Component} from "react";
import {VendingMachine} from "./models";
import {LocationMap} from "./location_map";
import {debounce} from 'ts-debounce';
import {postAPI} from "./api";
// @ts-ignore

let google = window.google;

interface Props {
    vendingMachine: VendingMachine

    updated()
}

interface State {
    saving: boolean
    can_save: boolean
    address: string | null
    location: google.maps.GeocoderResult | null
    problem: string
}

export class VendingMachineAddressPanel extends Component<Props, State> {
    constructor(props) {
        super(props);

        this.state = {
            saving: false,
            address: this.props.vendingMachine.address,
            can_save: false,
            problem: '',
            location: null
        };

        this.updateMap = debounce(this.updateMap, 1000);

        this.updateMap();
    }

    handleChange(event) {
        this.setState({
            can_save: false,
            address: event.target.value
        });

        this.updateMap();
    }

    updateMap() {
        let me = this;
        let old_address = this.state.address ? this.state.address : '';

        let geoCoder = new google.maps.Geocoder();

        geoCoder.geocode({'address': old_address}, (results, status) => {
            console.log(results);
            console.log(status);
            console.log('this address' + me.state.address);
            console.log('old: ' + old_address);
            if (me.state.address === old_address) {
                if (status === google.maps.GeocoderStatus.OK) {
                    this.setState({
                        can_save: true,
                        location: results[0]
                    });
                } else {
                    this.setState({
                        can_save: false,
                        problem: status.toString()
                    });

                }
            }
        });
    }

    handleSave() {
        this.setState({saving: true});
        if (this.state.location) {
            postAPI(`/api/vending_machines/${this.props.vendingMachine.uuid}/location`, {
                address: this.state.address,
                latitude: this.state.location.geometry.location.lat(),
                longitude: this.state.location.geometry.location.lng()
            }).then((result) => {
                this.setState({saving: false});
                if (result.data) {
                    // ok
                    this.props.updated();
                }
            });
        }
    }

    render() {
        let address = "";
        if (this.state.address) {
            address = this.state.address;
        }
        return (
            <div className={"well"}>
                <div className={"row"}>
                    <div className={"col-xs-8"}>
                        <h3>Address</h3>
                        <input className={"form-control"} value={address} onChange={this.handleChange.bind(this)}/>
                        <br/>
                        <button
                            onClick={this.handleSave.bind(this)}
                            disabled={this.state.can_save ? false : true}
                            title={this.state.can_save ? "" : this.state.problem}
                            className={"btn btn-success"}>
                            {this.state.saving ? "Saving..." : "Update"}
                        </button>
                    </div>
                    <div className={"col-xs-4"}>
                        {this.state.location != null ?
                            <LocationMap
                                vendingMachine={this.props.vendingMachine}
                                address={this.state.address ? this.state.address : ''}
                                center={{
                                    lat: this.state.location.geometry.location.lat(),
                                    lng: this.state.location.geometry.location.lng()
                                }}
                                zoom={18}/>
                            :
                            null
                        }
                    </div>
                </div>
            </div>
        );
    }
}