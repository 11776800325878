import React, {Component} from "react";
import DatePicker from 'react-datepicker';
import {IGlobalDataContext, withGlobalData} from "./contexts/global-data";
import moment from "moment";
import {postAPI} from "./api";
import {Helmet} from "react-helmet";
import {DisplayTime} from "./display_time";
import {FormatMoney} from "./format_money";
import {CSVLink} from 'react-csv';


interface Props extends IGlobalDataContext {

}

interface CreditCardTransaction{
    "timestamp": number
    "itemPrice": number
    "cashlessValue": number
    "itemNumber": string
    "accountNumber": string | null
    "vendingMachineName": string
    "isRefunded": boolean
    txnRef?: string | null
}

interface State {
    fromDate: Date
    toDate: Date
    reportLoading: boolean
    transactions : CreditCardTransaction[]
}

class CreditCardReportRaw extends Component<Props, State> {
    constructor(props) {
        super(props);

        this.state = {
            fromDate: new Date(),
            toDate: new Date(),
            reportLoading: false,
            transactions: []
        }
    }

    report(e) {
        e.preventDefault();
        e.stopPropagation();

        console.log(moment(this.state.fromDate).format("YYYY-MM-D"));
        console.log(moment(this.state.toDate).format("YYYY-MM-D"));
        this.setState({reportLoading: true});
        postAPI(`/api/credit_card_report`, {
            fromDate: moment(this.state.fromDate).format("YYYY-MM-D"),
            toDate: moment(this.state.toDate).format("YYYY-MM-D"),
        }).then((resp) => {
            this.setState({reportLoading: false});
            if (resp.data.success) {
                this.setState({transactions: resp.data.transactions});
            } else {
                window.alert(resp.data.message);
            }
        });
        return false;
    }

    fromDateChange(date) {
        this.setState({fromDate: date})
    }

    toDateChange(date) {
        this.setState({toDate: date})
    }

    prepareCsvHeader(){
        return [
            {label: 'Date', key: 'date'},
            {label: 'Vending Machine', key: 'vm'},
            {label: 'MDB Code', key: 'in'},
            {label: 'Item Price', key: 'ip'},
            {label: 'Surcharge', key: 'surcharge'},
            {label: 'Total Amount', key: 'ta'},
            {label: 'LatPay Account', key: 'la'},
            {label: 'Status', key: "status"},
            {
                label: 'Transaction Reference', key: "txnRef"
            }
        ]
    }

    prepareCsvData(){
        let csvData:{}[] = []
        this.state.transactions.forEach((trans)=>{
            csvData.push({
                "date": moment.unix(trans.timestamp).toString(),
                "vm": trans.vendingMachineName,
                "in": trans.itemNumber,
                "ip": trans.itemPrice,
                "surcharge": trans.cashlessValue - trans.itemPrice,
                "ta": trans.cashlessValue,
                "la": trans.accountNumber,
                'status': trans.isRefunded ? "Refunded" : "Processed",
                "txnRef": trans.txnRef
            })
        })
        console.log(csvData)
        return csvData
    }

    render() {
        const rows = this.state.transactions.map((transaction, index)=>{
            return <tr key={index}>
                <td><DisplayTime timestamp={transaction.timestamp} expanded={true}/></td>
                <td>{transaction.vendingMachineName}</td>
                <td>{transaction.itemNumber}</td>
                <td><FormatMoney cents={transaction.itemPrice}/></td>
                <td><FormatMoney cents={transaction.cashlessValue-transaction.itemPrice}/></td>
                <td>
                    <FormatMoney cents={transaction.cashlessValue}/>
                </td>
                <td>{transaction.accountNumber}</td>
                <td>{transaction.isRefunded ? "Refunded" : "Processed"}</td>
                <td>{transaction.txnRef}</td>
            </tr>
        })
        let dateFormat = moment().locale(this.props.me.localeISO).localeData().longDateFormat("L").replace("DD", "dd").replace("YYYY", "yyyy");
        return (
            <div>
                <Helmet titleTemplate="%s - Vending on Track">
                    <title>Credit Card Report</title>
                </Helmet>
                <section className="content-header">
                    <h1>
                        Credit Card Report
                    </h1>
                </section>
                <section className="content">
                    <div className={"box box-success"}>
                        <div className={"box-body with-border"}>
                            <div className="form-group">
                                <label>From: </label>
                                <DatePicker className="form-control"
                                            selected={this.state.fromDate}
                                            onChange={this.fromDateChange.bind(this)} dateFormat={dateFormat}/>
                            </div>
                            <div className="form-group">
                                <label>To: </label>
                                <DatePicker className="form-control"
                                            selected={this.state.toDate}
                                            onChange={this.toDateChange.bind(this)} dateFormat={dateFormat}/>
                            </div>
                            <div className="form-group">
                                <button onClick={this.report.bind(this)} className="btn btn-success">Generate report</button>
                            </div>
                        </div>
                    </div>
                    {this.state.reportLoading &&
                    <div className={"box box-success"}>
                        <div className={"box-header"}>
                            <h1>Loading ...</h1>
                        </div>
                    </div>
                    }
                    {!this.state.reportLoading && this.state.transactions.length !== 0 &&
                    <div className="box box-success">
                        <div className={"box-header"}>
                            <h1>Report</h1>
                        </div>
                        <div className="box-body">
                            <table className="table table-bordered">
                                <thead>
                                    <tr>
                                        <th>Date</th>
                                        <th>Vending Machine</th>
                                        <th>MDB Code</th>
                                        <th>Item Price</th>
                                        <th>Surcharge</th>
                                        <th>Total Amount</th>
                                        <th>LatPay Account</th>
                                        <th>Status</th>
                                        <th>Transaction Reference</th>
                                    </tr>
                                </thead>
                                <tbody>
                                {rows}
                                </tbody>
                            </table>
                        </div>
                        <div>
                            <CSVLink headers = {this.prepareCsvHeader()}
                                     data={this.prepareCsvData()}
                                     filename={"Credit Revenue Report.csv"}>Download As CSV</CSVLink>
                        </div>
                    </div>
                    }
                </section>
            </div>
        )
    }

}

const CreditCardReport = withGlobalData(CreditCardReportRaw);
export default CreditCardReport;
