import React, {Component} from 'react';
import {Message, Notification, notificationSorting} from "./models";
import {getAPI, postAPI} from "./api";
import ReactPaginate from "react-paginate";
import {IGlobalDataContext, withGlobalData} from "./contexts/global-data";
import {NotificationTabComponent} from "./notification_tab_component";

interface Props extends IGlobalDataContext {
    uuid: string

    loadVendingMachineDetail()
}

interface State {
    notifications: Array<Notification>
    totalNotifications: number
    totalPages: number
    page: number
}

class NotificationTabWithoutOnline extends Component<Props, State> {
    constructor(props) {
        super(props);

        this.state = {
            notifications: [],
            page: 0,
            totalPages: 1,
            totalNotifications: 0,
        }
    }

    componentDidMount(): void {
        this.loadNotifications(0);
    }

    loadNotifications(page): void {
        getAPI(`/api/vending_machines/${this.props.uuid}/notifications?page=${page}`).then((response) => {
            console.log('notifications ,', response.data.notifications.content)
            this.setState({
                notifications: response.data.notifications.content,
                totalPages: response.data.notifications.totalPages,
                totalNotifications: response.data.notifications.totalElements,
            });
        })
    }

    acknowledge(notification: Notification) {
        postAPI(`/api/notifications/${notification.id}/ack`, {}).then((response) => {
            console.log(response);
            if (response.data.success) {
                this.loadNotifications(this.state.page);
                this.props.loadVendingMachineDetail();
            } else {
                window.alert(response.data.message);
            }

            this.props.reloadNotificationCount();

        })
    }

    clearNotifications(e) {
        e.preventDefault();
        if (window.confirm(`Acknowledge all notifications about this vending machine`)) {
            postAPI(`/api/vending_machines/${this.props.uuid}/notifications/acknowledges`, {}).then((response) => {
                if (response.data.success) {
                    this.setState({
                        page: 0,
                        notifications: []
                    });
                    this.props.pushFlashMessage(new Message("Successfully acknowledged all notifications", "success"));
                    this.props.loadVendingMachineDetail();
                } else {
                    window.alert(response.data.message);
                }

                this.props.reloadNotificationCount();
            });
            return false;
        } else {
            return false;
        }
    }

    handlePageClick(selectedItem: { selected: number }) {
        this.setState({
            page: selectedItem.selected,
        });

        this.loadNotifications(selectedItem.selected);
    }

    render() {
        const notifications = this.state.notifications.sort(notificationSorting).map((notification: Notification) => {
            return (<NotificationTabComponent key={notification.id} ack={(e) => {
                e.preventDefault();
                this.acknowledge(notification);
                return false;
            }} notification={notification}/>);
        });

        return (
            <div className={""}>
                {
                    this.state.notifications.length === 0 ?
                        <div className="">
                            <div className="box box-success">
                                <div className="box-header">
                                    <h2>No active notifications</h2>
                                </div>
                            </div>
                        </div>
                        :
                        <div>
                            <div className="box box-success">
                                <div className="box-header">
                                    <h2>Current Notifications</h2>
                                    <span>Total: {this.state.totalNotifications} notifications</span>
                                </div>
                                <div className="box-body with-border">

                                    <ReactPaginate
                                        pageRangeDisplayed={2}
                                        marginPagesDisplayed={2}
                                        pageCount={this.state.totalPages}
                                        initialPage={0}
                                        onPageChange={this.handlePageClick.bind(this)}
                                        containerClassName={"pagination pagination-sm no-margin"}
                                        activeClassName={'active'}
                                    />

                                    {this.state.notifications.length > 0 &&

                                        <form className="form" onSubmit={this.clearNotifications.bind(this)}>
                                            <input type="submit" className="btn btn-warning"
                                                   value="Acknowledge all notifications"/>
                                            <hr/>
                                        </form>
                                    }
                                    <ul className="timeline">
                                        {notifications}
                                    </ul>
                                </div>
                            </div>
                        </div>
                }
            </div>
        );
    }
}

const NotificationTab = withGlobalData(NotificationTabWithoutOnline);

export default NotificationTab;



