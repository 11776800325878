import React, {Component} from 'react';
import {IAuthenticationContext, withAuthentication} from "./authentication";
import {Redirect} from "react-router-dom";

interface Props extends IAuthenticationContext {

}

class _AuthenticationProtected extends Component<Props> {
    render() {
        if (this.props.authentication.authenticated === null) {
            return (<div/>); // TODO: it should be a spinner
        }


        if (!this.props.authentication.authenticated) {
            return (<Redirect to="/login"/>);
        }

        if (this.props.authentication.authenticated){
            if (this.props.authentication.tfaRequired){
                return (<Redirect to={"/verify_tfa"}/>);
            } else {
                if (!this.props.authentication.isApproved){
                    return (<Redirect to={"/notApproved"}/>);
                } else if (!this.props.authentication.hasCompany){
                    return (<Redirect to={"/join"}/>);
                } else {
                    return (<div>
                        {this.props.children}
                    </div>);
                }
            }
        }
    }
}


const AuthenticationProtected = withAuthentication(_AuthenticationProtected);
export {AuthenticationProtected};
