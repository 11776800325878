import {ServiceType} from "@bufbuild/protobuf";
import {createConnectTransport,} from "@bufbuild/connect-web";

import {createPromiseClient, Interceptor, PromiseClient} from "@bufbuild/connect";

import {useMemo} from "react";


const authInjector: Interceptor = (next) => async (req) => {
    const authenticationToken = localStorage.getItem("authentication_token");
    if (authenticationToken) {
        req.header.set("Authorization", `${authenticationToken}`);
    }

    return await next(req);
};


const transport = createConnectTransport({
    // FIXME: we need to use production URL here for local debug, server sdie push relies on http/2.0, create-react-app proxy only supports http/1.1
    // baseUrl: "https://mdb-ng-production-admin-grpc.vendingontrack.com/",
    baseUrl: "/",
    interceptors: [authInjector]
});

export function useClient<T extends ServiceType>(service: T): PromiseClient<T> {
    // We memoize the client, so that we only create one instance per service.
    return useMemo(() => {
        const client = createPromiseClient(service, transport);
        return client;
    }, [service]);
}
