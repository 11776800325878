import React, {Component, Fragment} from 'react';

import {getAPI, postAPI} from "./api";

import {IGlobalDataContext, withGlobalData} from "./contexts/global-data";
import {CompanyClient, VendingMachine,} from "./models";
import CompanyClientsTable from "./company_client_table";
import {Typeahead} from "react-bootstrap-typeahead";
import {Helmet} from "react-helmet";

interface Props extends IGlobalDataContext {
}

interface State {
    loading: boolean
    message: string | null,
    clients: Array<CompanyClient>
    emails: string
    name: string
    selectedVendingMachines: Array<VendingMachine>
    saving: boolean,
}

class CompanyClientsPage extends Component<Props, State> {
    constructor(props) {
        super(props);

        this.state = {
            loading: false,
            message: null,
            clients:[],
            emails:"",
            name: "",
            selectedVendingMachines: [],
            saving: false,
        };
    }

    loadCompanyClients() {
        getAPI(`/api/company_clients`).then((response) => {
            if (response.data.success) {
                this.setState({clients: response.data.companyClientInfos});
            } else {
                this.setState({message: response.data.message})
            }
        })
    }

    componentDidMount(): void {
        this.loadCompanyClients();
    }


    handleName(e) {
        e.preventDefault();

        this.setState({name: e.target.value});
    }

    handleEmails(e) {
        e.preventDefault();

        this.setState({emails: e.target.value});
    }

    submit(e) {
        e.preventDefault();

        this.setState({saving: true});

        const emails = this.state.emails;
        const name = this.state.name;
        const vmIds = this.state.selectedVendingMachines.map(vendingMachine => vendingMachine.uuid);
        this.setState({emails: ''});
        this.setState({name: ''});
        this.setState({selectedVendingMachines: []});

        postAPI("/api/company_client/", {
            emails: emails,
            name: name,
            vmIds: vmIds
        }).then((result) => {
            if (result.data.success) {
                this.loadCompanyClients();
            }else{
                window.alert(result.data.message)
            }
            this.setState({saving: false});
        })
    }

    allocateVendingMachines(vendingMachines) {
        this.setState({
            selectedVendingMachines: vendingMachines
        })
    }

    render() {
        let allocatedVendingMachine:Array<String> = [];
        allocatedVendingMachine = this.state.clients.flatMap(client => {
            return client.vmIds
        });

        const allocatableVendingMachine = this.props.allVendingMachines.allVendingMachines.filter(vendingMachine => {
            return !allocatedVendingMachine.includes(vendingMachine.uuid)
        })

        return (
            <Fragment>
                <Helmet titleTemplate="%s - Vending on Track">
                    <title>Company Post Pay Clients Management</title>
                </Helmet>
                <section className="content-header">
                    <h1>
                        Company Post Pay Clients Management
                    </h1>
                </section>

                <section className="content">

                    <div className="box box-primary">
                        <div className="box-header with-border">
                            <h3 className="box-title">Create Post Pay Client</h3>
                            <form onSubmit={this.submit.bind(this)}>
                                <div className="box-body">
                                    <div className="form-group">
                                        <label htmlFor="name">Client Company Name</label>
                                        <input onChange={this.handleName.bind(this)} value={this.state.name} type="text"
                                               className="form-control" id="emails"
                                               placeholder="Enter User's Email(s)"/>
                                    </div>
                                    <div className="form-group">
                                        <label htmlFor="name">Client Admin Email</label>
                                        <input onChange={this.handleEmails.bind(this)} value={this.state.emails} type="text"
                                               className="form-control" id="emails"
                                               placeholder="Enter User's Email(s)"/>
                                    </div>
                                    <div className="form-group">
                                        <label htmlFor="name">Allocate Vending Machine</label>
                                        <Typeahead
                                            clearButton
                                            selected={this.state.selectedVendingMachines}
                                            multiple
                                            labelKey={"name"}
                                            onChange={this.allocateVendingMachines.bind(this)}
                                            options={allocatableVendingMachine}
                                            placeholder="Allocate Vending Machines"
                                        />
                                    </div>
                                </div>

                                <div className="box-footer">
                                    <button type="submit" disabled={this.state.saving} className="btn btn-primary">Save</button>
                                </div>
                            </form>
                        </div>
                    </div>
                    {this.state.message ?
                        <div className="callout callout-danger">
                            <h4>There was an error when we tried to load your clients for this company.</h4>

                            <p>{this.state.message}</p>
                        </div>
                        :
                        (this.state.loading ?
                                <div className="callout callout-info">
                                    <h4>Loading clients...</h4>

                                    <p>We are listing all your clients. It won't be long.</p>
                                </div>
                                :
                                <div>
                                    <h3 className={""}>All Post Pay Clients</h3>

                                    <div className={"row"}>
                                        <div className={"col-xs-12"}>
                                            <div className="box with-border box-primary">
                                                <div className="box-body no-padding">
                                                    <CompanyClientsTable clients={this.state.clients}
                                                                         clientsShouldReload={this.componentDidMount.bind(this)}
                                                                        allVendingMachines={this.props.allVendingMachines}/>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                        )
                    }
                </section>
            </Fragment>
        );
    }
}

export default withGlobalData(CompanyClientsPage);
