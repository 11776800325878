import React, {Component} from "react";
import DatePicker from 'react-datepicker';
import {IGlobalDataContext, withGlobalData} from "./contexts/global-data";
import moment from "moment";
import {getAPI} from "./api";
import {Helmet} from "react-helmet";
import {Transaction, VendingMachine} from "./models";
import SalesTr from "./components/sales_tr";
import ReactPaginate from "react-paginate";
import {Highlighter, Typeahead} from "react-bootstrap-typeahead";
import VendingMachineGroupSelector from "./vending_machine_group_selector";


interface Props extends IGlobalDataContext {

}

interface State {
    fromDate: Date
    toDate: Date
    reportLoading: boolean
    transactions : Transaction[],
    currentPage: number
    totalPage:number
    selectedVendingMachine:VendingMachine|null
    last4:string
}

class BankCardReportRaw extends Component<Props, State> {
    constructor(props) {
        super(props);

        this.state = {
            fromDate: new Date(),
            toDate: new Date(),
            reportLoading: false,
            transactions: [],
            currentPage: 0,
            totalPage: 1,
            selectedVendingMachine:null,
            last4:"",
        }
    }

    getTransactionData(page:number, from:string, to:string, vmId:string|null, last4:string|null){
        this.setState({reportLoading: true});
        let requestUrl = `/api/credit_card_transaction?page=${page}&from=${from}&to=${to}`;
        if (vmId != null){
            requestUrl = requestUrl + `&vending_machine_uuid=${vmId}`
        }
        if (last4 != null){
            requestUrl = requestUrl + `&last4=${last4}`
        }
        getAPI(requestUrl).then((response)=>{
            this.setState({reportLoading: false});
            if (response.data.success){
                this.setState({
                    transactions: response.data.data.transactions,
                    totalPage:response.data.data.totalPage,
                    currentPage:response.data.data.currentPage
                })
            } else {
                window.alert(response.data.message)
            }
        })
    }

    report(e) {
        e.preventDefault();
        e.stopPropagation();

        const page = 0;
        const from = moment(this.state.fromDate).format("YYYY-MM-D");
        const to = moment(this.state.toDate).format("YYYY-MM-D");
        const vmId = this.state.selectedVendingMachine !== null ? this.state.selectedVendingMachine.uuid : null;
        const last4 = this.state.last4 !== "" ? this.state.last4 : null;
        this.getTransactionData(page, from, to, vmId, last4);
        return false;
    }

    fromDateChange(date) {
        this.setState({fromDate: date})
    }

    toDateChange(date) {
        this.setState({toDate: date})
    }

    handlePageClick(selectedItem: { selected: number }){
        console.log("onclick")
        const page = selectedItem.selected;
        const from = moment(this.state.fromDate).format("YYYY-MM-D");
        const to = moment(this.state.toDate).format("YYYY-MM-D");
        const vmId = this.state.selectedVendingMachine !== null ? this.state.selectedVendingMachine.uuid : null;
        const last4 = this.state.last4 !== "" ? this.state.last4 : null;
        this.getTransactionData(page, from, to, vmId, last4);
    }

    renderOption(option, props, index) {
        return [
            <Highlighter key="name" search={props.text}>
                {option.name}
            </Highlighter>,
            <div key="detail" className="drop-down-vending-machine-detail">
                <div className="address">
                    Address: {option.address}
                </div>
            </div>,
        ];
    }

    reloadData(e){
        const page = this.state.currentPage;
        const from = moment(this.state.fromDate).format("YYYY-MM-D");
        const to = moment(this.state.toDate).format("YYYY-MM-D");
        const vmId = this.state.selectedVendingMachine !== null ? this.state.selectedVendingMachine.uuid : null;
        const last4 = this.state.last4 !== "" ? this.state.last4 : null;
        this.getTransactionData(page, from, to, vmId, last4);
    }

    handleVendingMachineChange(vendingMachines:Array<VendingMachine>){
        if (vendingMachines.length > 0){
            this.setState({selectedVendingMachine: vendingMachines[0]}, ()=>{console.log(this.state.selectedVendingMachine)});
        } else {
            this.setState({selectedVendingMachine:null})
        }
    }

    handleLast4Change(e){
        e.preventDefault();
        e.stopPropagation();
        this.setState({
            last4:e.target.value
        })
    }



    render() {
        let dateFormat = moment().locale(this.props.me.localeISO).localeData().longDateFormat("L").replace("DD", "dd").replace("YYYY", "yyyy");
        const rows = this.state.transactions.map((transaction)=>{
            return <SalesTr isShrunk={false} key={transaction.transactionUUID}
                            isExpandTime={false}
                            reloadData={this.reloadData.bind(this)}
                            isShowMachine={true}
                            trans={transaction} isShowLast4={true}/>
        })
        const elegibleVendingMachines = this.props.allVendingMachines.allVendingMachines;
        const selectedVendingMachines = this.state.selectedVendingMachine !== null ? [this.state.selectedVendingMachine]:undefined;
        return (
            <div>
                <Helmet titleTemplate="%s - Vending on Track">
                    <title>Credit Card Purchase Report</title>
                </Helmet>
                <section className="content-header">
                    <h1>
                        Credit Card Purchase Report
                    </h1>
                </section>
                <section className="content">
                    <div className={"box box-success"}>
                        <div className={"box-body with-border"}>
                            <div className="form-group">
                                <label>From: </label>
                                <DatePicker className="form-control"
                                            selected={this.state.fromDate}
                                            onChange={this.fromDateChange.bind(this)} dateFormat={dateFormat}/>
                            </div>
                            <div className="form-group">
                                <label>To: </label>
                                <DatePicker className="form-control"
                                            selected={this.state.toDate}
                                            onChange={this.toDateChange.bind(this)} dateFormat={dateFormat}/>
                            </div>
                            <div className="form-group">
                                <label className={"inline-label"} >Vending Machine: </label> &nbsp; &nbsp;
                                <div className={"inline-div"}>
                                    <VendingMachineGroupSelector
                                        onSelectedVendingMachine={(vm)=>{
                                            this.handleVendingMachineChange([vm])
                                            return Promise.resolve(true);
                                        }}
                                        button={<button className={"btn btn-default"}>expend</button>}
                                        selectGroup={false}
                                    />
                                </div>
                                <Typeahead
                                    id={1}
                                    onChange={this.handleVendingMachineChange.bind(this)}
                                    multiple={false}
                                    options={elegibleVendingMachines}
                                    labelKey="name"
                                    placeholder={"Select vending machine"}
                                    renderMenuItemChildren={this.renderOption.bind(this)}
                                    selected={selectedVendingMachines}
                                />
                            </div>
                            <div className="form-group">
                                <label>Last4: </label>
                                <input className="form-control" value={this.state.last4} onChange={this.handleLast4Change.bind(this)}/>
                            </div>
                            <div className="form-group">
                                <button onClick={this.report.bind(this)} className="btn btn-success">Generate report</button>
                            </div>
                        </div>
                    </div>
                    <div className="box box-success">
                        <div className={"box-header"}>
                            <h1>Report</h1>
                            {this.state.reportLoading &&
                                <h5>Loading ...</h5>
                            }
                        </div>
                        <div className="box-body">
                            <table className="table table-bordered">
                                <thead>
                                <tr>
                                    <th>Time</th>
                                    <th>Selection</th>
                                    <th>Price</th>
                                    <th>Payment Method</th>
                                    <th>Machine</th>
                                    <th>Last 4</th>
                                </tr>
                                </thead>
                                <tbody>
                                {rows}
                                </tbody>
                            </table>
                        </div>
                        <ReactPaginate
                            pageRangeDisplayed={2}
                            marginPagesDisplayed={2}
                            pageCount={this.state.totalPage}
                            onPageChange={this.handlePageClick.bind(this)}
                            initialPage={0}
                            containerClassName={"pagination pagination-sm no-margin"}
                            activeClassName={'active'}
                        />
                    </div>
                </section>
            </div>
        )
    }

}

const BankCardReport = withGlobalData(BankCardReportRaw);
export default BankCardReport;