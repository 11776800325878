import React, {Component} from "react";
import {CompanyClient, VendingMachine} from "./models";
import {DisplayTime} from "./display_time";
import {postAPI} from "./api";
import { Link } from "react-router-dom";
import {VendingMachineLink} from "./components/vending_machine_link";



interface Props{
    client: CompanyClient
    clientsShouldReload()
    allocatedVendingMachines: Array<VendingMachine>
}

interface State {
}

class CompanyClientTableRow extends Component<Props, State> {
    constructor(props) {
        super(props);

        this.state = {};
    }

    removeClient(event, client: CompanyClient) {
        event.stopPropagation();
        event.preventDefault();

        if (window.confirm("Are you sure to remove post pay client " + client.name)) {
            postAPI(`/api/company_client/remove`, {
                clientUuid: client.uuid
            }).then((resp) => {
                if (resp.data.success) {
                    this.props.clientsShouldReload();
                } else {
                    window.alert(resp.data.message);
                }
            })
        }
    }

    render() {
        let client = this.props.client;
        const vendingMachineLinks = this.props.allocatedVendingMachines.map((vendingMachine, i) => (
            <React.Fragment key={i}>{<VendingMachineLink vendingMachine={vendingMachine} setting={false}/>}{i < (this.props.allocatedVendingMachines.length - 1) ? <br /> : ''}</React.Fragment>
        ));
        const splitAdminEmails = client.adminEmails.split(",");
        const adminEmailComponents = splitAdminEmails.map((email, i) => (
            <React.Fragment key={i}>{email}{i < (splitAdminEmails.length - 1) ? <br /> : ''}</React.Fragment>
        ));
        return (
            <tr>
                <td><DisplayTime timestamp={client.createAt}/></td>
                <td>{client.name}</td>
                <td>{adminEmailComponents}</td>
                <td>{vendingMachineLinks}</td>
                <td>{client.status}</td>
                <td>
                    {client.status === "ACTIVE" &&
                    <>
                        <Link to={`/company_clients/${client.uuid}`} className={"btn btn-success"}>Edit</Link>
                        <button onClick={(event) => {
                            return this.removeClient(event, client)
                        }} className="btn btn-danger" style={{marginLeft: "5px"}}>Delete
                        </button>
                    </>
                    }
                </td>
            </tr>
        );
    }
}

export {CompanyClientTableRow}