import React, {Component, Fragment} from "react";
import marker from './vending_machine_marker.png';
import {VendingMachine} from "./models";
import ReactTooltip from 'react-tooltip'
import {VendingMachineLink} from "./components/vending_machine_link";
import {FormatMoneyWithFlexibleSignSize} from "./format_money_without_style";


interface Props {
    lat: number,
    lng: number,
    vendingMachine: VendingMachine
}

interface State {

}

export class VendingMachineMarker extends Component<Props, State> {
    constructor(props) {
        super(props);

        this.state = {};
    }

    render(): React.ReactElement<any, string | React.JSXElementConstructor<any>> | string | number | {} | React.ReactNodeArray | React.ReactPortal | boolean | null | undefined {
        return (
            <Fragment>
                <div data-tip data-for={this.props.vendingMachine.uuid}
                     style={{border: "1px solid gray", textAlign: "left", width: "200px", opacity: 0.9}}>
                    <img alt={"marker"} style={{float: "left"}} src={marker} width={"30px"}/>
                    <div style={{
                        padding: "5px",
                        backgroundColor: "white",
                        textOverflow: "ellipsis",
                        whiteSpace: "nowrap"
                    }}>
                        <span style={{fontSize: "larger"}}>
                            <VendingMachineLink vendingMachine={this.props.vendingMachine}/>
                        </span>
                        <br/>
                        {this.props.vendingMachine.online ?
                            <span>Online <i className="fa fa-circle-o text-green"> </i></span> :
                            <span>Offline <i className="fa fa-circle-o text-gray"> </i></span>}
                    </div>
                </div>

                <ReactTooltip id={this.props.vendingMachine.uuid}>
                    <table className={"table"}>
                        <tbody>
                        <tr>
                            <td>Today's Revenue</td>
                            <td><FormatMoneyWithFlexibleSignSize cents={this.props.vendingMachine.todayRevenue}/></td>
                        </tr>
                        <tr>
                            <td>Address</td>
                            <td>{this.props.vendingMachine.address}</td>
                        </tr>
                        </tbody>
                    </table>
                </ReactTooltip>

            </Fragment>
        )
    }
}