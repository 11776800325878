import React from 'react';
import {Document, Page, Text, View, StyleSheet} from '@react-pdf/renderer';

const styles = StyleSheet.create({
    page: { paddingTop: 30, paddingBottom:30},
    table: {width: "auto", borderStyle: "solid", borderWidth: 1, borderRightWidth: 0, borderBottomWidth: 0, marginRight:40, marginLeft:40},
    tableRow: { margin: "auto", flexDirection: "row" },
    indexCol: { width: "5%", borderStyle: "solid", borderWidth: 1, borderLeftWidth: 0, borderTopWidth: 0},
    productCol: { width: "60%", borderStyle: "solid", borderWidth: 1, borderLeftWidth: 0, borderTopWidth: 0},
    purchaseQuantityCol: { width: "35%", borderStyle: "solid", borderWidth: 1, borderLeftWidth: 0, borderTopWidth: 0 },
    unitCol: { width: "50%", borderStyle: "solid", borderWidth: 1, borderLeftWidth: 0, borderTopWidth: 0},
    quantityCol: { width: "50%", borderStyle: "solid", borderWidth: 1, borderLeftWidth: 0, borderTopWidth: 0},
    tableCell: { margin: "auto", marginTop: 5, fontSize: 10 },
    title:{fontSize:"20px", textAlign: "center"},
    date:{fontSize:"10px", textAlign:"center", marginTop:20, marginBottom:20}
})





// Create Document Component

export default function PDF(props) {
    console.log(props.data)
    return <Document>
        <Page size="A4" style={styles.page}>
            <View style={styles.title}>
                <Text>
                    Purchase Order
                </Text>
            </View>
            <View style={styles.date}>
                <Text>
                    {Date()}
                </Text>
            </View>
            <View style={styles.table}>
                <View style={styles.tableRow}>
                    <View style={styles.indexCol}>
                        <Text style={styles.tableCell}>No</Text>
                    </View>
                    <View style={styles.productCol}>
                        <Text style={styles.tableCell}>Product</Text>
                    </View>
                    <View style={styles.purchaseQuantityCol}>
                        <Text style={styles.tableCell}>Purchase Options</Text>
                    </View>
                </View>
                {
                    props.data.map((item, index)=>{
                            return <View key={index} style={styles.tableRow}>
                                <View style={styles.indexCol}>
                                    <Text style={styles.tableCell}>
                                        {index+1}
                                    </Text>
                                </View>
                                <View style={styles.productCol}>
                                    <Text style={styles.tableCell}>
                                        {item?.name}
                                    </Text>
                                </View>
                                <View style={styles.purchaseQuantityCol}>
                                    <View style={styles.tableRow}>
                                        <View style={styles.unitCol}>
                                            <Text style={styles.tableCell}>
                                                {item?.productUnit}
                                            </Text>
                                        </View>
                                        <View style={styles.quantityCol}>
                                            <Text style={styles.tableCell}>
                                                Quantity
                                            </Text>
                                        </View>
                                    </View>
                                    {
                                        item.purchaseQuantities.map((purchaseQuantity, i)=>{
                                            return <View key={i} style={styles.tableRow}>
                                                <View style={styles.unitCol}>
                                                    <Text style={styles.tableCell}>
                                                        {purchaseQuantity.unit}
                                                    </Text>
                                                </View>
                                                <View style={styles.quantityCol}>
                                                    <Text style={styles.tableCell}>
                                                        {purchaseQuantity.quantity}
                                                    </Text>
                                                </View>
                                            </View>
                                        })
                                    }
                                </View>
                            </View>
                    })
                }
            </View>
        </Page>
    </Document>
}