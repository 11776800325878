import React, {Component} from "react";
import {Link, Redirect} from "react-router-dom";
import {Helmet} from "react-helmet";
import {postAPI} from "./api";

interface Props {
}

interface State {
    siteName:string
    isSendEmail:boolean
    siteOwnerEmailAddress:string
    redirect: boolean,
    formUuid:string | null,
}

class AddProductRecommendationFormPanel extends Component<Props, State> {
    constructor(props: Props) {
        super(props);

        this.state = {
            siteName:"",
            isSendEmail:false,
            siteOwnerEmailAddress:"",
            redirect:false,
            formUuid : null
        };
    }

    onIsSendEmailChange(e:any){
        e.preventDefault();
        e.stopPropagation();
        this.setState({
            isSendEmail: e.target.value === 'true'
        })
    }

    onSiteNameChange(e:any){
        e.preventDefault();
        e.stopPropagation();
        this.setState({
            siteName:e.target.value
        });
    }

    onSiteOwnerEmailChange(e:any){
        e.preventDefault();
        e.stopPropagation();
        this.setState({
            siteOwnerEmailAddress:e.target.value
        });
    }

    createForm(e:any){
        e.preventDefault();
        e.stopPropagation();
        postAPI(`/api/product_recommendation_form`, {siteName:this.state.siteName, siteOwnerEmail:this.state.siteOwnerEmailAddress !== "" ? this.state.siteOwnerEmailAddress : null}).then((response)=>{
            if (response.data.success){
                this.setState({redirect:true, formUuid:response.data.productRecommendationForm.uuid})
                //redirect to add product page
            } else {
                window.alert(response.data.message)
            }
        })
    }

    render() {
        if (this.state.redirect){
            return (<Redirect to={`/product_recommendation_form/${this.state.formUuid}/edit`}/>);
        }
        return <section className="content">
            <Helmet titleTemplate="%s - Vending on Track">
                <title>Create Product Recommendation Form</title>
            </Helmet>
            <h1>Create Product Recommendation Form</h1>
            <form onSubmit={this.createForm.bind(this)}>
                <div className="form-group">
                    <label>Site Name</label>
                    <input className="form-control" value={this.state.siteName} placeholder={"Site Name"}
                           onChange={this.onSiteNameChange.bind(this)}/>
                </div>
                <div className="form-group">
                    <label>Do you want to email the form to site owner automatically</label>
                    <select className="form-control" value={this.state.isSendEmail.toString()} onChange={this.onIsSendEmailChange.bind(this)}>
                        <option value="true">Yes</option>
                        <option value="false">No</option>
                    </select>
                </div>
                {this.state.isSendEmail && <div className="form-group">
                    <label>Site Owner Email</label>
                    <input className="form-control" value={this.state.siteOwnerEmailAddress} placeholder={"Site Owner Email Address"}
                           onChange={this.onSiteOwnerEmailChange.bind(this)}/>
                </div>}
                <input type="submit" className="btn btn-success" value="Next"/>
                &nbsp; &nbsp;
                <Link className={"btn btn-danger"} to={"/product_recommendation_form"}>Cancel</Link>
            </form>
        </section>;
    }
}

export {AddProductRecommendationFormPanel};
