import DatePicker from "react-datepicker";
import React, {useEffect, useState} from "react";
import {TelemetryvendingMachinev1BlackoutPeriod} from "./telemetry/vending_machine/v1/typescript-axios";

interface Props {
    index: number,
    blackoutPeriod: TelemetryvendingMachinev1BlackoutPeriod,
    updateBlackoutPeriod: (index: number, blackoutPeriod: TelemetryvendingMachinev1BlackoutPeriod) => void,
    remove: (index: number) => void,
}

function convertTimeStringToDate(timeString) {
    const [hours, minutes, seconds] = timeString.split(':').map(Number);
    const date = new Date();
    date.setHours(hours);
    date.setMinutes(minutes);
    date.setSeconds(seconds);
    return date;
}

export function VendingMachineBlackoutTimer(prop: Props) {
    const [startTime, setStartTime] = useState<Date | null>(null);
    const [endTime, setEndTime] = useState<Date | null>(null);

    useEffect(() => {
            setStartTime(convertTimeStringToDate(prop.blackoutPeriod.startTime ?? "00:00:00"));
            setEndTime(convertTimeStringToDate(prop.blackoutPeriod.finishTime ?? "00:00:00"));
            }
    , [prop.blackoutPeriod])

    const remove = () => {
        prop.remove(prop.index)
    }


    const onChangeStartTime = (date: Date | null) => {
        setStartTime(date);
        let blackoutPeriod = {
            startTime: date?.toLocaleTimeString() ?? "",
            finishTime:endTime?.toLocaleTimeString() ?? ""
        }
        prop.updateBlackoutPeriod(prop.index, blackoutPeriod)
    }

    const onChangeEndTime = (date: Date | null) => {
        setEndTime(date);
        let blackoutPeriod = {
            startTime:startTime?.toLocaleTimeString() ?? "",
            finishTime: date?.toLocaleTimeString() ?? ""
        }
        prop.updateBlackoutPeriod(prop.index, blackoutPeriod)
    }

  return (
      <div className={"form-group"}>
          <div className={"row"}>
              <div className={"col-xs-2"} style={{display: 'flex', alignItems: 'center'}}>
                  <label>From: </label>
                  <DatePicker className={"form-control"}
                              showTimeSelect
                              showTimeSelectOnly
                              timeIntervals={1}
                              dateFormat="HH:mm:ss"
                              timeFormat="HH:mm:ss"
                              minDate={new Date()}
                              maxDate={new Date()}
                              selected={startTime}
                              onChange={onChangeStartTime}
                  />
              </div>
              <div className={"col-xs-2"} style={{display: 'flex', alignItems: 'center'}}>
                  <label>To: </label>
                  <DatePicker className={"form-control"}
                              showTimeSelect
                              showTimeSelectOnly
                              timeIntervals={1}
                              dateFormat="HH:mm:ss"
                              timeFormat="HH:mm:ss"
                              minDate={new Date()}
                              maxDate={new Date()}
                              selected={endTime}
                              onChange={onChangeEndTime}
                  />
              </div>
              <div className={"form-group"}>
                  <button className={"btn btn-warning"} onClick={remove}>Remove</button>
              </div>
          </div>
      </div>
  );
}