import React from 'react';
import {Authentication} from "./models";


export interface IAuthenticationContext {
    authentication: Authentication;

    login(token: string, email: string, isApproved:boolean, hasCompany:boolean, tfaRequired:boolean);

    logout();
}

const AuthenticationContext = React.createContext<IAuthenticationContext>(
    {
        authentication: {
            authenticated: null,
            isApproved:false,
            hasCompany:false,
            tfaRequired:false
        },
        logout: () => {
        },
        login: (t, e) => {
        },
    }
);


function withAuthentication(C: any) {
    return function AuthenticationComponent(props: any) {
        return (
            <AuthenticationContext.Consumer>
                {({authentication, login, logout}) =>
                    <C {...props} authentication={authentication} login={login} logout={logout}/>}
            </AuthenticationContext.Consumer>
        );
    }
}

export {AuthenticationContext, withAuthentication}