import React, {Component} from 'react';
import {Link} from "react-router-dom";



interface Props{
    add:Function
}

interface State{
    category:string
}


class AddCategoryPanel extends Component<Props, State> {
    constructor(props:Props) {
        super(props);

        this.state = {
            category:'',
        };
    }



    render(){
        return(
            <div>
                <form onSubmit={(e)=>{  e.preventDefault();
                    if(this.state.category!==''){
                        this.props.add(this.state.category);
                        this.setState({category:''})
                    }else{
                        alert("All Field is Mandatory")
                    }}}>
                    <div className="form-group">
                        <label>Category</label>
                        <input className="form-control" value={this.state.category} placeholder={"Category Name"}
                               onChange={(event)=>this.setState({category:event.target.value})}/>
                    </div>
                    <input type="submit" className="btn btn-success" value="Add Category"/>
                    &nbsp; &nbsp;
                    <Link className={"btn btn-danger"} to={"/categories"}>Cancel</Link>
                </form>
            </div>
        )
    }
}

export {AddCategoryPanel};