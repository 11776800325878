import React from "react";
import {DisplayTime} from "../display_time";
import {CompanyUserRole, Message, Transaction} from "../models";
import InlineEditProduct from "./InlineEditProduct";
import {FormatMoney} from "../format_money";
import {IGlobalDataContext, withGlobalData} from "../contexts/global-data";
import {VendingMachineLink} from "./vending_machine_link";
import InlineProductPhoto from "../inline_product_photo";
import {getAPI, postAPI,vmcpOrderServiceApi} from "../api";
import {Link} from "react-router-dom";
import PriceDisplay from "../price_display";
import {SalesDetail} from "../sales_detail";
import {SalesVmcpOrderDetail} from "../sales_vmcp_order_detail";
import {
 V1VmcpOrder
} from "../telemetry/vending_machine/v1/typescript-axios";



interface IProps extends IGlobalDataContext {
    trans: Transaction
    isExpandTime: boolean
    isShowMachine: boolean
    isShrunk?: boolean
    handleCoilChange?(number):void

    reloadData(): void
    isShowLast4:boolean
}

const SalesTrRaw: React.SFC<IProps> = props => {
    const [state, setState] = React.useState({
        isShowInlineEditProduct: false,
    });
    const [showSalesDetail, setShowSalesDetail] = React.useState({isShowSalesDetail: false});
    const [salesMobile, setSalesMobile] = React.useState<string>("")
    const [creditCardLastFourDigits, setCreditCardLastFourDigits] = React.useState("")
    const [gatewayAccount, setGatewayAccount ] = React.useState("")
    const [invoiceIdentity, setInvoiceIdentity ] = React.useState("")
    const [invoiceUrl, setInvoiceUrl ] = React.useState("")
    const [salesDate, setSalesDate] = React.useState("")
    const [salesVendingMachineAddress, setSalesVendingMachineAddress] = React.useState("")
    const [salesVendingState, setSalesVendingState] = React.useState("")

    const [showBasketTransactionDetail, setShowBasketTransactionDetail] = React.useState({isShowBasketTransactionDetail: false})

    const [showVmcpOrderDetail, setShowVmcpOrderDetail] = React.useState(false);

    const [vmcpOrderDetail, setVmcpOrderDetail] = React.useState<V1VmcpOrder>({});

    const showInlineEdit = () => {
        setState({isShowInlineEditProduct: !state.isShowInlineEditProduct});
    };

    const closeInlineEdit = () => {
        setState({isShowInlineEditProduct: false});
    };

    let {trans, isExpandTime, isShowMachine, reloadData, isShrunk, findVendingMachine, isShowLast4} = props;
    let {product} = trans;

    const vendingMachine = findVendingMachine(trans.vending_machine_uuid);

    const onSubmitted = () => {
        setState({isShowInlineEditProduct: false});
        reloadData()
    };


    const getSalesDetail = () => {
        if (!showSalesDetail.isShowSalesDetail){
            getAPI(`/api/transactions/uuid/${trans.transactionUUID}`).then((response)=>{
                if (response.data.success){
                    if (response.data.order.account !== null){
                        setSalesMobile(response.data.order.account.mobile)
                    }
                    if (response.data.order.created_at !== null){
                        setSalesDate(new Date(response.data.order.created_at*1000).toString())
                    }
                    if (response.data.order.vending_machine !== null){
                        setSalesVendingMachineAddress(response.data.order.vending_machine.address)
                    }
                    if (response.data.order.state !== null){
                        setSalesVendingState(response.data.order.state)
                    }
                    if (response.data.order.account.last_four_digits !== null){
                        setCreditCardLastFourDigits(response.data.order.account.last_four_digits)
                    }
                    if (response.data.order.invoice_identity !== null){
                        setInvoiceIdentity(response.data.order.invoice_identity)
                    }
                    if (response.data.order.invoice_url !== null){
                        setInvoiceUrl(response.data.order.invoice_url)
                    }
                    if (response.data.order.account.gateway_account !== null){
                        setGatewayAccount(response.data.order.account.gateway_account)
                    }
                    console.log(response.data.order)
                    setShowSalesDetail({isShowSalesDetail: true})
                } else {
                    window.alert(response.data.message);
                }
            })
        } else {
            setShowSalesDetail({isShowSalesDetail: false})
        }

    }

    const getVmcpOrderDetail = () =>{
        if(!showVmcpOrderDetail){
            new vmcpOrderServiceApi().vmcpOrderServiceGetVmcpOrderDetail(trans.vmcpOrderUuid as string).then(res => {
                setVmcpOrderDetail(res.data.order as V1VmcpOrder)
                setShowVmcpOrderDetail(true)
            })
        }else{
            setShowVmcpOrderDetail(false)
        }
    }

    const toggleShowBasketTransaction = () => {
        setShowBasketTransactionDetail({isShowBasketTransactionDetail: !showBasketTransactionDetail.isShowBasketTransactionDetail})
    }

    const makeRefund = () =>{
        if (window.confirm("Are you sure to refund the transaction?") === true) {
            postAPI(`/api/transactions/refund/${trans.transactionUUID}`, {}).then((response) => {
                console.log("refund")
                console.log(response)
                if (response.data.success) {
                    props.reloadData();
                    props.pushFlashMessage(new Message("Refunded", "success"));
                } else {
                    window.alert(response.data.message);
                }
            })
        }
    }

    let basketTransactionsDetail;
    if (showBasketTransactionDetail.isShowBasketTransactionDetail){
        basketTransactionsDetail = trans.basketTransactions.map((item, index)=>{
            return <tr key={index}>
                <td>{item.product.name}</td>
                <td>{item.mdbItemNumber}</td>
                <td><FormatMoney cents={item.itemPrice}/></td>
                <td>{item.success.toString()}</td>
            </tr>
        })
    }


    if (isShrunk) {
        return (
            <React.Fragment>
                <tr>

                    <td>
                        {!trans.basket && trans.product && <div>
                            {trans.product.name} - {trans.itemNumber}
                            {isShowMachine && !trans.product.placeholder && (
                                <div>
                                    <em>{vendingMachine ?
                                        <VendingMachineLink vendingMachine={vendingMachine}/> : trans.machineName}</em>
                                </div>
                            )}
                        </div>}
                    </td>

                    <td>
                        <PriceDisplay transaction={trans} getSalesDetail={getSalesDetail} getVmcpOrderDetail={getVmcpOrderDetail}/>
                    </td>

                    <td>
                        <DisplayTime timestamp={trans.timestamp} />
                    </td>
                </tr>
                {
                    showSalesDetail.isShowSalesDetail &&
                    <SalesDetail isMobilePaymentTransaction={trans.mobilePaymentTransaction}
                                 isCreditCardTransaction={trans.creditCardTransaction}
                                 salesMobile={salesMobile}
                                 creditCardLastFourDigits={creditCardLastFourDigits}
                                 salesVendingMachineAddress={salesVendingMachineAddress}
                                 salesVendingState={salesVendingState}
                                 salesDate={salesDate}
                                 refunded={trans.refunded}
                                 makeRefund={makeRefund}
                                 invoiceIdentity={invoiceIdentity}
                                 invoiceUrl={invoiceUrl}
                                 gatewayAccount={gatewayAccount}
                                 transactionUuid={trans.transactionUUID}/>
                }



            </React.Fragment>
        )
    }


    return (
        <React.Fragment>
            <tr>
                <td><DisplayTime timestamp={trans.timestamp} expanded={isExpandTime}/></td>

                <td>
                    {product && product.placeholder && state.isShowInlineEditProduct && (
                        <div>
                            <i onClick={closeInlineEdit} className="fa fa-close fa-lg"></i>
                            <InlineEditProduct
                                onSubmitted={onSubmitted}
                                mergeSrcProductId={product.id}/>
                        </div>
                    )}
                    {!trans.basket && <div onClick={showInlineEdit}>
                        <Link to={"/vending_machines/"+trans.vending_machine_uuid+"/sales/?mdbs="+trans.mdbRaw} onClick={()=>{
                            if (props.handleCoilChange){
                                props.handleCoilChange(trans.mdbRaw)
                            }
                        }}>
                            { props.me.role !== CompanyUserRole.REPORT.valueOf() && <span>{trans.itemNumber}</span> }
                        </Link>
                        &nbsp;
                        {product && product.placeholder && !state.isShowInlineEditProduct && props.me.role !== CompanyUserRole.REPORT.valueOf() && (
                            <span> <i className="fa fa-edit"></i> &nbsp; </span>
                        )}

                        {product && !product.placeholder && <InlineProductPhoto product={trans.product}/>}
                        {product && product.name}
                    </div>}
                    {trans.basket && <span style={{cursor:"pointer"}} onClick={toggleShowBasketTransaction}><i className="fas fa-shopping-basket"></i>Basket Transaction</span>}
                </td>
                <td>
                    <PriceDisplay transaction={trans} getSalesDetail={getSalesDetail} getVmcpOrderDetail={getVmcpOrderDetail}/>
                </td>
                <td>{trans.paymentMethod}</td>
                {isShowMachine && (
                    <td>{vendingMachine ? <VendingMachineLink vendingMachine={vendingMachine}/> : null}</td>
                )}
                {isShowLast4 && <td>
                    <span>{trans.last4}</span>
                </td>}
            </tr>
            {
                showSalesDetail.isShowSalesDetail &&
                <SalesDetail isMobilePaymentTransaction={trans.mobilePaymentTransaction}
                             isCreditCardTransaction={trans.creditCardTransaction}
                             salesMobile={salesMobile}
                             creditCardLastFourDigits={creditCardLastFourDigits}
                             salesVendingMachineAddress={salesVendingMachineAddress}
                             salesVendingState={salesVendingState}
                             salesDate={salesDate}
                             refunded={trans.refunded}
                             makeRefund={makeRefund}
                             invoiceIdentity={invoiceIdentity}
                             invoiceUrl={invoiceUrl}
                             gatewayAccount={gatewayAccount}
                             transactionUuid={trans.transactionUUID}/>
            }
            {showBasketTransactionDetail.isShowBasketTransactionDetail && <tr>
                <td colSpan={7}>
                    <table className={"table table-striped"}>
                        <thead>
                        <tr>
                            <th>
                                Name
                            </th>
                            <th>
                                Mdb Item Number
                            </th>
                            <th>
                                Item Price
                            </th>
                            <th>
                                Success
                            </th>
                        </tr>
                        </thead>
                        <tbody>
                        {basketTransactionsDetail}
                        </tbody>
                    </table>
                </td>
            </tr>}

            {
                showVmcpOrderDetail && <SalesVmcpOrderDetail vmcpOrderDetail={vmcpOrderDetail}></SalesVmcpOrderDetail>
            }
        </React.Fragment>
    )
};


const SalesTr = withGlobalData(SalesTrRaw);
export default SalesTr;
