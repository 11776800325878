import React, {Component} from "react";
import {AllVendingMachines, CompanyClient} from "./models";
import {CompanyClientTableRow} from "./company_client_table_row";

interface Props {
    clients: Array<CompanyClient>
    clientsShouldReload()

    allVendingMachines: AllVendingMachines
}

interface State {
}

class CompanyClientsTable extends Component<Props, State> {
    constructor(props) {
        super(props);

        this.state = {
        };
    }

    render(): React.ReactElement | string | number | {} | React.ReactNodeArray | React.ReactPortal | boolean | null | undefined {
        const rows = this.props.clients.map((client) => {
            let allocatedVendingMachines = this.props.allVendingMachines.allVendingMachines.filter(vendingMachine => {
                return client.vmIds.includes(vendingMachine.uuid);
            })
            return (<CompanyClientTableRow key={client.uuid} client={client}
                                           clientsShouldReload={this.props.clientsShouldReload}
                                           allocatedVendingMachines={allocatedVendingMachines}
            />);
        });

        return (
            <table className={"table table-condense"}>
                <thead>
                <tr>
                    <th>Created At</th>
                    <th>Name</th>
                    <th>Admin Emails</th>
                    <th>Vending Machines</th>
                    <th>Status</th>
                    <th>Operation</th>
                </tr>
                </thead>

                <tbody>
                {rows}
                </tbody>
            </table>
        );
    }
}

export default CompanyClientsTable
