import React, {Component} from "react";
import {Refill} from "./models";
import {RefillsTable} from "./refills_table";

interface Props {
    refills: Array<Refill>
    refillsShouldReload()
}

interface State {

}

export class RefillsComponent extends Component<Props, State> {
    constructor(props) {
        super(props);

        this.state = {}
    }


    render(): React.ReactElement<any, string | React.JSXElementConstructor<any>> | string | number | {} | React.ReactNodeArray | React.ReactPortal | boolean | null | undefined {
        return (<div className={"box statsBox"}>

            <div className={"box-header"}>
                <h1>Refills</h1>
            </div>

            <div className={"box-body"}>
                <RefillsTable refills={this.props.refills} refillsShouldReload={this.props.refillsShouldReload}/>
            </div>
        </div>);
    }
}