import React, {Component} from "react";
import {Link} from "react-router-dom";
import {CompanyUserRole, VendingMachine} from "../models";
import {IGlobalDataContext,withGlobalData} from "../contexts/global-data";

interface Props extends IGlobalDataContext {
    vendingMachine: VendingMachine

    // if this should point to setting or normal view
    setting?: boolean
}

interface State {
}

class _VendingMachineLink extends Component<Props, State> {
    render() {

        const onlineIndicator = this.props.vendingMachine.online ?
            (<span title="online"
                   className="online-indicator online"></span>)
            :
            (<span title="offline"
                   className="online-indicator offline"></span>);

        const post = this.props.setting ? '/sales' : '';
        return (
            <span>
                <span>
                    {onlineIndicator}
                    {this.props.me?.role === CompanyUserRole.REPORT.valueOf() ?
                    <Link data-toggle="tooltip" data-placement="top" title={this.props.vendingMachine.name}
                          to={`/vending_machines/${this.props.vendingMachine.uuid}/sales`}>{this.props.vendingMachine.name} </Link>
                    :
                    <>
                        <Link data-toggle="tooltip" data-placement="top" title={this.props.vendingMachine.name}
                              to={`/vending_machines/${this.props.vendingMachine.uuid}${post}`}>{this.props.vendingMachine.name} </Link>
                        &nbsp;
                        <Link data-toggle="tooltip" data-placement="top"
                              to={`/vending_machines/${this.props.vendingMachine.uuid}/notification`}>
                            {(this.props.vendingMachine.activeNotificationCount && (this.props.vendingMachine.activeNotificationCount > 0)) ?
                                <span
                                    title={`${this.props.vendingMachine.activeNotificationCount} notifications for this vending machine`}
                                    className="badge bg-yellow">{this.props.vendingMachine.activeNotificationCount}</span> : null}
                        </Link>
                    </>
                    }
                </span>
            </span>
        );
    }
}

export const VendingMachineLink = withGlobalData(_VendingMachineLink);