import {IAuthenticationContext, withAuthentication} from "./authentication";
import React, {Component} from "react";
import VersionInfo from "./version_info";
import {postAPI} from "./api";
import {Redirect} from "react-router-dom";

interface Props extends IAuthenticationContext {
}

interface State {
    randomCode:string
    error_message:string | null
}

class _VerifyTfa extends Component<Props, State> {
    constructor(props) {
        super(props);

        this.state = {
            randomCode:"",
            error_message:null
        };
    }

    handleRandomCodeChange(e){
        this.setState({randomCode:e.target.value})
    }

    verifyTfn(e){
        e.preventDefault();
        e.stopPropagation();
        postAPI(`/api/users/verify_tfa`, {"randomCode" : this.state.randomCode}).then((response)=>{
            if (response.data.success){
                const _token = response.data.securityToken
                const _email = response.data.email
                const _isApproved = response.data.isApproved
                const _hasCompany = response.data.hasCompany
                const _tfaRequired = response.data.tfaRequired
                this.setState({error_message:response.data.reason})
                this.props.login(_token, _email, _isApproved, _hasCompany, _tfaRequired);
            } else {
                this.props.logout();
            }
        })
    }

    render() {
        let error_message = this.state.error_message ?
            (<div className="alert alert-danger">{this.state.error_message}</div>)
            : null;
        if (!this.props.authentication.tfaRequired){
            return (<Redirect to={"/"}/>);
        } else {
            return <div className="login-box">

                <div className="login-logo">
                    Vending on Track
                </div>
                <div className="login-box-body">
                    <p className="login-box-msg">Two Factors Authentication</p>
                    {error_message}

                    <form onSubmit={this.verifyTfn.bind(this)}>

                        <div className="form-group has-feedback">
                            <input type="text" value={this.state.randomCode}
                                   onChange={this.handleRandomCodeChange.bind(this)} className="form-control" placeholder="TFA Code"/>
                        </div>
                        <div className="row">
                            <div className="col-xs-2">
                            </div>
                            <div className="col-xs-8">
                                <input type="submit" className="btn btn-primary btn-block btn-flat" value="Verify"/>
                            </div>
                        </div>
                        <br/>
                        <br/>
                        <div className="row">
                            <div className="col-xs-12">
                                <button className="btn btn-block btn-link" style={{textAlign: "end"}} onClick={()=>{this.props.logout()}}>Logout</button>
                            </div>
                        </div>

                        <br/>

                        <div style={{color: "white"}}>
                            <VersionInfo/>
                        </div>
                    </form>
                </div>
            </div>
        }
    }
}

const VerifyTfa = withAuthentication(_VerifyTfa);

export {VerifyTfa};