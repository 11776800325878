import React, {useEffect, useState} from "react";
import {DirectionsRenderer} from "@react-google-maps/api";

interface Props {
    between: {
        destination: google.maps.LatLngLiteral
        origin: google.maps.LatLngLiteral
    }

    updateDistance(meters, minutes): void
}


const cache = {};

function getRouting(route, cb) {
    const key = JSON.stringify(route);

    if (cache[key]) {
        cb(cache[key]);
    } else {

        const directionService = new google.maps.DirectionsService();
        directionService.route({travelMode: google.maps.TravelMode.DRIVING, ...route}, result => {
            cache[key] = result;
            cb(cache[key]);
            console.log(result);
        });
    }
}

function Route(props: Props) {
    const [routing, setRouting] = useState<any>(null);

    function route() {
        getRouting(props.between, (result: google.maps.DirectionsResult) => {
            setRouting(result);
            if (result && result.routes) {
                if (result.routes.length > 0) {
                    if (result.routes[0].legs.length > 0) {
                        const leg = result.routes[0].legs[0];
                        props.updateDistance(leg.distance.value, leg.duration.value);
                    }
                }
            }
        })
    }

    useEffect(route, [props.between, props]);


    return (<>
        {routing  &&

            <DirectionsRenderer
                options={{
                    directions: routing,
                    markerOptions: {
                        visible: false,
                        position: props.between.origin
                    }
                }}/>
        }
        </>);
}

export default Route;
