import React from 'react';
import {CreditCardType, Transaction} from "./models";
import masterCardLogo from "./assets/masterCardLogo.png";
import amexLogo from "./assets/amexLogo.png";
import visaLogo from "./assets/visaLogo.png";
import discoverLogo from "./assets/discoverLogo.png";

interface Props {
    transaction:Transaction
}

export default function CreditCardBadge(props: Props) {
    if (!props.transaction.brand) {
        return null
    }

    if (props.transaction.brand.toUpperCase() === CreditCardType.AMEX) {
        return <img className={"hover-cursor-pointer"} src={amexLogo} alt="VendCoinLogo" width= '30'/>
    }

    if (props.transaction.brand.toUpperCase() === CreditCardType.VISA) {
        return <img className={"hover-cursor-pointer"} src={visaLogo} alt="VendCoinLogo" width= '30'/>
    }

    if (props.transaction.brand.toUpperCase() === CreditCardType.MASTERCARD) {
        return <img className={"hover-cursor-pointer"} src={masterCardLogo} alt="VendCoinLogo" width= '30'/>
    }

    if (props.transaction.brand.toUpperCase() === CreditCardType.DISCOVER) {
        return <img className={"hover-cursor-pointer"} src={discoverLogo} alt="VendCoinLogo" width= '30'/>
    }
    
    return null
}
