import React, {Component} from "react";
import {VendingMachine} from "./models";
import {postAPI} from "./api";

interface Props {
    vendingMachine: VendingMachine
    new_name: string

    onFinished()
}

interface State {
    name: string
}

export class VendingMachineNameEditing extends Component<Props, State> {
    constructor(props) {
        super(props);

        this.state = {
            name: this.props.new_name
        }
    }

    handleUpdate(event) {
        event.preventDefault();
        this.setState({name: event.target.value});
        return false;
    }

    handleSave(e) {
        e.preventDefault();

        postAPI(`/api/vending_machines/${this.props.vendingMachine.uuid}/name`, {name: this.state.name})
            .then((response) => {
                if (response.data.success) {
                    this.props.onFinished();
                } else {
                    window.alert(response.data.message);
                }
            });

        return false;
    }

    render() {
        return (<div>
            <label>Change Name</label>
            <input onChange={this.handleUpdate.bind(this)} className={"form-control"} value={this.state.name}/>
            <input onClick={this.handleSave.bind(this)} type={"submit"} className={"btn btn-success"}
                   value={"Update name"}/>
        </div>);

    }
}