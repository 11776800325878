import React, {Component} from "react";
import {RefillsTable} from "./refills_table";
import {Refill} from "./models";
import {getAPI} from "./api";
import ReactPaginate from "react-paginate";
import {Helmet} from "react-helmet";

interface Props {

}

interface State {
    refills: Array<Refill>
    page: number
    totalPages: number
}

export class RefillsPage extends Component<Props, State> {
    constructor(props) {
        super(props);

        this.state = {
            refills: [],
            page: 0,
            totalPages: 0
        };

        this.init(0);
    }


    reloadRefills() {
        this.init(this.state.page);
    }

    render() {
        // @ts-ignore
        // @ts-ignore
        return (
            <div>
                <Helmet titleTemplate="%s - Vending on Track">
                    <title>Refills</title>
                </Helmet>
                <section className="content">
                    <div className={"box statsBox"} >

                        <div className={"box-header"}>
                            <h1>
                                Refills
                            </h1>
                        </div>

                        <div className={"box-body"}>
                            <ReactPaginate
                                pageRangeDisplayed={2}
                                marginPagesDisplayed={2}
                                pageCount={this.state.totalPages}
                                initialPage={0}
                                onPageChange={this.handlePageClick.bind(this)}
                                containerClassName={"pagination pagination-sm no-margin"}
                                activeClassName={'active'}

                            />

                            <RefillsTable refills={this.state.refills}
                                          refillsShouldReload={this.reloadRefills.bind(this)}/>

                        </div>
                    </div>
                </section>
            </div>
        );
    }

    handlePageClick(selectedItem: { selected: number }): void {
        console.log("Selected: " + selectedItem.selected);
        this.setState({
            page: selectedItem.selected
        });

        this.init(selectedItem.selected);
    }

    private init(page: number) {
        getAPI(`/api/refills?page=${page}`).then((response) => {
            this.setState({
                totalPages: response.data.refills.totalPages,
                refills: response.data.refills.content
            })
        })
    }
}