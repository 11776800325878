import React, { Component } from "react";
import { CompanyUserRole, Message, VendingMachine } from "./models";
import { postAPI } from "./api";
import { IGlobalDataContext, withGlobalData } from "./contexts/global-data";

interface Props extends IGlobalDataContext {
    vendingMachine: VendingMachine
}

interface State {
    dex_now_wip: boolean
    upload_dex_wip: boolean
    refill_wip: boolean
}

class TelemetryDeviceButtonsRaw extends Component<Props, State> {
    constructor(props) {
        super(props);

        this.state = {
            dex_now_wip: false,
            upload_dex_wip: false,
            refill_wip: false
        }
    }

    dex_now(e) {
        this.setState({ dex_now_wip: true });
        postAPI(`/api/buttons/dex_now`, {
            vendingMachineUuid: this.props.vendingMachine.uuid
        }).then((response) => {
            if (response.data.success) {
            } else {
                alert(response.data.message);
            }

            this.setState({ dex_now_wip: false });
        });

        e.preventDefault();
        return false;
    }

    upload_dex(e) {
        this.setState({ upload_dex_wip: true });
        postAPI(`/api/buttons/upload_dex`, {
            vendingMachineUuid: this.props.vendingMachine.uuid
        }).then((response) => {
            if (response.data.success) {
            } else {
                alert(response.data.message);
            }

            this.setState({ upload_dex_wip: false });
        });

        e.preventDefault();
        return false;
    }

    refill_wip(e) {
        this.setState({ refill_wip: true });
        postAPI(`/api/buttons/refill`, {
            vendingMachineUuid: this.props.vendingMachine.uuid
        }).then((response) => {
            if (response.data.success) {
                this.props.pushFlashMessage(new Message("Refill done!", "success"));
            } else {
                alert(response.data.message);
            }

            this.setState({ refill_wip: false });
        });

        e.preventDefault();
        return false;
    }

    render() {

        if (this.props.me.role === CompanyUserRole.DRIVER.valueOf()) {
            return (
                <div>
                    <div className="row metro-buttons">
                        <div className={"col-xs-12"}>
                            <h1>Speed buttons</h1>
                        </div>

                        <div className={"col-sm-3"}>

                            <button onClick={this.refill_wip.bind(this)}
                                className={"btn  btn-danger metro-button btn-block"}>
                                Refill Done!
                                {
                                    this.state.refill_wip ? (
                                        <span>&nbsp;<span className={"fa fa-spinner fa-spin"}> </span></span>) : null
                                }
                            </button>
                        </div>
                    </div>
                    <hr />
                    {this.props.vendingMachine && (
                        <a href={`/vending_machines/${this.props.vendingMachine.uuid}`}>back to vending machine</a>)}
                </div>
            );
        }
        return (
            <div>
                <div className="row metro-buttons">
                    <div className={"col-xs-12"}>
                        <h1>Speed buttons</h1>
                    </div>

                    <div className={"col-sm-3"}>
                        <button onClick={this.dex_now.bind(this)} className={"btn  btn-success metro-button btn-block"}>
                            Force DEX Now
                            {
                                this.state.dex_now_wip ? (
                                    <span>&nbsp;<span className={"fa fa-spinner fa-spin"}> </span></span>) : null
                            }
                        </button>
                    </div>

                    <div className={"col-sm-3"}>

                        <button onClick={this.upload_dex.bind(this)} className={"btn btn-info metro-button btn-block"}>
                            Force upload current DEX
                            {
                                this.state.upload_dex_wip ? (
                                    <span>&nbsp;<span className={"fa fa-spinner fa-spin"}> </span></span>) : null
                            }
                        </button>
                    </div>
                    <div className={"col-sm-3"}>

                        <button onClick={this.refill_wip.bind(this)}
                            className={"btn  btn-danger metro-button btn-block"}>
                            Refill Done!
                            {
                                this.state.refill_wip ? (
                                    <span>&nbsp;<span className={"fa fa-spinner fa-spin"}> </span></span>) : null
                            }
                        </button>
                    </div>

                    <div className={"col-sm-3"}>
                        <div>
                            <a href={`/vending_machines/${this.props.vendingMachine.uuid}/remote_vend`}
                                className={"btn btn-success metro-button btn-block"}>
                                Remote vend
                            </a>
                        </div>
                    </div>
                </div>
                <hr />
                {this.props.vendingMachine && (
                    <a href={`/vending_machines/${this.props.vendingMachine.uuid}`}>back to vending machine</a>)}
            </div>
        );
    }
}

export const TelemetryDeviceButtons = withGlobalData(TelemetryDeviceButtonsRaw);
