
// properly present money as string to be used in csv and chart
export function formatMoneyWithSymbol(cents: number|null, decimalPoints: number, currentSymbol:string): string {
    if (cents == null) {
        return '-';
    }

    return `${currentSymbol}${((cents) / Math.pow(10, decimalPoints)).toFixed(decimalPoints)}`;
}


// used in product map
export function formatMoneyWithoutSymbol(cents: number, decimalPoints: number): string {
    return `${((cents) / Math.pow(10, decimalPoints)).toFixed(decimalPoints)}`;
}


export function formatCents(dollar: string, decimalPoints: number): number {
    return parseInt((parseFloat(dollar) * Math.pow(10, decimalPoints)).toFixed(0));
}