import {Product} from "./models";
import React, {Component, MouseEvent} from "react";
import {Link} from "react-router-dom";
import {Highlighter, Typeahead} from "react-bootstrap-typeahead";

interface Props {
    ingredient_list: Array<Product>;

    hide_dialog(): void;

    onSubmit(e: any, ingredientMapping): void;
}

interface State {
    capacity: number;
    restockCapacity: number;

    selectedIngredient: Product | null
}

class AddIngredientMappingDialog extends Component<Props, State> {
    constructor(props: Props) {
        super(props);

        this.state = {
            capacity: 0,
            restockCapacity: 0,
            selectedIngredient: null,
        }
    }

    handleCapacityChange(e){
        e.preventDefault();

        this.setState({
            capacity: e.target.value
        });
    }

    handleRestockCapacityChange(e){
        e.preventDefault();

        this.setState({
            restockCapacity: e.target.value
        });
    }

    handleIngredientSelection(ingredients: Array<Product>): void {
        if (ingredients.length > 0) {
            this.setState({
                selectedIngredient: ingredients[0]
            });
        }
    }

    onSubmit(event): void {
        if (this.state.selectedIngredient) {
            let ingredientMapping = {
                ingredientId:this.state.selectedIngredient.id,
                capacity:this.state.capacity,
                restockCapacity:this.state.restockCapacity,
                currentLevel: 0
            };

            this.props.onSubmit(event, ingredientMapping);
        } else {
            // nothing, since product_id is not set.
        }
    }

    handleCancel(event: MouseEvent<HTMLButtonElement>): void {
        event.preventDefault();

        this.props.hide_dialog();
    }

    render() {
        const renderMenuItemChildren = (option: Product, props, index) => {
            return [
                <Highlighter key={"name"} search={props.text}>{option.name}</Highlighter>
            ];
        };


        return <form action="" onSubmit={this.onSubmit.bind(this)}>
            <div className="form-group">
                <label>Capacity</label>
                <span
                    className="label-explain">&nbsp; Capacity is the absolute maximum capacity for the coil.</span>

                <input onChange={this.handleCapacityChange.bind(this)} className="form-control" type="text"
                       value={this.state.capacity}/>
            </div>

            <div className="form-group">
                <label>Restock Capacity</label>

                <span className="label-explain">&nbsp; Restock Capacity is the target restock count for that given time. In certain circumstances, capacity can be larger than restock capacity.</span>

                <input onChange={this.handleRestockCapacityChange.bind(this)} className="form-control" type="text"
                       value={this.state.restockCapacity}/>
            </div>

            <div className="form-group">
                <label>Ingredient</label>

                <span className="label-explain">&nbsp; The product for this coil. If it's a new product, please follow <Link
                    to={"/products/add"}>this link</Link> to finish product setup first and then comeback. </span>

                <Typeahead id={"typeahead"}
                           renderMenuItemChildren={renderMenuItemChildren}
                           selected={this.state.selectedIngredient ? [this.state.selectedIngredient] : []} labelKey="name"
                           onChange={this.handleIngredientSelection.bind(this)} options={this.props.ingredient_list}/>
            </div>

            <div className="form-group">
                <input type="submit" className="btn btn-success"/>
                <button onClick={this.handleCancel.bind(this)} className="btn btn-default">Cancel</button>
            </div>
        </form>
    }
}

export {AddIngredientMappingDialog};
