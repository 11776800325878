import React, {Component, Fragment} from 'react';
import {V1VmcpOrder} from "./telemetry/vending_machine/v1/typescript-axios";
import {Link} from "react-router-dom";
import moment from "moment";
import { DisplayTime } from './display_time';

interface Props {
    vmcpOrderDetail:V1VmcpOrder
}

class SalesVmcpOrderDetail extends Component<Props> {
    // eslint-disable-next-line
    constructor(props: Props) {
        super(props);
    }
    render() {
        return <Fragment>
            <tr>
                <td colSpan={7}>
                    <table className={"table table-striped"}>
                        <tbody>
                            <tr>
                                <td>State:</td>
                                <td>{this.props.vmcpOrderDetail.state}</td>
                            </tr>
                            <tr>
                                <td>PaymentGateway:</td>
                                <td>{this.props.vmcpOrderDetail.paymentGateway}</td>
                            </tr>
                            <tr>
                                <td>QrcodeId:</td>
                                <td>{this.props.vmcpOrderDetail.qrcodeId}</td>
                            </tr>
                            <tr>
                                <td>QrpayId:</td>
                                <td>{this.props.vmcpOrderDetail.qrpayId}</td>
                            </tr>
                            <tr>
                                <td>PaidAt:</td>
                                <td><DisplayTime timestamp={moment(this.props.vmcpOrderDetail.paidAt).unix()} /></td>
                            </tr>
                            <tr>
                                <td>RefundedAt:</td>
                                <td><DisplayTime timestamp={moment(this.props.vmcpOrderDetail.refundedAt).unix()} /></td>
                            </tr>
                            <tr>
                                <td>ReceiptId:</td>
                                <td>
                                    <Link to={'/searchorder?receiptId='+this.props.vmcpOrderDetail.receiptId}>
                                        {this.props.vmcpOrderDetail.receiptId}
                                    </Link>
                                </td>
                            </tr>
                        </tbody>
                    </table>
                </td>
            </tr>
        </Fragment>;
    }
}

export {SalesVmcpOrderDetail}
