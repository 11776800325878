import React, {Component} from "react";
import {Refill, User} from "./models";
import {RefillRow} from "./refill_row";
import {IGlobalDataContext, withGlobalData} from "./contexts/global-data";
import {getAPI} from "./api";

interface Props extends IGlobalDataContext {
    refills: Array<Refill>
    refillsShouldReload()
}

interface State {
    users:Array<User>
}

class _RefillsTable extends Component<Props, State> {
    constructor(props) {
        super(props);

        this.state = {
            users : []
        };
    }


    componentDidMount() {
        this.reloadAllUser();
    }

    reloadAllUser(){
        getAPI("/api/companies/users").then((response) => {
            this.setState({
                users:response.data.users
            })
        })
    }

    render(): React.ReactElement<any, string | React.JSXElementConstructor<any>> | string | number | {} | React.ReactNodeArray | React.ReactPortal | boolean | null | undefined {
        const rows = this.props.refills.map((refill) => {
            return (<RefillRow vendingMachines={this.props.allVendingMachines} key={refill.uuid} refill={refill}
                               refillShouldReload={this.props.refillsShouldReload} users={this.state.users}/>);
        });

        return (
            <table className={"table table-condense"}>
                <thead>
                <tr>
                    <th>Time</th>
                    <th>Item Count</th>
                    <th>Coins</th>
                    <th>Notes</th>
                    <th>Vending Machine</th>
                    <th>Refilled By</th>
                    <th>Operation</th>
                </tr>
                </thead>

                <tbody>
                {rows}
                </tbody>
            </table>
        );
    }
}

export const RefillsTable = withGlobalData(_RefillsTable);
