import React, {Component} from 'react';
import {getAPI, postAPI} from "./api";

interface Props {
    isTfaEnabled:boolean | undefined
    load()
}

interface State {
    secretKey:string | null,
    qrCodeContent:string | null
    randomCode:string
}

export class TfaSetupForm  extends Component<Props, State> {
    QRCode = require('qrcode.react');
    constructor(props) {
        super(props);

        this.state = {
            secretKey:null,
            qrCodeContent:null,
            randomCode:""
        }
    }

    componentDidMount() {
        this.getTfaSetupData();
    }

    getTfaSetupData(){
        getAPI(`/api/users/setup_tfa`).then((response)=>{
            if (response.data.success){
                this.setState({
                    secretKey:response.data.tfaSetupCode.secretKey,
                    qrCodeContent:response.data.tfaSetupCode.qrCodeContent
                })
            }
        })
    }

    setupTfa(e){
        e.preventDefault()
        e.stopPropagation()
        postAPI(`/api/users/setup_tfa`, {
            secretKey:this.state.secretKey,
            randomCode:this.state.randomCode
        }).then((response)=>{
            if (response.data.success){
                this.props.load()
            } else {
                window.alert(response.data.message)
            }
        })
    }

    onRandomCodeChange(e){
        e.preventDefault()
        e.stopPropagation()
        this.setState({
            randomCode:e.target.value
        })
    }

    render() {
        console.log(this.props.isTfaEnabled)
        return <div className="box box-info">
            <div className="box-header">
                <h3 className="box-title">Setup Two Factors Authentication</h3>
            </div>
            {!this.props.isTfaEnabled && <div className="box-body">
                <p>
                    Set up Google Authenticator
                </p>
                <p>Set up your two factor authentication by scanning the QR code below. Alternatively, you can enter the following code manually</p>
                {this.state.secretKey && <p><b>{this.state.secretKey}</b></p>}
                {this.state.qrCodeContent && <this.QRCode value={this.state.qrCodeContent} />}
                <form onSubmit={this.setupTfa.bind(this)}>
                    <div className="form-group">
                        <label>Please enter the random code that generates from Google Authenticator: </label>
                        <input className="form-control" onChange={this.onRandomCodeChange.bind(this)}/>
                    </div>
                    <input type="submit" className="btn btn-success" value="Setup"/>
                </form>
            </div>}
            {this.props.isTfaEnabled && <div className="box-body">
                <p>Two Factors Authentication is enabled</p>
            </div>}
        </div>
    }
}