import {withAuthentication} from "./authentication";
import React, {Component} from 'react';

import {VendingMachinesTable} from './vending_machines_table';
import {IGlobalDataContext, withGlobalData} from "./contexts/global-data";
import {Helmet} from "react-helmet";

interface Props extends IGlobalDataContext {
}

interface State {
    loading: boolean
    message: string | null
}

class _VendingMachines extends Component<Props, State> {
    constructor(props) {
        super(props);

        this.state = {
            loading: false,
            message: null,
        };

        this.props.reloadAllVendingMachines(); // for online status
    }


    render() {
        const machines = {
            vendingMachines: this.props.allVendingMachines.allVendingMachines,
            loading: this.props.allVendingMachines.initialLoading,
            failedToLoad: this.props.allVendingMachines.failedToLoad,
            errorMessage: this.props.allVendingMachines.errorMessage,
        }
        return (
            <section className="content">
                <Helmet titleTemplate="%s - Vending on Track">
                    <title>Vending Machines</title>
                </Helmet>
                <h1>Vending Machines</h1>

                {this.state.message ?
                    <div className="callout callout-danger">
                        <h4>There was an error when we tried to load your vending machines.</h4>

                        <p>{this.state.message}</p>
                    </div>
                    :
                    (this.state.loading || this.props.allVendingMachines.initialLoading ?
                        <div className="callout callout-info">
                            <h4>Loading vending machines...</h4>

                            <p>We are listing all your vending machines. It won't be long.</p>
                        </div>
                        :
                        <VendingMachinesTable
                            withFilter={true}
                            vending_machines={machines}
                            isLinkedToSettings={true}/>)
                }
            </section>
        );
    }
}

export const VendingMachines = withGlobalData(withAuthentication(_VendingMachines));
