import React, {Component} from 'react';
import {ProductMapping, VendingMachine} from "./models";
import {IGlobalDataContext, withGlobalData} from "./contexts/global-data";
import GridLayout from 'react-grid-layout';
import {getAPI, postAPI} from "./api";
import InlineProductPhoto from "./inline_product_photo";
import {FormatMoney} from "./format_money";


interface Props extends IGlobalDataContext {
    vendingMachine: VendingMachine;
}

interface State {
    coils: Array<ProductMapping>
    loading: boolean
}

interface Layout {
    x,
    y,
    w,
    h: number

    moved: boolean

    i: string // product mapping id
}

class RawVendingMachinePlanogram extends Component<Props, State> {
    private layout: Array<Layout> = [];

    constructor(props) {
        super(props);

        this.state = {
            coils: [],
            loading: false
        };

        this.loadCoils();
    }

    onLayoutChange(layout) {
        this.layout = layout;
        console.log(layout);
    }

    onResetPlanogram(e) {
        e.preventDefault();
        e.stopPropagation();

        if (!window.confirm("This will reset plangram for this vending machine. Are you sure?")) {
            return;
        }

        postAPI(`/api/vending_machines/${this.props.vendingMachine.uuid}/planogram/reset`, {}).then((response) => {
            console.log(response);

            this.loadCoils();

        });

        return false;
    }

    loadCoils() {
        this.setState({loading: true});
        getAPI(`/api/vending_machines/${this.props.vendingMachine.uuid}/product_mappings`).then((response) => {
            console.log("Response is: ");
            console.log(response);
            this.setState({loading: false});
            if (response.data.success) {
                this.setState({coils: response.data.mapping});
            } else {
                window.alert(response.data.message);
            }
        });
    }

    onSavePlanogram(e) {
        e.preventDefault();
        e.stopPropagation();

        const layout = this.layout;

        postAPI(`/api/vending_machines/${this.props.vendingMachine.uuid}/planogram`, {items: layout}).then((resp) => {
            if (resp.data.success) {
                window.alert('all good');
            } else {
                window.alert('failed');
            }
        })
    }

    render() {
        const isWarning = function (productMapping: ProductMapping): boolean {
            if (productMapping.currentStock) {
                if (productMapping.currentStock <= (productMapping.restock * .3)) {
                    return true;
                } else {
                    return false;
                }
            }

            return false;
        };
        const items = this.state.coils.map((productMapping) => {
            const product = this.props.findProduct(productMapping.product_id);
            return (
                <div className="planogram-item" key={productMapping.id} data-grid={{
                    x: productMapping.position,
                    y: productMapping.tray,
                    w: productMapping.width,
                    h: productMapping.height,
                    maxH: 1
                }}>
                    <div title={product ? product.name : ''} className="item-name">
                        {product ? <InlineProductPhoto hover={false} product={product}/> : null}
                        {product?.name}
                    </div>
                    <div className="attributes">
                        <span className={"text-" + (isWarning(productMapping) ? "danger quantity-warning" : "--")}>
                        Stock: {productMapping.currentStock}/{productMapping.restock}/{productMapping.capacity}
                        </span>
                        <br/>
                        <FormatMoney cents={productMapping.price}/>
                        <br/>
                        <span title={"DEX"}>{productMapping.code}</span> <span
                        title={"MDB"}>[{productMapping.mdb_code}]</span>
                    </div>
                </div>
            );
        });
//            {i: 'c', x: 4, y: 0, w: 1, h: 1, maxH: 1}
        return (
            <div className="statsBox">
                <section className="content-header">
                    <h2>Planogram</h2>
                    <p>Planogram is a visual representation method to show the product mapping within a vending machine.
                        It does <b>not</b> have anything to do with functionality.</p>
                </section>
                <div className="well">
                    <button
                        onClick={this.onSavePlanogram.bind(this)}
                        className={"btn btn-success"}>Save
                    </button>
                    &nbsp; &nbsp;
                    <button onClick={this.onResetPlanogram.bind(this)}
                            className={"btn btn-danger"}>Reset planogram
                    </button>
                </div>
                {this.state.loading ? <span>Loading...</span> :
                    <GridLayout preventCollision={true} compactType={"horizontal"}
                                onLayoutChange={this.onLayoutChange.bind(this)} className="layout planogram" cols={12}
                                rowHeight={120} width={1200}>
                        {items}
                    </GridLayout>
                }
            </div>
        );
    }
}


const VendingMachinePlanogram = withGlobalData(RawVendingMachinePlanogram);
export default VendingMachinePlanogram;

