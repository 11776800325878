import React, {Component} from 'react';
import {CreditCardSurchargeGroup, Message, VendingMachine} from "./models";
import {getAPI, postAPI} from "./api";
import {IGlobalDataContext, withGlobalData} from "./contexts/global-data";
import {Link} from "react-router-dom";

interface Props extends IGlobalDataContext{
    vendingMachine: VendingMachine;
}

interface State {
    isCreditCardSurchargePayableByConsumer: boolean,
    ruleGroups:CreditCardSurchargeGroup[],
    selectedGroupUuid: string | null
}

class _VendingMachineCreditCardPanel extends Component<Props, State> {
    constructor(props: Props) {
        super(props);

        this.state = {
            isCreditCardSurchargePayableByConsumer: true,
            ruleGroups : [],
            selectedGroupUuid:null
        }
    }


    componentDidMount() {
        this.loadCreditCardRule();
    }

    loadCreditCardRule(){
        getAPI("/api/credit_card_surcharge_group").then((response) => {
            if (response.data.success) {
                this.setState({ruleGroups: response.data.creditCardSurchargeGroups})
            }
        })
        // getAPI(`/api/vending_machines/${this.props.vendingMachine.uuid}/credit_card_surcharge_payable_by_consumer`).then((response) => {
        //     if (response.data.success) {
        //         this.setState({isCreditCardSurchargePayableByConsumer: response.data.creditCardSurchargePayableByConsumer})
        //     }
        // })
        //
        // getAPI(`/api/vending_machines/${this.props.vendingMachine.uuid}/selected_credit_card_surcharge_group`).then((response) => {
        //     if (response.data.success) {
        //         this.setState({selectedGroupUuid: response.data.creditCardSurchargeGroup ? response.data.creditCardSurchargeGroup.uuid : null})
        //     }
        // })

        getAPI(`/api/vending_machines/${this.props.vendingMachine.uuid}/credit_card_setting`).then((response) => {
            if (response.data.success) {
                this.setState({
                    selectedGroupUuid: response.data.vendingMachineCreditCardSetting.creditCardSurchargeGroup ? response.data.vendingMachineCreditCardSetting.creditCardSurchargeGroup.uuid : null,
                    isCreditCardSurchargePayableByConsumer: response.data.vendingMachineCreditCardSetting.creditCardSurchargePayableByConsumer
                })
            }
        })
    }

    handleSaveSelectedCreditSurchargeGroup(e) {
        e.preventDefault();
        if (window.confirm("Are you sure to change the credit card surcharge group setting?")) {
            postAPI(`/api/vending_machines/${this.props.vendingMachine.uuid}/selected_credit_card_surcharge_group`,
                {"uuid": this.state.selectedGroupUuid}).then((response) => {
                if (response.data.success) {
                    this.loadCreditCardRule()
                    this.props.pushFlashMessage(new Message("Saved", "success"))
                } else {
                    alert(response.data.message)
                }
            })
        }
    }

    selectSurchargeGroup(e) {
        e.preventDefault()
        if (e.target.value === "") {
            this.setState({selectedGroupUuid: null})
        } else {
            this.setState({selectedGroupUuid: e.target.value})
        }
    }

    handleCreditCardPayableByConsumer(e) {
        e.preventDefault()
        if (e.target.value === "true") {
            this.setState({isCreditCardSurchargePayableByConsumer: true}, () => {
                console.log(this.state.isCreditCardSurchargePayableByConsumer)
            })
        } else {
            this.setState({isCreditCardSurchargePayableByConsumer: false}, () => {
                console.log(this.state.isCreditCardSurchargePayableByConsumer)
            })
        }
    }

    handleSaveIsCreditCardSurchargePayableByConsumer(e) {
        e.preventDefault();
        if (window.confirm("Are you sure to change the credit card surcharge payable setting?")) {
            postAPI(`/api/vending_machines/${this.props.vendingMachine.uuid}/credit_card_surcharge_payable_by_consumer`,
                {"creditCardSurchargePayableByConsumer": this.state.isCreditCardSurchargePayableByConsumer}).then((response) => {
                if (response.data.success) {
                    this.loadCreditCardRule()
                    this.props.pushFlashMessage(new Message("Saved", "success"))
                } else {
                    alert(response.data.message)
                }
            })
        }
    }

    render() {
        const options = this.state.ruleGroups.map((group)=>{
            return <option key={group.uuid} value={group.uuid}>{group.name}</option>
        })
        return <div className="statsBox">

            <section className="content-header">
                <h2>Credit Card</h2>
                <p>Vending Machine level credit card surcharge setting</p>
            </section>

            <section className="content">
                <div className={"row"}>
                    <div className={"col-xs-12 col-sm-4"}>
                        <div className={"well"}>
                            <form action="">
                                <div className={"form-group"}>
                                    <label>Selected Credit Surcharge Group:</label>
                                    <select className={"form-control"}
                                            value={this.state.selectedGroupUuid ? this.state.selectedGroupUuid : ""}
                                            onChange={this.selectSurchargeGroup.bind(this)}>
                                        <option value={""}>Same as Company Level Setting</option>
                                        {options}
                                    </select>
                                </div>
                                <div>
                                    <button className="btn btn-success"
                                            onClick={this.handleSaveSelectedCreditSurchargeGroup.bind(this)}> Save
                                    </button>
                                    &nbsp;&nbsp;&nbsp;&nbsp;
                                    <Link className="btn btn-primary" to={`/company_credit_card_surcharge_rules`}>Add Surcharge Rule</Link>
                                </div>
                            </form>
                        </div>

                        <div className={"well"}>
                            <form action="">
                                <div className={"form-group"}>
                                    <label>Credit Card Surcharge Payable By Consumer:</label>
                                    <select className={"form-control"}
                                            value={this.state.isCreditCardSurchargePayableByConsumer ? "true" : "false"}
                                            onChange={this.handleCreditCardPayableByConsumer.bind(this)}>
                                        <option value="true">Yes</option>
                                        <option value="false">No</option>
                                    </select>
                                </div>
                                <div>
                                    <button className="btn btn-success"
                                            onClick={this.handleSaveIsCreditCardSurchargePayableByConsumer.bind(this)}> Save
                                    </button>
                                </div>
                            </form>
                        </div>
                    </div>
                </div>
            </section>
        </div>
    }
}

const VendingMachineCreditCardPanel = withGlobalData(_VendingMachineCreditCardPanel);

export default VendingMachineCreditCardPanel;