import React, {ChangeEvent, Component, FormEvent, MouseEvent} from "react";
import {Product, ProductMapping} from "./models";
import {Highlighter, Typeahead} from 'react-bootstrap-typeahead';
import {Link} from "react-router-dom";
import InlineProductPhoto from "./inline_product_photo";


interface AddProductMappingDialogProps {
    product_list: Array<Product>;

    hide_dialog(): void;

    onSubmit(e: any, ProductMapping): void;
}

interface State {
    mdb_code: string;
    code: string;
    price: string;
    original_price: string;
    capacity: number;
    restock: number;

    selectedProduct: Product | null
}

class AddProductMappingDialog extends Component<AddProductMappingDialogProps, State> {
    constructor(props: AddProductMappingDialogProps) {
        super(props);

        this.state = {
            mdb_code: '',
            code: '',
            price: '1.1',
            original_price: '1.1',
            capacity: 10,
            restock: 10,
            selectedProduct: null,
        }
    }


    handleMDBCode(event: ChangeEvent<HTMLInputElement>): void {
        event.preventDefault();

        this.setState({
            mdb_code: event.target.value
        });

    }

    handleCode(event: ChangeEvent<HTMLInputElement>): void {
        event.preventDefault();

        this.setState({
            code: event.target.value
        });

    }

    handlePrice(event: ChangeEvent<HTMLInputElement>): void {
        event.preventDefault();

        this.setState({
            price: event.target.value
        });

    }

    handleOriginalPrice(event: ChangeEvent<HTMLInputElement>): void {
        event.preventDefault();

        this.setState({
            original_price: event.target.value
        });

    }

    handleCapacity(event: ChangeEvent<HTMLInputElement>): void {
        event.preventDefault();

        this.setState({
            capacity: parseInt(event.target.value)
        });

    }

    handleRestock(event: ChangeEvent<HTMLInputElement>): void {
        event.preventDefault();

        this.setState({
            restock: parseInt(event.target.value)
        });

    }

    handleProductSelection(products: Array<Product>): void {
        if (products.length > 0) {
            this.setState({
                selectedProduct: products[0]
            }, ()=>{
                if(this.state.selectedProduct?.productType === "COMPOSITE"){
                    this.setState({capacity:999, restock:999})
                }
            });
        }
    }

    handleCancel(event: MouseEvent<HTMLButtonElement>): void {
        event.preventDefault();

        this.props.hide_dialog();
    }

    onSubmit(event: FormEvent<HTMLFormElement>): void {
        if (this.state.selectedProduct) {
            let productMapping: ProductMapping = {
                height: 0, position: 0, tray: 0, width: 0,
                code: this.state.code,
                mdb_code: this.state.mdb_code,
                price: parseFloat(this.state.price) * 100,
                original_price: parseFloat(this.state.original_price) * 100,
                capacity: this.state.capacity,
                restock: this.state.restock,
                product_id: this.state.selectedProduct.id,
                cycleCounting: false,
                is_faulty: null
            };

            this.props.onSubmit(event, productMapping);
        } else {
            // nothing, since product_id is not set.
        }
    }


    render() {
        const renderMenuItemChildren = (option: Product, props, index) => {
            return [
                <Highlighter key={"name"} search={props.text}>{option.name}</Highlighter>,
                <div key="productInfo">
                    <InlineProductPhoto product={option}/> {option.name}
                </div>,
            ];
        };

        return (
            <form onSubmit={(e) => {
                this.onSubmit(e)
            }} action="#">
                <div className="form-group">
                    <label>Code</label>

                    <span className="label-explain">&nbsp; Code represents the vending machine internal code for that particular coil, it's mostly used in DEX machines. It must be unique within a vending machine. In most cases, it's the same as MDB code.</span>

                    <input onChange={this.handleCode.bind(this)} className="form-control" type="text"
                           value={this.state.code}/>
                </div>

                <div className="form-group">
                    <label>MDB Code</label>

                    <span className="label-explain">&nbsp; MDB Code represents the vending machine internal code for that particular coil used in MDB communication, it's used in most of the machines. It must be unique within a vending machine. In most cases, it's the same as <em>Code</em>.</span>

                    <input onChange={this.handleMDBCode.bind(this)} className="form-control" type="text"
                           value={this.state.mdb_code}/>
                </div>

                <div className="form-group">
                    <label>Price</label>

                    <span className="label-explain">&nbsp; The stick price for this column. The unit is dollar.</span>

                    <input onChange={this.handlePrice.bind(this)} className="form-control" type="text"
                           value={this.state.price}/>
                </div>

                <div className="form-group">
                    <label>Original Price</label>

                    <span className="label-explain">&nbsp; The stick original price for this column. The unit is dollar.</span>

                    <input onChange={this.handleOriginalPrice.bind(this)} className="form-control" type="text"
                           value={this.state.original_price}/>
                </div>

                <div className="form-group">
                    <label>Capacity</label>
                    <span
                        className="label-explain">&nbsp; Capacity is the absolute maximum capacity for the coil.</span>

                    <input onChange={this.handleCapacity.bind(this)} className="form-control" type="text"
                           value={this.state.capacity} disabled={this.state.selectedProduct?.productType === "COMPOSITE"}/>
                </div>

                <div className="form-group">
                    <label>Restock Capacity</label>

                    <span className="label-explain">&nbsp; Restock Capacity is the target restock count for that given time. In certain circumstances, capacity can be larger than restock capacity.</span>

                    <input onChange={this.handleRestock.bind(this)} className="form-control" type="text"
                           value={this.state.restock} disabled={this.state.selectedProduct?.productType === "COMPOSITE"}/>
                </div>

                <div className="form-group">
                    <label>Product</label>

                    <span className="label-explain">&nbsp; The product for this coil. If it's a new product, please follow <Link
                        to={"/products/add"}>this link</Link> to finish product setup first and then comeback. </span>

                    <Typeahead id={"typeahead"}
                               renderMenuItemChildren={renderMenuItemChildren}
                               defaultInputValue={this.state.selectedProduct ? this.state.selectedProduct.name : ''}
                               labelKey="name"
                               onChange={this.handleProductSelection.bind(this)} options={this.props.product_list}/>
                </div>

                <div className="form-group">
                    <input type="submit" className="btn btn-success"/>
                    <button onClick={this.handleCancel.bind(this)} className="btn btn-default">Cancel</button>
                </div>
            </form>
        );
    }
}

export {AddProductMappingDialog};
